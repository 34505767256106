import {observer} from 'mobx-react';
import React from 'react';
import {Header, HeaderController} from '../Header/HeaderView';
import {lang} from '../utils/Lang';
import WellDone from '../components/WellDone';


export const ProfilingPageWellDone = observer(({controller, onGoToResult}) => {
    return (
        <React.Fragment>
            <main className="fadeIn bg-style1">
                <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                <div className="appContent">
                    <div className="appContent-body slideDown">
                        <WellDone title={lang.get('profiling.welldone.title')}
                                  subtitle={lang.get('profiling.welldone.subtitle')}
                                  content={lang.get('profiling.welldone.content')}
                                  btnTitle={lang.get('profiling.welldone.button')} onBtnClick={onGoToResult}/>
                    </div>
                </div>
            </main>
        </React.Fragment>
    );
});