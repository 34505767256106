import {lang} from '../utils/Lang';
import {observer} from 'mobx-react/index';
import React from 'react';
import Input from '../components/Inputs/Input';
import {InputSelect} from '../components/Inputs/InputSelect';
import InputCheckbox from '../components/Inputs/InputCheckbox';
import {ContentTitle} from '../components/ContentTitle';

const CompanyEditView = observer(({controller}) => {
    return (
        <React.Fragment>
            <ContentTitle title={lang.get('appheader.companyProfile.title')}
                          subtitle={lang.get('appheader.companyProfile.subtitle')}/>
            <div className="card company-edit-page">
                <div className="card-body">
                    <div className="card-header small">
                        <div className="card-header-icon">
                            <i className="far fa-building"/>
                        </div>
                        <div className="card-header-title">
                            <h2>{lang.get('registration.company.companyDetails')}</h2>
                        </div>
                    </div>
                    <div className="card-content form-layout">
                        <Input name="name"
                               placeholder={lang.get('registration.company.name')}
                               validatedValue={controller.name}/>

                        <Input name="address"
                               placeholder={lang.get('registration.company.address')}
                               validatedValue={controller.address}/>

                        <div className="input-wrap-two">
                            <Input name="zipCode"
                                   placeholder={lang.get('registration.zipCode')}
                                   validatedValue={controller.zipCode}/>
                            <Input name="city"
                                   placeholder={lang.get('registration.city')}
                                   validatedValue={controller.city}/>
                        </div>

                        <InputSelect controller={controller.countrySelectController}
                                     name="country"
                                     placeholder={lang.get('registration.country')}/>

                        <div className="input-wrap-two">
                            <Input name="vatID"
                                   placeholder={lang.get('registration.company.vatId')}
                                   validatedValue={controller.vatID}/>
                            <Input name="noOfEmployees"
                                   placeholder={lang.get('registration.company.noOfEmployees')}
                                   validatedValue={controller.noOfEmployees}
                                   type="number"
                                   maxLength={7}/>
                        </div>

                        <InputSelect controller={controller.industrySelectController}
                                     name="industry"
                                     placeholder={lang.get('registration.company.industry')}/>

                        <InputCheckbox name="ohwMembership"
                                       label={lang.get('registration.company.ohwMembership')}
                                       validatedValue={controller.ohwMembership}/>

                        {controller.industry.value === 'I' &&
                        <Input name="hogastID"
                               placeholder={lang.get('registration.company.hogastID')}
                               validatedValue={controller.hogastID}/>
                        }
                    </div>
                </div>
            </div>
            <div className="app-footer-automatic">
                <div/>
                <button id="company-register-btn" className="btn center" onClick={() => controller.submit()}>
                    <span>{lang.get('registration.submitBtn')}</span>
                    <i className="far fa-save"/>
                </button>
                <div/>
            </div>
        </React.Fragment>
    );
});

export default CompanyEditView;