import React from 'react';
import {decorate, computed, action} from 'mobx';
import {observer} from 'mobx-react';
import {InfoBox2, InfoBox2Controller} from './Inputs/InfoBox2';
import {MultiSelectionListView, SingleSelectionListController} from './MultiSelectionList';

class SelectionInfoBoxController {
    infoBoxController = new InfoBox2Controller();
    selectionController = new SingleSelectionListController();

    constructor(options, selectedValue) {
        this.setItems(options);
        this.selectByValue(selectedValue);
    }

    setItems(options) {
        this.selectionController.setItems(options);
    }

    get selectedItem() {
        return this.selectionController.selectedItem;
    }

    selectByValue(itemValue) {
        this.selectionController.selectByValue(itemValue);
    }

    async show() {
        return this.infoBoxController.show();
    }
}

decorate(SelectionInfoBoxController, {
    selectedItem: computed,
    setItems: action
});

const SelectionInfoBoxView = observer(({title, legend, controller, id}) => {
    return (
        <InfoBox2 icon={'far fa-exclamation-triangle'} title={title} hasYes={true} hasNo={true} id={id}
                  controller={controller.infoBoxController}>
            <MultiSelectionListView controller={controller.selectionController}/>
        </InfoBox2>
    );
});

export {SelectionInfoBoxView, SelectionInfoBoxController};