import React from 'react';
import {isValidEmail} from '../../utils/Validators';
import {observable, action, decorate} from 'mobx';
import {observer} from 'mobx-react';
import {lang} from '../utils/Lang';


class EmailInputStore {
    email = '';
    isEmailValid = true;

    validate() {
        this.isEmailValid = isValidEmail(this.email);
    }
}

decorate(EmailInputStore, {
    email: observable,
    isEmailValid: observable,
    validate: action
});


const EmailInput = observer(({state, name}) => (
    <div className={'form-input-wrap mb-1 fadeIn ' + (!state.isEmailValid && 'error')}>
        <input type="email" name={name} placeholder={lang.get('profilingSignUp.start.emailPlaceholder')}
               className="form-input txtCenter"
               value={state.email}
               onChange={event => state.email = event.target.value}/>
        {!state.isEmailValid &&
        <div className="errorBox txtCenter">{lang.get('validators.email.invalid')}</div>
        }
    </div>
));
export {EmailInput, EmailInputStore};