import React from 'react';
import {observer} from 'mobx-react';
import InputWrap from './InputWrap';
import {hideIfNot} from '../../../utils/Utils';

const DynamicInputCheckbox = observer(({name, label, validatedValue, disabled}) => {

    let toggle = () => {
        if (disabled === true) return;
        validatedValue.focus();
        validatedValue.valueWithTouch = !validatedValue.value;
        validatedValue.blur();
    };
    let change = (newValue) => {
        validatedValue.focus();
        validatedValue.valueWithTouch = newValue;
        validatedValue.blur();
    };

    return (
        <InputWrap id={name} validationMsg={validatedValue.displayError}>
            <div className={`input-check-box horizontal ${(validatedValue.value === true ? 'selected' : '')}`}>
                <label htmlFor={name} onClick={() => toggle()}>
                    <input type="checkbox"
                           name={name}
                           value={validatedValue.value}
                           checked={validatedValue.value}
                           onChange={(e) => change(e.target.checked)}
                           disabled={disabled}
                    />
                    <i className="far fa-check"/>
                </label>
                <span>{label}</span>
                <i className="fa fa-spinner fa-spin save-icon" style={hideIfNot(validatedValue.isBeingChanged)}/>
                <i className="fa fa-check save-icon" style={hideIfNot(validatedValue.isSaved)}/>
            </div>
        </InputWrap>
    );
});

export default DynamicInputCheckbox;