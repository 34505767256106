import React, {Component} from 'react';
import {BarChart} from './BarChart';
import {hideIfNot, roundSpirit} from '../../utils/Utils';
import {Header, HeaderController} from '../Header/HeaderView';
import {action, decorate, observable, runInAction} from 'mobx';
import {observer} from 'mobx-react';
import {lang} from '../utils/Lang';
import {calculateMovingAverage, calculateTendency, ReportFilter, ReportService} from '../../domain/ReportService';
import {ReportCardItem} from './ReportCardItem';
import SolutingCollectionStore from '../../domain/Soluting/SolutingCollectionStore';
import SolutingListGroup from '../Soluting/SolutingList/SolutingListGroup';
import {ContentTitle, Subtitle} from '../components/ContentTitle';
import LoadingModal from '../components/LoadingModal';
import {LicensedAction, Role} from '../../domain/Role';
import {InfoBox2, InfoBox2Controller} from '../components/Inputs/InfoBox2';
import {ReportScore} from './ReportScore';
import {ButtonSelect, InputSelect, InputSelectController} from '../components/Inputs/InputSelect';
import ValidatedValue from '../components/Inputs/ValidatedValue';
import {TeamPath} from '../../domain/Team/Team';
import {LockedIcon} from '../components/LockedIcon';
import {LicenseLock} from '../components/LicenseLock';

class ReportViewPageController {

    movingAvgsOptions = [
        {
            'text': lang.get('report.filterByMovingAvgs.zero'),
            'value': 1
        },
        {
            'text': lang.get('report.filterByMovingAvgs.3weeks'),
            'value': 3
        },
        {
            'text': lang.get('report.filterByMovingAvgs.6weeks'),
            'value': 6
        },
        {
            'text': lang.get('report.filterByMovingAvgs.12weeks'),
            'value': 12
        }
    ];


    isLoading = false;
    infoBoxController = new InfoBox2Controller();
    lastReportNotAvailableInfoBox = new InfoBox2Controller();
    barChartInfoBoxController = new InfoBox2Controller();
    participationChartInfoBoxController = new InfoBox2Controller();
    infoBoxTitle = '';
    infoBoxContent = '';

    contentTitleContent = '';

    solutingCollectionStore = new SolutingCollectionStore();
    teamFilterController = new InputSelectController([], new ValidatedValue([], null), true);

    movingAvgsController = new InputSelectController(this.movingAvgsOptions, new ValidatedValue([], 6), false);


    reportData = {
        title: 'default',
        spirit: null,
        lastSurveys: [],
        participation: [],
        subSpirit: {},
        other: {}
    };

    report = {
        title: 'default',
        spirit: null,
        lastSurveys: [],
        participation: [],
        subSpirit: {},
        other: {}
    };
    reportFilter = null;

    reportName = '';
    solutingGroups = [];


    constructor(navigator, userStore, categoryId, reportFilter) {
        this.navigator = navigator;
        this.userStore = userStore;
        this._reportService = new ReportService();
        this.categoryId = categoryId;

        this.reportFilter = reportFilter;
        if (!this.reportFilter) this.reportFilter = new ReportFilter(null, null);

        if (this.reportFilter.movingAverageCount !== null) {
            this.movingAvgsController.validatedValue.value = this.reportFilter.movingAverageCount;
        }


        let name = categoryId;
        if (!name || name === 'default') {
            name = 'doodsSpirit';
        }
        this.contentTitleContent = lang.get(`report.infobox.${name}.content`);

        this.load(categoryId, this.reportFilter);

        this.teamFilterController.validatedValue.onFinalChange = (oldValue, newValue, isValid) => {
            let teamPath = null;
            if (newValue !== null) teamPath = new TeamPath(newValue);

            this.filterReportByGroup(teamPath);
        };

        this.movingAvgsController.validatedValue.onFinalChange = (oldValue, newValue, isValid) => {
            this.reportFilter.movingAverageCount = newValue;
            this.recalculateReport();
            this.navigator.pushStateWithoutPageChange(this.navigator.siteMap.pages.ReportWithIdAndFilter, (this.categoryId ? this.categoryId : 'default'), this.reportFilter);
        };
    }

    openInfoBoxCategory(name) {
        this.showInfoBox(lang.get(`report.infobox.${name}.title`), lang.get(`report.infobox.${name}.content`));
    }

    openInfoBoxOther(name) {
        this.showInfoBox(lang.get(`report.infobox.${name}.title`), lang.get(`report.infobox.${name}.content`));
    }

    async openInfoBoxFilter() {
        let result = await this.reportFilterInfoBoxController.show();
        if (result === 'yes') {
            this.filterReportByGroup(this.reportFilterInfoBoxController.selectedGroupPath);
        }
    };

    filterReportByGroup(teamPath) {
        this.reportFilter.teamPath = teamPath;
        this.goTo(this.categoryId);
    }

    async recalculateReport() {
        let movingAverageWindow = this.movingAvgsController.validatedValue.value;
        if (movingAverageWindow === null) movingAverageWindow = 6;

        this.report = {};
        this.report.title = this.reportData.title;
        this.report.lastSurveys = calculateMovingAverage(this.reportData.lastSurveys, movingAverageWindow);
        this.report.spirit = null;
        if (this.report.lastSurveys.length > 0) this.report.spirit = this.report.lastSurveys[0].value;
        this.report.participation = calculateMovingAverage(this.reportData.participation, movingAverageWindow);
        this.report.subSpirit = {};
        this.report.other = {};
        Object.keys(this.reportData.subSpirit).forEach(subId => {
            this.report.subSpirit[subId] = calculateMovingAverage(this.reportData.subSpirit[subId], movingAverageWindow);
        });
        Object.keys(this.reportData.other).forEach(key => {
            this.report.other[key] = calculateMovingAverage(this.reportData.other[key], movingAverageWindow);
        });

        this.loadSolutings()
    }

    async load(categoryName, reportFilter) {
        this.isLoading = true;
        try {

            if (!categoryName || categoryName === 'default') {
                this.reportName = 'default';
            } else if (categoryName.split('.').length === 1) {
                // category report
                this.reportName = categoryName;

            } else if (categoryName.split('.').length === 2) {
                // sub category report
                this.reportName = categoryName;
            }
            let reportData = await this._reportService.getReport(this.reportName, reportFilter);
            runInAction(() => {
                this.reportData = reportData;
                this.recalculateReport();

                // if last week is not shown, show user message about this
                if (this.report.lastSurveys.length > 0 && this.report.lastSurveys[0].value === null)
                    this.lastReportNotAvailableInfoBox.show();

                this.isLoading = false;
            });

            //find teams that we can filter on
            let availableTeamsForFiltering = await this.userStore.currentCompanyUser.getTeamsForAction(Role.ReadReportForTeam);
            let options = availableTeamsForFiltering.map(it => {
                // value: TeamPath object, text: text
                return {value: it.path.asString, text: it.name};
            });
            this.teamFilterController.setItems(options);
            this.teamFilterController.singleSelectController.items[0].text = lang.get('report.filterByTeams.all');

            if (this.reportFilter.teamPath)
                this.teamFilterController.validatedValue.value = this.reportFilter.teamPath.asString;

            this.loadSolutings();
        } catch (e) {
            console.error(`error loading report ${categoryName}`, e);
            this.isLoading = false;
        }
    }

    async loadSolutings() {
        if (this.reportName !== '') {
            this.solutingCollectionStore.loadByCategory(this.reportName, this.movingAvgsController.validatedValue.value)
                .then(() => {
                    this.solutingGroups = this.solutingCollectionStore.groups;
                })
                .catch((e) => console.error(`error loading solutings ${this.reportName}`, e));
        }
    }


    goTo(categoryName) {
        if (categoryName) this.navigator.goToPage(this.navigator.siteMap.pages.ReportWithIdAndFilter, categoryName, this.reportFilter);
        else this.navigator.goToPage(this.navigator.siteMap.pages.ReportWithIdAndFilter, 'default', this.reportFilter);
    }

    goBack() {
        this.navigator.back();
    }

    goToSolutings() {
        this.navigator.goToPage(this.navigator.siteMap.pages.SolutingList);
    }

    showInfoBox(title, content) {
        this.infoBoxController.show();
        this.infoBoxTitle = title;
        this.infoBoxContent = content;
    }
}

decorate(ReportViewPageController, {
    isLoading: observable,
    report: observable.shallow,
    isCategoryReport: observable,
    infoBoxTitle: observable,
    infoBoxContent: observable,
    reportName: observable,
    solutingGroups: observable,
    load: action,
    goBack: action,
    goTo: action,
    movingAvgsController: observable
});

const ReportPageView = observer(class ReportView extends Component {
    createSolutingListGroup(group) {
        return <SolutingListGroup key={group.name} name={group.name} items={group.items}
                                  navigator={this.props.controller.navigator} hideTitle={true}/>
    }

    getCardsByCategory(report, controller) {
        return Object.keys(report.subSpirit)
            .map(key => {
                let weekValues = report.subSpirit[key];
                let value = null;
                let tendency = null;
                if (weekValues.length > 0 && weekValues[0].value !== null) {
                    value = weekValues[0].value;
                    tendency = calculateTendency(weekValues);
                }
                let langPath = key;
                let goToDetails = () => controller.goTo(key);
                return <ReportCardItem key={key}
                                       title={lang.get(`report.${langPath}.title`)}
                                       value={value}
                                       tendency={tendency}
                                       openInfoBox={() => controller.openInfoBoxCategory(langPath)}
                                       goToDetails={goToDetails}/>;
            });
    }

    getOtherCards(report, controller) {
        return Object.keys(report.other)
            .map(key => {
                let weekValues = report.other[key];
                let value = null;
                let tendency = null;
                if (weekValues.length > 0 && weekValues[0].value !== null) {
                    value = weekValues[0].value;
                    tendency = calculateTendency(weekValues);
                }
                return <ReportCardItem key={key}
                                       title={lang.get(`report.${key}.title`)}
                                       value={value}
                                       tendency={tendency}
                                       openInfoBox={() => controller.openInfoBoxOther(key)}
                                       lightVersion={true}/>;
            });
    }

    render() {
        let controller = this.props.controller;
        let report = controller.report;

        let subcategoriesLocked = !controller.userStore.isLicensedFor(LicensedAction.ReportViewSubCategory);
        let filterByTeamLocked = !controller.userStore.isLicensedFor(LicensedAction.ReportViewFilteredByTeam);

        let cardsByCategory = this.getCardsByCategory(report, controller);
        let otherCards = this.getOtherCards(report, controller);

        let mainTitle = lang.get('report.doodsspirit.title');
        if (report.title !== 'default') {
            mainTitle = lang.get(`report.${report.title}.title`);
        }

        let vsLastWeek = null;
        if (report.lastSurveys.length > 1 && report.lastSurveys[0].value !== null && report.lastSurveys[1].value !== null) {
            vsLastWeek = roundSpirit(report.lastSurveys[0].value) - roundSpirit(report.lastSurveys[1].value);
        }

        let subTitle = (
            <ul className="breadcrumb">
                <li><a href="#/" onClick={(e) => {
                    e.preventDefault();
                    controller.goTo(null);
                }}><h3>{lang.get('report.doodsspirit.title')}</h3></a></li>
                {report.title !== 'default' &&
                <li><a href="#/" onClick={(e) => {
                    e.preventDefault();
                    controller.goTo(report.title.split('.')[0])
                }}><h3>{lang.get(`report.${report.title.split('.')[0]}.title`)}</h3></a></li>
                }
                {report.title.split('.').length === 2 &&
                <li><h3>{lang.get(`report.${report.title}.title`)}</h3></li>
                }
            </ul>);

        let reportData = report.lastSurveys.slice().reverse();
        let participationData = report.participation.slice().reverse().map(it => {
            return {...it, value: it.value * 100};
        });

        let goToSolutings = () => controller.goToSolutings();
        let solutings = controller.solutingGroups.map((group) => {
            return this.createSolutingListGroup(group);
        });

        let subcategoriesLockedClass = '';
        if (subcategoriesLocked === true) subcategoriesLockedClass = 'license-lock-active';

        return (
            <main className="">
                <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                <InfoBox2 icon={'far fa-exclamation-triangle'} title={controller.infoBoxTitle}
                          hasClose={true}
                          controller={controller.infoBoxController}>
                    <p dangerouslySetInnerHTML={{__html: controller.infoBoxContent}}/>
                </InfoBox2>

                <InfoBox2 icon={'far fa-exclamation-triangle'}
                          hasClose={true}
                          controller={controller.lastReportNotAvailableInfoBox}
                          title={lang.get('report.lastReportUnavailable.title')}>
                    {lang.getDangerous('report.lastReportUnavailable.content')}
                </InfoBox2>

                <InfoBox2 icon={'far fa-exclamation-triangle'}
                          hasClose={true}
                          controller={controller.barChartInfoBoxController}
                          title={lang.get('report.graph.infoBox-spirit.title')}>
                    {lang.getDangerous('report.graph.infoBox-spirit.content')}
                </InfoBox2>
                <InfoBox2 icon={'far fa-exclamation-triangle'}
                          hasClose={true}
                          controller={controller.participationChartInfoBoxController}
                          title={lang.get('report.graph.infoBox-participation.title')}>
                    {lang.getDangerous('report.graph.infoBox-participation.content')}
                </InfoBox2>

                <div className="appContent">
                    <div className="appContent-body fadeIn report-page">
                        <LoadingModal isVisible={controller.isLoading}/>

                        {/* doods spirit header */}
                        <div className="header">
                            <div className="background-color-pine-green"/>
                            <div className="background-color-dark-slate-blue"/>
                            <ContentTitle title={mainTitle} info={controller.contentTitleContent}>
                                <Subtitle>
                                    {subTitle}
                                </Subtitle>
                            </ContentTitle>
                            {/* big spirit icon */}
                            <ReportScore spirit={report.spirit}
                                         spiritDifference={vsLastWeek}>
                                <LicenseLock isLocked={filterByTeamLocked} navigator={controller.navigator}>
                                    <ButtonSelect controller={controller.teamFilterController}
                                                  name={'input-select-team-filter-small'}
                                                  blue
                                                  isLicenseLocked={false}
                                                  placeholder={lang.get('report.filterByTeamsBtn')}/>
                                </LicenseLock>
                            </ReportScore>

                            <div className={`tabs license-lock-wrapper ${subcategoriesLockedClass}`}>
                                <div
                                    className={`tab tab-default ${(controller.reportName === 'default' ? 'active' : '')}`}
                                    onClick={() => {
                                        controller.goTo('default')
                                    }}>{lang.get('report.doodsspirit.title')}</div>
                                <div
                                    className={`tab license-lock-opacity tab-growth ${(controller.reportName === 'growth' ? 'active' : '')}`}
                                    onClick={() => {
                                        controller.goTo('growth')
                                    }}>{lang.get('report.growth.title')}</div>
                                <div
                                    className={`tab license-lock-opacity tab-culture ${(controller.reportName === 'culture' ? 'active' : '')}`}
                                    onClick={() => {
                                        controller.goTo('culture')
                                    }}>{lang.get('report.culture.title')}</div>
                                <div
                                    className={`tab license-lock-opacity tab-relationship ${(controller.reportName === 'relationship' ? 'active' : '')}`}
                                    onClick={() => {
                                        controller.goTo('relationship')
                                    }}>{lang.get('report.relationship.title')}</div>
                                <div className={`tab license-lock-opacity tab-facts ${(controller.reportName === 'facts' ? 'active' : '')}`}
                                     onClick={() => {
                                         controller.goTo('facts')
                                     }}>{lang.get('report.facts.title')}</div>
                                {subcategoriesLocked && <LockedIcon/>}
                            </div>
                            <div className="filter-row">
                                <div>
                                    <div className="value-with-tendency">
                                        <i className="fa fa-caret-up" style={hideIfNot(vsLastWeek > 0)}/>
                                        <i className="fa fa-caret-down" style={hideIfNot(vsLastWeek < 0)}/>
                                        <span>{roundSpirit(vsLastWeek)}</span>
                                    </div>
                                    <h4>{lang.get('report.vsLastWeek')}
                                        <button className="btn none"><i className="far fa-question-circle"/></button>
                                    </h4>
                                </div>
                                <LicenseLock isLocked={filterByTeamLocked} navigator={controller.navigator}>
                                    <InputSelect controller={controller.teamFilterController}
                                                 name={'input-select-team-filter-big'}
                                                 blue
                                                 licenseLock={filterByTeamLocked}
                                                 placeholder={lang.get('report.filterByTeamsBtn')}/>
                                </LicenseLock>
                            </div>
                        </div>


                        <div className="card report-graph-card no-lines">
                            <div className="card-body">
                                <BarChart data={reportData}
                                          onInfoIconClicked={() => controller.barChartInfoBoxController.show()}
                                          movingAvgsFilter={controller.movingAvgsController}
                                          maxYValue={10}
                                          title={mainTitle}
                                          graphKey={mainTitle}/>
                            </div>
                        </div>


                        {/* categories */}
                        <div className="button-row" style={hideIfNot(cardsByCategory.length > 0)}>
                            <h3>{lang.get('report.byCategory.header')}</h3>
                            <div className="horizontal-line m-left"/>
                        </div>

                        <LicenseLock isLocked={subcategoriesLocked} showText={true} navigator={controller.navigator}>
                            <div id="report-category-list" className="category-list" style={hideIfNot(cardsByCategory.length > 0)}>
                                {cardsByCategory}
                            </div>
                        </LicenseLock>

                        {/* other metrics */}
                        <div className="button-row" style={hideIfNot(otherCards.length > 0)}>
                            <h3>{lang.get('report.otherMetrics.header')}</h3>
                            <div className="horizontal-line m-left"/>
                        </div>

                        <div className="category-list" style={hideIfNot(otherCards.length > 0)}>
                            {otherCards}
                        </div>

                        <div className="card report-graph-card mt-2 no-lines" style={hideIfNot(participationData.length > 0)}>
                            <div className="card-body">
                                <BarChart data={participationData}
                                          onInfoIconClicked={() => controller.participationChartInfoBoxController.show()}
                                          maxYValue={100}
                                          title={lang.get('report.participation.title')}/>
                            </div>
                        </div>

                        {/* solutings */}
                        <div className="button-row" style={hideIfNot(solutings.length > 0)}>
                            <h3>{lang.get('report.solutings.recommended')}</h3>
                            <div className="horizontal-line m-left"/>
                            <button className="btn right" onClick={goToSolutings}>
                                <span>{lang.get('report.solutings.viewAll')}</span> <i className="far fa-layer-plus"/>
                            </button>
                        </div>
                        <div className="soluting-list" style={hideIfNot(solutings.length > 0)}>
                            {solutings}
                        </div>

                    </div>
                </div>
                {/*<ListFooter onBack={goBack} iconBack={Theme.icons.iconsArrowLeft} backTitle={lang.get('report.button.back')}/>*/}
            </main>
        );
    }
});

export {ReportPageView, ReportViewPageController};