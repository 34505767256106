import {axios} from '../../utils/WrappedAxios';
import {decorate, observable} from 'mobx';

export default class Notification {
    constructor(id, isRead, createInstant, payload) {
        this.id = id;
        this.isRead = isRead;
        this.createInstant = createInstant;
        this.payload = payload;
    }

    async markAsRead() {
        if (this.isRead === true)
            return;
        await axios.put(`/api/notification/${this.id}/read`);
        this.isRead = true;
    }
};

decorate(Notification, {
    isRead: observable
});