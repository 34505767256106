import React from 'react';
import {decorate, observable, action} from 'mobx';
import {observer} from 'mobx-react';
import {notificationManager} from '../components/Notifications';
import {lang} from '../utils/Lang';
import UserService from '../../services/UserService';
import UserCollection from '../../domain/User/UserCollection';
import {ProfilingPageStepView, ProfilingPageStepController} from './ProfilingPageStepView';
import {ProfilingPageResultView} from './ProfilingPageResultView';
import {ProfilingResultController} from '../ProfilingResult/ProfilingResultPage';
import {ProfilingPageWellDone} from './ProfilingPageWellDone';
import {ModalRecaptcha, ModalRecaptchaStore} from '../components/ModalRecaptcha';


class ProfilingController {
    userService = new UserService();
    userStore;
    profilingStepController;
    profilingResultController;
    token = '';
    showPage = null;
    repeatButNotLoggedIn = false;
    userCollection = new UserCollection();
    email = null;
    recaptchaModal = new ModalRecaptchaStore();

    constructor(navigator, userStore, email) {
        this.navigator = navigator;
        this.userStore = userStore;
        this.email = email;
        this.goToProfilingSteps();
    }

    goToProfilingSteps() {
        this.profilingStepController = new ProfilingPageStepController(this.navigator, this.userStore, () => this.onProfilingStepsFinished());
        this.showPage = 'steps';
    }

    repeatProfiling() {
        if (!this.userStore.isLoggedIn && !this.userStore.isRegistered) {
            this.repeatButNotLoggedIn = true;
        }
        this.goToProfilingSteps();
    }

    async onProfilingStepsFinished() {
        if ((this.userStore.isLoggedIn && this.userStore.isRegistered) || this.repeatButNotLoggedIn) {
            await this.userService.updateToken(
                this.profilingStepController.token,
                this.repeatButNotLoggedIn ? this.email : this.userStore.email
            );
            notificationManager.info(lang.get('notification.profilingResultUpdated'));
            window.facebookWrapper.track("User repeated profiling", { email: this.email });
            this.goToResult(this.profilingStepController.token);
        } else {
            let recaptchaToken = await this.recaptchaModal.showAsync();
            await this.userCollection.createUser(
                this.email,
                lang.language,
                this.profilingStepController.token,
                recaptchaToken
            );
            notificationManager.infoNoDismiss(lang.get('notification.gainFullAccess'));
            window.facebookWrapper.track("User finished profiling", { email: this.email });
            this.goToWellDone();
        }
    }

    goToWellDone() {
        this.showPage = 'welldone';
    }

    goToResult(token) {
        this.profilingResultController = new ProfilingResultController(this.navigator, this.userStore, token);
        this.showPage = 'result';
    }
}

decorate(ProfilingController, {
    signUpController: observable,
    token: observable,
    showPage: observable,
    goToSignUp: action,
    goToProfilingSteps: action,
    goToResult: action,
    repeatButNotLoggedIn: observable,
});

const ProfilingPage = observer(({controller}) => {
    let currentPage;
    switch (controller.showPage) {
        case 'steps':
            currentPage = <ProfilingPageStepView controller={controller.profilingStepController}/>;
            break;
        case 'welldone':
            currentPage = <ProfilingPageWellDone controller={controller.profilingStepController}
                                                 onGoToResult={() => controller.goToResult(controller.profilingStepController.token)}/>;
            break;
        case 'result':
            currentPage = <ProfilingPageResultView controller={controller.profilingStepController}
                                                   onRepeat={() => controller.repeatProfiling()}/>;
            break;
        default:
            currentPage = <div/>;
            break;
    }

    return (
        <React.Fragment>
            <ModalRecaptcha controller={controller.recaptchaModal}/>
            {currentPage}
        </React.Fragment>
    )
});

export {ProfilingPage, ProfilingController};