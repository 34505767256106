import React from 'react';
import {lang} from '../utils/Lang';

const LoadingModal = ({isVisible}) => {
    return (
        <React.Fragment>
            {isVisible &&
            <div className="loading-modal">
                <span>{lang.get('loading.text')}</span>
            </div>
            }
        </React.Fragment>
    );
};

export default LoadingModal;