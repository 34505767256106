import React from 'react';
import {decorate, observable} from 'mobx';
import {observer} from 'mobx-react';
import {EmailInput, EmailInputStore} from './EmailInput';
import {ModalRecaptcha, ModalRecaptchaStore} from './ModalRecaptcha';
import {notificationManager} from './Notifications';
import {lang} from '../utils/Lang';
import UserCollection from '../../domain/User/UserCollection';


class LoginRecaptchaFormController {
    userCollection = new UserCollection();
    emailStore = new EmailInputStore();
    modalRecaptchaStore = new ModalRecaptchaStore();
    checkIsEmailRegistered = false;


    constructor(navigator, onRecaptchaVerified, checkIsEmailRegistered) {
        this.navigator = navigator;
        this.checkIsEmailRegistered = checkIsEmailRegistered;
        this.onRecaptchaVerified = onRecaptchaVerified;
    }

    async showRecaptcha() {
        let token = await this.modalRecaptchaStore.showAsync();
        this.onRecaptchaVerified(this.emailStore.email, token);
    }

    submit(event) {
        event.preventDefault();

        this.emailStore.validate();
        if (!this.emailStore.isEmailValid) return;

        if (this.checkIsEmailRegistered) {
            this.userCollection.isEmailRegistered(this.emailStore.email)
                .then(isEmailRegistered => {
                    if (isEmailRegistered) {
                        notificationManager.info(lang.get('profilingSignUp.start.email.exists'));
                    } else {
                        this.showRecaptcha();
                    }
                });
        } else {
            this.showRecaptcha();
        }
    }
}

decorate(LoginRecaptchaFormController, {
    emailStore: observable,
    showRecaptcha: observable,
});

const LoginRecaptchaForm = observer(({controller, children}) => (
    <React.Fragment>
        <ModalRecaptcha controller={controller.modalRecaptchaStore}/>
        <form onSubmit={(e) => controller.submit(e)}>
            <EmailInput state={controller.emailStore} name={'email'}/>
            {children}
        </form>
    </React.Fragment>
));

export {LoginRecaptchaForm, LoginRecaptchaFormController};