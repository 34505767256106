import React, {Component} from 'react';
import {action, decorate, observable} from 'mobx';
import {observer} from 'mobx-react';
import FeedbackCollection from '../../domain/Feedback/FeedbackCollection';
import {ListFilterController, ListFilterView} from '../components/ListFilterView';
import FeedbackListView from './FeedbackList/FeedbackListView';
import moment from 'moment';
import {lang} from '../utils/Lang';
import {Header, HeaderController} from '../Header/HeaderView';
import MediaQuery from '../utils/MediaQuery';
import {ContentTitle} from '../components/ContentTitle';
import ValidatedValue from "../components/Inputs/ValidatedValue";
import {notificationManager} from "../components/Notifications";
import UserCollection from "../../domain/User/UserCollection";
import {CompanyUser} from "../../domain/User/CompanyUser";

class FeedbackListPageController {

    filterDate = {
        id: 1,
        name: 'filterDate',
        placeholder: lang.get('feedbackFilter.date.title'),
        validatedValue: new ValidatedValue([], null),
        options: [
            {
                'text': lang.get('feedbackFilter.none'),
                'value': null
            },
            {
                'text': lang.get('feedbackFilter.date.lastWeek'),
                'value': '1w'
            },
            {
                'text': lang.get('feedbackFilter.date.last2Weeks'),
                'value': '2w'
            },
            {
                'text': lang.get('feedbackFilter.date.lastMonth'),
                'value': '1M'
            },
            {
                'text': lang.get('feedbackFilter.date.last3Months'),
                'value': '3M'
            }
        ]
    };
    filterSubGroup = {
        id: 2,
        name: 'filterSubGroup',
        placeholder: lang.get('feedbackFilter.subcategory.title'),
        validatedValue: new ValidatedValue([], null),
        options: [
            {
                text: lang.get('feedbackFilter.none'),
                value: null
            }
        ]
    };
    filterTone = {
        id: 3,
        name: 'filterTone',
        placeholder: lang.get('feedbackFilter.tone.title'),
        validatedValue: new ValidatedValue([], null),
        options: [
            {
                'text': lang.get('feedbackFilter.none'),
                'value': null
            },
            {
                'text': lang.get('feedbackFilter.tone.positive'),
                'value': 1
            },
            {
                'text': lang.get('feedbackFilter.tone.negative'),
                'value': 2
            }
        ]
    };
    filterLabel = {
        id: 4,
        name: 'filterLabel',
        placeholder: lang.get('feedbackFilter.label.title'),
        validatedValue: new ValidatedValue([], null),
        options: [
            {
                'text': lang.get('feedbackFilter.none'),
                'value': null
            },
            {
                'text': lang.get('feedback.label.important'),
                'value': 1
            },
            {
                'text': lang.get('feedback.label.idea'),
                'value': 2
            },
            {
                'text': lang.get('feedback.label.followup'),
                'value': 3
            }
        ]
    };
    filterStatus = {
        id: 5,
        name: 'filterStatus',
        placeholder: lang.get('feedbackFilter.status.title'),
        validatedValue: new ValidatedValue([], null),
        options: [
            {
                'text': lang.get('feedbackFilter.none'),
                'value': null
            },
            {
                'text': lang.get('feedback.status.active'),
                'value': 1
            },
            {
                'text': lang.get('feedback.status.archived'),
                'value': 2
            },
            {
                'text': lang.get('feedback.status.unread'),
                'value': 3
            },
            {
                'text': lang.get('feedback.status.read'),
                'value': 4
            },
        ]
    };

    filters = [this.filterStatus, this.filterDate, this.filterSubGroup, this.filterTone, this.filterLabel];


    userStore;
    navigator;
    isLoading = false;
    isFilterVisible = false;
    filterController;
    filterCount = 0;
    feedbackStore = new FeedbackCollection();

    /**
     * id / name / selectedOption {id / label / value}
     * @type {Array}
     */
    constructor(navigator, userStore) {
        this.userStore = userStore;
        this.navigator = navigator;

        this.filterController = new ListFilterController(
            () => {
                // on close
                if (MediaQuery.isMobile()) // only close filter automatically if on mobile
                    this.closeFilter();
            },
            filterEventData => {
                // on apply
                if (MediaQuery.isMobile()) // only close filter automatically if on mobile
                    this.closeFilter();
                this.applyFilter(filterEventData);
            },
            filterEventData => {
                // on change
                this.feedbackStore.getCountForFilter(this._getFilter(filterEventData))
                    .then(count => {
                        this.filterCount = count;
                    });
            },
            this.filters);
        this.applyFilter([]).then();

        this.showNotificationsOfPossibleMissingFeedbacks().then();
    }

    async showNotificationsOfPossibleMissingFeedbacks() {
        const companyUser = new CompanyUser(this.userStore.companyUserId);
        const userCollection = new UserCollection();
        if (await companyUser.getIsCompanyAdmin()) {
            if ((await userCollection.getCompanyUsers(null, null, null)).length < 3) {
                notificationManager.info(lang.get('feedbackList.companyHidden'))
            }
        } else {
            const adminTeams = await companyUser.getAdminTeams();
            const isTeamAdmin = adminTeams.length > 0;
            if (isTeamAdmin) {
                for (let i = 0; i < adminTeams.length; i++) {
                    const teamId = adminTeams[i].id;
                    const teamName = adminTeams[i].name;
                    const userCountInTeam = (await userCollection.getCompanyUsers(null, null, teamId)).length;
                    if (userCountInTeam < 3) {
                        notificationManager.info(lang.get('feedbackList.teamsHidden').replace('$teamName', teamName))
                    }
                }
            }
        }
    }

    openFilter() {
        this.filterCount = this.feedbackStore.feedbackList.length;
        this.isFilterVisible = true;
    }

    closeFilter() {
        this.isFilterVisible = false;
    }

    toggleFilter() {
        if (this.isFilterVisible) this.closeFilter();
        else this.openFilter();
    }

    static getFilterValueIfAvailable(filterEventData, filterName) {
        for (let i = 0; i < filterEventData.length; i++) {
            let item = filterEventData[i];
            if (item.name === filterName)
                return item.selectedOption.value;
        }
        return null;
    }

    _getFilter(filterEventData) {
        // date is specific since it's value is indication of what we have to subtract from now
        let dateFilterValue = FeedbackListPageController.getFilterValueIfAvailable(filterEventData, 'filterDate');
        let dateValue = null;
        if (dateFilterValue)
            dateValue = moment().subtract(dateFilterValue[0], dateFilterValue[1]).format();

        let statusFilterValue = FeedbackListPageController.getFilterValueIfAvailable(filterEventData, 'filterStatus');
        let statusValue = null;
        let isReadValue = null;
        if(statusFilterValue){
            switch(statusFilterValue){
                case 1:
                case 2:{
                    statusValue = statusFilterValue;
                    isReadValue = null;
                    break;
                }
                case 3:
                case 4:{
                    statusValue = null;
                    isReadValue = statusFilterValue === 4;
                    break;
                }
                default:{
                    break;
                }
            }
        }

        return {
            status: statusValue,
            date: dateValue,
            subGroup: FeedbackListPageController.getFilterValueIfAvailable(filterEventData, 'filterSubGroup'),
            tone: FeedbackListPageController.getFilterValueIfAvailable(filterEventData, 'filterTone'),
            label: FeedbackListPageController.getFilterValueIfAvailable(filterEventData, 'filterLabel'),
            isRead: isReadValue
        };
    }

    /**
     * Applies filter based on filterEventData that we get from ListFilterController
     *
     * @param {Array} filterEventData - [{id, name, selectedOption: {id, label, value}}]
     */
    async applyFilter(filterEventData) {
        this.feedbackStore.clear();
        this.isLoading = true;

        let filter = this._getFilter(filterEventData);

        try {
            await this.feedbackStore.loadWithFilter(filter);
        } finally {
            this.isLoading = false;
            this.filterCount = this.feedbackStore.feedbackList.length;
        }
    }

    goToFeedback(feedbackId) {
        this.navigator.goToPage(this.navigator.siteMap.pages.Feedback, feedbackId);
    }
}

decorate(FeedbackListPageController, {
    isLoading: observable,
    isFilterVisible: observable,
    filterCount: observable,
    openFilter: action,
    closeFilter: action,
    toggleFilter: action,
    resetFilter: action,
    applyFilter: action
});

const FeedbackListPageView = observer(class FeedbackListPageView extends Component {
    render() {
        let controller = this.props.controller;

        let filterPage = <ListFilterView controller={controller.filterController}/>;
        let listPage = <FeedbackListView onFilterOpen={() => controller.openFilter()}
                                         feedbackList={controller.feedbackStore.feedbackList}
                                         goToFeedback={(id) => controller.navigator.goToPage(controller.navigator.siteMap.pages.Feedback, id)}/>;


        let searchResult = lang.get('feedbackList.countText').replace('{}', controller.filterCount);

        return (
            <React.Fragment>
                <main className="fadeIn feedback-list">
                    <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                    <div className='appContent'>
                        <div className={'appContent-body'}>
                            <ContentTitle title={lang.get('appheader.feedback.title')}
                                          subtitle={lang.get('appheader.feedback.subtitle')}/>
                            <div className="button-row">
                                <p>{searchResult}</p>
                                <div className="horizontal-line m-left"/>
                                <button className="btn" id="toggle-filters-btn" name="toggle" onClick={() => controller.toggleFilter()}><i
                                    className="far fa-sliders-h"/><span>{lang.get('feedbackList.filterBtn')}</span>
                                </button>
                            </div>
                            {controller.isFilterVisible && filterPage}
                            {listPage}
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
});

export {FeedbackListPageController, FeedbackListPageView};