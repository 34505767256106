import vanillaAxios from 'axios';
import { notificationManager } from '../page/components/Notifications';
import {lang} from '../page/utils/Lang';


let axios = vanillaAxios.create();

const addInterceptor = (navigator, userStore) => {
    axios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error.response.status === 403) {
                notificationManager.info(lang.get('notification.error.default_403'));
                userStore.logout()
                    .then(() => {
                        navigator.goToPage(this.navigator.siteMap.pages.Login);
                    });
            } else if (error.response.status === 401) {
                notificationManager.info(lang.get('notification.error.default_401'));
            } else if (error.response.status === 402) {
                notificationManager.info(lang.get('notification.error.default_402'))
            } else if (error.response.status === 500) {
                notificationManager.info(lang.get('notification.error.default_500'));
            } else if (error.response.status === 400) {
                if(error.response.headers["error-text"]){
                    notificationManager.info(lang.get("notification.error." + error.response.headers["error-text"]));
                }else{
                    notificationManager.info(lang.get('notification.error.default_400'));
                }
            }
            throw error;
        }
    );
};

const setJWT = (jwtToken) => {
    if (jwtToken)
        axios.defaults.headers.common['Authorization'] = jwtToken;
    else
        delete axios.defaults.headers.common['Authorization'];
};

export {axios, addInterceptor, setJWT};
