import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {decorate, observable} from 'mobx';
import {Header, HeaderController} from '../../Header/HeaderView';
import {UserListController, UserListView} from './UserListView';
import {lang} from '../../utils/Lang';
import iconAddUser from '../../../assets/img/icons-tm-add-user.svg';
import UserCollection from '../../../domain/User/UserCollection';
import InviteCollection from '../../../domain/Invite/InviteCollection';
import {InvitationBox, InvitationBoxController} from '../../components/InvitationBox';
import {InfoBox2, InfoBox2Controller} from '../../components/Inputs/InfoBox2';
import {UserFilter, UserFilterController, UserFilterView} from './UserFilterView';
import {hideIfNot} from '../../../utils/Utils';
import {ContentTitle} from '../../components/ContentTitle';
import {CompanyUser} from '../../../domain/User/CompanyUser';

class UserListPageController {
    userListController = new UserListController();

    filterController = new UserFilterController();
    showFilter = false;

    invitationLink = '';
    inviteUsersInfoBoxController = new InfoBox2Controller();
    removeUserFromCompanyInfoBoxController = new InfoBox2Controller();
    deactivateUserInfoBoxController = new InfoBox2Controller();

    constructor(navigator, userStore) {
        this.navigator = navigator;
        this.userStore = userStore;

        this.filterController.onChange = (userFilter) => {
            this.load(userFilter);
        };
        this.filterController.onCancel = () => this.showFilter = false;


        this.userListController.setup([],
            () => this.inviteUsers(),
            (user) => this.navigator.goToPage(this.navigator.siteMap.pages.ViewUser, user.id),
            (user) => this.navigator.goToPage(this.navigator.siteMap.pages.EditUser, user.id),
            (user) => this.removeUserFromCompany(user),
            (user) => this.deactivateUser(user)
        );

        this.load(new UserFilter(false));
    }

    async load(userFilter) {
        let users = await new UserCollection().getCompanyUsers(userFilter.isDeactivated, userFilter.isRemoved);
        this.userListController.setUsers(users);
    }

    async inviteUsers() {
        let invite = await new InviteCollection().createInvite();
        this.invitationLink = `${window.location.origin.toString()}/#/invite/` + invite.id;
        this.inviteUsersInfoBoxController.show();
    }

    goToTeamList() {
        this.navigator.goToPage(this.navigator.siteMap.pages.TeamList);
    }

    toggleFilter() {
        this.showFilter = !this.showFilter;
    }

    async removeUserFromCompany(user) {
        let result = await this.removeUserFromCompanyInfoBoxController.show();
        if (result === 'yes') {
            let companyUser = new CompanyUser(user.id);
            await companyUser.setIsRemoved(true);
            this.userListController.removeUsers([user]);
        }
    }

    async deactivateUser(user) {
        let result = await this.deactivateUserInfoBoxController.show();
        if (result === 'yes') {
            let companyUser = new CompanyUser(user.id);
            await companyUser.setIsDeactivated(true);
            this.userListController.removeUsers([user]);
        }
    }
}

decorate(UserListPageController, {
    showFilter: observable,
    invitationLink: observable,
});

const UserListPageView = observer(class UserListPageView extends Component {
    render() {
        let controller = this.props.controller;

        return (
            <main className="fadeIn">
                <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                <div className="appContent user-list-page">
                    <div className="appContent-body">
                        <ContentTitle title={lang.get('appheader.teamMgmt.employees.title')}
                                      subtitle={lang.get('appheader.teamMgmt.employees.subtitle')}/>
                        <div className="button-row">
                            <div className="horizontal-line"/>
                            <button id="toggle-filter-btn" className="btn" onClick={() => controller.toggleFilter()}>
                                <span>{lang.get('teamManagement.filter.btn')}</span><i className="far fa-sliders-h"/></button>
                        </div>
                        <div style={hideIfNot(controller.showFilter)}>
                            <UserFilterView controller={controller.filterController}/>
                        </div>
                        <UserListView controller={controller.userListController} userStore={controller.userStore} navigator={controller.navigator}/>
                    </div>
                </div>
                <InfoBox2 hasYes={true}
                          title={lang.get('teamManagement.infoBox.employees.invite.title')}
                          icon={iconAddUser}
                          id={'inviteInfoBox'}
                          controller={controller.inviteUsersInfoBoxController}>
                    <InvitationBox controller={new InvitationBoxController(controller.invitationLink)}/>
                </InfoBox2>
                <InfoBox2 hasYes={true} hasNo={true}
                          title={lang.get('teamManagement.infoBox.removeUserFromCompany.title')}
                          icon={'far fa-trash-alt'}
                          id={'removeUserFromCompanyInfoBox'}
                          controller={controller.removeUserFromCompanyInfoBoxController}>
                    <p>
                        {lang.get('teamManagement.infoBox.removeUserFromCompany.content')}
                    </p>
                </InfoBox2>
                <InfoBox2 hasYes={true} hasNo={true}
                          title={lang.get('teamManagement.infoBox.deactivateUser.title')}
                          icon={'far fa-user-slash'}
                          id={'deactivateUserInfoBox'}
                          controller={controller.deactivateUserInfoBoxController}>
                    <p>
                        {lang.get('teamManagement.infoBox.deactivateUser.content')}
                    </p>
                </InfoBox2>
            </main>
        );
    }
});

export {UserListPageController, UserListPageView};