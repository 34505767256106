import {decorate, observable, action} from 'mobx';
import {axios} from '../../utils/WrappedAxios';
import _ from 'lodash';

class COQCollection {
    coqs = [];

    async createCoq(content, categoryId) {
        let response = await axios.post('/api/coq', {
            content: content,
            categoryId: categoryId,
        });
        return response.data;
    }

    async loadList() {
        return axios.get('/api/coq')
            .then((response) => {
                this.coqs = response.data.coqs;
            });
    }

    async deleteCoq(id) {
        await axios.delete(`/api/coq/${id}`);
        _.remove(this.coqs, it => it.id === id);
    }
}

decorate(COQCollection, {
    coqs: observable,
    load: action
});

export default COQCollection;