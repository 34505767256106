import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable, action, decorate, computed} from 'mobx';
import _ from 'lodash';

class MultiSelectionListController {
    items = [];
    selectedIndexes = {};

    setItems(items) {
        this.clearSelection();
        this.items = items;
    }

    isSelected(index) {
        return this.selectedIndexes[index] === true;
    }

    get selectedItems() {
        return Object.keys(this.selectedIndexes).map(it => this.items[it]);
    }

    selectIndex(index) {
        this.selectedIndexes[index] = true;
    }

    deselectIndex(index) {
        delete this.selectedIndexes[index];
    }

    toggleIndex(index) {
        if (this.isSelected(index))
            this.deselectIndex(index);
        else
            this.selectIndex(index);
    }

    clearSelection() {
        this.selectedIndexes = {};
    }
}

class SingleSelectionListController {
    items = [];
    selectedIndex = null;

    setItems(items) {
        this.items = items;
        this.selectedIndex = null;
    }

    isSelected(index) {
        return this.selectedIndex === index;
    }

    get selectedItem() {
        if (this.selectedIndex === null || this.selectedIndex === -1) return null;
        return this.items[this.selectedIndex];
    }

    selectIndex(index) {
        this.selectedIndex = index;
    }

    selectByValue(value) {
        this.selectedIndex = _.findIndex(this.items, it => it.value === value);
    }

    deselectIndex(index) {
        if (this.selectedIndex === index)
            this.selectedIndex = null;
    }

    toggleIndex(index) {
        this.clearSelection();
        this.selectIndex(index);
    }

    clearSelection() {
        this.selectedIndex = null;
    }
}

decorate(MultiSelectionListController, {
    items: observable.shallow,
    selectedIndexes: observable,
    selectedItems: computed,
    setItems: action,
    selectIndex: action,
    deselectIndex: action,
    toggleIndex: action
});

decorate(SingleSelectionListController, {
    items: observable.shallow,
    selectedIndex: observable,
    selectedItem: computed,
    setItems: action,
    selectIndex: action,
    deselectIndex: action,
    toggleIndex: action
});

const MultiSelectionListView = observer(class MultiSelectionListView extends Component {
    render() {
        const { controller } = this.props;
        let isMultiSelect = controller instanceof MultiSelectionListController;
        let selectIndex = (index) => controller.selectIndex(index);
        let deselectIndex = (index) => controller.deselectIndex(index);
        let toggleIndex = (index) => controller.toggleIndex(index);
        let isSelected = (index) => controller.isSelected(index);

        let items = controller.items.map((it, index) =>
            <div
                className={`input-check-box ${(isSelected(index) === true ? 'selected' : '')} ${isMultiSelect ? 'multi-select' : ''}`}
                key={index} onClick={() => toggleIndex(index)} id={it.value || '0'}>
                <label htmlFor="">
                    <input type="checkbox" value={it.value || ''} checked={isSelected(index)}
                           onChange={(e) => e.target.checked ? selectIndex(index) : deselectIndex(index)}/>
                    {isMultiSelect ? <i className="far fa-check"/> : <i className="fas fa-circle"/>}
                </label>
                <span>{it.text}</span>
            </div>
        );

        return <div className="multi-selection-list">
            {items}
        </div>;
    }
});

export {MultiSelectionListController, MultiSelectionListView, SingleSelectionListController};