import {axios} from '../../utils/WrappedAxios';
import {TeamPath} from './Team';

export default class TeamCollection {
    async getTeamList() {
        let response = await axios.get('/api/teamManagement/team');
        return response.data.teams;
    }

    async createTeam(teamName, parentTeamId) {
        let response = await axios.post('/api/teamManagement/team', {name: teamName, parentTeamId: parentTeamId});
        return response.data;
    }

    async getTeamsAndSubteams() {
        let response = await axios.get('/api/teamManagement/teams');
        return response.data.teams.map(it => {
            return {team: it.team, teamPath: new TeamPath(it.teamPath)};
        });
    }

    async addUserToTeams(userId, teamIds){
        return axios.put(`/api/teamManagement/user/${userId}/teams`, {teamIds: teamIds});
    }
};