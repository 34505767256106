import _ from 'lodash';
import {decorate, observable, action, computed} from 'mobx';

class ScrollEvents {
    _contentWrapElement = null;

    _touchedTopListeners = [];
    _touchedBottomListeners = [];
    _untouchedTopListeners = [];
    _untouchedBottomListeners = [];
    _movedListeners = [];

    /**
     * top / bottom / middle
     */
    _currentState = null;

    // constructor() {
    // this.addTouchedTopListener(() => console.log('Top'));
    // this.addTouchedBottomListener(() => console.log('Bottom'));
    // this.addUntouchedTopListener(() => console.log('UntouchedTop'));
    // this.addUntouchedBottomListener(() => console.log('UntouchedBottom'));
    // this.addMovedListener(() => console.log('Moved'));
    // }

    get isTop() {
        if (this._currentState === 'top')
            return true;
        if (this._contentWrapElement.scrollHeight === this._contentWrapElement.offsetHeight)
            return true;
        return false;
    }

    get isBottom() {
        if (this._currentState === 'bottom')
            return true;
        if (this._contentWrapElement.scrollHeight === this._contentWrapElement.offsetHeight)
            return true;
        return false;
    }

    get isMiddle() {
        if (this._currentState === 'middle')
            return true;
        if (this._contentWrapElement.scrollHeight === this._contentWrapElement.offsetHeight)
            return true;
        return false;
    }

    _calculateCurrentState() {
        if (this._contentWrapElement.scrollTop < 5)
            return 'top';
        else if (this._contentWrapElement.scrollTop >= (this._contentWrapElement.scrollHeight - this._contentWrapElement.offsetHeight))
            return 'bottom';
        else
            return 'middle';
    }

    addTouchedTopListener(listener) {
        if (!_.indexOf(this._touchedTopListeners, listener) !== -1)
            this._touchedTopListeners.push(listener);
    }

    addTouchedBottomListener(listener) {
        if (!_.indexOf(this._touchedBottomListeners, listener) !== -1)
            this._touchedBottomListeners.push(listener);
    }

    addUntouchedTopListener(listener) {
        if (!_.indexOf(this._untouchedTopListeners, listener) !== -1)
            this._untouchedTopListeners.push(listener);
    }

    addUntouchedBottomListener(listener) {
        if (!_.indexOf(this._untouchedBottomListeners, listener) !== -1)
            this._untouchedBottomListeners.push(listener);
    }

    addMovedListener(listener) {
        if (!_.indexOf(this._movedListeners, listener) !== -1)
            this._movedListeners.push(listener);
    }

    removeTouchedTopListener(listener) {
        _.pull(this._touchedTopListeners, listener);
    }

    removeTouchedBottomListener(listener) {
        _.pull(this._touchedBottomListeners, listener);
    }

    removeUntouchedTopListener(listener) {
        _.pull(this._untouchedTopListeners, listener);
    }

    removeUntouchedBottomListener(listener) {
        _.pull(this._untouchedBottomListeners, listener);
    }

    removeMovedListener(listener) {
        _.pull(this._movedListeners, listener);
    }

    _onScroll(e) {
        let newState = this._calculateCurrentState();
        let oldState = this._currentState;
        this._movedListeners.forEach(it => it(e));
        if (newState !== oldState) {
            this._currentState = newState;
            if (newState === 'top')
                this._touchedTopListeners.forEach(it => it(e));
            else if (newState === 'bottom')
                this._touchedBottomListeners.forEach(it => it(e));

            if (oldState === 'top')
                this._untouchedTopListeners.forEach(it => it(e));
            else if (oldState === 'bottom')
                this._untouchedBottomListeners.forEach(it => it(e));
        }
    }

    startTrackingScroll(contentWrapElement) {
        if (this._contentWrapElement)
            throw Error('already tracking scroll on one object');

        if (!contentWrapElement)
            throw Error('cannot start tracking on null');

        this._contentWrapElement = contentWrapElement;
        this._currentState = this._calculateCurrentState();
        this._contentWrapElement.addEventListener('scroll', this._onScroll.bind(this));
    }

    stopTrackingScroll() {
        if (this._contentWrapElement) {
            this._contentWrapElement.removeEventListener('scroll', this._onScroll.bind(this));
            this._contentWrapElement = null;
        }
    }

    scrollToTop() {
        if (this._contentWrapElement)
            this._contentWrapElement.scrollTop = 0;
    }

    scrollToBottom() {
        if (this._contentWrapElement)
            this._contentWrapElement.scrollTop = this._contentWrapElement.scrollHeight;
    }
}

decorate(ScrollEvents, {
    startTrackingScroll: action,
    stopTrackingScroll: action,
    isBottom: computed,
    isMiddle: computed,
    isTop: computed,
    scrollToTop: action,
    _currentState: observable
});

export default ScrollEvents;