import {observer} from 'mobx-react';
import React from 'react';
import {NavigationSubItem} from './NavigationSubItem';
import {action, decorate, observable} from 'mobx';
import {hideIfNot} from '../../../utils/Utils';


class NavigationSubItemController {
    isVisible = false;

    show() {
        this.isVisible = !this.isVisible;
    }
}

decorate(NavigationSubItemController, {
    isVisible: observable,
    show: action
});


const NavigationItem = observer(({item, selectedMenuItem, onClose, navigationSubItemController}) => {
    let isCurrent = item === selectedMenuItem;

    let displayText = item.name;
    let displayIcon = item.icon;
    let onSelect = item.onSelect;
    let isPremium = item.isPremium;
    let hasSubmenu = item.hasSubmenu;
    let subMenuItems = item.subMenuItems;
    let isVisible = item.isVisible;


    let navigationSubItems;
    let isSubItemCurrent = false;
    if (hasSubmenu) {
        isSubItemCurrent = subMenuItems.indexOf(selectedMenuItem) > -1;
        navigationSubItems = subMenuItems.map((it) => {
            return <NavigationSubItem key={it.id} displayText={it.name} displayIcon={it.icon} onSelect={it.onSelect}
                                      isCurrent={it === selectedMenuItem} onClose={onClose}/>;
        });
    }

    let cssCurrentClass = isCurrent ? 'current' : '';
    let cssSubMenuActive = navigationSubItemController.isVisible || isSubItemCurrent ? 'sub-active' : '';
    let cssPremiumClass = isPremium ? 'premium' : '';

    return (
        <li className={`nav-menu-item ${cssCurrentClass} ${cssPremiumClass} ${cssSubMenuActive}`}
            style={hideIfNot(isVisible)}>
            <a
                href="#/"
                onClick={(e) => {
                    e.preventDefault();
                    if (hasSubmenu) {
                        navigationSubItemController.show()
                    } else {
                        onSelect();
                    }
                }}
            >
                <i className={displayIcon}/>
                <span>{displayText}</span>
                {hasSubmenu && navigationSubItemController.isVisible &&
                <i className="far fa-angle-up arrow"/>
                }
                {hasSubmenu && !navigationSubItemController.isVisible &&
                <i className="far fa-angle-down arrow"/>
                }
            </a>

            {hasSubmenu &&
            <ul className="sub-menu" style={hideIfNot(navigationSubItemController.isVisible || isSubItemCurrent)}>
                {navigationSubItems}
            </ul>
            }
        </li>
    );
});

export {NavigationItem, NavigationSubItemController};