import {decorate, observable, action} from 'mobx';
import {axios} from '../../utils/WrappedAxios';

export class OpenDiscussionItem {
    openDiscussionId = null;
    isCurrentUserOwner;
    topicName;

    createdById;
    createdByName;

    subGroupName;
    teamGroupNames = [];
    status;
    isCurrentUserAnonymous;
    /**
     * val createdAt: Instant,
     * val userId: String?,
     * val userName: String?,
     * val content: String
     * @type {Array}
     */
    messages = [];

    constructor(openDiscussionId) {
        this.openDiscussionId = openDiscussionId;
    }

    load() {
        return axios
            .get(`/api/feedback/${this.openDiscussionId}`)
            .then(response => {
                this.topicName = response.data.questionText;
                this.isCurrentUserOwner = response.data.isCurrentUserOwner;
                this.subGroupName = response.data.subGroupName;
                this.teamGroupNames = response.data.teamGroupNames;
                this.status = response.data.status;
                this.isCurrentUserAnonymous = response.data.isCurrentUserAnonymous;
                this.createdById = response.data.createdById;
                this.createdByName = response.data.createdByName;
                this.messages = response.data.messages;
            });
    }

    changeStatus(status) {
        return axios.post(`/api/feedback/${this.openDiscussionId}/status`, {
          status,
        })
          .then(() => {
              this.status = status;
          });
    }

    sendMessage(content) {
        return axios.post(`/api/feedback/${this.openDiscussionId}`, {
          content,
        })
          .then(response => {
              this.messages.push(response.data);
          });
    }

    setIsAnonymous(isAnonymous) {
        return axios.post(`/api/feedback/${this.openDiscussionId}/anonymous`, {
          isAnonymous,
        })
          .then(() => {
              return this.load();
          });
    }
}

decorate(OpenDiscussionItem, {
    openDiscussionId: observable,
    topicName: observable,
    subGroupName: observable,
    teamGroupNames: observable,
    status: observable,
    isCurrentUserAnonymous: observable,
    messages: observable,
    sendMessage: action,
    createdById: observable
});