import React, {Component} from 'react';
import {SurveyListItem} from './SurveyListItemNew';
import {observer} from 'mobx-react';
import {observable, decorate} from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/hr';
import 'moment/locale/hu';
import 'moment/locale/sl';
import SurveyCollectionStore from '../../domain/Survey/SurveyCollectionStore';
import LoadingModal from '../components/LoadingModal';
import {axios} from '../../utils/WrappedAxios';
import {Header, HeaderController} from '../Header/HeaderView';
import {lang} from '../utils/Lang';
import ScrollEvents from '../../utils/ScrollEvents';
import {ContentTitle} from '../components/ContentTitle';

class SurveyListPageController {
    userStore;
    navigator;
    surveyCollection = new SurveyCollectionStore();

    isLoading = true;

    constructor(navigator, userStore) {
        this.userStore = userStore;
        this.navigator = navigator;

        this.refresh()
    }

    // TODO-low remove this
    createSurvey() {
        axios.get('/api/help/create-survey')
            .then(() => this.refresh());
    }

    refresh() {
        this.isLoading = true;
        this.surveyCollection.load()
            .then(() => this.isLoading = false)
            .catch(() => {
                this.navigator.goToPage(this.navigator.siteMap.pages.Dashboard);
                this.isLoading = false;
            });
    }
}

decorate(SurveyListPageController, {
    isLoading: observable
});

const SurveyListPageView = observer(class SurveyListPageView extends Component {
    scrollEvents = new ScrollEvents();

    componentDidMount() {
        this.scrollEvents.startTrackingScroll(document.getElementsByClassName('appContent')[0]);
    }

    componentWillUnmount() {
        this.scrollEvents.stopTrackingScroll();
    }

    createSurveyListItem(survey) {
        return <SurveyListItem key={survey.surveyId}
                               title={moment(survey.createdAt).format('MMM YYYY')}
                               status={survey.status}
                               date={moment(survey.createdAt).locale(lang.language).format('MMMM DD, YYYY')}
                               dots={0}
                               hasOQ={survey.hasOQ}
                               hasFeedback={survey.hasFeedback}
                               onClick={() => {
                                   if (survey.status === 'Open') {
                                       if (survey.type === 'Initial')
                                           this.props.controller.navigator.goToPage(this.props.controller.navigator.siteMap.pages.InitialSurvey);
                                       else
                                           this.props.controller.navigator.goToPage(this.props.controller.navigator.siteMap.pages.Survey, survey.surveyId);
                                   }
                               }}/>;
    }

    render() {
        let controller = this.props.controller;

        let surveyCollection = controller.surveyCollection;

        let openSurveys = _.filter(surveyCollection.surveys, it => it.status === 'Open');
        let pastSurveys = _.filter(surveyCollection.surveys, it => it.status === 'Expired' || it.status === 'Finished');

        let openSurveyItems = openSurveys.map((survey) => {
            return this.createSurveyListItem(survey)
        });
        let pastSurveyItems = pastSurveys.map((survey) => {
            return this.createSurveyListItem(survey)
        });

        return (
            <React.Fragment>
                <LoadingModal isVisible={controller.isLoading}/>
                <main className="fadeIn">
                    <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                    <div className="appContent">
                        <div className="appContent-body">
                            <ContentTitle title={lang.get('appheader.survey.title')}
                                          subtitle={lang.get('appheader.survey.subtitle')}/>
                            <div className="survey-list">
                                <div className="survey-list-header">
                                    <h2>{lang.get('surveyList.group.open')}</h2>
                                </div>
                                <div className="survey-list-items">
                                    {openSurveyItems != null && openSurveyItems.length > 0 ? openSurveyItems :
                                        <p className="survey-list-no-data">{lang.get('surveyList.group.noData')}</p>}
                                </div>
                            </div>
                            <div className="survey-list">
                                <div className="survey-list-header">
                                    <h2>{lang.get('surveyList.group.archive')}</h2>
                                </div>
                                <div className="survey-list-items survey-past">
                                    {pastSurveyItems != null && pastSurveyItems.length > 0 ? pastSurveyItems :
                                        <p className="survey-list-no-data">{lang.get('surveyList.group.noData')}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
});

export {SurveyListPageView, SurveyListPageController};