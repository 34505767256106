import React, {Component} from 'react';
import {lang} from '../utils/Lang';
import {observer} from 'mobx-react';
import {decorate, observable} from 'mobx';
import {ContentTitle} from '../components/ContentTitle';
import FacebookShareButton from '../../utils/FacebookShareButton';

class ProfilingResultViewController {
    token = null;
    toggleItems = [true, true, true];  //by default all visible

    constructor(token) {
        this.token = token;
    }

    toggleListItem(itemIndex) {
        this.toggleItems[itemIndex] = !this.toggleItems[itemIndex];
    }

    isExtended(itemIndex) {
        return this.toggleItems[itemIndex];
    }

}

decorate(ProfilingResultViewController, {
    toggleItems: observable,
    token: observable,
});


const ProfilingResultView = observer(class ProfilingResultContentView extends Component {
    constructor(props) {
        super(props);

        this.tab1 = React.createRef();
        this.tab2 = React.createRef();
        this.tab3 = React.createRef();
    }

    activeIfExtended(index) {
        if (this.props.controller.isExtended(index))
            return 'active';
    }

    removeBorderIfExtended(index) {
        if (this.props.controller.isExtended(index))
            return 'no-border';
    }

    showExtendOrCollapse(index) {
        if (this.props.controller.isExtended(index)) {
            return 'far fa-chevron-up';
        } else {
            return 'far fa-chevron-down';
        }
    }

    getProfilingResultsPDFLink() {
        switch (window.language.language) {
            case 'DE':
            case 'EN':
            case 'HU':
            case 'SL':
            case 'HR':
                return "https://docs.google.com/uc?export=download&id=1o58iLxRwlDpblJqXMeX-S_gwscgzPo8A";
            default:
                break
        }
    }


    render() {
        let controller = this.props.controller;

        let description = lang.get('profilingResult.' + controller.token + '.description.main');
        description = description.split('<br>').map((it, index) => <p key={index}>{it}</p>);
        let toggleListItem = (index, ref) => {
            controller.toggleListItem(index);
            setTimeout(() => {
                ref.current.scrollIntoView({
                    block: 'start'
                });
            }, 0);

        };

        let facebook_share_href = 'https://www.doods.team/';
        if(lang.language === 'EN') facebook_share_href += '?lang=en';
        let facebook_share_title = lang.get('profilingResult.' + controller.token + '.title');
        let facebook_share_desc = lang.get('appheader.profiling.result.subtitle');
        let facebook_share_picture = `https://s3.eu-central-1.amazonaws.com/doods/web-resources/profiling-images/${controller.token}.jpg`;
        let facebook_share_hashtag = '';
        let facebook_share_quote = lang.get('profilingResult.' + controller.token + '.title');

        return (
            <React.Fragment>
                <ContentTitle title={lang.get('profilingResult.' + controller.token + '.title')}
                              subtitle={lang.get('appheader.profiling.result.subtitle')}/>
                <div className="card slideIn">
                    <div className="card-body">
                        <div className="card-image"
                             style={{backgroundImage: `url("https://s3.eu-central-1.amazonaws.com/doods/web-resources/profiling-images/${controller.token}.jpg")`}}/>
                        <FacebookShareButton href={facebook_share_href}
                                             title={facebook_share_title}
                                             desc={facebook_share_desc}
                                             picture={facebook_share_picture}
                                             hashtag={facebook_share_hashtag}
                                             quote={facebook_share_quote}>Share</FacebookShareButton>
                        <div ref={this.tab1} className={`card-header small ${this.removeBorderIfExtended(0)}`}
                             onClick={() => toggleListItem(0, this.tab1)}>
                            <div className="card-header-icon">
                                <i className="far fa-user-circle"/>
                            </div>
                            <div className="card-header-title">
                                <h2 className="">{lang.get('profilingResult.groupTitle.basic')}</h2>
                            </div>
                            <div className="card-header-arrow">
                                <i className={this.showExtendOrCollapse(0)}/>
                            </div>
                        </div>
                        <div className={`card-content ${this.activeIfExtended(0)}`}>
                            <h3>{lang.get('profilingResult.groupTitle.desc')}</h3>
                            <p dangerouslySetInnerHTML={{__html: lang.get('profilingResult.' + controller.token + '.basic.behaviour')}}/>
                            <h3>{lang.get('profilingResult.basic.attributeTitle')}</h3>
                            <p dangerouslySetInnerHTML={{__html: lang.get('profilingResult.' + controller.token + '.basic.synonym')}}/>
                            <div className="motto-content">
                                <h3>
                                    <span
                                        dangerouslySetInnerHTML={{__html: lang.get('profilingResult.' + controller.token + '.basic.motto')}}/>
                                </h3>
                                <span
                                    dangerouslySetInnerHTML={{__html: lang.get('profilingResult.' + controller.token + '.basic.mottoAuthor')}}/>
                            </div>
                        </div>
                        <div ref={this.tab2} className={`card-header small ${this.removeBorderIfExtended(1)}`}
                             onClick={() => toggleListItem(1, this.tab2)}>
                            <div className="card-header-icon">
                                <i className="far fa-users"/>
                            </div>
                            <div className="card-header-title">
                                <h2 className="">{lang.get('profilingResult.groupTitle.personalities')}</h2>
                            </div>
                            <div className="card-header-arrow">
                                <i className={this.showExtendOrCollapse(1)}/>
                            </div>
                        </div>
                        <div className={`card-content ${this.activeIfExtended(1)}`}>
                            <h3>{lang.get('profilingResult.person.fictionTitle')}</h3>
                            <p dangerouslySetInnerHTML={{__html: lang.get('profilingResult.' + controller.token + '.personality.fictional')}}/>
                            <h3>{lang.get('profilingResult.person.RealTitle')}</h3>
                            <p dangerouslySetInnerHTML={{__html: lang.get('profilingResult.' + controller.token + '.personality.real')}}/>
                        </div>
                        <div ref={this.tab3} className="card-header small no-border"
                             onClick={() => toggleListItem(2, this.tab3)}>
                            <div className="card-header-icon">
                                <i className="far fa-book-reader"/>
                            </div>
                            <div className="card-header-title">
                                <h2 className="">{lang.get('profilingResult.groupTitle.desc')}</h2>
                            </div>
                            <div className="card-header-arrow">
                                <i className={this.showExtendOrCollapse(2)}/>
                            </div>
                        </div>
                        <div className={`card-content ${this.activeIfExtended(2)}`}>
                            {description}
                        </div>
                    </div>
                </div>
                <div className="profiling-result-links">
                    <a href={`${this.getProfilingResultsPDFLink()}`} download="DOODsPROFILING.pdf">{lang.get('profiling.allResults.link')}</a>
                </div>
            </React.Fragment>
        );
    }
});

export {ProfilingResultView, ProfilingResultViewController};