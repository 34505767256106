import {axios} from '../../utils/WrappedAxios';
import {TeamPath} from '../Team/Team';

class CompanyUser {
    constructor(id) {
        this.id = id;
    }

    async getProfile() {
        let response = await axios.get(`/api/company-user/${this.id}/profile`);
        return response.data;
    }

    async get() {
        let response = await axios.get(`/api/company-user/${this.id}`);
        return response.data;
    }

    async getCompanyId() {
        try {
            let response = await axios.get('/api/company/getCompanyId');
            return response.data.companyId;
        } catch (e) {
            if (e.response.status === 404) return null;
            throw e;
        }
    }

    getTeams() {
        return axios.get(`/api/company-user/${this.id}/teams`)
            .then(response => {
                return response.data.teams;
            })
    }

    removeFromTeam(teamId) {
        return axios.delete(`/api/company-user/${this.id}/teams/${teamId}`);
    }

    getAdminTeams() {
        return axios.get(`/api/company-user/${this.id}/adminteams`)
            .then(response => {
                return response.data.teams;
            })
    }

    removeFromAdmin(teamId) {
        return axios.delete(`/api/company-user/${this.id}/adminteams/${teamId}`);
    }

    getTeamsForAction(action) {
        return axios.get(`/api/company-user/${this.id}/getTeamsForAction?action=${action}`)
            .then(response => {
                return response.data.teams.map(it => {
                    return {
                        path: new TeamPath(it.id),
                        name: it.name
                    };
                });
            })
    }

    getRole() {
        return axios.get(`/api/user/${this.id}/role`)
            .then(response => {
                return response.data;
            })
    }

    setRole(newRoleName) {
        return axios.put(`/api/user/${this.id}/role`, {role: newRoleName});
    }

    getIsCompanyAdmin() {
        return axios.get(`/api/company-user/${this.id}/iscompanyadmin`)
            .then(response => {
                return response.data;
            })
    }

    setIsCompanyAdmin(newValue) {
        if (newValue === true)
            return axios.put(`/api/company-user/${this.id}/iscompanyadmin`);
        else
            return axios.delete(`/api/company-user/${this.id}/iscompanyadmin`);
    }

    setIsOnHoliday(newValue) {
        return axios.put(`/api/company-user/${this.id}/isOnHoliday`, newValue);
    };

    setIsRemoved(newValue) {
        return axios.put(`/api/company-user/${this.id}/isRemoved`, newValue);
    }

    setIsDeactivated(newValue) {
        return axios.put(`/api/company-user/${this.id}/isDeactivated`, newValue);
    }

    sendReminder() {
        return axios.post(`/api/company-user/${this.id}/sendReminder`)
    }
};

export {CompanyUser};