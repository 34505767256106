import {decorate, observable, action} from 'mobx';
import {axios} from '../../utils/WrappedAxios';

class FeedbackCollection {
    /**
     * {
     *  val id: String,
     *  val createdAt: Instant,
     *  val updatedAt: Instant,
     *  val createdBy: String?,
     *  val createdById: String?,
     *  val isCurrentUserAnonymous: Boolean,
     *  val status: TopicStatus,
     *  val type: TopicType,
     *  val label: TopicLabel?,
     *  val tone: TopicTone?,
     *  val rootGroupId: String?,
     *  val subGroupId: String?,
     *  val subGroupName: String?,
     *  val questionText: String?,
     *  val firstMessage: String?,
     *  val unreadCount: Int
     * }
     * @type {Array}
     */
    feedbackList = [];

    load() {
        return axios.get('/api/feedback')
            .then((response) => {
                this.feedbackList = response.data.items;
            });
    }

    loadWithFilter(filter) {
        return axios.get('/api/feedback', {
            params: {
                type: [1, 2],
                d: filter.date,
                sg: filter.subGroup,
                t: filter.tone,
                l: filter.label,
                s: filter.status,
                r: filter.isRead
            }
        })
            .then((response) => {
                this.feedbackList = response.data.items;
            });
    }

    getCountForFilter(filter) {
        return axios.get('/api/feedback/count', {
            params: {
                type: [1, 2],
                d: filter.date,
                sg: filter.subGroup,
                t: filter.tone,
                l: filter.label,
                s: filter.status,
                r: filter.isRead
            }
        }).then((response) => {
            return response.data.count;
        });
    }

    clear() {
        this.feedbackList = [];
    }
}

decorate(FeedbackCollection, {
    feedbackList: observable,
    load: action,
    loadWithFilter: action,
    getSubGroupOptions: action
});

export default FeedbackCollection;