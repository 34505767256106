import React from 'react';
import {observer} from 'mobx-react';
import InputWrap from './InputWrap';

const Textarea = observer(({name, extraClass, placeholder, validatedValue, maxLength, rows, autocomplete, children, disabled}) => {
    return (
        <InputWrap id={name} validationMsg={validatedValue.displayError} extraClass={extraClass}>
            <textarea
                   name={name}
                   className="form-input"
                   id={name}
                   rows={rows}
                   placeholder={placeholder}
                   value={validatedValue.value ? validatedValue.value : ''}
                   maxLength={maxLength}
                   autoComplete={autocomplete}
                   onChange={(e) => validatedValue.valueWithTouch = e.target.value}
                   onBlur={() => validatedValue.blur()}
                   onFocus={() => validatedValue.focus()}
                   disabled={disabled}
            />
            {children}
        </InputWrap>
    );
});

export default Textarea;