import React, {Component} from 'react';
import {decorate, observable} from 'mobx';
import {observer} from 'mobx-react';
import {lang} from '../../utils/Lang';
import {ContentTitle} from '../../components/ContentTitle';
import {AnonymousCheckBox} from '../../components/AnonymousCheckBox';


class SurveyFeedbackController {
    value;
    isAnonymous = true;
    userName = '';
    isCompanyAdmin = false;

    constructor(value, isAnonymous, userName, isCompanyAdmin, showCannotChangeAnonymityInfoBox) {
        this.value = value || '';
        this.isAnonymous = isAnonymous;
        this.userName = userName;
        this.isCompanyAdmin = isCompanyAdmin;
        this.showCannotChangeAnonymityInfoBox = showCannotChangeAnonymityInfoBox
    }

    toggleAnonymity() {
        if (this.isCompanyAdmin){
            this.showCannotChangeAnonymityInfoBox()
        }else{
            this.isAnonymous = !this.isAnonymous
        }
    }
}

decorate(SurveyFeedbackController, {
    value: observable,
    isAnonymous: observable,
    isCompanyAdmin: observable
});

const SurveyFeedbackView = observer(class SurveyFeedbackView extends Component {
    render() {
        let controller = this.props.controller;

        return (
            <React.Fragment>
                <ContentTitle title={this.props.title}/>
                <form id="surveyOpenQuestion" method="" name="surveyOpenQuestion">
                    <div className="card card-survey-question feedback slideIn">
                        <div className="card-body">
                            <div className="card-header small">
                                <div className="card-header-icon">
                                    <i className="far fa-comment"/>
                                </div>
                                <div className="card-header-title">
                                    <h2 className="">{lang.get('header.title.feedback')}</h2>
                                </div>
                            </div>
                            <div className="card-content">
                                <h3>{lang.get('survey.normal.feedback.info')}</h3>
                                <textarea id="open-question" name="answerOQ" placeholder={lang.get('feedback.form.question')}
                                          defaultValue="" rows="5" maxLength="1500" onChange={(e) => {
                                    e.preventDefault();
                                    controller.value = e.target.value;
                                }} value={controller.value}/>
                            </div>
                            <div className="card-footer">
                                <span className="skip-label">{lang.get('feedback.skip.btn')}</span>
                                <AnonymousCheckBox isChecked={controller.isAnonymous} disabled={controller.isCompanyAdmin}
                                                   onToggle={() => controller.toggleAnonymity()}
                                                   name={controller.userName}/>
                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
});

export {SurveyFeedbackView, SurveyFeedbackController};
