import React, {Component} from 'react';
import {hideIfNot} from '../../../utils/Utils';
import {lang} from "../../utils/Lang";

class OpenDiscussionListItem extends Component {

    getOpenDiscussionItemStyle() {
        let style = {
            categoryClass: 'statement',
        };


        switch (this.props.category) {
            case 'growth':
                style.categoryClass = 'growth';
                break;
            case 'culture':
                style.categoryClass = 'culture';
                break;
            case  'relationship':
                style.categoryClass = 'relationship';
                break;
            case  'facts':
                style.categoryClass = 'facts';
                break;
            case 'recommendation':
                style.categoryClass = 'recommendation';
                break;
            default:
                style.categoryClass = 'statement';
                break;
        }

        return style;
    }


    render() {
        let style = this.getOpenDiscussionItemStyle();

        return (
            <li className={'card no-lines slideIn'} onClick={(e) => {
                e.preventDefault();
                this.props.onClick();
            }}>
                <div className="card-body" data-selenium-topic-id={this.props.topicId}>
                    <div className="card-header">
                        <div className={'card-header-icon ' + style.categoryClass}><i
                            className="far fa-comments"/></div>
                        <div className="card-header-title two-row">
                            <h2 className="">{this.props.title}</h2>
                            <h3 className="">
                                <div>{lang.get('feedback.date')}: <span>{this.props.createdAt}</span>&nbsp;/&nbsp;</div>
                                <div>{lang.get('feedback.updateDate')}: <span>{this.props.updatedAt}</span></div>
                            </h3>
                        </div>
                        <div className="open-discussion-item-status">
                            <i className="far fa-comments"/>
                            <span className="unreadStatus" style={hideIfNot(this.props.unreadCount > 0)}/>
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}

export {OpenDiscussionListItem};