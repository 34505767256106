import React from 'react';
import {LockedIcon} from './LockedIcon';

/**
 * Use this class to wrap elements that you want license lock displayed on (width / height will be 100% and lock icon will be centered
 * Everything inside of it marked with class license-lock-opacity will get opacity 0.2
 */
const LicenseLock = ({showText, isLocked, navigator, children}) => {
    if (isLocked === false) return children;

    return (
        <div className="license-lock-wrapper license-lock-active">
            {children}
            <LockedIcon showText={showText} navigator={navigator}/>
        </div>
    );
};

export {LicenseLock};