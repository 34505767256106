import React, {Component} from 'react';
import {lang} from '../utils/Lang';
import {observer} from 'mobx-react';
import {findFirstOrNull} from '../utils/ReactHelpers';
import {InfoBox2, InfoBox2Controller} from './Inputs/InfoBox2';

const Title = ({children}) => (
    <React.Fragment>
        {children}
    </React.Fragment>
);

const Subtitle = ({children}) => (
    <React.Fragment>
        {children}
    </React.Fragment>
);

const Info = ({children}) => (
    <React.Fragment>
        {children}
    </React.Fragment>
);

const ContentTitle = observer(class ContentTitle extends Component {
    render() {
        let title = findFirstOrNull(this.props.children, Title);
        if (title == null && this.props.title) title = this.props.title;

        let subtitle = findFirstOrNull(this.props.children, Subtitle);
        if (subtitle == null && this.props.subtitle) subtitle = <h3>{this.props.subtitle}</h3>;

        let info = findFirstOrNull(this.props.children, Info);
        if (info == null && this.props.info) info = <p>{this.props.info}</p>;

        let infoBox2Controller = new InfoBox2Controller();

        return (
            <React.Fragment>
                <InfoBox2 icon={'far fa-question-circle'} title={lang.get('notification.title.general')}
                          controller={infoBox2Controller} hasClose={true}>{info}</InfoBox2>
                <div className="appContent-header">
                    <div className={`appContent-header-title ${(info ? 'has-info' : '')}`}>
                        <h1>
                            <span>
                                {title}
                                {info &&
                                <a href="#/" onClick={(e) => {
                                    e.preventDefault();
                                    infoBox2Controller.show();
                                }}><i className="far fa-question-circle"/></a>
                                }
                            </span>
                        </h1>
                    </div>
                    {subtitle &&
                    <div className='appContent-header-subtitle'>
                        {subtitle}
                    </div>
                    }
                </div>
            </React.Fragment>
        );
    }
});

export {ContentTitle, Title, Subtitle, Info};