class Role {
    static GroupViewOverview = 'GroupViewOverview';
    static CompanyViewIsAdmin = 'CompanyViewIsAdmin';
    static FeedbackReply = 'FeedbackReply';
    static FeedbackRead = 'FeedbackRead';
    static FeedbackChangeLabel = 'FeedbackChangeLabel';
    static FeedbackChangeStatus = 'FeedbackChangeStatus';
    static OpenDiscussionRead = 'OpenDiscussionRead';
    static OpenDiscussionReply = 'OpenDiscussionReply';
    static EditCompanyData = 'EditCompanyData';
    static CompanyEditAdmin = 'CompanyEditAdmin';
    static DeleteCompany = 'DeleteCompany';
    static InviteUsers = 'InviteUsers';
    static GroupAddUser = 'GroupAddUser';
    static GroupRemoveUser = 'GroupRemoveUser';
    static GroupChangeTeamName = 'GroupChangeTeamName';
    static GroupChangeManager = 'GroupChangeManager';
    static GroupAddAdmin = 'GroupAddAdmin';
    static GroupRemoveAdmin = 'GroupRemoveAdmin';
    static GroupCreate = 'GroupCreate';
    static GroupDelete = 'GroupDelete';
    static UserGetTeamAccess = 'UserGetTeamAccess';
    static UserViewDetails = 'UserViewDetails';
    static UserViewRole = 'UserViewRole';
    static UserChangeRole = 'UserChangeRole';
    static UserViewTeams = 'UserViewTeams';
    static ReadReport = 'ReadReport';
    static ReadReportForTeam = 'ReadReportForTeam';
    static UserEditDetails = 'UserEditDetails';
    static UserGetNotifications = 'UserGetNotifications';
    static UserReadNotification = 'UserReadNotification';
    static UserChangeIsDeactivated = 'UserChangeIsDeactivated';
    static UserChangeIsOnHoliday = 'UserChangeIsOnHoliday';
    static UserChangeIsRemoved = 'UserChangeIsRemoved';
    static SendSurveyReminder = 'SendSurveyReminder';
    static SurveyViewOverview = 'SurveyViewOverview';
    static SurveyView = 'SurveyView';
    static SurveyUpdate = 'SurveyUpdate';
    static SolutingViewOverview = 'SolutingViewOverview';
    static SolutingView = 'SolutingView';
    static COQCreate = 'COQCreate';
    static COQUpdate = 'COQUpdate';
    static COQDelete = 'COQDelete';
    static COQViewOverview = 'COQViewOverview';
    static SubscriptionPlan = 'SubscriptionPlan';

    static DoodsStatisticsView = 'DoodsStatisticsView';
}

class LicensedAction {
    static ReportViewSubCategory = 'ReportViewSubCategory';
    static ReportViewFilteredByTeam = 'ReportViewFilteredByTeam';
    static SolutingViewLocked = 'SolutingViewLocked';
    static FeedbackReply = 'FeedbackReply';
    static TeamManagementEdit = 'TeamManagementEdit';
    static CoqManagement = 'CoqManagement';
    static UpgradeSubscriptionPlan = 'UpgradeSubscriptionPlan';
}

export {Role, LicensedAction};