import {axios} from '../../utils/WrappedAxios';

class User {
    id = null;

    constructor(id) {
        this.id = id;
    }

    async register(firstName, lastName, birthYear, gender, street, zipCode, city, country, industry) {
        await axios.put(`/api/user/${this.id}/details`, {
            firstName: firstName,
            lastName: lastName,
            birthYear: birthYear,
            gender: gender,
            street: street,
            zipCode: zipCode,
            city: city,
            country: country,
            industry: industry
        });
    }
}

export {User};