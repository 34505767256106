import React from 'react';
import {decorate, observable, action} from 'mobx';
import {observer} from 'mobx-react';
import {NotificationCollection, NotificationFilter} from '../../domain/Notifications/NotificationCollection';
import {lang} from '../utils/Lang';
import {InputSelect, InputSelectController} from '../components/Inputs/InputSelect';
import ValidatedValue from '../components/Inputs/ValidatedValue';

class NotificationFilterController {
    notificationGroups = NotificationCollection.notificationTypes.reduce((accumulator, currentValue) => {
        if (!accumulator[currentValue.group]) {
            accumulator[currentValue.group] = [];
        }
        accumulator[currentValue.group].push(currentValue);
        return accumulator;
    }, {});

    statusOptions = [
        {text: lang.get('notification.all.title'), value: null},
        {text: lang.get('notification.status.unread'), value: 'unread'},
        {text: lang.get('notification.status.read'), value: 'read'}
    ];

    onChange = (notificationFilter) => true;
    onCancel = () => true;

    constructor() {
        let filterEventOptions = [{text: lang.get('notification.all.title'), value: null}];
        Object.keys(this.notificationGroups).forEach(it => filterEventOptions.push({
            text: lang.get(`notification.${it}.title`),
            value: it
        }));

        this.filterEvent = {
            name: 'event',
            placeholder: lang.get('notification.filter.byEvents'),
            controller: new InputSelectController(filterEventOptions, new ValidatedValue([], null))
        };

        this.filterStatus = {
            name: 'status',
            placeholder: lang.get('notification.filter.byStatus'),
            controller: new InputSelectController(this.statusOptions, new ValidatedValue([], null))
        };
    }

    onApply() {
        let isRead = null;
        if (this.filterStatus.controller.selectedValue) isRead = this.filterStatus.controller.selectedValue === 'read';
        let group = null;
        if (this.filterEvent.controller.selectedValue) group = this.filterEvent.controller.selectedValue;

        let type = null;

        this.onChange(new NotificationFilter(isRead, type, group));
    }

    onClearFilter() {
        this.filterStatus.controller.validatedValue.value = null;
        this.filterEvent.controller.validatedValue.value = null;
    }
}

decorate(NotificationFilterController, {
    isReadSelected: observable,
    isUnreadSelected: observable,
    selectedType: observable,
    onApply: action
});

const NotificationFilterView = observer(({controller}) => (
    <React.Fragment>
        <div className="notification-filter">
            <div>
                <label className="input-select-label"
                       htmlFor={controller.filterEvent.name}>{controller.filterEvent.placeholder}</label>
                <InputSelect blue name={controller.filterEvent.name} placeholder={controller.filterEvent.placeholder}
                             controller={controller.filterEvent.controller} showNullInsteadPlaceholder={true}/>
            </div>
            <div>
                <label className="input-select-label"
                       htmlFor={controller.filterStatus.name}>{controller.filterStatus.placeholder}</label>
                <InputSelect blue name={controller.filterStatus.name} placeholder={controller.filterStatus.placeholder}
                             controller={controller.filterStatus.controller} showNullInsteadPlaceholder={true}/>
            </div>
        </div>
        <div className="button-row">
            <button className="btn" onClick={() => controller.onClearFilter()}><i
                className="far fa-undo"/><span>{lang.get('teamManagement.resetBtn')}</span></button>
            <div className="horizontal-line"/>
            <button className="btn" onClick={() => controller.onApply()}><span>{lang.get('teamManagement.btn.apply')}</span><i
                className="far fa-save"/></button>
        </div>
    </React.Fragment>
));

export {NotificationFilterView, NotificationFilterController};