import {axios} from '../../utils/WrappedAxios';

class TeamPath {
    constructor(teamPath) {
        this.teamPath = teamPath.split('/');
    }

    getId() {
        return this.teamPath[this.teamPath.length - 1];
    }

    getParentId() {
        if (!this.hasParent) return null;
        return new TeamPath(this.teamPath.slice(0, this.teamPath.length - 1).join('/'));
    }

    getRootTeamPath() {
        if (!this.hasParent) return null;
        return new TeamPath(this.teamPath[0]);
    }

    get level() {
        let regexCount = this.asString.match(/\//g);
        if (regexCount === null) return 0;
        return regexCount.length;
    }

    get hasParent() {
        return this.teamPath.length > 1;
    }

    get asString() {
        return this.teamPath.join('/');
    }
}

class Team {
    constructor(teamId) {
        this.teamId = teamId;
    }

    getOverview() {
        return axios.get(`/api/teamManagement/team/${this.teamId}`)
            .then(response => {
                return response.data;
            });
    }

    getTeamEmployees() {
        return axios.get(`/api/teamManagement/team/${this.teamId}/users`)
            .then(response => {
                return response.data.users;
            });
    }

    addUsersToTeam(users) {
        return axios.put(`/api/teamManagement/team/${this.teamId}/users`, {users: users});
    }

    removeUsersFromTeam(users) {
        return axios.delete(`/api/teamManagement/team/${this.teamId}/users`, {data: {users: users}});
    }

    getTeamAdmins() {
        return axios.get(`/api/teamManagement/team/${this.teamId}/admin`)
            .then(response => {
                return response.data.users;
            });
    }

    addTeamAdmins(users) {
        return axios.put(`/api/teamManagement/team/${this.teamId}/admin`, {users: users});

    }

    removeUsersFromAdmin(users) {
        return axios.delete(`/api/teamManagement/team/${this.teamId}/admin`, {data: {users: users}});

    }

    changeManager(newManagerId) {
        return axios.put(`/api/teamManagement/team/${this.teamId}/manager`, {userId: newManagerId});
    }

    changeName(newName) {
        return axios.put(`/api/teamManagement/team/${this.teamId}/name`, {name: newName});
    }

    createSubTeam(name) {
        return axios.post('/api/teamManagement/team', {name: name, parentTeamId: this.teamId})
            .then(response => response.data);
    }

    deleteSubTeam(teamId) {
        return axios.delete(`/api/teamManagement/team/${teamId}`);
    }

    getInviteLink() {
        return axios.get(`/api/invite/${this.teamId}`)
            .then(response => response.data.inviteId);
    }
}

export {Team, TeamPath};