import React from 'react';
import {decorate, action} from 'mobx';
import {observer} from 'mobx-react';
import {lang} from '../../utils/Lang';
import {InputSelect, InputSelectController} from '../../components/Inputs/InputSelect';
import ValidatedValue from '../../components/Inputs/ValidatedValue';

class UserFilter {
    constructor(isDeactivated, isRemoved) {
        this.isDeactivated = isDeactivated;
        this.isRemoved = isRemoved;
    }
}

class UserFilterController {
    isDeactivatedFilterOptions = [
        {text: lang.get('teamManagement.userFilter.all'), value: 'all'},
        {text: lang.get('teamManagement.userFilter.active'), value: 'active'},
        {text: lang.get('teamManagement.userFilter.deactivated'), value: 'deactivated'},
        {text: lang.get('teamManagement.userFilter.removed'), value: 'removed'}
    ];

    onChange = (userFilter) => true;
    onCancel = () => true;

    constructor() {
        this.filterStatus = {
            name: 'userFilterStatus',
            placeholder: lang.get('teamManagement.userFilter.title'),
            controller: new InputSelectController(this.isDeactivatedFilterOptions, new ValidatedValue([], 'all'))
        };
    }

    onApply() {
        let filterValue = this.filterStatus.controller.selectedValue;
        let isDeactivated = false;
        let isRemoved = false;

        if (filterValue === 'active') {
            isDeactivated = false;
            isRemoved = false;
        } else if (filterValue === 'deactivated') {
            isDeactivated = true;
            isRemoved = true;
        } else if (filterValue === 'removed') {
            isDeactivated = false;
            isRemoved = true;
        }

        this.onChange(new UserFilter(isDeactivated, isRemoved));
    }

    onClearFilter() {
        this.filterStatus.controller.validatedValue.value = null;
    }
}

decorate(UserFilterController, {
    onApply: action
});

const UserFilterView = observer(({controller}) => (
    <React.Fragment>
        <div className="user-filter">
            <div>
                <label className="input-select-label"
                       htmlFor={controller.filterStatus.name}>{controller.filterStatus.placeholder}</label>
                <InputSelect blue name={controller.filterStatus.name} placeholder={controller.filterStatus.placeholder}
                             controller={controller.filterStatus.controller} showNullInsteadPlaceholder={true}/>
            </div>
        </div>
        <div className="button-row">
            <button id="clear-filter-btn" className="btn" onClick={() => controller.onClearFilter()}><i
                className="far fa-undo"/><span>{lang.get('teamManagement.resetBtn')}</span></button>
            <div className="horizontal-line"/>
            <button id="apply-filter-btn" className="btn" onClick={() => controller.onApply()}><span>{lang.get('teamManagement.btn.apply')}</span><i
                className="far fa-save"/></button>
        </div>
    </React.Fragment>
));

export {UserFilterView, UserFilterController, UserFilter};