import React from 'react';
import {lang} from '../utils/Lang';

const LockedIcon = ({showText, navigator}) => {

    let onLockClick = () => navigator !== undefined ? navigator.goToPage(navigator.siteMap.pages.SubscriptionPlan) : false;

    return (
        <div className="license-lock-icon">
            <i className="far fa-lock-alt" onClick={() => onLockClick()}/>
            {showText && <h2 onClick={() => onLockClick()}>{lang.get('licenseLock.title')}</h2>}
            {showText && <p onClick={() => onLockClick()}> {lang.get('licenseLock.subtitle')}</p>}
        </div>
    )
};

export {LockedIcon};