import React from 'react';
import {observer} from 'mobx-react';
import {action, computed, decorate, runInAction} from 'mobx';
import InputWrap from './InputWrap';
import {InfoBox2, InfoBox2Content, InfoBox2Controller} from './InfoBox2';
import {MultiSelectionListView, SingleSelectionListController} from '../MultiSelectionList';
import _ from 'lodash';
import {hideIfNot} from '../../../utils/Utils';

const InputSelect = observer(({controller, name, placeholder, txtCenter, blue, showNullInsteadPlaceholder, disabled}) => (
    <InputWrap id={name} validationMsg={controller.validatedValue.displayError}>
        <div className={`form-input-dropdown ${blue ? 'blue' : ''}`}>
            <input type="text"
                   className={`form-input ${(txtCenter ? 'txtCenter' : '')} license-lock-opacity`}
                   placeholder={(showNullInsteadPlaceholder === true ? controller.selectedText : placeholder)}
                   onClick={(evt) => {
                       evt.preventDefault();
                       evt.stopPropagation();
                       controller.show();
                   }}
                   value={controller.selectedText || ''}
                   onChange={(e) => true}
                   readOnly
                   data-selenium-value={controller.selectedValue}
                   data-selenium-index={controller.selectedIndex}
                   disabled={disabled}
            />
            <i className="right fa fa-caret-down" style={hideIfNot(!controller.validatedValue.isSaved)}/>
            <i className="right fa fa-check save-icon" style={hideIfNot(hideIfNot(!controller.validatedValue.isSaved))}/>
        </div>
        <InfoBox2 icon={'far fa-question-circle'} title={placeholder} controller={controller.infoBoxController}
                  hasYes={true} hasNo={true}>
            <InfoBox2Content>
                <MultiSelectionListView controller={controller.singleSelectController} listName={name} />
            </InfoBox2Content>
        </InfoBox2>
    </InputWrap>
));

const ButtonSelect = observer(({controller, name, placeholder, txtCenter, blue, showNullInsteadPlaceholder, isLicenseLocked, disabled}) => (
    <InputWrap id={name} validationMsg={controller.validatedValue.displayError}>
        <div className={`form-input-dropdown ${blue ? 'blue' : ''}`}>
            <button className="btn"
                    onClick={(evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        if (isLicenseLocked) return;
                        controller.show();
                    }}><span>{(showNullInsteadPlaceholder === true ? controller.selectedText : placeholder)}</span> <i
                className='far fa-sliders-h license-lock-opacity'/></button>
        </div>
        <InfoBox2 icon={'far fa-question-circle'} title={placeholder} controller={controller.infoBoxController}
                  hasYes={true} hasNo={true}>
            <InfoBox2Content>
                <MultiSelectionListView controller={controller.singleSelectController}/>
            </InfoBox2Content>
        </InfoBox2>
    </InputWrap>
));

class InputSelectController {
    infoBoxController = new InfoBox2Controller();
    singleSelectController = new SingleSelectionListController();
    loadData = null;

    constructor(items, validatedValue, hasNull) {
        this.validatedValue = validatedValue;
        this.hasNull = hasNull;

        this.setItems(items);
    }

    async show() {
        let showAction = this.infoBoxController.show();
        // if we have registered function for loading data then first wait for data to be loaded

        if (this.loadData !== null) {
            this.setItems([]);
            await this.loadData();
        }

        this.validatedValue.focus();
        let previousIndex = this.selectedIndex;
        this.singleSelectController.selectIndex(previousIndex);
        let infoBoxResult = await showAction;
        if (infoBoxResult !== 'yes') return;

        // user made selection and it's not null
        if (this.singleSelectController.selectedItem) {
            this.validatedValue.valueWithTouch = this.singleSelectController.selectedItem.value;
            this.validatedValue.blur();
        }
    }

    get itemsHasNull() {
        let index = _.findIndex(this.singleSelectController.items, it => it.value === null) >= 0;
        return index;
    }

    get selectedIndex() {
        if (this.itemsHasNull === false && this.validatedValue.value === null) return null;
        let index = _.findIndex(this.singleSelectController.items, it => it.value === this.validatedValue.value);
        if (index >= 0) return index;
        else return null;
    }

    get selectedItem() {
        if (this.selectedIndex === null) return null;
        return this.singleSelectController.items[this.selectedIndex];
    }

    get selectedText() {
        if (!this.selectedItem) return null;
        if (this.hasNull === true && this.selectedItem.value == null) return null;
        return this.selectedItem.text;
    }

    get selectedValue() {
        if (!this.selectedItem) return null;
        return this.selectedItem.value;
    }

    setItems(items) {
        runInAction(() => {
            let options = items.slice();
            if (this.hasNull === true) {
                options.unshift({value: null, text: '-'});
            }
            this.singleSelectController.setItems(options);
        });
    }
}

decorate(InputSelectController, {
    show: action,
    itemsHasNull: computed,
    selectedItem: computed,
    selectedIndex: computed,
    selectedText: computed,
    selectedValue: computed
});

export {InputSelect, ButtonSelect, InputSelectController};