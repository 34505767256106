import vanillaAxios from 'axios';
import {axios} from '../utils/WrappedAxios';

class UserService {
    async isLoggedIn() {
        try {
            let userIdResponse = await vanillaAxios.get('/api/auth');
            let userProfile = await axios.get(`/api/user/${userIdResponse.data}`);
            return {isLoggedIn: true, profile: userProfile.data};
        } catch (e) {
            if (e.response.status === 401)
                return {isLoggedIn: false};
            throw e;
        }
    }

    async getJwtToken(verificationId) {
        let result = await axios.get(`/api/auth/authenticate?verificationId=${verificationId}`);
        return result.data;
    }

    async refreshJWT() {
        let result = await axios.get('/api/auth/refresh-jwt');
        return result.data;
    }

    updateToken(profilingToken, email) {
        return axios.post('/api/auth/update-token', {
            profilingToken: profilingToken,
            email: email
        });
    }

    sendLoginEmail(email, recaptchaToken) {
        return axios.post('/api/auth/send-login-email', {
            email: email,
            recaptcha: recaptchaToken
        });
    }

    async deleteUser(userId) {
        return axios.delete(`/api/user/${userId}`);
    }
}

export default UserService;