import React, {Component} from 'react';
import {
    COQSurveyItem,
    FeedbackSurveyItem,
    OpenQuestionSurveyItem,
    SliderQuestionSurveyItem,
    SurveyStore
} from '../../../domain/Survey/SurveyStore';
import {decorate, observable, computed, action} from 'mobx';
import {observer} from 'mobx-react';
import LoadingModal from '../../components/LoadingModal';
import {lang} from '../../utils/Lang';
import StepsFooterBar from '../../components/StepsFooterBar';
import {SurveyOpenQuestionController, SurveyOpenQuestionView} from './SurveyOpenQuestionView';
import {SurveySliderQuestionController, SurveySliderQuestionView} from './SurveySliderQuestionView';
import {SurveyFeedbackController, SurveyFeedbackView} from './SurveyFeedbackView';
import SurveyResultView from './SurveyResultView';
import ScrollEvents from '../../../utils/ScrollEvents';
import {Header, HeaderController} from '../../Header/HeaderView';
import {InfoBox2, InfoBox2Controller} from '../../components/Inputs/InfoBox2';
import {SurveyCOQController, SurveyCOQView} from './SurveyCOQView';
import {CompanyUser} from "../../../domain/User/CompanyUser";

class SurveyPageController {
    isLoaded = false;
    navigator;
    userStore;
    surveyId;
    survey;
    showPage = null;
    isCompanyAdmin = false;

    currentStep = 0;
    items = [];
    actionInProgress = false;

    cancelSurveyController = new InfoBox2Controller();
    cannotChangeAnonymityInfoBoxController = new InfoBox2Controller();

    constructor(navigator, userStore, surveyId) {
        this.navigator = navigator;
        this.userStore = userStore;
        this.surveyId = surveyId;

        this.survey = new SurveyStore(surveyId);
        this.load();
        this.showPage = 'steps'
    }

    async load() {
        this.isLoaded = false;

        try {

            this.companyUser = new CompanyUser(this.userStore.companyUserId);
            this.isCompanyAdmin = await this.companyUser.getIsCompanyAdmin();
            let isAnonymous = !this.isCompanyAdmin;   //company admin cannot change their anonymity within the surveys, all other users and group admins can

            await this.survey.load();

            if (!this.survey.isOpen) {
                this.navigator.goToPage(this.navigator.siteMap.pages.SurveyList);
            } else {
                this.survey.items.forEach(it => {
                    if (it instanceof OpenQuestionSurveyItem) {
                        let dependant = this.items[this.items.length - 1];
                        this.items.push(new SurveyOpenQuestionController(it.positiveText, it.negativeText, it.value, isAnonymous, dependant, this.userStore.displayName, this.isCompanyAdmin, () => this.showCannotChangeAnonymityInfoBox()));
                    } else if (it instanceof SliderQuestionSurveyItem) {
                        this.items.push(new SurveySliderQuestionController(it.text, it.value));
                    } else if (it instanceof FeedbackSurveyItem) {
                        this.items.push(new SurveyFeedbackController(it.value, isAnonymous, this.userStore.displayName, this.isCompanyAdmin, () =>  this.showCannotChangeAnonymityInfoBox()));
                    } else if (it instanceof COQSurveyItem) {
                        this.items.push(new SurveyCOQController(it.coqId, it.title, it.value, isAnonymous, this.userStore.displayName, this.isCompanyAdmin, () => this.showCannotChangeAnonymityInfoBox()));
                    } else throw new Error('not implemented');
                });
            }
        } catch (e) {
            this.navigator.goToPage(this.navigator.siteMap.pages.Dashboard);
        } finally {
            this.isLoaded = true;
        }
    }


    get currentItem() {
        if (!this.isLoaded) return null;
        return this.items[this.currentStep];
    }

    get canGoNext() {
        //if current page is slider user must move/touch slider to proceed
        if (this.items[this.currentStep] instanceof SurveySliderQuestionController) {
            return this.currentStep < this.items.length - 1 && this.items[this.currentStep].sliderController.sliderMoved;
        }

        return this.currentStep < this.items.length - 1;
    }

    goNext() {
        this.currentStep++;
    }

    get canGoPrev() {
        return this.currentStep > 0;
    }

    goPrev() {
        this.currentStep--;
    }

    get isLastStep() {
        return this.currentStep === this.items.length - 1;
    }

    goFinish() {
        if (!this.actionInProgress) {
            this.actionInProgress = true;
            this.items.forEach((it, idx) => {
                if (it instanceof SurveyOpenQuestionController) {
                    this.survey.items[idx].fill(it.isPositive, it.value, it.isAnonymous)
                } else if (it instanceof SurveyFeedbackController) {
                    this.survey.items[idx].fill(it.value, it.isAnonymous);
                } else if (it instanceof SurveySliderQuestionController) {
                    this.survey.items[idx].fill(it.value);
                } else if (it instanceof SurveyCOQController) {
                    this.survey.items[idx].fill(it.value, it.isAnonymous);
                }
            });
            this.survey.finish()
                .then(
                    () => {
                        this.actionInProgress = false;
                        this.goToResult()
                    }
                );
        }
    }

    goToResult() {
        this.showPage = 'result';
    }

    cancel() {
        this.cancelSurveyController.show()
            .then(result => {
                if (result === 'yes') {
                    this.navigator.back();
                }
            });
    }

    showCannotChangeAnonymityInfoBox(){
        this.cannotChangeAnonymityInfoBoxController.show()
    }
}

decorate(SurveyPageController, {
    isLoaded: observable,
    currentStep: observable,
    items: observable,
    currentItem: computed,
    canGoPrev: computed,
    goPrev: action,
    canGoNext: computed,
    goNext: action,
    isLastStep: computed,
    goFinish: action,
    showPage: observable,
    actionInProgress: observable,
    isCompanyAdmin: observable
});

const SurveyPageView = observer(class SurveyPageView extends Component {
    scrollEvents = new ScrollEvents();

    componentDidMount() {
        this.scrollEvents.startTrackingScroll(document.getElementsByClassName('appContent')[0]);
    }

    componentWillUnmount() {
        this.scrollEvents.stopTrackingScroll();
    }

    renderItem(item, title) {
        if (!item)
            return;
        if (item instanceof SurveySliderQuestionController)
            return (<SurveySliderQuestionView controller={item} title={title}/>);
        else if (item instanceof SurveyOpenQuestionController)
            return (<SurveyOpenQuestionView controller={item} title={title}/>);
        else if (item instanceof SurveyFeedbackController)
            return (<SurveyFeedbackView controller={item} title={title}/>);
        else if (item instanceof SurveyCOQController) {
            return (<SurveyCOQView controller={item} title={title}/>);
        } else throw Error('Not implemented');
    }

    getTitle(item) {
        if (!item)
            return;
        let surveyType = this.props.controller.survey.type;
        if (surveyType === 'company') {
            return lang.get('header.title.weeklySurvey');
        }
        return lang.get('header.title.personalSurvey');
    }


    getAnonymousNote(item) {
        if (!item)
            return;
        if (item instanceof SurveyCOQController) {
            return lang.getDangerous('survey.footer.isAnonymous.coq')
        } else {
            return lang.getDangerous('survey.footer.isAnonymous');
        }
    }

    calculateStep(currentStep, item, items) {
        if (!item)
            return;
        if (item instanceof SurveySliderQuestionController) {
            //Find index of openQuestion and if current item is greater then index subtract current step, better then hardcoded > 3 but maybe we need to find some better solution...
            let openQuestionItemIndex = 0;
            items.map(function (it, ix) {
                if (it instanceof SurveyOpenQuestionController) openQuestionItemIndex = ix;
                return it; //must return some value, react shows some warning
            });
            if (currentStep > openQuestionItemIndex) return currentStep - 1;
            return currentStep;
        } else if (item instanceof SurveyOpenQuestionController)
            return currentStep - 1;
        else if (item instanceof SurveyFeedbackController)
            return currentStep - 2;
        else if (item instanceof SurveyCOQController)
            return currentStep - 2;
        else throw Error('Not implemented');
    }

    render() {
        let controller = this.props.controller;
        let currentStep = controller.currentStep + 1;
        let totalSteps = controller.items.length - 1 - 1; //total questions - open question

        return (
            <React.Fragment>
                <InfoBox2 id="cancelSurveyInfoBox"
                          title={lang.get('modal.survey.cancel.Title')}
                          icon={'far fa-exclamation-triangle'}
                          hasYes={true}
                          hasNo={true}
                          controller={controller.cancelSurveyController}>
                    <p>{lang.get('survey.normal.cancel.text')}</p>
                </InfoBox2>
                <InfoBox2 icon={'far fa-exclamation-triangle'}
                          title={lang.get('feedbackMessages.infoBox.changeAnonymous.title')}
                          hasYes={true}
                          hasNo={false}
                          controller={controller.cannotChangeAnonymityInfoBoxController}>
                    <p>{lang.get('feedbackMessages.infoBox.changeAnonymous.adminCannotChange')}</p>
                </InfoBox2>
                <LoadingModal isVisible={!controller.isLoaded}/>
                <main className={`fadeIn ${controller.showPage === 'result' ? 'bg-style1' : ''}`}>
                    <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                    <div className="appContent">
                        <div className="appContent-body">
                            {
                                controller.showPage === 'steps' &&
                                <React.Fragment>
                                    {this.renderItem(controller.currentItem, this.getTitle(controller.currentItem))}
                                    <StepsFooterBar
                                        scrollEvents={this.scrollEvents}
                                        canPrev={controller.canGoPrev}
                                        clickPrev={() => {
                                            controller.goPrev();
                                            this.scrollEvents.scrollToTop();
                                        }}
                                        canNext={controller.canGoNext}
                                        clickNext={() => {
                                            controller.goNext();
                                            this.scrollEvents.scrollToTop();
                                        }}
                                        canFinish={controller.isLastStep}
                                        clickFinish={() => controller.goFinish()}
                                        isNextBtnActive={controller.canGoNext}
                                        isPrevBtnActive={controller.canGoPrev}
                                        showCancel={false}
                                        clickCancel={() => controller.cancel()}
                                        showSteps={true}
                                        currentStep={this.calculateStep(currentStep, controller.currentItem, controller.items)}
                                        totalSteps={totalSteps}/>
                                    <p className="survey-anonymous-note">{this.getAnonymousNote(controller.currentItem)}</p>
                                </React.Fragment>
                            }
                            {
                                controller.showPage === 'result' &&
                                <SurveyResultView
                                    goToDashboard={() => controller.navigator.goToPage(controller.navigator.siteMap.pages.Dashboard)}/>
                            }
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
});

export {SurveyPageController, SurveyPageView};
