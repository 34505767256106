import React, {Component} from 'react';
import {observer} from 'mobx-react';
import InputWrap from './InputWrap';
import {hideIfNot} from '../../../utils/Utils';


const DynamicInput = observer(class DynamicInput extends Component {
    timeoutHandler = null;

    render() {
        let type = this.props.type;
        let name = this.props.name;
        let extraClass = this.props.extraClass;
        let placeholder = this.props.placeholder;
        let validatedValue = this.props.validatedValue;
        let maxLength = this.props.maxLength;
        let autocomplete = this.props.autocomplete;
        let disabled = this.props.disabled;

        let onChange = (e) => {
            validatedValue.valueWithTouch = e.target.value;
            if (this.timeoutHandler != null) clearTimeout(this.timeoutHandler);
            this.timeoutHandler = setTimeout(() => {
                validatedValue.blur();
            }, 2500);
        };

        return (
            <InputWrap id={name} validationMsg={validatedValue.displayError} extraClass={extraClass}>
                <div className="form-input-dropdown">
                    <input type={type || 'text'}
                           className="form-input"
                           name={name}
                           id={name}
                           placeholder={placeholder}
                           value={validatedValue.value ? validatedValue.value : ''}
                           maxLength={maxLength}
                           autoComplete={autocomplete}
                           onChange={onChange}
                           onInput={(e) => {
                               if (type === 'number') e.target.value = e.target.value.slice(0, e.target.maxLength)
                           }}
                        // onBlur={() => validatedValue.blur()}
                           onFocus={() => validatedValue.focus()}
                           disabled={disabled}
                    />
                    <i className="fa fa-spinner fa-spin save-icon" style={hideIfNot(validatedValue.isBeingChanged)}/>
                    <i className="fa fa-check save-icon" style={hideIfNot(validatedValue.isSaved)}/>
                </div>
            </InputWrap>
        );
    }
});

export default DynamicInput;