import React from 'react';
import {action, decorate, observable} from 'mobx';
import {observer} from 'mobx-react';

class ListItemController {
    constructor(text, id, childrenItems, isActive, canGoToViewTeam, goToViewTeam ) {
        this.text = text;
        this.id = id;
        this.childrenItems = childrenItems;
        this.isActive = isActive;
        this.goToViewTeam = goToViewTeam;
        this.canGoToViewTeam = canGoToViewTeam;
    }

    toggle() {
        if(!this.hasChildren) return;
        this.isActive = !this.isActive;
    }


    getChildrenCount() {
        let sum = 0;
        this.childrenItems.map(it => it.getChildrenCount()).forEach(count => sum += count);
        return sum + this.childrenItems.length;
    }

    get hasChildren() {
        return this.childrenItems.length > 0;
    }
}

decorate(ListItemController, {
    isActive: observable,
    toggle: action
});

class TeamListHierarchyController {
    rootItem;

    constructor(hierarchyItem, goToViewTeam, canGoToViewTeam) {



        this.rootItem = this.convertInputDataItemToListItemState(hierarchyItem,canGoToViewTeam, goToViewTeam);
    }

    convertInputDataItemToListItemState(inputDataItem, canGoToViewTeam, goToViewTeam) {
        let children = [];
        if(inputDataItem.childrenItems) children = inputDataItem.childrenItems.map(it => this.convertInputDataItemToListItemState(it, canGoToViewTeam, goToViewTeam));
        return new ListItemController(inputDataItem.text, inputDataItem.id, children, false, canGoToViewTeam, goToViewTeam);
    }

}

decorate(TeamListHierarchyController, {
    rootItem: observable
});

const ListItemView = observer(({item}) => {
    return (
        <ul>
            <li className={`${item.hasChildren ? 'has-children' : ''} ${item.isActive ? 'active' : ''}`} onClick={(e) => {
                e.stopPropagation();
                item.toggle();
            }}>
                <span onClick={(e) => {
                    e.stopPropagation();
                    item.canGoToViewTeam && item.goToViewTeam(item.id)
                }}>{item.text}</span>
                {item.childrenItems.map((childItem,index) => <ListItemView key={index} item={childItem}/>)}
            </li>
        </ul>
    );
});

const TeamListHierarchyView = observer(({controller}) => (
    <React.Fragment>
        <h3 className={`root-name ${controller.rootItem.hasChildren ? 'has-children' : ''} ${(controller.rootItem.isActive ? 'active' : '')}`} onClick={(e) => {
            e.stopPropagation();
            controller.rootItem.toggle();
        }}> <span onClick={(e) => {
            e.stopPropagation();
            controller.rootItem.canGoToViewTeam && controller.rootItem.goToViewTeam(controller.rootItem.id);
        }}>{controller.rootItem.text} ({controller.rootItem.getChildrenCount()})</span></h3>
        <div className="hierarchy-list">
            {controller.rootItem.childrenItems.map((it, index) => <ListItemView key={index} item={it}/>)}
        </div>
    </React.Fragment>
));

export {TeamListHierarchyView, TeamListHierarchyController};