import {axios} from '../../utils/WrappedAxios';

export default class InviteUser {
    constructor(id, email) {
        this.id = id;
        this.email = email;
    }

    create() {
        return axios.put(`/api/invite/users/${this.id}/${this.email}`)
            .then(() => true)
            .catch(error => {
                if (error.response.status === 404) return false;
                throw error;
            });
    }

    delete(){
        return axios.delete(`/api/invite/users/${this.email}`)
            .then(() => true)
            .catch(error => {
                if (error.response.status === 404) return false;
                throw error;
            });
    }
};