import React from 'react';
import {observer} from 'mobx-react';
import InputWrap from './InputWrap';

const InputCheckbox = observer(({name, label, validatedValue, disabled}) => {

    let toggle = () => {
        if (disabled === 'disabled') return;
        validatedValue.focus();
        validatedValue.valueWithTouch = !validatedValue.value;
        validatedValue.blur();
    };
    let change = (newValue) => {
        validatedValue.focus();
        validatedValue.valueWithTouch = newValue;
        validatedValue.blur();
    };

    return (
        <InputWrap id={name} validationMsg={validatedValue.displayError}>
            <div className={`input-check-box horizontal ${(validatedValue.value === true ? 'selected' : '')}`}>
                <label htmlFor={name} onClick={() => toggle()}>
                    <input type="checkbox"
                           name={name}
                           value={validatedValue.value}
                           checked={validatedValue.value}
                           onChange={(e) => change(e.target.checked)}
                           disabled={disabled}
                    />
                    <i className="far fa-check"/>
                </label>
                <span>{label}</span>
            </div>
        </InputWrap>
    );
});

export default InputCheckbox;