import React from 'react';
import {decorate, observable, action} from 'mobx';
import {observer} from 'mobx-react';
import {findFirstOrNull} from '../../utils/ReactHelpers';
import {hideIfNot} from '../../../utils/Utils';

class InfoBox2Controller {
    isVisible = false;
    resolve = null;

    /**
     *  return values 'yes', 'no', 'close'
     */
    show() {
        return new Promise(resolve => {
            this.resolve = resolve;
            this.isVisible = true;
        });
    }

    hide() {
        this.resolve = null;
        this.isVisible = false;
    }

    _onYes() {
        this.isVisible = false;
        if (this.resolve !== null) this.resolve('yes');
    };

    _onNo() {
        this.isVisible = false;
        if (this.resolve !== null) this.resolve('no');
    };

    _onClose() {
        this.isVisible = false;
        if (this.resolve !== null) this.resolve('close');
    }
}

decorate(InfoBox2Controller, {
    isVisible: observable,
    show: action,
    _onYes: action,
    _onNo: action,
    _onClose: action
});

const InfoBox2Header = observer(({children}) => {
    return <React.Fragment>{children}</React.Fragment>
});

const InfoBox2Content = observer(({children}) => {
    return <React.Fragment>{children}</React.Fragment>
});

const InfoBox2 = observer(({id, title, icon, children, hasYes, hasNo, hasClose, controller}) => {
    let onYes = (e) => {
        e.preventDefault();
        controller._onYes();
    };
    let onNo = (e) => {
        e.preventDefault();
        controller._onNo();
    };
    let onClose = (e) => {
        e.preventDefault();
        controller._onClose();
    };

    let header = findFirstOrNull(children, InfoBox2Header);
    if (header === null) header =
        <React.Fragment><i className={(icon ? icon : 'far fa-question-circle')}/><h2>{title}</h2></React.Fragment>;
    let content = findFirstOrNull(children, InfoBox2Content);
    if (content === null) content = <React.Fragment>{children}</React.Fragment>;

    return (
        <React.Fragment>
            <div className="info-box" id={id} style={hideIfNot(controller.isVisible)}>
                <div className="info-box-body">
                    <div className="info-box-header">
                        {header}
                        <button className="btn plain" onClick={onClose} style={hideIfNot(hasClose)}><i
                            className="far fa-times"/></button>
                    </div>
                    <div className="info-box-content">
                        {content}
                    </div>
                    <div className="info-box-footer">
                        <button className="btn plain decline" onClick={onNo} style={hideIfNot(hasNo)}><i
                            className="far fa-times"/></button>
                        <button className="btn plain apply" onClick={onYes} style={hideIfNot(hasYes)}><i
                            className="far fa-check"/></button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
});

export {InfoBox2, InfoBox2Controller, InfoBox2Content, InfoBox2Header};