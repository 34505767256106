import {hideIfNot, roundSpirit} from '../../utils/Utils';
import React from 'react';
import {calculateColor} from './ColorCalculator';

export const ReportCardItem = ({title, value, tendency, goToDetails, openInfoBox, lightVersion}) => {
    let color = calculateColor(value);

    let valueLabel = 'n/a';
    let progressBar = 0.0;

    if (value !== null) {
        valueLabel = roundSpirit(value);
        progressBar = 10 * value;
    }

    let isLight = '';
    if (lightVersion === true) isLight = 'light';

    return (
        <div className={`report-card ${isLight} license-lock-opacity`} onClick={goToDetails}>
            <span className="title">
                <h4>
                    {title}
                    <button className="btn none" onClick={(e) => {
                        e.stopPropagation();
                        openInfoBox();
                    }}><i className="far fa-question-circle"/></button>
                </h4>
            </span>
            <div className="value-with-tendency">
                <i className="fa fa-caret-up" style={hideIfNot(tendency > 0)}/>
                <i className="fa fa-caret-down" style={hideIfNot(tendency < 0)}/>
                <span>{valueLabel}</span>
            </div>
            <div className="progress-bar-wrap">
                <div className={`progress-bar progress-bar-${color}`} style={{width: `${progressBar}%`}}/>
            </div>
        </div>);
};