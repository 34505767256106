import React from 'react';
import {decorate, action, computed, observable} from 'mobx';
import {observer} from 'mobx-react';
import countryList from '../../assets/resources/countries';
import {
    validateBirthYear,
    validateIsNumber,
    validateNotBlank,
    validateEmail,
    validateNotNull
} from '../../utils/Validators';
import {lang} from '../utils/Lang';
import {notificationManager} from '../components/Notifications';
import {Header, HeaderController} from '../Header/HeaderView';
import {ContentTitle} from '../components/ContentTitle';
import ValidatedValue from '../components/Inputs/ValidatedValue';
import {InputSelect, InputSelectController} from '../components/Inputs/InputSelect';
import Input from '../components/Inputs/Input';
import InputGender from '../components/Inputs/InputGender';
import LoadingModal from '../components/LoadingModal';


let industryKeys = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U'];
let genderKeys = ['m', 'f'];

class UserProfileEditController {
    isLoading = false;

    genderOptions = genderKeys.map(it => {
        return {
            value: it,
            text: lang.get(`registration.gender.${it}`)
        }
    });
    countryOptions = countryList.map(it => {
        return {
            value: it.code,
            text: it.name
        };
    });
    industryOptions = industryKeys.map(it => {
        return {
            value: it,
            text: lang.get(`industryList.${it}`)
        };
    });

    firstName = new ValidatedValue([validateNotBlank]);
    lastName = new ValidatedValue([validateNotBlank]);
    email = new ValidatedValue([validateEmail]);
    yearOfBirth = new ValidatedValue([validateNotBlank, validateIsNumber, validateBirthYear]);
    gender = new ValidatedValue([], this.genderOptions[0].value);
    street = new ValidatedValue([validateNotBlank]);
    zipCode = new ValidatedValue([validateNotBlank]);
    city = new ValidatedValue([validateNotBlank]);
    country = new ValidatedValue([validateNotNull], null);
    industry = new ValidatedValue([validateNotNull], null);

    formFields = [
        this.firstName,
        this.lastName,
        this.email,
        this.yearOfBirth,
        this.gender,
        this.street,
        this.zipCode,
        this.city,
        this.country,
        this.industry
    ];

    constructor(navigator, userStore) {
        this.navigator = navigator;
        this.userStore = userStore;

        let hasNull = true;
        this.countrySelectController = new InputSelectController(this.countryOptions, this.country, hasNull);
        this.industrySelectController = new InputSelectController(this.industryOptions, this.industry, hasNull);

        if (this.userStore.userDetails) {
            this.firstName.value = this.userStore.userDetails.firstName;
            this.lastName.value = this.userStore.userDetails.lastName;
            this.yearOfBirth.value = this.userStore.userDetails.birthYear;
            this.gender.value = this.userStore.userDetails.gender;
            this.street.value = this.userStore.userDetails.street;
            this.zipCode.value = this.userStore.userDetails.zipCode;
            this.city.value = this.userStore.userDetails.city;
            this.country.value = this.userStore.userDetails.country;
            this.industry.value = this.userStore.userDetails.industry;
        }

        this.email.value = this.userStore.email;

        if (!userStore.isRegistered) notificationManager.custom(lang.get('notification.completeYourProfile'), lang.get('notification.title.completeProfile'))
    }

    get currentCountryValue() {
        return this.countryOptions.find((element) => {
            return element.value === this.country.value;
        }).text;
    }

    get currentIndustryValue() {
        return this.industryOptions.find((element) => {
            return element.value === this.industry.value;
        }).text;
    }

    get isFormValid() {
        return this.formFields.filter(it => it.isValid === false).length === 0;
    }

    async submit() {
        this.formFields.forEach(it => it.touch());
        if (!this.isFormValid) {
            return;
        }

        this.isLoading = true;
        await this.userStore.currentUser.register(
            this.firstName.value,
            this.lastName.value,
            this.yearOfBirth.value,
            this.gender.value,
            this.street.value,
            this.zipCode.value,
            this.city.value,
            this.country.value,
            this.industry.value
        );
        await this.userStore.checkLogin(); // refresh user data
        this.isLoading = false;

        if(this.userStore.currentCompanyUser === null)
            this.navigator.goToPage(this.navigator.siteMap.pages.NonCompanyUserWelcomeScreen);
        else
            this.navigator.goToPage(this.navigator.siteMap.pages.ViewProfile);
    }
}

decorate(UserProfileEditController, {
    isLoading: observable,
    currentCountryValue: computed,
    currentIndustryValue: computed,
    isFormValid: computed,
    validate: action,
    submit: action
});

const UserProfileEditPage = observer(({theme, controller}) => {


    return (
        <React.Fragment>
            <main className="fadeIn">
                <LoadingModal isVisible={controller.isLoading}/>
                <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                <div className="appContent">
                    <div className="appContent-body app-footer-automatic-parent">
                        <ContentTitle title={lang.get('appheader.userProfile.title')}
                                      subtitle={lang.get('appheader.userProfile.subtitle')}/>
                        <form onSubmit={() => true}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-header small">
                                        <div className="card-header-icon">
                                            <i className="far fa-user-circle"/>
                                        </div>
                                        <div className="card-header-title">
                                            <h2>{lang.get('registration.personalDetails')}</h2>
                                        </div>
                                    </div>
                                    <div className="card-content form-layout">
                                        <Input name="firstName"
                                               placeholder={lang.get('registration.firstName') + ' *'}
                                               autocomplete="given-name"
                                               validatedValue={controller.firstName}/>

                                        <Input name="lastName"
                                               placeholder={lang.get('registration.lastName') + ' *'}
                                               autocomplete="family-name"
                                               validatedValue={controller.lastName}/>

                                        <Input name="email"
                                               placeholder={lang.get('registration.email') + ' *'}
                                               autocomplete="email"
                                               validatedValue={controller.email}
                                               disabled={true}/>

                                        <Input name="yearOfBirth"
                                               placeholder={lang.get('registration.birthYear') + ' *'}
                                               validatedValue={controller.yearOfBirth}
                                               type="number"
                                               maxLength={4}>
                                        </Input>

                                        <InputGender validatedValue={controller.gender}/>
                                    </div>
                                    <div className="card-header small">
                                        <div className="card-header-icon">
                                            <i className="far fa-file-user"/>
                                        </div>
                                        <div className="card-header-title">
                                            <h2>{lang.get('registration.additionalDetails')}</h2>
                                        </div>
                                    </div>
                                    <div className="card-content form-layout">
                                        <Input name="street"
                                               placeholder={lang.get('registration.street')}
                                               validatedValue={controller.street}/>
                                        <div className="input-wrap-two">
                                            <Input name="zipCode"
                                                   placeholder={lang.get('registration.zipCode')}
                                                   validatedValue={controller.zipCode}/>
                                            <Input name="city"
                                                   placeholder={lang.get('registration.city')}
                                                   validatedValue={controller.city}/>
                                        </div>
                                        <InputSelect controller={controller.countrySelectController}
                                                     name="country"
                                                     placeholder={lang.get('registration.country')}/>

                                        <InputSelect controller={controller.industrySelectController}
                                                     name="industry"
                                                     placeholder={lang.get('registration.industry')}/>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="app-footer-automatic">
                            <div/>
                            <button className="btn center" onClick={() => controller.submit()}>
                                <span>{lang.get('registration.submitBtn')}</span> <i className="far fa-save"/></button>
                            <div/>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>
    );
});

export {UserProfileEditPage, UserProfileEditController};
