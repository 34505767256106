import {axios} from '../../utils/WrappedAxios';
import {decorate, observable, action} from 'mobx';
import Notification from './Notification';
import _ from 'lodash';
import {AddedToCompanyNotification} from './Types/AddedToCompanyNotification';
import {AddedToTeamNotification} from './Types/AddedToTeamNotification';
import {NewSurveyNotification} from './Types/NewSurveyNotification';
import {TopicUpdatedNotification} from './Types/TopicUpdatedNotification';
import {UserDisconnectedFromCompanyNotification} from './Types/UserDisconnectedFromCompanyNotification';
import {TopicCreatedNotification} from './Types/TopicCreatedNotification';

class NotificationFilter {
    constructor(isRead, type, group) {
        this.isRead = isRead;
        this.type = type;
        this.group = group;
    }

    toQueryString() {
        let filters = [];
        if (this.isRead !== null) filters.push(`isRead=${this.isRead}`);
        if (this.type) filters.push(`type=${this.type}`);
        if (this.group) filters.push(`group=${this.group}`);

        return _.join(filters, '&');
    }
}

class NotificationCollection {
    static notificationTypes = [
        AddedToCompanyNotification,
        AddedToTeamNotification,
        NewSurveyNotification,
        TopicUpdatedNotification,
        TopicCreatedNotification,
        UserDisconnectedFromCompanyNotification
    ];

    async getList(notificationFilter) {
        let queryString = '';
        if (notificationFilter) {
            queryString = `?${notificationFilter.toQueryString()}`;
        }
        let notificationResponse = await axios.get(`/api/notification${queryString}`);
        return notificationResponse.data.notifications.map(it => new Notification(it.id, it.isRead, it.createInstant, this._parsePayload(it.payload)));
    }

    async hasUnreadNotifications() {
        let response = await axios.get('/api/notification/hasUnread');
        return response.data;
    }

    _parsePayload(payload) {
        for (let i = 0; i < NotificationCollection.notificationTypes.length; i++) {
            let result = NotificationCollection.notificationTypes[i].tryParse(payload);
            if (result != null) {
                return result;
            }
        }
    }
}

decorate(NotificationCollection, {
    notifications: observable.shallow,
    markAllAsRead: action,
    load: action
});

export {NotificationCollection, NotificationFilter};