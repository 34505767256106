import {axios} from '../utils/WrappedAxios';

class StatisticsService {
    async getCompanyStatistics() {
        let response = await axios.get('/api/statistics/company');
        return response.data;
    }

    async getUsersWithoutCompany(){
        let response = await  axios.get('/api/statistics/users-without-company');
        return response.data;
    }
}

export {StatisticsService};