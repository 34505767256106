import {ContentTitle} from './ContentTitle';
import React from 'react';


const WellDone = ({title, subtitle, content, btnTitle, onBtnClick}) => {
    return (
        <React.Fragment>
            <ContentTitle title={title} subtitle={subtitle}/>
            <div className="score-survey">
                <div className="score-container">
                    <div className="score-outer-circle">
                        <div className="score-inner-circle">
                            <div className="score-inner-circle-content">
                                <i className="fal fa-award"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="survey-result">
                <p>{content}</p>
                <button className="btn" onClick={() => onBtnClick()}><span>{btnTitle}</span> <i
                    className="far fa-arrow-circle-right"/></button>
            </div>
        </React.Fragment>
    );
};
export default WellDone;

