import React, {Component} from 'react';
import ScrollEvents from '../../utils/ScrollEvents';


class HeaderBarWrapper extends Component {
    scrollEvents = new ScrollEvents();

    render() {
        return (
            <div className="appHeader">
                <div className="appHeaderWrap">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default HeaderBarWrapper;
