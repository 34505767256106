import React from 'react';
import {lang} from './utils/Lang';
import {decorate} from 'mobx';
import {observer} from 'mobx-react';
import logoImage from '../assets/img/logo-doods.svg';
import {ChangeLanguageInput, ChangeLanguageInputController} from './components/ChangeLanguageInput';
import {EmailInput, EmailInputStore} from './components/EmailInput';
import {notificationManager} from './components/Notifications';
import UserCollection from '../domain/User/UserCollection';

class SignUpPageController {
    languageSelectController = new ChangeLanguageInputController();
    emailStore = new EmailInputStore();
    userCollection = new UserCollection();

    constructor(navigator, userStore) {
        this.navigator = navigator;
        this.userStore = userStore;
    }

    async goToProfiling() {
        this.emailStore.validate();
        if (!this.emailStore.isEmailValid) return;
        let isEmailRegistered = await this.userCollection.isEmailRegistered(this.emailStore.email);

        if (isEmailRegistered) {
            notificationManager.info(lang.get('profilingSignUp.start.email.exists'));
        } else {
            this.navigator.goToPageWithoutStateChange(this.navigator.siteMap.postPages.Profiling, this.emailStore.email);
        }
    }

    goToLogin() {
        this.navigator.goToPage(this.navigator.siteMap.pages.Login)
    }
}

decorate(SignUpPageController, {});

const SignUpPage = observer(({controller}) => {
    let goToProfiling = (e) => {
        e.preventDefault();
        controller.goToProfiling();
    };

    return (
        <main className="bg-style2">
            <div className="appContent">
                <div className="appContent-body fadeIn login-content">
                    <div className="logo">
                        <img src={logoImage} alt="DooDs Logo"/>
                    </div>
                    <div className="content">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-header">
                                    <h1>{lang.get('login.header.signup')}</h1>
                                    <ChangeLanguageInput controller={controller.languageSelectController} txtCenter={true}/>
                                </div>
                                <div className="card-content">
                                    <div className="indent-wrap">
                                        <p>{lang.get('profilingSignUp.start.instruction')}</p>
                                    </div>
                                    <form onSubmit={goToProfiling}>
                                        <EmailInput state={controller.emailStore} name={'email'}/>
                                        <button id="signup-submit" type="submit" className="btn fadeIn blue">
                                            <span>{lang.get('login.button.startProfiling')}</span>
                                        </button>
                                    </form>
                                </div>
                                <div className="card-footer">
                                    <p>{lang.get('profilingSignUp.start.goToLogin')}
                                        <a href="#/" id="goto-login-btn" onClick={(e) => {
                                            e.preventDefault();
                                            controller.goToLogin();
                                        }}> {lang.get('login.button.returnToLogin')}</a>
                                    </p>
                                    <div className="small-text">
                                        <p>{lang.getDangerous('login.footer.gdprNote')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
});

export {SignUpPageController, SignUpPage};