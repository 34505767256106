import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {decorate, action, observable} from 'mobx';
import {Header, HeaderController} from '../Header/HeaderView';
import {lang} from '../utils/Lang';
import {ContentTitle} from '../components/ContentTitle';
import {COQEditItem, COQEditItemController} from "./COQEditItem";

class COQEditPageController {
    userStore;
    navigator;

    constructor(navigator, userStore, coqId) {
        this.userStore = userStore;
        this.navigator = navigator;
        this.coqId = coqId;
        this.COQEditItemController = new COQEditItemController(this.coqId)
    }

    onBack() {
        this.navigator.back();
    }

}

decorate(COQEditPageController, {
    onDelete: action,
    onEdit: action,
    coqId: observable
});

const COQEditPageView = observer(class COQEditPageView extends Component {


    render() {
        let controller = this.props.controller;


        return (
            <React.Fragment>
                <main className="fadeIn">
                    <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                    <div className="appContent">
                        <div className="appContent-body">
                            <ContentTitle title={lang.get("appheader.coq.edit.title")}
                                          subtitle={lang.get("appheader.coq.edit.subtitle")}/>

                            <COQEditItem controller={controller.COQEditItemController}/>

                            <div className="app-footer-automatic">
                                <div/>
                                <button className="btn center coq-btn-back" onClick={() => controller.onBack()}>
                                    <i className="far fa-arrow-circle-left"/>
                                    <span>{lang.get('coq.edit.btn.back')}</span>
                                </button>
                                <div/>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
});

export {COQEditPageView, COQEditPageController};