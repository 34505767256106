import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {lang} from '../utils/Lang'
import {ContentTitle} from '../components/ContentTitle';
import {decorate, observable} from "mobx";
import InviteUserCollection from "../../domain/Invite/InviteUserCollection";

class CompanyInvitesController {
    navigator;
    userStore;
    items = [];
    inviteUserCollection = new InviteUserCollection();

    constructor(navigator, userStore) {
        this.navigator = navigator;
        this.userStore = userStore;
        this.loadTeams();
    }

    async loadTeams() {
        await this.inviteUserCollection.load();
        this.items.clear();
        this.items = this.inviteUserCollection.items;
    }
}

decorate(CompanyInvitesController, {
    items: observable,
});


const CompanyInvitesView = observer(class CompanyInvitesView extends Component {
    renderListItem(id, inviteId, email, status) {
        return <li key={id} id={`item-${id}`}>
            <div className="text">
                <h3>
                    {email} ({status})
                </h3>
            </div>
        </li>;
    }

    render() {
        let controller = this.props.controller;


        let goToCompanySettings = () => {
            controller.navigator.goToPage(controller.navigator.siteMap.pages.CompanySettings)
        };

        let items = controller.items;
        let renderItems = items.map(it => this.renderListItem(
            it.id,
            it.inviteId,
            it.email,
            lang.get("invites.status." + it.status)
         ));

        return (
            <React.Fragment>
                <ContentTitle title={lang.get('appheader.invites.title')} subtitle={lang.get('appheader.invites.subtitle')}/>
                <div className="card">
                    <div className="card-body">
                        <div className="card-header small">
                            <div className="card-header-icon">
                                <i className="far fa-address-book"/>
                            </div>
                            <div className="card-header-title">
                                <h2>{lang.get('company.landing.title.invites')}</h2>
                            </div>
                        </div>
                        <div className="card-content">
                            <ul className="list-with-buttons">
                                {renderItems}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="app-footer-automatic">
                    <div/>
                    <button className="btn center coq-btn-back" onClick={goToCompanySettings}>
                        <i className="far fa-arrow-circle-left"/>
                        <span>{lang.get('coq.edit.btn.back')}</span>
                    </button>
                    <div/>
                </div>
            </React.Fragment>
        );
    }
});

export {CompanyInvitesView, CompanyInvitesController};
