import React, {Component} from 'react';
import {SolutingListItem} from './SolutingListItem';
import {hideIfNot} from '../../../utils/Utils';
import {SolutingSlider} from './SolutingSlider';
import _ from 'lodash';

class SolutingListGroup extends Component {

    createSolutingListItem(item) {
        return <SolutingListItem key={item.id} title={item.title} description={item.description}
                                 category={item.category} groupId={item.groupId} imageUrl={item.imageUrl}
                                 breadcrumbs={item.breadcrumbs} isLocked={item.isLocked} isFavorite={false}
                                 onClick={() => this.props.navigator.goToPage(this.props.navigator.siteMap.pages.Soluting, item.id)}
                                 navigator={this.props.navigator}/>
    }

    render() {
        let sortedGroupItems = _.orderBy(this.props.items, 'isLocked', ['asc']);
        let groupItems = sortedGroupItems.map((item) => {
            return this.createSolutingListItem(item);
        });

        return (
            <div className="soluting-group">
                <div className="group-title" style={hideIfNot(!this.props.hideTitle)}>
                    <h2>{this.props.name}</h2>
                </div>
                <SolutingSlider>
                    {groupItems}
                </SolutingSlider>
            </div>
        );
    }
}

export default SolutingListGroup;