import React from 'react';

const AnonymousCheckBox = ({isChecked, disabled, onToggle, name, hidden}) => (
    <div className={`input-check-box-anonymous ${(disabled ? 'disabled' : '')} ${(hidden ? 'hidden' : '')} ${(isChecked ? 'isAnonymous' : '')}`}
         onClick={onToggle}>
        {isChecked ?
            <i className="far fa-user-secret"/>
            :
            <div className="user-name">{name}</div>
        }
        <div className="switch">
            <input type="checkbox"
                   checked={isChecked}
                   data-selenium-disabled={disabled} //regular disabled will prevent on click event but we must show info box on change, we use this data attribute for tests
            />
            <span className="slider"/>
        </div>
    </div>
);

export {AnonymousCheckBox};