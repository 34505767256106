import React, {Component} from 'react';
import {notificationManager} from '../components/Notifications';
import {lang} from '../utils/Lang';
import LoadingModal from '../components/LoadingModal';
import {axios, setJWT} from '../../utils/WrappedAxios';
import Cookies from 'js-cookie';

class VerificationPageController {
    constructor(navigator, userStore, verificationId, queryString) {
        this.navigator = navigator;
        this.userStore = userStore;
        this.verificationId = verificationId;
        this.returnUrl = null;
        if (queryString && queryString.returnUrl) this.returnUrl = queryString.returnUrl;

        if (queryString && queryString.type === 'helperLogin') this.signIn(queryString.email);
        else this.useVerification();
    }

    async signIn(email) {
        let jwtToken = (await axios.get(`/api/help/jwt-login/${email}`)).data;
        setJWT(jwtToken);
        Cookies.set('jwt-token', jwtToken, {expires: 365});
        await this.userStore.checkLogin();
        window.facebookWrapper.track("User logged in", { email: email });
        this.navigator.goToPage(this.navigator.siteMap.pages.Dashboard);
    }

    async useVerification() {
        if (!this.userStore.isLoggedIn) {
            try {
                await this.userStore.login(this.verificationId);
            } catch (e) {
                notificationManager.error(lang.get('notification.error.cannot_verify_token'), e);
                this.navigator.goToPage(this.navigator.siteMap.pages.Login);
            }
        }

        // this must be here because of bug in navigator implementation (you cannot push new state in while constructor of current state is running)
        setTimeout(() => {
            try {
                if (this.returnUrl) {
                    let url = decodeURIComponent(this.returnUrl);
                    this.navigator.pushState(url);
                } else this.navigator.goToPage(this.navigator.siteMap.pages.Dashboard);
            } catch (e) {
                this.navigator.goToPage(this.navigator.siteMap.pages.Dashboard);
            }
        }, 0);
    }
}

class VerificationPageView extends Component {
    render() {
        return (<main>
            <div className="appContent">
                <div className="appContent-body verification-page">
                    <LoadingModal isVisible={true}/>
                </div>
            </div>
        </main>);
    }
}

export {VerificationPageController, VerificationPageView};