import React from 'react';
import {observer} from 'mobx-react';

const InputWrap = observer(({id, validationMsg, extraClass, children}) => (
    <div id={`inputWrap-${id}`} className={'form-input-wrap ' + (validationMsg ? 'error ' : '') + (extraClass ? extraClass : ' ')}>
        {children}
        {validationMsg &&
        <div className="errorBox">{validationMsg}</div>
        }
    </div>
));

export default InputWrap;