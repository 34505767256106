import React from 'react';
import ui from '../../assets/resources/ui.json';
import industryOptions from '../../assets/resources/industryOptions.json';
import profilingMatrix from '../../assets/resources/profilingMatrix.json';
import profilingResults from '../../assets/resources/profilingResults.json';
import {decorate, observable, action} from 'mobx';

class Lang {
    language = 'EN';
    languageDescription = 'English';
    isDevelopmentENV = true;

    dicts = [
        ui, industryOptions, profilingResults, profilingMatrix
    ];

    setLanguage(newLang) {
        this.language = newLang;
        if (newLang === 'DE') this.languageDescription = 'Deutsch';
        if (newLang === 'EN') this.languageDescription = 'English';
        if (newLang === 'HU') this.languageDescription = 'Magyar';
        if (newLang === 'SL') this.languageDescription = 'Slovak';
        if (newLang === 'HR') this.languageDescription = 'Hrvatski';
    }

    get(item) {
        for (let i = 0; i < this.dicts.length; i++) {
            if (this.dicts[i][item]) {
                if (this.dicts[i][item][this.language] === null)
                    return `--- missing ${item}/${this.language} ---`;
                else {
                    if (!this.dicts[i][item][this.language] && this.isDevelopmentENV === true)
                        return `--- missing ${item}/${this.language} ---`;
                    else
                        return this.dicts[i][item][this.language];
                }
            }
        }
        return `--- missing ${item} ---`
    }

    getDangerous(item) {
        return <span dangerouslySetInnerHTML={{__html: lang.get(item)}}/>;
    }

    todo(text) {
        return text;
    }
}

decorate(Lang, {
    language: observable,
    languageDescription: observable,
    setLanguage: action
});

let lang = new Lang();
window.language = lang;
if (!window.doods) window.doods = {};
window.doods.lang = lang;

export {lang};