import React from 'react';
import {Score} from './Score';
import {hideIfNot, roundSpirit} from '../../utils/Utils';

export const ReportScore = ({spirit, spiritDifference, children}) => {
    let previousText = roundSpirit(spiritDifference);

    return (
        <div className="report-score">
            <Score spirit={spirit}/>
            <div className="extended-row">
                {children}
                <div className="btn small-value-with-tendency">
                    <i className="fa fa-caret-up" style={hideIfNot(spiritDifference > 0)}/>
                    <span>{previousText}</span>
                    <i className="fa fa-caret-down" style={hideIfNot(spiritDifference < 0)}/>
                </div>
            </div>
        </div>
    );
};