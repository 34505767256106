import React, {Component} from 'react';
import {lang} from '../utils/Lang';
import {computed, decorate} from "mobx";
import {observer} from "mobx-react";
import {InputSelect, InputSelectController} from "../components/Inputs/InputSelect";
import ValidatedValue from "../components/Inputs/ValidatedValue";
import {validateNotBlank, validateNotNull} from "../../utils/Validators";
import Textarea from "../components/Inputs/Textarea";
import Surveys from "../../domain/Survey/Surveys";
import {LicenseLock} from "../components/LicenseLock";

class COQCreateItemController {
    content = new ValidatedValue([validateNotBlank]);
    category = new ValidatedValue([validateNotNull], null);
    categoryOptions = [];

    formFields = [
        this.content,
        this.category,
    ];

    constructor(navigator, coqCollection, refreshListCallback) {
        this.navigator = navigator;
        this.coqCollection = coqCollection;
        this.refreshListCallback = refreshListCallback;
        this.loadCategories();
    }

    async loadCategories() {
        this.categorySelectController = new InputSelectController(this.categoryOptions, this.category);

        let categories = await new Surveys().getCategories();

        this.categoryOptions = [
            {text: lang.get('coq.categories.options.none'), value: null},
            ...categories.map(it => {
                return {value: it.id, text: it.text};
            })];

        this.categorySelectController.setItems(this.categoryOptions);
    }

    get isFormValid() {
        return this.formFields.filter(it => it.isValid === false).length === 0;
    }

    async submit() {
        this.formFields.forEach(it => it.touch());
        if (!this.isFormValid) {
            return;
        }
        this.isLoading = true;
        this.coqCollection.createCoq(
            this.content.value,
            this.category.value,
        ).then(() => {
            this.resetFormFields();
            this.refreshListCallback();
        });
        this.isLoading = false;
    }

    resetFormFields() {
        this.content.value = "";
        this.category.value = null;
    }
}


decorate(COQCreateItemController, {
    isFormValid: computed
});

const COQCreateItem = observer(class COQCreateItem extends Component {
    render() {
        let controller = this.props.controller;
        let isLicenseLocked = this.props.isLicenseLocked;

        return (
            <React.Fragment>
                <form id="coq" method="" name="coq">
                    <LicenseLock isLocked={isLicenseLocked} showText={true} navigator={controller.navigator}>
                        <div className="card card-custom-open-question license-lock-opacity">
                            <div className="card-body">
                                <div className="card-header small">
                                    <div className="card-header-icon">
                                        <i className="far fa-comment-edit"/>
                                    </div>
                                    <div className="card-header-title">
                                        <h2 className="">{lang.get('coq.new.card.title')}</h2>
                                    </div>
                                </div>
                                <div className="card-content">
                                    <Textarea name="coq"
                                              rows="2"
                                              maxLength="1500"
                                              placeholder={lang.get('coq.form.question')}
                                              validatedValue={controller.content}/>
                                </div>
                                <div className="card-footer">
                                    <div>
                                        <button id="next-btn" className="btn right"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    controller.submit(e)
                                                }}>
                                            <span>{lang.get('coq.form.post.btn')}</span>
                                            <i className="far fa-share-square"/>
                                        </button>
                                    </div>
                                    <InputSelect controller={controller.categorySelectController}
                                                 name="category"
                                                 placeholder={lang.get('coq.form.category')}/>
                                </div>
                            </div>
                        </div>
                    </LicenseLock>
                </form>
            </React.Fragment>
        );
    }
});


export {COQCreateItem, COQCreateItemController};