import React, {Component} from 'react';
import Slider from 'react-rangeslider';
import {computed, decorate, observable} from 'mobx';
import {observer} from 'mobx-react';

// Include the default styles for slider
import 'react-rangeslider/lib/index.css'
import {Score} from '../Report/Score';
import {roundSpirit} from '../../utils/Utils';

class RangeSliderController {
    value = 5;
    sliderMoved = false;

    get formattedValue() {
        return roundSpirit(this.value)
    }
}

decorate(RangeSliderController, {
    value: observable,
    sliderMoved: observable,
    formattedValue: computed
});


const RangeSlider = observer(class RangeSlider extends Component {
    render() {

        let controller = this.props.controller;

        const labels = {
            0: '', // pseudo image is set via css
            100: '' // pseudo image is set via css
        };

        return (
            <div className="surveySlider slideDown">
                <form className="surveySliderWrap" method="" name="surveyOpenQuestion">
                    <Score spirit={controller.value}/>
                    <div className="sliderBar">
                        <Slider
                            min={0}
                            max={100}
                            value={controller.value * 10}
                            labels={labels}
                            tooltip={false}
                            onChange={(value) => {
                                controller.value = value / 10;
                                controller.sliderMoved = true;
                            }}
                        />
                    </div>
                </form>
            </div>
        )
    }
});


export {RangeSlider, RangeSliderController};