import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable, decorate, computed} from 'mobx';
import {NavigationMenu, NavigationMenuController} from './Navigation/NavigationMenuView';
import {lang} from '../utils/Lang';
import HeaderBarWrapper from '../components/HeaderBarWrapper';
import logoImg from '../../assets/img/logo-doods.svg'
import {ReportService} from '../../domain/ReportService';
import {roundSpirit} from '../../utils/Utils';
import {Role, LicensedAction} from '../../domain/Role';

class HeaderController {
    userStore;
    navigator;

    avgScore;
    menuItems = [];

    reportService = new ReportService();

    constructor(navigator, userStore) {
        this.userStore = userStore;
        this.navigator = navigator;

        this.avgScore = 'n/a';

        this.setMenuItems();

        this.navigationMenuController = new NavigationMenuController(this.navigator, this.userStore, this.menuItems);
        this.loadDoodsSpirit();
    }

    async loadDoodsSpirit() {
        if (this.userStore.isLoggedIn && this.userStore.canDoAction(Role.ReadReport)) {
            let spirit = await this.reportService.getSpirit();
            this.avgScore = roundSpirit(spirit);
        }
    }

    setMenuItems() {
        this.menuItems =  [
            {
                id: 1,
                name: lang.get('navigation.item.dashboard'),
                icon: 'far fa-tachometer-alt',
                isPremium: false,
                isVisible: this.navigator.canGoTo(this.navigator.siteMap.pages.Dashboard),
                onSelect: () => this.navigator.goToPage(this.navigator.siteMap.pages.Dashboard),
                page: () => [this.navigator.siteMap.pages.Dashboard, this.navigator.siteMap.pages.Report, this.navigator.siteMap.pages.ReportWithIdAndFilter]
            },
            {
                id: 2,
                name: lang.get('navigation.item.home'),
                icon: 'far fa-tachometer-alt',
                isPremium: false,
                isVisible: this.userStore.currentCompanyUser === null,
                onSelect: () => this.navigator.goToPage(this.navigator.siteMap.pages.NonCompanyUserWelcomeScreen),
                page: () => [this.navigator.siteMap.pages.NonCompanyUserWelcomeScreen, this.navigator.siteMap.pages.CompanySettings]
            },
            {
                id: 3,
                name: lang.get('navigation.item.profiling'),
                icon: 'far fa-user',
                isPremium: false,
                isVisible: this.navigator.canGoTo(this.navigator.siteMap.pages.ProfilingResult),
                onSelect: () => this.navigator.goToPage(this.navigator.siteMap.pages.ProfilingResult, this.userStore.profile.profilingToken),
                page: () => this.navigator.siteMap.pages.ProfilingResult
            },
            {
                id: 4,
                name: lang.get('navigation.item.survey'),
                icon: 'far fa-poll',
                isPremium: false,
                isVisible: this.navigator.canGoTo(this.navigator.siteMap.pages.SurveyList),
                onSelect: () => this.navigator.goToPage(this.navigator.siteMap.pages.SurveyList),
                page: () => this.navigator.siteMap.pages.SurveyList
            },
            {
                id: 5,
                name: lang.get('navigation.item.soluting'),
                icon: 'far fa-lightbulb',
                isPremium: false,
                isVisible: this.navigator.canGoTo(this.navigator.siteMap.pages.SolutingList),
                onSelect: () => this.navigator.goToPage(this.navigator.siteMap.pages.SolutingList),
                page: () => [this.navigator.siteMap.pages.SolutingList, this.navigator.siteMap.pages.Soluting, this.navigator.siteMap.pages.SolutingArticle]
            },
            {
                id: 6,
                name: lang.get('navigation.item.feedback'),
                icon: 'far fa-comment',
                isPremium: false,
                isVisible: this.navigator.canGoTo(this.navigator.siteMap.pages.FeedbackList),
                onSelect: () => this.navigator.goToPage(this.navigator.siteMap.pages.FeedbackList),
                page: () => [this.navigator.siteMap.pages.FeedbackList, this.navigator.siteMap.pages.Feedback]
            },
            {
                id: 7,
                name: lang.get('navigation.item.openDiscussion'),
                icon: 'far fa-comments',
                isPremium: false,
                isVisible: this.navigator.canGoTo(this.navigator.siteMap.pages.OpenDiscussionList),
                onSelect: () => this.navigator.goToPage(this.navigator.siteMap.pages.OpenDiscussionList),
                page: () => [this.navigator.siteMap.pages.OpenDiscussionList, this.navigator.siteMap.pages.OpenDiscussion]
            },
            {
                id: 8,
                name: lang.get('navigation.item.notifications'),
                icon: 'far fa-bell',
                isPremium: false,
                isVisible: this.navigator.canGoTo(this.navigator.siteMap.pages.NotificationList),
                onSelect: () => this.navigator.goToPage(this.navigator.siteMap.pages.NotificationList),
                page: () => this.navigator.siteMap.pages.NotificationList
            },
            {
                id: 9,
                name: lang.get('navigation.item.companySettings'),
                icon: 'far fa-building',
                isPremium: false,
                isVisible: this.navigator.canGoTo(this.navigator.siteMap.pages.CompanySettings),
                onSelect: () => this.navigator.goToPage(this.navigator.siteMap.pages.CompanySettings),
                page: () => this.navigator.siteMap.pages.CompanySettings
            },
            {
                id: 10,
                name: lang.get('navigation.item.teamManagement'),
                icon: 'far fa-users',
                isPremium: false,
                hasSubmenu: true,
                isVisible: this.navigator.canGoTo(this.navigator.siteMap.pages.UserList) && this.navigator.canGoTo(this.navigator.siteMap.pages.TeamList),
                onSelect: () => {
                },
                subMenuItems: [
                    {
                        id: 11,
                        name: lang.get('navigation.item.employees'),
                        icon: 'far fa-address-book',
                        isPremium: this.navigator.canGoTo(this.navigator.siteMap.pages.UserList),
                        onSelect: () => this.navigator.goToPage(this.navigator.siteMap.pages.UserList),
                        page: () => [this.navigator.siteMap.pages.UserList, this.navigator.siteMap.pages.EditUser, this.navigator.siteMap.pages.ViewUser]
                    },
                    {
                        id: 12,
                        name: lang.get('navigation.item.groups'),
                        icon: 'far fa-draw-circle',
                        isPremium: this.navigator.canGoTo(this.navigator.siteMap.pages.TeamList),
                        onSelect: () => this.navigator.goToPage(this.navigator.siteMap.pages.TeamList),
                        page: () => [this.navigator.siteMap.pages.TeamList, this.navigator.siteMap.pages.EditTeam, this.navigator.siteMap.pages.ViewTeam]
                    }
                ]
            },
            {
                id: 13,
                name: lang.get("navigation.item.coq"),
                icon: 'far fa-comment-edit',
                isPremium: false,
                isVisible: this.navigator.canGoTo(this.navigator.siteMap.pages.CustomOpenQuestions),
                onSelect: () => this.navigator.goToPage(this.navigator.siteMap.pages.CustomOpenQuestions),
                page: () => this.navigator.siteMap.pages.CustomOpenQuestions
            },
            {
                id: 14,
                name: lang.get('navigation.item.premiumUpgrade'),
                icon: 'far fa-star',
                isPremium: true,
                isVisible: this.navigator.canGoTo(this.navigator.siteMap.pages.SubscriptionPlan) && !this.userStore.isLicensedFor(LicensedAction.UpgradeSubscriptionPlan),
                onSelect: () => this.navigator.goToPage(this.navigator.siteMap.pages.SubscriptionPlan),
                page: () => this.navigator.siteMap.pages.SubscriptionPlan
            },
            {
                id: 15,
                name: lang.get('navigation.item.helpDesk'),
                icon: 'far fa-question-circle',
                isPremium: false,
                isVisible: true,
                onSelect: () => window.open('http://doods.freshdesk.com', '_blank')
            },
            {
                id: 16,
                name: lang.get('navigation.item.logOut'),
                icon: 'far fa-sign-out',
                isPremium: false,
                isVisible: true,
                onSelect: () => this.navigator.logout()
            }
        ];
    }

    get hasNewMessages() {
        return false;
    }

    get hasNewNotifications() {
        return this.userStore.hasNewNotifications;
    }

    toggleNavigationMenu() {
        this.navigationMenuController.toggleNavigationMenu();
    }
}

decorate(HeaderController, {
    menuItems: observable,
    hasNewNotifications: computed,
    hasNewMessages: computed,
    avgScore: observable
});

const Header = observer(class Header extends Component {
    render() {
        let controller = this.props.controller;
        let classHasNewMessages = controller.hasNewMessages ? 'has-notification' : '';
        let classHasNewNotifications = controller.hasNewNotifications ? 'has-notification' : '';

        let goToFeedbackList = () => controller.navigator.goToPage(controller.navigator.siteMap.pages.FeedbackList);
        let goToNotificationList = () => controller.navigator.goToPage(controller.navigator.siteMap.pages.NotificationList);
        let goToDashboard = () => controller.navigator.goToPage(this.navigator.siteMap.pages.Dashboard);

        return (
            <HeaderBarWrapper>
                <div className="nav">
                    <div className="nav-menu-btn">
                        {controller.userStore.isLoggedIn &&
                        <button className="nav-icon" onClick={() => controller.toggleNavigationMenu()}>
                            <i className="fas fa-bars"/>
                        </button>
                        }
                    </div>
                    <div className="nav-logo">
                        <img src={logoImg} alt="Doods"/>
                    </div>
                    <div className="nav-info">
                        {controller.userStore.isLoggedIn &&
                        <React.Fragment>
                            <button className="nav-icon" onClick={() => goToDashboard()}>
                                <i className="far fa-heart-circle"/>
                                <span>{controller.avgScore}</span>
                            </button>
                            <button className={`nav-icon ${classHasNewMessages}`} onClick={goToFeedbackList}>
                                <i className="far fa-comments"/>
                            </button>
                            <button className={`nav-icon ${classHasNewNotifications}`} onClick={goToNotificationList}>
                                <i className="far fa-bell"/>
                            </button>
                        </React.Fragment>
                        }
                    </div>
                    {controller.userStore.isLoggedIn &&
                    <NavigationMenu controller={controller.navigationMenuController}/>
                    }
                </div>
            </HeaderBarWrapper>
        );
    }
});
export {Header, HeaderController};