import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Header, HeaderController} from '../../Header/HeaderView';
import {lang} from '../../utils/Lang';
import SolutingItemStore from '../../../domain/Soluting/SolutingItemStore';
import {decorate, observable} from 'mobx';
import LoadingModal from '../../components/LoadingModal';
import {ContentTitle} from '../../components/ContentTitle';

class SolutingPageViewController {
    userStore;
    navigator;
    soluting = {
        breadcrumbs: [],
        lessonsByCategory: []
    };
    isLoading = false;
    solutingId;

    constructor(navigator, userStore, solutingId) {
        this.userStore = userStore;
        this.navigator = navigator;
        this.solutingId = solutingId;

        this.solutingItemStore = new SolutingItemStore();
        this.loadSoluting()
    }

    loadSoluting() {
        this.isLoading = true;
        this.solutingItemStore.load(this.solutingId)
            .then(() => {
                this.isLoading = false;
                this.soluting = this.solutingItemStore.soluting;
            })
            .catch(() => {
                this.navigator.goToPage(this.navigator.siteMap.pages.Dashboard);
                this.isLoading = false;
            });

    }

    goBack() {
        return () => this.navigator.back();
    }

    goToSolutingList() {
        return () => this.navigator.goToPage(this.navigator.siteMap.pages.SolutingList);
    }

}


decorate(SolutingPageViewController, {
    soluting: observable,
    solutingId: observable,
    isLoading: observable
});


const SolutingLessonList = ({lesson, controller, category}) => {

    let completedClass = lesson.isFinished ? 'completed' : '';

    return (
        <React.Fragment>
            <input type="hidden" value={category}/>
            <div className="lesson-item" key={lesson} onClick={(e) => {
                e.preventDefault();
                controller.navigator.goToPage(controller.navigator.siteMap.pages.SolutingArticle, controller.solutingId, lesson.id)
            }}>
                <div className="lesson-progress">
                    <i className={`far fa-check ${completedClass}`}/>
                </div>
                <div className="lesson-title">
                    <h4>{lesson.title}</h4>
                    <p>{lesson.description}</p>
                </div>
            </div>
        </React.Fragment>
    );
};

const SolutingPageView = observer(class SolutingPageView extends Component {

    lessonsByCategory(categoryLessons, controller) {
        let categoryName = categoryLessons.name;

        return categoryLessons.lessons.map(lesson => {
            return <SolutingLessonList key={lesson.id} category={categoryName} lesson={lesson} controller={controller}/>
        });
    }

    render() {
        let controller = this.props.controller;
        let soluting = this.props.controller.soluting;
        let category = soluting.category;
        let groupId = soluting.groupId;
        let solutingImage = {
            backgroundImage: `url(${soluting.imageUrl})`
        };

        let categoryClass = '';
        if (soluting.category) {
            switch (soluting.category.toLowerCase()) {
                case 'growth':
                    categoryClass = 'growth';
                    break;
                case 'facts':
                    categoryClass = 'facts';
                    break;
                case 'relationship':
                    categoryClass = 'relationship';
                    break;
                case 'culture':
                    categoryClass = 'culture';
                    break;
                default:
                    categoryClass = '';
                    break;
            }
        }
        ;

        let lessonsList = soluting.lessonsByCategory.map(lessons => {
            return this.lessonsByCategory(lessons, controller, categoryClass);
        });


        return (
            <React.Fragment>
                <LoadingModal isVisible={controller.isLoading}/>
                <main className="fadeIn">
                    <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                    <div className="appContent">
                        <div className="appContent-body soluting-item">
                            <ContentTitle title={category} subtitle={lang.get(`report.infobox.${groupId}.title`)}/>
                            <div className={`card slideIn ${categoryClass}`}>
                                <div className="card-body">
                                    <div className="card-image" style={solutingImage}/>
                                    <div className="card-header">
                                        <h2>{lang.get(`report.infobox.${groupId}.title`)}</h2>
                                        <p>{lang.get(`report.infobox.${groupId}.content`)}</p>
                                        <h3>{soluting.title}</h3>
                                    </div>
                                    <div className="card-content">
                                        <p>{soluting.description}</p>
                                        <div className="soluting-lessons">
                                            {lessonsList}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="app-footer-automatic">
                                <div/>
                                <button id="cancel-btn" className="btn center" onClick={controller.goToSolutingList()}>
                                    <i className="far fa-times-circle"/></button>
                                <div/>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
});

export {SolutingPageViewController, SolutingPageView}