import React, {Component} from 'react';
import {lang} from '../../utils/Lang';
import {decorate, observable, action} from 'mobx';
import {observer} from 'mobx-react';
import _ from 'lodash';
import {Role} from '../../../domain/Role';
import {hideIfNot} from '../../../utils/Utils';
import {ScrollPreserver} from "../../../utils/ScrollPreserver";


class UserListController {
    users = []; // id, name
    goToViewUser = () => false;
    goToEditUser = () => false;
    inviteUser = () => false;
    onDeleteUser = () => false;
    onDeactivateUser = () => false;

    setup(users, inviteUser, goToViewUser, goToEditUser, deleteUser, deactivateUser) {
        this.inviteUser = inviteUser;
        this.goToEditUser = goToEditUser;
        this.goToViewUser = goToViewUser;
        this.onDeleteUser = deleteUser;
        this.onDeactivateUser = deactivateUser;
        this.users.clear();
        this.users.push(...users);
    }

    setUsers(users) {
        this.users.clear();
        this.users.push(...users);
    }

    addUsers(users) {
        this.users.push(...users);
    }

    removeUsers(users) {
        _.pullAllWith(this.users, users, (lhs, rhs) => lhs.id === rhs.id);
    }

    deleteUser(user) {
        this.onDeleteUser(user);
    }

    deactivateUser(user) {
        this.onDeactivateUser(user);
    }
}

decorate(UserListController, {
    users: observable.shallow,
    setup: action
});

const UserListItem = ({
    id, name, profilingToken, isCompanyAdmin,
    hasGroup, canDeactivateUser, canDeleteUser, onDelete, onDeactivate, canGoToViewUser, goToViewUser, canGoToEditUser, goToEditUser, isOnHoliday, isInactive, isRemoved, isDeactivated
}) => {
    let profilingResult = 'n/a';
    if (profilingToken) profilingResult = lang.get(`profilingResult.${profilingToken}.title`);

    return (
        <li key={id} id={`user-${id}`} onClick={canGoToViewUser ? goToViewUser : false} className={`${((isRemoved) ? 'deactivated' : '')}`}>
            <div className="text">
                <h3>
                    {name}
                    <i className={'far fa-tree-palm selected'} style={hideIfNot(isOnHoliday)}/>
                    <i className={'far fa-crown selected'} style={hideIfNot(isCompanyAdmin)}/>
                    <i className={'far fa-users selected'} style={hideIfNot(hasGroup)}/>
                    <i className={'far fa-bed selected'} style={hideIfNot(isInactive)}/>
                </h3>
                <h4>{profilingResult}</h4>
            </div>
            <div className="icons">
                <button className="btn plain action-deactivate" style={hideIfNot(canDeactivateUser && !isDeactivated && !isRemoved)} onClick={(e) => {
                    e.stopPropagation();
                    onDeactivate()
                }}><i className="far fa-user-slash"/></button>
                <button className="btn plain action-delete" style={hideIfNot(canDeleteUser && !isRemoved)} onClick={(e) => {
                    e.stopPropagation();
                    onDelete()
                }}><i className="far fa-trash-alt"/></button>
                <button className="btn plain action-preview" style={hideIfNot(canGoToViewUser)} onClick={(e) => {
                    e.stopPropagation();
                    goToViewUser()
                }}><i className="far fa-eye"/></button>
                <button className="btn plain action-edit" style={hideIfNot(canGoToEditUser)} onClick={(e) => {
                    e.stopPropagation();
                    goToEditUser()
                }}><i className="far fa-edit"/></button>
            </div>
        </li>);
};

const UserListView = observer(class UserListView extends Component {
    getSnapshotBeforeUpdate(prevProps, prevState) {
        return ScrollPreserver.getScrollPosition();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        ScrollPreserver.updateScrollPosition(snapshot);
    }

    render() {
        let controller = this.props.controller;
        let userStore = this.props.userStore;
        let navigator = this.props.navigator;

        let canInviteUsers = userStore.canDoAction(Role.InviteUsers);
        let canGoToEditUser = navigator.canGoTo(navigator.siteMap.pages.EditUser);
        let canGoToViewUser = navigator.canGoTo(navigator.siteMap.pages.ViewUser);
        let canDeactivateUser = userStore.canDoAction(Role.UserChangeIsDeactivated);
        let canDeleteUser = userStore.canDoAction(Role.UserChangeIsRemoved);

        let inviteUser = () => {
            controller.inviteUser();
        };

        let renderItems = controller.users.map(user => {
            return <UserListItem key={user.id}
                                 id={user.id}
                                 name={user.name}
                                 hasGroup={user.hasGroup}
                                 isCompanyAdmin={user.isCompanyAdmin}
                                 profilingToken={user.profilingToken}
                                 canGoToEditUser={canGoToEditUser && !user.isRemoved}
                                 goToEditUser={() => controller.goToEditUser(user)}
                                 canDeactivateUser={canDeactivateUser}
                                 canDeleteUser={canDeleteUser}
                                 onDelete={() => controller.deleteUser(user)}
                                 onDeactivate={() => controller.deactivateUser(user)}
                                 canGoToViewUser={canGoToViewUser}
                                 goToViewUser={() => controller.goToViewUser(user)}
                                 isOnHoliday={user.isOnHoliday}
                                 isInactive={user.isInactive}
                                 isRemoved={user.isRemoved}/>
        });


        return (
            <React.Fragment>
                <div className="card">
                    <div className="card-body">
                        <div className="card-header small">
                            <div className="card-header-icon">
                                <i className="far fa-address-book"/>
                            </div>
                            <div className="card-header-title">
                                <h2>{lang.get('teamManagement.employees')}</h2>
                                <button className="btn plain" style={hideIfNot(canInviteUsers)} onClick={inviteUser}>
                                    <span>{lang.get('teamManagement.addBtn')}</span><i className="far fa-user-plus"/></button>
                            </div>
                        </div>
                        <div className="card-content">
                            <ul className="list-with-buttons">
                                {renderItems}
                            </ul>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
});

export {UserListView, UserListController};