import {axios} from '../../utils/WrappedAxios';

export default class UserCollection {
    async getCompanyUsers(isDeactivated, isRemoved, teamId) {
        let queries = [];
        if (isDeactivated === true || isDeactivated === false) queries.push(`isDeactivated=${isDeactivated}`);
        if (isRemoved === true || isRemoved === false) queries.push(`isRemoved=${isRemoved}`);
        if (teamId) queries.push(`teamId=${teamId}`);
        let queryString = '';
        if (queries.length > 0) queryString = `?${queries.join('&')}`;

        let response = await axios.get(`/api/teamManagement/users${queryString}`);

        return response.data.users;
    }

    async createUser(email, language, profilingToken, recaptchaToken) {
        let response = await axios.post('/api/user', {
            email: email,
            language: language,
            profilingToken: profilingToken,
            recaptcha: recaptchaToken
        });
        return response.data;
    }

    async isEmailRegistered(email) {
        try {
            await axios.get(`/api/auth/exists?email=${email}`);
            return true;
        } catch (e) {
            if (e.response.status === 404) return false;
            throw e;
        }
    }
}