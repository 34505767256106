import React, {Component} from 'react';
import {lang} from '../../utils/Lang';

class FeedbackListItem extends Component {
    getFeedbackItemStyle() {
        let style = {
            categoryClass: 'feedbackCategoryOQ1',
            dotClass: '', // is OQ positive or negative
            anonymousClass: '',
            kitchenClass: '',
            importantClass: '',
            inProgressClass: ''
        };

        if (this.props.tone === 'Positive') {
            style.dotClass = 'isPositive';
        }
        if (this.props.tone === 'Negative') {
            style.dotClass = 'isNegative';
        }

        if (this.props.category === '') {
            style.categoryClass = 'feedbackCategoryOQ2';
        }

        switch (this.props.rootGroup) {
            case 'growth':
                style.categoryClass = 'growth';
                break;
            case 'culture':
                style.categoryClass = 'culture';
                break;
            case  'relationship':
                style.categoryClass = 'relationship';
                break;
            case  'facts':
                style.categoryClass = 'facts';
                break;
            case 'recommendation':
                style.categoryClass = 'recommendation';
                break;
            default:
                style.categoryClass = 'statement';
                break;

        }

        return style;
    }

    showSubGroupLabel() {
        if (this.props.subGroup)
            return this.props.subGroup;

        return lang.get('survey.normal.feedback.title');
    }

    oqOrStatementIcon() {
        if (this.props.tone) // if it has tone it's open question
            return 'far fa-comment';
        else
            return 'far fa-star';
    }

    render() {
        let style = this.getFeedbackItemStyle();

        let tagCreatedBy = <div
            className={`pill ${(this.props.tagCreatedBy ? '' : 'grey')}`}>{this.props.tagCreatedBy || 'Anonymous'}</div>;
        let tagLabel = this.props.tagLabel ? <div
            className={`pill ${(this.props.tagLabel === 'Important' ? 'important' : '')}`}>{this.props.tagLabel}</div> : '';
        let tagStatus = this.props.tagStatus ? <div
            className={`pill ${(this.props.tagStatus === 'Archived' ? 'grey' : '')}`}>{this.props.tagStatus}</div> : '';

        let unreadCount = this.props.unreadCount > 0 ?
            <span className="unreadCount">{this.props.unreadCount}</span> : '';

        return (
            <li className={'card no-lines slideIn'} onClick={(e) => {
                e.preventDefault();
                this.props.onClick();
            }}>
                <div className="card-body">
                    <div className="card-header">
                        <div className={'card-header-icon ' + style.categoryClass}><i
                            className={this.oqOrStatementIcon()}/></div>
                        <div className="card-header-title two-row">
                            <h2 className="">{this.showSubGroupLabel()}</h2>
                            <h3 className="">
                                <div>{lang.get('feedback.date')}: <span>{this.props.createdAt}</span>&nbsp;/&nbsp;</div>
                                <div>{lang.get('feedback.updateDate')}: <span>{this.props.updatedAt}</span></div>
                            </h3>
                        </div>
                        <div className="feedback-item-status">
                            {style.dotClass && <i className={'far fa-dot-circle ' + style.dotClass}/>}
                            {unreadCount}
                        </div>
                    </div>
                    <div className="card-content padding-s">
                        {this.props.title && <h3>{this.props.title}</h3>}
                        <p>{this.props.description}</p>
                    </div>
                    <div className="card-footer feedback-item-footer">
                        <div className="pills">
                            {tagCreatedBy}
                            {tagLabel}
                            {tagStatus}
                        </div>
                        <button className="btn btnSmall blue" onClick={() => this.props.onClick()}>
                            <span>{lang.get('feedback.viewMessages.btn')}</span></button>
                    </div>
                </div>
            </li>
        );
    }
}

export {FeedbackListItem};