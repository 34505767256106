import React, {Component} from 'react';
import {decorate, observable, action} from 'mobx';
import {observer} from 'mobx-react';
import { find } from 'lodash';
import {Header, HeaderController} from '../Header/HeaderView';
import {NotificationFilterController, NotificationFilterView} from './NotificationFilterView';
import {lang} from '../utils/Lang';
import {ContentTitle} from '../components/ContentTitle';
import {NotificationCardBigView} from './NotificationCardBigView';
import {hideIfNot} from '../../utils/Utils';

class NotificationListPageController {
    filterController = new NotificationFilterController();
    notifications = [];
    showFilter = false;

    constructor(navigator, userStore) {
        this.navigator = navigator;
        this.userStore = userStore;
        this.notificationCollection = userStore.notificationCollection;


        this.filterController.onChange = (notificationFilter) => {
            // this.showFilter = false;
            this.notifications.clear();
            this.notificationCollection.getList(notificationFilter)
                .then(notifications => {
                    this.notifications.push(...notifications);
                });
        };
        this.filterController.onCancel = () => this.showFilter = false;

        this.load();
    }

    async load() {
        let getListRequest = this.notificationCollection.getList();
        let notifications = await getListRequest;
        this.notifications.clear();
        this.notifications.push(...notifications);
    }

    async loadFilter() {
        // let count = await this.notificationCollection.getCount();
        // this.filterController.setCount(count);
    }

    async markNotificationAsRead(notification) {
        await notification.markAsRead();
    }

    goToNotification(notification) {
      if (notification.isRead === false)
        this.markNotificationAsRead(notification);

      // After click on notification check if is last unreaded and remove notification bell indicator if is.
      const hasUnread = find(this.notifications, (notification) => !notification.isRead);
      !hasUnread && this.userStore.markAllNotificationsAsRead();

      notification.payload.getLink(this.navigator)();
    }

    async markAllAsRead() {
      this.userStore.markAllNotificationsAsRead();
      await Promise.all(this.notifications.map(it => it.markAsRead()));
    }

    didLoadCount = false;

    toggleFilter() {
        this.showFilter = !this.showFilter;
        if (this.showFilter === true && this.didLoadCount === false) {
            this.loadFilter();
        }
    }
}

decorate(NotificationListPageController, {
    notifications: observable.shallow,
    showFilter: observable,
    toggleFilter: observable,
    markAllAsRead: action,
    markNotificationAsRead: action
});

const NotificationListPageView = observer(class NotificationListPageView extends Component {
    render() {
        let controller = this.props.controller;
        let notificationCards = controller.notifications
            .map(notification =>
                <NotificationCardBigView
                    key={notification.id}
                    title={notification.payload.title}
                    content={notification.payload.content}
                    isRead={notification.isRead}
                    group={notification.payload.group}
                    createInstant={notification.createInstant}
                    goToLink={() => controller.goToNotification(notification)}
                    onClick={() => controller.markNotificationAsRead(notification)}/>);

        return (
            <main className="fadeIn">
                <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                <div className="appContent">
                    <div className="appContent-body notification-page">
                        <ContentTitle title={lang.get('appheader.notification.title')}
                                      subtitle={lang.get('appheader.notification.subtitle')}/>

                        <div className="button-row">
                            <button className="btn" onClick={() => controller.markAllAsRead()}>
                                <span>{lang.get('notification.markAllAsReadBtn')}</span></button>
                            <div className="horizontal-line"/>
                            <button className="btn" onClick={() => controller.toggleFilter()}>
                                <span>{lang.get('notification.filterBtn')}</span><i className="far fa-sliders-h"/>
                            </button>
                        </div>
                        <div style={hideIfNot(controller.showFilter)}>
                            <NotificationFilterView controller={controller.filterController}/>

                        </div>
                        <div className="notification-list">
                            {notificationCards}
                        </div>
                    </div>
                </div>
            </main>
        );
    }
});

export {NotificationListPageView, NotificationListPageController};