import React from 'react';
import {action, decorate, observable} from 'mobx';
import {observer} from 'mobx-react';
import Cookie from '../domain/Cookie';
import logoImage from '../assets/img/logo-doods.svg';
import {lang} from './utils/Lang';
import {notificationManager} from './components/Notifications';
import Invite from '../domain/Invite/Invite';
import {ChangeLanguageInput, ChangeLanguageInputController} from './components/ChangeLanguageInput';
import {EmailInput, EmailInputStore} from './components/EmailInput';
import UserCollection from '../domain/User/UserCollection';
import InviteUser from "../domain/Invite/InviteUser";


class InviteService {
    userStore;
    inviteId = null;
    cookie;
    cookieName = 'DoodsInviteID';

    constructor(userStore) {
        this.userStore = userStore;
        this.cookie = new Cookie();
        this.getCookieIsAvailable();
    }

    setInvite(inviteId) {
        this.inviteId = inviteId;

        if (this.inviteId) {
            let expDate = new Date();
            expDate.setFullYear(expDate.getFullYear() + 1);
            this.cookie.set(this.cookieName, this.inviteId, expDate);
        }
    }

    getCookieIsAvailable() {
        this.inviteId = this.cookie.get(this.cookieName, {doNotParse: true});
    }

    userHasInvite() {
        return this.validateInviteCookie(this.inviteId);
    }

    validateInviteCookie(id) {
        if (id) {
            return id.match(/^[0-9a-fA-F]{24}$/)
        }
        return false;
    }

    removeCookie() {
        this.inviteId = null;
        this.cookie.remove(this.cookieName);
    }

    tryToUseInvite() {
        if (this.userStore.isLoggedIn) {
            if (this.userHasInvite()) {
                return new Invite(this.inviteId).use()
                    .then(result => {
                        this.removeCookie();
                        if (result === true) {
                            notificationManager.info(lang.get('invitation.notification.connected'));
                        } else {
                            notificationManager.warn(lang.get('invitation.notification.failed'));
                        }
                    });
            }
        }
    }
}

class InvitePageController {
    navigator;
    userStore;
    inviteService;
    inviteUserStore;
    userCollection = new UserCollection();

    languageSelectController = new ChangeLanguageInputController();
    emailStore = new EmailInputStore();

    showPage = 'landing';

    constructor(navigator, userStore, inviteService, id) {
        this.navigator = navigator;
        this.userStore = userStore;
        this.inviteService = inviteService;

        if (this.inviteService.validateInviteCookie(id)) {
            inviteService.setInvite(id);
        } else {
            this.navigator.goToPage(this.navigator.siteMap.pages.Dashboard);
            return;
        }

        if (this.userStore.isLoggedIn) {
            this.showPage = 'confirmation';
        }
    }

    async goToProfiling() {
        this.emailStore.validate();
        if(!this.emailStore.isEmailValid) return;
        let isEmailRegistered = await this.userCollection.isEmailRegistered(this.emailStore.email);

        if (isEmailRegistered) {
            notificationManager.info(lang.get('profilingSignUp.start.email.exists'));
        } else {

            //save invite user tracking
            if(this.inviteService.inviteId){
                this.inviteUserStore = new InviteUser(this.inviteService.inviteId, this.emailStore.email);
                this.inviteUserStore.create();
            }

            this.navigator.goToPageWithoutStateChange(this.navigator.siteMap.postPages.Profiling, this.emailStore.email);
        }
    }

    goToLogin() {
        this.navigator.goToPage(this.navigator.siteMap.pages.Login)
    }

    async acceptInvitation() {
        await this.inviteService.tryToUseInvite();
        await this.userStore.refreshJWT();

        //delete invite user tracking
        if(this.userStore.email){
            this.inviteUserStore = new InviteUser(null, this.userStore.email);
            this.inviteUserStore.delete();
        }

        this.navigator.goToPage(this.navigator.siteMap.pages.Dashboard);
    }

    declineInvitation() {
        this.inviteService.removeCookie();
        this.navigator.goToPage(this.navigator.siteMap.pages.Dashboard);
    }
}

decorate(InvitePageController, {
    id: observable,
    showPage: observable,
    acceptInvitation: action,
    declineInvitation: action
});


const Landing = observer(({controller}) => {

    let goToProfiling = (e) => {
        e.preventDefault();
        controller.goToProfiling();
    };

    return (
        <React.Fragment>
            <div className="card-header">
                <h1>{lang.get('login.header.invitation')}</h1>
                <ChangeLanguageInput controller={controller.languageSelectController} txtCenter={true}/>
            </div>
            <div className="card-content">
                <div className="indent-wrap">
                    <h3>{lang.get('invitation.note.header')}</h3>
                    <p>
                        {lang.get('invitation.note.default')}
                    </p>
                </div>
                <form onSubmit={goToProfiling}>
                    <EmailInput state={controller.emailStore} name={'email'}/>
                    <button id="signup-submit" type="submit" className="btn fadeIn blue">
                        <span>{lang.get('login.button.startProfiling')}</span>
                    </button>
                </form>
            </div>
            <div className="card-footer">
                <p>{lang.get('profilingSignUp.start.goToLogin')}
                    <a href="#/" id="goto-login-btn" onClick={(e) => {
                        e.preventDefault();
                        controller.goToLogin();
                    }}> {lang.get('login.button.returnToLogin')}</a>
                </p>
                <div className="small-text">
                    <p>{lang.getDangerous('login.footer.gdprNote')}</p>
                </div>
            </div>
        </React.Fragment>
    );
});

const Confirmation = observer(({controller}) => {
    return (
        <React.Fragment>
            <div className="card-header">
                <h1>{lang.get('login.header.invitation')}</h1>
            </div>
            <div className="card-content">
                <div className="indent-wrap">
                    <h2>{lang.get('invitation.note.header')}</h2>
                    <p>
                        {lang.get('invitation.note.confirm')}
                    </p>
                </div>
                <button id="login-submit" type="submit" className="btn mb-1 slideIn"
                        onClick={(e) => {
                            e.preventDefault();
                            controller.acceptInvitation();
                        }}><span>{lang.get('invitation.button.accept')}</span></button>
                <button id="login-submit" type="submit" className="btn slideIn"
                        onClick={(e) => {
                            e.preventDefault();
                            controller.declineInvitation();
                        }}><span>{lang.get('invitation.button.decline')}</span></button>
            </div>
            <div className="card-footer">
                <div className="small-text">
                    <p>{lang.getDangerous('login.footer.gdprNote')}</p>
                </div>
            </div>
        </React.Fragment>
    );
});


const InvitePage = observer(({controller}) => (
    <main className="bg-style2">
        <div className="appContent">
            <div className="appContent-body fadeIn login-content">
                <div className="logo">
                    <img src={logoImage} alt="DooDs Logo"/>
                </div>
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            {controller.showPage === 'landing' &&
                            <Landing controller={controller}/>
                            }
                            {controller.showPage === 'confirmation' &&
                            <Confirmation controller={controller}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
));

export {InvitePage, InvitePageController, InviteService};
