import {lang} from '../../../page/utils/Lang';
import {TopicUpdatedNotification} from './TopicUpdatedNotification';

class TopicCreatedNotification {
    group = TopicCreatedNotification.group;

    constructor(topicId, createdBy) {
        this.topicId = topicId;
        this.createdBy = createdBy || 'n/a';
    }

    get title() {
        return lang.get(`notification.${TopicUpdatedNotification.group}.title`);
    }

    get content() {
        return lang.get(`notification.${TopicUpdatedNotification.typeName}.content`).replace('$userName', this.createdBy);
    }

    getLink(navigator) {
        return () => navigator.goToPage(navigator.siteMap.pages.Feedback, this.topicId);
    }

    static tryParse(json) {
        if (json.type !== this.typeName) return null;
        return new TopicCreatedNotification(json.topicId, json.createdBy);
    }

    static get typeName() {
        return 'topicCreated';
    }

    static get group() {
        return 'Feedback';
    }
}

export {TopicCreatedNotification};