export class ScrollPreserver {

    static getScrollPosition(){
        let scrollElement = document.getElementsByClassName("appContent");
        return scrollElement[0].scrollTop;
    }


    static updateScrollPosition(snapshot) {
        let scrollElement = document.getElementsByClassName("appContent");
        scrollElement[0].scrollTop = snapshot;
    }
}