import {lang} from '../../../page/utils/Lang';

class AddedToCompanyNotification {
    group = AddedToCompanyNotification.group;

    constructor(companyId, userName) {
        this.companyId = companyId;
        this.userName = userName;
    }

    getLink(navigator) {
        return null;
    }

    get title() {
        return lang.get(`notification.${AddedToCompanyNotification.group}.title`);
    }

    get content() {
        return lang.get(`notification.${AddedToCompanyNotification.typeName}.content`).replace('$userName', this.userName);
    }

    static tryParse(json) {
        if (json.type !== this.typeName) return null;
        return new AddedToCompanyNotification(json.companyId);
    }

    static get typeName() {
        return 'addedToCompany';
    }

    static get group() {
        return 'TeamManagement';
    }
}

export {AddedToCompanyNotification};