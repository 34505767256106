import React from 'react';
import {lang} from '../../utils/Lang';
import {LicenseLock} from '../../components/LicenseLock';

export const SolutingListItem = ({title, description, category, groupId, imageUrl, breadcrumbs, isLocked, isFavorite, onClick, navigator}) => {

    let categoryClass = '';
    if (category) {
        switch (category.toLowerCase()) {
            case 'growth':
                categoryClass = 'growth';
                break;
            case 'facts':
                categoryClass = 'facts';
                break;
            case 'relationship':
                categoryClass = 'relationship';
                break;
            case 'culture':
                categoryClass = 'culture';
                break;
            case 'recommendation':
                categoryClass = 'recommendation';
                break;
            default:
                categoryClass = '';
                break;
        }
    }

    // let breadcrumbItems = breadcrumbs.map((breadcrumb) => {
    //   return <span key={breadcrumb}>{breadcrumb}</span>
    // });
    // let cssFavoriteClass = isFavorite ? "fas" : "far";
    let solutingImage = {
        backgroundImage: `url(${imageUrl})`
    };

    return (
        <LicenseLock isLocked={isLocked} showText={true} navigator={navigator}>
            <div className={`card group-item license-lock-opacity ${categoryClass}`}
                 onClick={(e) => {
                     e.preventDefault();
                     onClick();
                 }}>
                <div className='card-body'>
                    <div className="soluting-card-image" style={solutingImage}>
                        <span className="group-name-header">{category}</span>
                    </div>
                    <div className="card-header">
                        <div className="header-title">
                            <span>{lang.get(`report.infobox.${groupId}.title`)}</span>
                            <span className="cut-out">{lang.get(`report.infobox.${groupId}.content`)}</span>
                            {/*{breadcrumbItems}*/}
                        </div>
                        {/*<div className="header-favorite-action">*/}
                        {/*<i className={`${cssFavoriteClass} fa-star`}/>*/}
                        {/*</div>*/}
                    </div>
                    <div className="card-content">
                        <h3>{title}</h3>
                        <p className="cut-out">
                            {description}
                        </p>
                    </div>
                </div>
            </div>
        </LicenseLock>
    );
}