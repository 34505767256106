import React, {Component} from 'react';
import {Header, HeaderController} from '../../Header/HeaderView';
import {lang} from '../../utils/Lang';
import {decorate, observable, action} from 'mobx';
import {observer} from 'mobx-react';
import TeamCollection from '../../../domain/Team/TeamCollection';
import {Team} from '../../../domain/Team/Team';
import {ContentTitle} from '../../components/ContentTitle';
import {InfoBox2, InfoBox2Controller} from '../../components/Inputs/InfoBox2';
import {LicensedAction, Role} from '../../../domain/Role';
import {hideIfNot} from '../../../utils/Utils';
import {TeamListHierarchyView, TeamListHierarchyController} from '../../components/HierarchyItems';
import {LicenseLock} from '../../components/LicenseLock';
import _ from 'lodash';

class TeamListPageController {
    navigator;
    userStore;
    teams = []; // id, name, count, children
    teamCollection = new TeamCollection();
    confirmInfoBoxController = new InfoBox2Controller();

    constructor(navigator, userStore) {
        this.navigator = navigator;
        this.userStore = userStore;
        this.loadTeams();
    }

    async loadTeams() {
        let allTeamsAndSubteams = await this.teamCollection.getTeamsAndSubteams();
        this.teams.clear();

        let rootTeams = _.filter(allTeamsAndSubteams, it => it.teamPath.hasParent === false);
        let preparedTeamsAndSubteams = rootTeams.map(it => this.prepareTeam(it, allTeamsAndSubteams));

        this.teams = preparedTeamsAndSubteams;
    }

    prepareTeam(rootTeam, allTeamsAndSubteams) {
        let children = _.filter(allTeamsAndSubteams, potentialChild => potentialChild.teamPath.hasParent && potentialChild.teamPath.getParentId().asString === rootTeam.teamPath.asString)
        let preparedChildren = children.map(child => this.prepareTeam(child, allTeamsAndSubteams));

        return {id: rootTeam.team.id, text: rootTeam.team.name, childrenItems: preparedChildren};
    }

    async createTeam() {
        await this.teamCollection.createTeam(lang.get('teamManagement.team.defaultName'), null);
        await this.loadTeams();
    }

    goToUserList() {
        this.navigator.goToPage(this.navigator.siteMap.pages.UserList);
    }

    deleteTeam(teamId) {
        this.confirmInfoBoxController.show()
            .then(response => {
                if (response === 'yes') {
                    this.team = new Team(teamId);
                    this.team.deleteSubTeam(teamId)
                        .then(() => this.loadTeams());
                }
            });
    }
}

decorate(TeamListPageController, {
    teams: observable,
    loadTeams: action,
    createTeam: action,
    deleteTeam: action,
});


const TeamListPageView = observer(class TeamListView extends Component {
    renderListItem(id, text, goToViewTeam, onEdit, onDelete, canGoToEditTeam, canDelete, canGoToViewTeam, children) {
        return <li key={id} id={`team-${id}`}> {/*onClick={canGoToViewTeam ? goToViewTeam : false}>*/}
            <div className="text">
                <TeamListHierarchyView controller={new TeamListHierarchyController({text: text, id:id, childrenItems: children}, goToViewTeam, canGoToViewTeam)}/>
            </div>
            <div className="icons">
                <button className="btn plain action-delete" style={hideIfNot(canDelete)} onClick={(e) => {
                    e.stopPropagation();
                    onDelete(id)
                }}><i className="far fa-trash-alt"/></button>
                <button className="btn plain action-preview" style={hideIfNot(canGoToViewTeam)} onClick={(e) => {
                    e.stopPropagation();
                    goToViewTeam(id)
                }}><i className="far fa-eye"/></button>
                <button className="btn plain action-edit" style={hideIfNot(canGoToEditTeam)} onClick={(e) => {
                    e.stopPropagation();
                    onEdit(id)
                }}><i className="far fa-edit"/></button>
            </div>
        </li>;
    }

    render() {
        let controller = this.props.controller;

        let isLicenseLocked = !controller.userStore.isLicensedFor(LicensedAction.TeamManagementEdit);

        let canCreateTeam = controller.userStore.canDoAction(Role.GroupCreate);
        let canDeleteTeam = controller.userStore.canDoAction(Role.GroupDelete);
        let canGoToEditTeam = controller.navigator.canGoTo(controller.navigator.siteMap.pages.EditTeam);
        let canGoToViewTeam = controller.navigator.canGoTo(controller.navigator.siteMap.pages.ViewTeam);


        let createTeam = () => {
            controller.createTeam();
        };

        let goToViewTeam = (id )=>{
            controller.navigator.goToPage(controller.navigator.siteMap.pages.ViewTeam, id);
        };

        let goToEditTeam = (id)=>{
            controller.navigator.goToPage(controller.navigator.siteMap.pages.EditTeam, id)
        };

        let deleteTeam = (id)=>{
            controller.deleteTeam(id)
        };



        let items = controller.teams;
        let renderItems = items.map(it => this.renderListItem(
            it.id,
            it.text,
            goToViewTeam,
            goToEditTeam,
            deleteTeam,
            canGoToEditTeam,
            canDeleteTeam,
            canGoToViewTeam,
            it.childrenItems));

        return (
            <main className="fadeIn">
                <InfoBox2 hasYes={true} hasNo={true}
                          title={lang.get('teamManagement.infoBox.delete.team.title')}
                          icon={'fas fa-trash-alt'}
                          id={'deleteConfirmationInfoBox'}
                          controller={controller.confirmInfoBoxController}>
                    <p>
                        {lang.get('teamManagement.infoBox.delete.team.description')}
                    </p>
                </InfoBox2>
                <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                <div className="appContent">
                    <div className="appContent-body">
                        <ContentTitle title={lang.get('appheader.teamMgmt.groups.title')}
                                      subtitle={lang.get('appheader.teamMgmt.groups.subtitle')}/>
                        <LicenseLock isLocked={isLicenseLocked} showText={true} navigator={controller.navigator}>
                            <div className="card license-lock-opacity">
                                <div className="card-body">
                                    <div className="card-header small">
                                        <div className="card-header-icon">
                                            <i className="far fa-draw-circle"/>
                                        </div>
                                        <div className="card-header-title">
                                            {/*TODO - commit*/}
                                            <h2>Groups</h2>
                                            <button className="btn plain" style={hideIfNot(canCreateTeam)}
                                                    onClick={createTeam}><span>{lang.get('teamManagement.addBtn')}</span><i
                                                className="far fa-draw-circle"/></button>
                                        </div>
                                    </div>
                                    <div className="card-content">
                                        <ul className="list-with-buttons list-with-hierarchy">
                                            {renderItems}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </LicenseLock>
                    </div>
                </div>
            </main>
        );
    }
});

export {TeamListPageView, TeamListPageController};