import React from 'react'

const defaultWidth = 600;
const defaultHeight = 400;
const appId = 966242223397117; // this is some default app id from fb

export function openFacebookSharePopup(href, title, desc, picture, hashtag, quote) {
    const queryParams = [];
    if (href) queryParams.push(`href=${window.encodeURIComponent(href)}`);
    if (title) queryParams.push(`title=${window.encodeURIComponent(title)}`);
    if (desc) queryParams.push(`description=${window.encodeURIComponent(desc)}`);
    if (picture) queryParams.push(`picture=${window.encodeURIComponent(picture)}`); //picture param is no longer supported by facebook share
    if (hashtag) queryParams.push(`hashtag=%23${window.encodeURIComponent(hashtag)}`);
    if (quote) queryParams.push(`quote=${window.encodeURIComponent(quote)}`);

    const url = `https://www.facebook.com/dialog/share?app_id=${appId}&display=popup&${queryParams.join('&')}`;

    //calc size and center position of fb share popup
    const w = window.innerWidth > defaultWidth ? defaultWidth : window.innerWidth;
    const h = window.innerHeight > defaultHeight ? defaultHeight : window.innerHeight;
    const l = window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
    const t = window.screenTop !== undefined ? window.screenTop : window.screen.top;
    const left = (window.innerWidth - w) / 2 + l;
    const top = (window.innerHeight - h) / 2 + t;

    window.open(url, 'facebook share', `width=${w},height=${h},left=${left},top=${top},scrollbars=yes`)
}


export default function FacebookShareButton(props) {
    return (
        <button
            className="fb-share-button"
            onClick={() => openFacebookSharePopup(props.href, props.title, props.desc, props.picture, props.hashtag, props.quote)}>
            <span className="fb-logo">
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                    {/* eslint-disable-next-line max-len */}
                    <path fill='#ffffff' fillRule='evenodd' d='M8 14H3.667C2.733 13.9 2 13.167 2 12.233V3.667A1.65 1.65 0 0 1 3.667 2h8.666A1.65 1.65 0 0 1 14 3.667v8.566c0 .934-.733 1.667-1.667 1.767H10v-3.967h1.3l.7-2.066h-2V6.933c0-.466.167-.9.867-.9H12v-1.8c.033 0-.933-.266-1.533-.266-1.267 0-2.434.7-2.467 2.133v1.867H6v2.066h2V14z'/>
                </svg>
            </span>
            <span className="fb-share-text">
                {props.children}
            </span>
        </button>
    )
}