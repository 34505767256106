import React, {Component} from 'react';
import {decorate, observable, computed, action} from 'mobx';
import {observer} from 'mobx-react';
import {RangeSlider, RangeSliderController} from '../../components/RangeSlider';
import {lang} from '../../utils/Lang';
import StepsFooterBar from '../../components/StepsFooterBar';
import ScrollEvents from '../../../utils/ScrollEvents';
import {ContentTitle} from '../../components/ContentTitle';
import {InfoBox2, InfoBox2Controller} from '../../components/Inputs/InfoBox2';


class SurveySliderStepController {
    text = '';
    sliderController = new RangeSliderController();

    constructor(text, value) {
        this.text = text || '';
        this.sliderController.value = value || 5;
    }

    get value() {
        return this.sliderController.value;
    }
}

decorate(SurveySliderStepController, {
    text: observable,
    value: computed
});


class SurveyStepViewController {
    navigator;
    cancelSurveyController = new InfoBox2Controller();
    currentStep = 0;
    items = [];

    constructor(navigator, onFinish, items) {
        this.navigator = navigator;
        this.items = items.map(it => new SurveySliderStepController(it.text, it.value));
        this.onFinish = onFinish;
    }

    goPrev() {
        this.currentStep--;
    }


    get canGoPrev() {
        return this.currentStep > 0;
    }

    get canGoNext() {
        //if current page is sliderController user must move/touch sliderController to proceed
        return this.currentStep < this.items.length - 1 && this.items[this.currentStep].sliderController.sliderMoved;
    }

    goNext() {
        this.currentStep++;
    }

    get isLastStep() {
        return this.currentStep === this.items.length - 1 && this.items[this.currentStep].sliderController.sliderMoved;
    }


    goFinish() {
        this.onFinish();
    }

    cancel() {
        this.cancelSurveyController.show()
            .then(result => {
                if (result === 'yes') {
                    this.navigator.back();
                }
            });
    }
}

decorate(SurveyStepViewController, {
    currentStep: observable,
    items: observable,
    goPrev: action,
    canGoPrev: computed,
    goNext: action,
    canGoNext: computed,
    isLastStep: computed,
    goFinish: action,
    cancel: action
});

const InitialSurveyStepView = observer(class InitialSurveyStepView extends Component {
    scrollEvents = new ScrollEvents();

    componentDidMount() {
        this.scrollEvents.startTrackingScroll(document.getElementsByClassName('appContent')[0]);
    }

    componentWillUnmount() {
        this.scrollEvents.stopTrackingScroll();
    }

    render() {
        let controller = this.props.controller;
        let currentStep = controller.currentStep + 1;
        let totalSteps = controller.items.length;
        return (
            <React.Fragment>
                <InfoBox2 id="cancelSurveyInfoBox"
                          title={lang.get('modal.survey.cancel.Title')}
                          icon={'far fa-exclamation-triangle'}
                          hasYes={true}
                          hasNo={true}
                          controller={controller.cancelSurveyController}>
                    <p>{lang.get('modal.survey.cancel.Content')}</p>
                </InfoBox2>
                <ContentTitle title={lang.get('header.title.initialSurvey')}/>
                <div className="survey-textbox">
                    <div className="survey-text">
                        <p className="steps">Statement {currentStep} | {totalSteps}</p>
                        <p>
                            {controller.items[controller.currentStep].text}
                        </p>
                    </div>
                </div>
                <RangeSlider controller={controller.items[controller.currentStep].sliderController}/>
                <StepsFooterBar
                    canPrev={controller.canGoPrev}
                    clickPrev={() => {
                        controller.goPrev();
                        this.scrollEvents.scrollToTop();
                    }}
                    canNext={controller.canGoNext}
                    clickNext={() => {
                        controller.goNext();
                        this.scrollEvents.scrollToTop();
                    }}
                    canFinish={controller.isLastStep}
                    clickFinish={() => controller.goFinish()}
                    isNextBtnActive={controller.canGoNext}
                    isPrevBtnActive={controller.canGoPrev}
                    showCancel={true}
                    clickCancel={() => controller.cancel()}/>
                <p className="survey-anonymous-note">{lang.getDangerous('survey.footer.isAnonymous')}</p>
            </React.Fragment>
        );
    }
});

export {InitialSurveyStepView, SurveyStepViewController};