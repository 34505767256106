import UserService from '../services/UserService';
import {decorate, observable, action, computed, runInAction} from 'mobx';
import {lang} from '../page/utils/Lang';
import _ from 'lodash';
import moment from 'moment';
import {User} from './User/User';
import {NotificationCollection} from './Notifications/NotificationCollection';
import {setJWT} from '../utils/WrappedAxios';
import Cookies from 'js-cookie';
import {CompanyUser} from './User/CompanyUser';

class UserStore {
    userService = new UserService();
    isLoggedIn = null;
    userId = null;
    companyUserId = null;
    currentUser = null;
    currentCompanyUser = null;
    email = null;
    userDetails = null;
    profile = null;
    allowedActions = [];
    lastCheck = null;
    hasNewNotifications = false;

    notificationCollection = new NotificationCollection();
    jwtToken = null;

    constructor() {
        lang.setLanguage('DE');
        this.jwtToken = Cookies.get('jwt-token');
        setJWT(this.jwtToken);
    }

    async checkLogin() {
        this.lastCheck = moment();

        // TODO commit - don't check isLoggedIn if user does not have jwtToken in cookie (prevent 401 in logs)

        let response = await this.userService.isLoggedIn();
        if (response.isLoggedIn) {
            return runInAction(() => {
                this.isLoggedIn = true;
                this.userId = response.profile.userId;
                this.companyUserId = response.profile.companyUserId;
                if (this.companyUserId !== null)
                    this.currentCompanyUser = new CompanyUser(this.companyUserId);
                else
                    this.currentCompanyUser = null;
                this.currentUser = new User(this.userId);
                this.email = response.profile.email;
                this.userDetails = response.profile.details;
                this.profile = response.profile.profile;
                this.allowedActions = response.profile.allowedActions;
                this.licensedActions = response.profile.licensedActions;
                lang.setLanguage(response.profile.language);
                moment.locale(response.profile.language);
                this.checkNotifications()
            });
        } else {
            return runInAction(() => {
                this.isLoggedIn = false;
                this.userId = null;
                this.companyUserId = null;
                this.email = null;
                this.userDetails = null;
                this.profile = null;
                this.currentUser = null;
                this.currentCompanyUser = null;
            });
        }
    }

    get isRegistered() {
        return this.userDetails;
    }

    async login(verificationId) {
        let jwtToken = await this.userService.getJwtToken(verificationId);
        setJWT(jwtToken);
        Cookies.set('jwt-token', jwtToken, {expires: 365});
        return this.checkLogin();
    }

    async refreshJWT() {
        let jwtToken = await this.userService.refreshJWT();
        setJWT(jwtToken);
        Cookies.set('jwt-token', jwtToken, {expires: 365});
        await this.checkLogin();
    }

    async logout() {
        setJWT(null);
        Cookies.remove('jwt-token');
        return this.checkLogin();
    }

    get displayName() {
        if (this.isRegistered)
            return `${this.userDetails.firstName} ${this.userDetails.lastName}`;
        else
            return this.email;
    }

    checkNotificationsTimeout = null;

    async checkNotifications() {
        if (this.isLoggedIn === false || this.checkNotificationsTimeout !== null) return;

        try {
            this.hasNewNotifications = await this.notificationCollection.hasUnreadNotifications();
        } catch (e) {
            console.error('Could not get new notifications', e);
        }
        this.checkNotificationsTimeout = setTimeout(() => {
            this.checkNotificationsTimeout = null;
            this.checkNotifications();
        }, 60000);
    }

    get actions() {
        return this.allowedActions;
    }

    canDoAction(action) {
        return _.includes(this.actions, action)
    }

    isLicensedFor(action) {
        return _.includes(this.licensedActions, action);
    }

    markAllNotificationsAsRead() {
        this.hasNewNotifications = false;
    };

    async deleteUser() {
        await this.userService.deleteUser(this.userId);
        return this.checkLogin();
    }
}

decorate(UserStore, {
    isLoggedIn: observable,
    userId: observable,
    currentUser: observable,
    currentCompanyUser: observable,
    email: observable,
    userDetails: observable,
    profile: observable,
    checkLogin: action,
    isRegistered: computed,
    displayName: computed,
    logout: action,
    role: observable,
    actions: computed,
    allowedActions: observable,
    licensedActions: observable,
    hasNewNotifications: observable
});

export default UserStore;