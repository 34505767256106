import {axios} from '../../utils/WrappedAxios';
import {action, decorate, observable} from "mobx";

class InviteUserCollection {
    items = [];

    load() {
        return axios.get('/api/invite/users')
            .then((response) => {
                this.items = response.data.items;
            });
    }
}

decorate(InviteUserCollection, {
    items: observable,
    load: action,
});

export default InviteUserCollection;