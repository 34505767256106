import {lang} from '../../../page/utils/Lang';

class NewSurveyNotification {
    group = NewSurveyNotification.group;

    constructor(surveyId) {
        this.surveyId = surveyId;
    }

    get title() {
        return lang.get(`notification.${NewSurveyNotification.group}.title`);
    }

    get content() {
        return lang.get(`notification.${NewSurveyNotification.typeName}.content`);
    }

    getLink(navigator) {
        return () => navigator.goToPage(navigator.siteMap.pages.Survey, this.surveyId);
    }

    static tryParse(json) {
        if (json.type !== this.typeName) return null;
        return new NewSurveyNotification(json.surveyId);
    }

    static get typeName() {
        return 'newSurvey';
    }

    static get group() {
        return 'Survey';
    }
}

export {NewSurveyNotification};