import React, {Component} from 'react';
import {lang} from '../utils/Lang';
import {decorate, observable} from 'mobx';
import {observer} from 'mobx-react';
import LoadingModal from "../components/LoadingModal";
import {Header, HeaderController} from "../Header/HeaderView";
import {ContentTitle} from "../components/ContentTitle";

class SubscriptionPlanPageController {

    isLoading = true;

    constructor(navigator, userStore) {
        this.navigator = navigator;
        this.userStore = userStore;
        this.isLoading = false;
    }
}

decorate(SubscriptionPlanPageController, {
    isLoading: observable
});

const SubscriptionPlanPageView = observer(class SubscriptionPlanPageView extends Component {

    render() {
        let controller = this.props.controller;

        return (
            <React.Fragment>
                <LoadingModal isVisible={controller.isLoading}/>
                <main className="fadeIn">
                    <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                    <div className="appContent subscription-plan-content">
                        <div className="appContent-body">
                            <ContentTitle title={lang.get('appheader.subscriptionPlan.title')}/>
                            <div className="card">
                                <div className="card-body">
                                    <div>
                                        <div className="description-header">
                                            <div>
                                                <i className="far fa-star"/>
                                                <span dangerouslySetInnerHTML={{__html: lang.get("subscriptionPlan.label.subscriptionModels")}}/>
                                            </div>
                                        </div>
                                        <div className="description-subheader">
                                            <div>
                                                <span>{lang.get("subscriptionPlan.label.pricing")}</span>
                                            </div>
                                        </div>
                                        <div className="description-item">
                                            <i className="far fa-user"/>
                                            <span>{lang.get("subscriptionPlan.label.profiling")}</span>
                                        </div>
                                        <div className="description-item">
                                            <i className="far fa-poll"/>
                                            <span>{lang.get("subscriptionPlan.label.survey")}</span>
                                        </div>
                                        <div className="description-item">
                                            <i className="far fa-lightbulb"/>
                                            <span>{lang.get("subscriptionPlan.label.soluting")}</span>
                                        </div>
                                        <div className="description-item">
                                            <i className="far fa-tachometer-alt"/>
                                            <span>{lang.get("subscriptionPlan.label.reporting")}</span>
                                        </div>
                                        <div className="description-item">
                                            <i className="far fa-comment"/>
                                            <span>{lang.get("subscriptionPlan.label.feedbacks")}</span>
                                        </div>
                                        <div className="description-item">
                                            <i className="far fa-users"/>
                                            <span>{lang.get("subscriptionPlan.label.teammgmt")}</span>
                                        </div>
                                        <div className="description-item">
                                            <i className="far fa-user-plus"/>
                                            <span>{lang.get("subscriptionPlan.label.employees")}</span>
                                        </div>
                                        <div className="description-item">
                                            <i className="far fa-comment-edit"/>
                                            <span>{lang.get("subscriptionPlan.label.coq")}</span>
                                        </div>
                                        <div className="description-item">
                                            <i className="far fa-life-ring"/>
                                            <span>{lang.get("subscriptionPlan.label.support")}</span>
                                        </div>
                                        <div className="description-footer">
                                            <div>
                                                <span>{lang.get("subscriptionPlan.label.yearlyPlan")}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="plan-header">
                                            <i className="far fa-users"/>
                                            <div className="title">
                                                <h2>{lang.get("subscriptionPlan.label.header.businessFree")}</h2>
                                            </div>
                                        </div>
                                        <div className="plan-subheader">
                                            <div className="note center">
                                                <h3>{lang.get("subscriptionPlan.label.free")}</h3>
                                            </div>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.basicDetails")}</span>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.fullAccess")}</span>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.upTo4Solutings")}</span>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.basicReporting")}</span>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.readOnlyFeedback")}</span>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.limitedAccess")}</span>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.unlimited")}</span>
                                        </div>
                                        <div className="plan-item"/>
                                        <div className="plan-item"/>
                                        <div className="plan-footer">0 €</div>
                                    </div>
                                    <div>
                                        <div className="plan-header">
                                            <i className="far fa-star"/>
                                            <div className="title">
                                                <h2>{lang.get("subscriptionPlan.label.header.businessPremium")}</h2>
                                            </div>
                                        </div>
                                        <div className="plan-subheader">
                                            <div className="note alt">
                                                <h3>249 €</h3><span>{lang.get("subscriptionPlan.label.perMonth")}</span>
                                            </div>
                                            <a href={`mailto:support@doods.team`} className="btn btnSmall blue">{lang.get("subscriptionPlan.btn.contactUs")}</a>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.advancedDetails")}</span>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.fullAccess")}</span>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.unlimitedSolutings")}</span>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.advancedReporting")}</span>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.fullAccess")}</span>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.fullAccess")}</span>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.upTo80")}</span>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.fullAccess")}</span>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.premiumSupport")}</span>
                                        </div>
                                        <div className="plan-footer">
                                            <div>
                                                <span className="crossed">2.988 €</span>2.490 €
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="plan-header">
                                            <i className="far fa-building"/>
                                            <div className="title">
                                                <h2>{lang.get("subscriptionPlan.label.header.enterprise")}</h2>
                                            </div>
                                        </div>
                                        <div className="plan-subheader">
                                            <div className="note">
                                                <h3>{lang.get("subscriptionPlan.label.requestOffer")}</h3>
                                            </div>
                                            <a href={`mailto:support@doods.team`} className="btn btnSmall blue">{lang.get("subscriptionPlan.btn.contactUs")}</a>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.advancedDetails")}</span>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.fullAccess")}</span>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.unlimitedSolutings")}</span>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.advancedReporting")}</span>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.fullAccess")}</span>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.fullAccess")}</span>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.above80")}</span>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.fullAccess")}</span>
                                        </div>
                                        <div className="plan-item">
                                            <span>{lang.get("subscriptionPlan.label.premiumSupport")}</span>
                                        </div>
                                        <div className="plan-footer">{lang.get("subscriptionPlan.label.specialOffer")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
});

export {SubscriptionPlanPageView, SubscriptionPlanPageController};
