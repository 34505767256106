import React from 'react';
import {SelectionInfoBoxView} from './SelectionInfoBox';
import {lang} from '../utils/Lang';

const ChatMessageStatusInfoBox = ({controller, title, legend, id}) => {
    return (
        <SelectionInfoBoxView controller={controller}
                              title={lang.get('feedbackMessages.infoBox.changeStatus.title')}
                              legend={lang.get('feedbackMessages.infoBox.changeStatus.content')}
                              id='selectStatusInfoBox'/>
    );
};

export {ChatMessageStatusInfoBox};