import React from 'react';
import {lang} from '../utils/Lang';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {action, decorate, observable} from 'mobx';
import {observer} from 'mobx-react';


class InvitationBoxController {
    isVisible = false;


    constructor(invitationLink) {
        this.invitationLink = invitationLink;
    }

    showCopiedToClipboardMessage() {
        this.isVisible = true;
    }
}

decorate(InvitationBoxController, {
    isVisible: observable,
    showCopiedToClipboardMessage: action
});

const InvitationBox = observer(({controller}) => (
    <React.Fragment>
        <p>{lang.get('teamManagement.infoBox.employees.invite.description')}</p>
        <p className="form-input-wrap mt-1">
            <CopyToClipboard text={controller.invitationLink}
                             onCopy={() => controller.showCopiedToClipboardMessage()}
                             options={{format: 'text/plain'}}>
                <input className="form-input" type="text" readOnly={true} defaultValue={controller.invitationLink}/>
            </CopyToClipboard>
        </p>
        <CopyToClipboard text={controller.invitationLink}
                         onCopy={() => controller.showCopiedToClipboardMessage()}
                         options={{format: 'text/plain'}}>
            <button className="btn btnSmall mt-1"><span>{lang.get('teamManagement.infoBox.employees.invite.btn')}</span></button>
        </CopyToClipboard>
        {controller.isVisible &&
        <p className="mt-1">
            {lang.get('invitation.notification.copiedToClipboard')}
        </p>
        }
    </React.Fragment>
));

export {InvitationBox, InvitationBoxController};