import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {decorate, observable} from 'mobx';
import {Header, HeaderController} from '../Header/HeaderView';
import {ProfilingResultView, ProfilingResultViewController} from './ProfilingResultView';
import ScrollEvents from '../../utils/ScrollEvents';


class ProfilingResultController {
    token = null;

    constructor(navigator, userStore, token) {
        this.navigator = navigator;
        this.userStore = userStore;
        this.token = token;
    }
}

decorate(ProfilingResultController, {
    token: observable,
});


const ProfilingResultPage = observer(class ProfilingResultPage extends Component {
    scrollEvents = new ScrollEvents();

    componentDidMount() {
        this.scrollEvents.startTrackingScroll(document.getElementsByTagName('main')[0]);
        this.scrollEvents.scrollToTop();
    }

    componentWillUnmount() {
        this.scrollEvents.stopTrackingScroll();
    }

    render() {
        let controller = this.props.controller;
        return (
            <React.Fragment>
                <input type="hidden" id="result-token" value={controller.token}/> {/*selenium testing :( */}
                <main className="fadeIn bg-style1">
                    <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                    <div className="appContent">
                        <div className="appContent-body slideDown profiling-result">
                            <ProfilingResultView controller={new ProfilingResultViewController(controller.token)}/>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
});

export {ProfilingResultPage, ProfilingResultController};