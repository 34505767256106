import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable, decorate, action, computed} from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/hr';
import 'moment/locale/hu';
import 'moment/locale/sl';
import LoadingModal from '../components/LoadingModal';
import {Header, HeaderController} from '../Header/HeaderView';
import {lang} from '../utils/Lang';
import {ContentTitle} from '../components/ContentTitle';
import COQCollection from '../../domain/COQ/COQCollection';
import {COQListItem} from './COQListItem';
import {InfoBox2, InfoBox2Controller} from '../components/Inputs/InfoBox2';
import {COQCreateItem, COQCreateItemController} from './COQCreateItem';
import {LicensedAction, Role} from '../../domain/Role';

class COQListPageController {
    userStore;
    navigator;
    coqCollection = new COQCollection();
    infoBoxController = new InfoBox2Controller();
    confirmDeleteController = new InfoBox2Controller();
    infoBoxTitle = '';
    infoBoxContent = '';

    isLoading = true;

    constructor(navigator, userStore) {
        this.userStore = userStore;
        this.navigator = navigator;
        this.COQCreateItemController = new COQCreateItemController(this.navigator, this.coqCollection, () => this.refresh());
        this.refresh()
    }

    refresh() {
        this.isLoading = true;
        this.coqCollection.loadList()
            .then(() => this.isLoading = false)
            .catch(() => {
                this.navigator.goToPage(this.navigator.siteMap.pages.Dashboard);
                this.isLoading = false;
            });
    }

    showInfoBox(status) {
        this.infoBoxController.show();
        this.infoBoxTitle = lang.get(`coq.infobox.${status}.title`);
        this.infoBoxContent = lang.get(`coq.infobox.${status}.content`);
    }


    async onDelete(id) {
        let result = await this.confirmDeleteController.show();
        if (result !== 'yes') return;
        await this.coqCollection.deleteCoq(id);
    }

    onEdit(id) {
        this.navigator.goToPage(this.navigator.siteMap.pages.EditCustomOpenQuestion, id);
    }

    get canCreate() {
        return this.userStore.canDoAction(Role.COQCreate);
    }

    get isLicenseLocked(){
        return !this.userStore.isLicensedFor(LicensedAction.CoqManagement)
    }

    get canEdit() {
        return this.navigator.canGoTo(this.navigator.siteMap.pages.EditCustomOpenQuestion) && !this.isLicenseLocked;
    }

    get canDelete() {
        return this.userStore.canDoAction(Role.COQDelete) && !this.isLicenseLocked;
    }
}

decorate(COQListPageController, {
    infoBoxTitle: observable,
    infoBoxContent: observable,
    isLoading: observable,
    onDelete: action,
    onEdit: action,
    canCreate: computed,
    canEdit: computed,
    canDelete: computed
});

const COQListPageView = observer(class COQListPageView extends Component {
    createCOQListItem(item) {
        let controller = this.props.controller;

        return <COQListItem key={item.id}
                            id={item.id}
                            content={item.content}
                            status={item.status}
                            date={moment(item.createdAt).locale(lang.language).format('MMMM DD, YYYY')}
                            onDelete={(id) => {
                                controller.onDelete(id)
                            }}
                            onEdit={(id) => {
                                controller.onEdit(id)
                            }}
                            canEdit={controller.canEdit}
                            canDelete={controller.canDelete}
        />;
    }

    render() {
        let controller = this.props.controller;

        let coqCollection = controller.coqCollection;

        let activeCOQs = _.filter(coqCollection.coqs, it => it.status === 'Active');
        let sortedActiveCOQs = _.orderBy(activeCOQs, ['createdAt'], ['desc']);
        let nextCOQs = _.filter(coqCollection.coqs, it => it.status === 'Next');
        let sortedNextCOQs = _.orderBy(nextCOQs, ['createdAt'], ['asc']);
        let closedCOQS = _.filter(coqCollection.coqs, it => it.status === 'Used' || it.status === 'Canceled');
        let sortedClosedCOQs = _.orderBy(closedCOQS, ['createdAt'], ['desc']);

        let activeCOQItems = sortedActiveCOQs.map((item) => {
            return this.createCOQListItem(item)
        });
        let nextCOQItems = sortedNextCOQs.map((item) => {
            return this.createCOQListItem(item)
        });
        let closedCOQItems = sortedClosedCOQs.map((item) => {
            return this.createCOQListItem(item)
        });
        let canCreateCOQ = controller.canCreate;

        return (
            <React.Fragment>
                <LoadingModal isVisible={controller.isLoading}/>
                <main className="fadeIn">
                    <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                    <InfoBox2 icon={'far fa-exclamation-triangle'} title={controller.infoBoxTitle} hasClose={true}
                              controller={controller.infoBoxController}>
                        <p dangerouslySetInnerHTML={{__html: controller.infoBoxContent}}/>
                    </InfoBox2>
                    <InfoBox2 icon={'far fa-exclamation-triangle'} title={lang.get('confirmation.dialog.warning')}
                              hasYes={true} hasNo={true}
                              controller={controller.confirmDeleteController}>
                        <p>{lang.get('confirmation.dialog.content')}</p>
                    </InfoBox2>
                    <div className="appContent">
                        <div className="appContent-body">
                            <ContentTitle title={lang.get('appheader.coq.title')}
                                          subtitle={lang.get('appheader.coq.subtitle')}/>

                            {canCreateCOQ &&
                            <COQCreateItem controller={controller.COQCreateItemController} isLicenseLocked={controller.isLicenseLocked}/>
                            }

                            <div className="coq-list">
                                <div className="coq-list-header">
                                    <h2>{lang.get('coq.list.header.active')}
                                        <button className="btn none" onClick={(e) => {
                                            e.stopPropagation();
                                            controller.showInfoBox('active');
                                        }}><i className="far fa-question-circle"/></button>
                                    </h2>
                                </div>
                                <div className="coq-list-items coq-active">
                                    {activeCOQItems != null && activeCOQItems.length > 0 ? activeCOQItems :
                                        <p className="coq-list-no-data">{lang.get('coq.list.nodata')}</p>}
                                </div>
                            </div>
                            <div className="coq-list">
                                <div className="coq-list-header">
                                    <h2>{lang.get('coq.list.header.next')}
                                        <button className="btn none" onClick={(e) => {
                                            e.stopPropagation();
                                            controller.showInfoBox('next');
                                        }}><i className="far fa-question-circle"/></button>
                                    </h2>
                                </div>
                                <div className="coq-list-items coq-next">
                                    {nextCOQItems != null && nextCOQItems.length > 0 ? nextCOQItems :
                                        <p className="coq-list-no-data">{lang.get('coq.list.nodata')}</p>}
                                </div>
                            </div>
                            <div className="coq-list">
                                <div className="coq-list-header">
                                    <h2>{lang.get('coq.list.header.closed')}
                                        <button className="btn none" onClick={(e) => {
                                            e.stopPropagation();
                                            controller.showInfoBox('closed');
                                        }}><i className="far fa-question-circle"/></button>
                                    </h2>
                                </div>
                                <div className="coq-list-items coq-past">
                                    {closedCOQItems != null && closedCOQItems.length > 0 ? closedCOQItems :
                                        <p className="coq-list-no-data">{lang.get('coq.list.nodata')}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
});

export {COQListPageView, COQListPageController};