import React, {Component} from 'react';
import {decorate, observable, action} from 'mobx';
import {observer} from 'mobx-react';
import {ListFilterController, ListFilterView} from '../components/ListFilterView';
import moment from 'moment';
import {lang} from '../utils/Lang';
import {Header, HeaderController} from '../Header/HeaderView';
import MediaQuery from '../utils/MediaQuery';
import {ContentTitle} from '../components/ContentTitle';
import ValidatedValue from "../components/Inputs/ValidatedValue";
import OpenDiscussionListView from "./OpenDiscussionList/OpenDiscussionListView";
import OpenDiscussionCollection from "../../domain/OpenDiscussions/OpenDiscussionCollection";

class OpenDiscussionListPageController {

    filterDate = {
        id: 1,
        name: 'filterDate',
        placeholder: lang.get('feedbackFilter.date.title'),
        validatedValue: new ValidatedValue([], null),
        options: [
            {
                'text': lang.get('feedbackFilter.none'),
                'value': null
            },
            {
                'text': lang.get('feedbackFilter.date.lastWeek'),
                'value': '1w'
            },
            {
                'text': lang.get('feedbackFilter.date.last2Weeks'),
                'value': '2w'
            },
            {
                'text': lang.get('feedbackFilter.date.lastMonth'),
                'value': '1M'
            },
            {
                'text': lang.get('feedbackFilter.date.last3Months'),
                'value': '3M'
            }
        ]
    };
    filterGroups = {
        id: 2,
        name: 'filterGroups',
        placeholder: lang.get('feedbackFilter.categories.title'),
        validatedValue: new ValidatedValue([], null),
        options: [
            {
                text: lang.get('feedbackFilter.none'),
                value: null
            }
        ]
    };
    filterStatus = {
        id: 5,
        name: 'filterStatus',
        placeholder: lang.get('feedbackFilter.status.title'),
        validatedValue: new ValidatedValue([], null),
        options: [
            {
                'text': lang.get('feedbackFilter.none'),
                'value': null
            },
            {
                'text': lang.get('feedback.status.active'),
                'value': 1
            },
            {
                'text': lang.get('feedback.status.archived'),
                'value': 2
            }
        ]
    };

    filters = [this.filterDate, this.filterGroups, this.filterStatus];


    userStore;
    navigator;
    isLoading = false;
    isFilterVisible = false;
    filterController;
    filterCount = 0;
    openDiscussionStore = new OpenDiscussionCollection();

    /**
     * id / name / selectedOption {id / label / value}
     * @type {Array}
     */
    constructor(navigator, userStore) {
        this.userStore = userStore;
        this.navigator = navigator;

        this.filterController = new ListFilterController(
            () => {
                // on close
                if (MediaQuery.isMobile()) // only close filter automatically if on mobile
                    this.closeFilter();
            },
            filterEventData => {
                // on apply
                if (MediaQuery.isMobile()) // only close filter automatically if on mobile
                    this.closeFilter();
                this.applyFilter(filterEventData);
            },
            filterEventData => {
                // on change
                this.openDiscussionStore.getCountForFilter(this._getFilter(filterEventData))
                    .then(count => {
                        this.filterCount = count;
                    });
            },
            this.filters);
        this.applyFilter([]);
    }

    openFilter() {
        this.filterCount = this.openDiscussionStore.discussions.length;
        this.isFilterVisible = true;
    }

    closeFilter() {
        this.isFilterVisible = false;
    }

    toggleFilter() {
        if (this.isFilterVisible) this.closeFilter();
        else this.openFilter();
    }

    static getFilterValueIfAvailable(filterEventData, filterName) {
        for (let i = 0; i < filterEventData.length; i++) {
            let item = filterEventData[i];
            if (item.name === filterName)
                return item.selectedOption.value;
        }
        return null;
    }

    _getFilter(filterEventData) {
        // date is specific since it's value is indication of what we have to subtract from now
        let dateFilterValue = OpenDiscussionListPageController.getFilterValueIfAvailable(filterEventData, 'filterDate');
        let dateValue = null;
        if (dateFilterValue)
            dateValue = moment().subtract(dateFilterValue[0], dateFilterValue[1]).format();

        let filter = {
            date: dateValue,
            category: OpenDiscussionListPageController.getFilterValueIfAvailable(filterEventData, 'filterGroups'),
            status: OpenDiscussionListPageController.getFilterValueIfAvailable(filterEventData, 'filterStatus')
        };

        return filter;
    }

    /**
     * Applies filter based on filterEventData that we get from ListFilterController
     *
     * @param {Array} filterEventData - [{id, name, selectedOption: {id, label, value}}]
     */
    async applyFilter(filterEventData) {
        this.openDiscussionStore.clear();
        this.isLoading = true;

        let filter = this._getFilter(filterEventData);

        try {
            await this.openDiscussionStore.loadListWithFilter(filter);
        } finally {
            this.isLoading = false;
            this.filterCount = this.openDiscussionStore.discussions.length;
        }
    }
}

decorate(OpenDiscussionListPageController, {
    isLoading: observable,
    isFilterVisible: observable,
    filterCount: observable,
    openFilter: action,
    closeFilter: action,
    toggleFilter: action,
    resetFilter: action,
    applyFilter: action
});

const OpenDiscussionListPageView = observer(class OpenDiscussionListPageView extends Component {
    render() {
        let controller = this.props.controller;

        let filterPage = <ListFilterView controller={controller.filterController}/>;
        let listPage = <OpenDiscussionListView onFilterOpen={() => controller.openFilter()}
                                         discussions={controller.openDiscussionStore.discussions}
                                         goToDiscussion={(id) => controller.navigator.goToPage(controller.navigator.siteMap.pages.OpenDiscussion, id)}/>;


        let searchResult = lang.get('openDiscussionList.countText').replace('{}', controller.filterCount);

        return (
            <React.Fragment>
                <main className="fadeIn feedback-list">
                    <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                    <div className='appContent'>
                        <div className={'appContent-body'}>
                            <ContentTitle title={lang.get('appheader.opendiscussion.title')}
                                          subtitle={lang.get('appheader.opendiscussion.subtitle')}/>
                            <div className="button-row">
                                <p>{searchResult}</p>
                                <div className="horizontal-line m-left"/>
                                <button className="btn" id="toggle-filters-btn" name="toggle" onClick={() => controller.toggleFilter()}><i
                                    className="far fa-sliders-h"/><span>{lang.get('feedbackList.filterBtn')}</span>
                                </button>
                            </div>
                            {controller.isFilterVisible && filterPage}
                            {listPage}
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
});

export {OpenDiscussionListPageController, OpenDiscussionListPageView};