import React from 'react';
import {decorate, action, computed, observable, runInAction} from 'mobx';
import {observer} from 'mobx-react';
import countryList from '../../assets/resources/countries';
import {validateIsNumber, validateNotBlank, validateNotNull} from '../../utils/Validators';
import {lang} from '../utils/Lang';
import {notificationManager} from '../components/Notifications';
import CompanyStore from '../../domain/CompanyStore';
import CompanyEditView from './CompanyEditView'
import {Header, HeaderController} from '../Header/HeaderView';
import CompanyViewView from './CompanyViewView';
import LoadingModal from '../components/LoadingModal';
import CompanyCollection from '../../domain/Company/CompanyCollection';
import ValidatedValue from '../components/Inputs/ValidatedValue';
import CompanyResultView from './CompanyResultView';
import {InputSelectController} from '../components/Inputs/InputSelect';
import {CompanyInvitesController, CompanyInvitesView} from './CompanyInvitesView';
import {InfoBox2, InfoBox2Controller} from '../components/Inputs/InfoBox2';
import {Role} from '../../domain/Role';

let industryKeys = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U'];

class CompanySettingsController {
    userStore;
    navigator;
    isLoading = true;
    companyId = null;
    companyStore = new CompanyStore(this.companyId);
    showPage = null;
    companyRegistered = false;
    companyInvitesController;

    countryOptions = countryList.map(it => {
        return {
            value: it.code,
            text: it.name
        };
    });
    industryOptions = industryKeys.map(it => {
        return {
            value: it,
            text: lang.get(`industryList.${it}`)
        };
    });

    name = new ValidatedValue([validateNotBlank]);
    address = new ValidatedValue([validateNotBlank]);
    zipCode = new ValidatedValue([validateNotBlank, validateIsNumber]);
    city = new ValidatedValue([validateNotBlank]);
    country = new ValidatedValue([validateNotNull], null);
    vatID = new ValidatedValue([validateNotBlank]);
    industry = new ValidatedValue([validateNotNull], null);
    noOfEmployees = new ValidatedValue([validateNotBlank, validateIsNumber]);
    ohwMembership = new ValidatedValue([], false);
    hogastID = new ValidatedValue([], false);

    formFields = [
        this.name,
        this.address,
        this.zipCode,
        this.city,
        this.country,
        this.vatID,
        this.industry,
        this.noOfEmployees,
        this.ohwMembership,
        this.hogastID
    ];

    licenseType = 'Free';
    licenseExpiry = '2000-01-01';

    companyCloseConfirmation1Controller = new InfoBox2Controller();
    companyCloseConfirmation2Controller = new InfoBox2Controller();

    constructor(navigator, userStore) {
        this.navigator = navigator;
        this.userStore = userStore;

        let hasNull = true;
        this.countrySelectController = new InputSelectController(this.countryOptions, this.country, hasNull);
        this.industrySelectController = new InputSelectController(this.industryOptions, this.industry, hasNull);

        this.load();
    }

    async load() {
        this.isLoading = true;
        try {

            let companyId = null;
            if (this.userStore.currentCompanyUser !== null) companyId = await this.userStore.currentCompanyUser.getCompanyId();

            runInAction(() => {
                if (companyId === null) {
                    this.companyId = null;
                    this.companyStore = null;
                    this.companyRegistered = false;
                } else {
                    this.companyId = companyId;
                    this.companyStore = new CompanyStore(this.companyId);
                    this.companyRegistered = true;
                }

                if (this.companyRegistered === true) {
                    this.goToView();
                } else {
                    this.goToEdit();
                }
            });

            if (this.companyStore !== null) {
                let company = await this.companyStore.load();
                if (company) {
                    runInAction(() => {
                        this.name.value = company.companyDetails.name;
                        this.address.value = company.companyDetails.address;
                        this.zipCode.value = company.companyDetails.zipCode;
                        this.city.value = company.companyDetails.city;
                        this.country.value = company.companyDetails.country;
                        this.vatID.value = company.companyDetails.vatID;
                        this.industry.value = company.companyDetails.industry;
                        this.noOfEmployees.value = company.companyDetails.noOfEmployees;
                        this.ohwMembership.value = company.companyDetails.ohwMembership;
                        this.hogastID.value = company.companyDetails.hogastID;
                        this.licenseType = company.companyDetails.licenseType;
                        this.licenseExpiry = company.companyDetails.licenseExpiry;
                    });
                }
            }
        } finally {
            this.isLoading = false;
        }
    }

    get isFormValid() {
        return this.formFields.filter(it => it.isValid === false).length === 0;
    }

    async submit() {
        this.formFields.forEach(it => it.touch());
        if (!this.isFormValid) {
            return;
        }
        this.isLoading = true;
        try {
            this.companyStore = await new CompanyCollection().createCompany(
                this.name.value,
                this.address.value,
                this.zipCode.value,
                this.city.value,
                this.country.value,
                this.vatID.value,
                this.industry.value,
                this.noOfEmployees.value,
                this.ohwMembership.value,
                this.hogastID.value
            );

            await this.userStore.refreshJWT(); // HACK, put this here because user changes role (and get companyUserId in JWT token) so we need to reload
            if (!this.companyRegistered) {
                this.goToResult();
            } else {
                this.goToView();
            }
            this.isLoading = false;
        } catch (error) {
            notificationManager.error(lang.get('notification.errorSave'), error);
        }
    }

    goToEdit() {
        this.showPage = 'edit';
    }

    goToResult() {
        this.showPage = 'result';
    }

    goToView() {
        this.showPage = 'view';
    }

    goToInvites() {
        this.companyInvitesController = new CompanyInvitesController(this.navigator, this.userStore);
        this.showPage = 'invites';
    }

    goToDashboard() {
        this.navigator.goToPage(this.navigator.siteMap.pages.Dashboard);
    }

    goToUserList() {
        this.navigator.goToPage(this.navigator.siteMap.pages.UserList);
    }

    get canCloseCompany() {
        return this.userStore.canDoAction(Role.DeleteCompany);
    }

    async closeCompany() {
        let confirm1 = await this.companyCloseConfirmation1Controller.show();
        if (confirm1 !== 'yes') return;
        let confirm2 = await this.companyCloseConfirmation2Controller.show();
        if (confirm2 !== 'yes') return;

        let company = new CompanyStore(this.companyId);
        await company.closeCompany();
        await this.userStore.checkLogin();
        this.navigator.goToPage(this.navigator.siteMap.pages.Dashboard);
    }

    get currentCountryValue() {
        let selectedCountry = this.countryOptions.find((element) => {
            return element.value === this.country.value;
        });
        if (selectedCountry) return selectedCountry.text;
        else return '';
    }

    get currentIndustryValue() {
        let selectedIndustry = this.industryOptions.find((element) => {
            return element.value === this.industry.value;
        });
        if (selectedIndustry) return selectedIndustry.text;
        else return '';

    }

}

decorate(CompanySettingsController, {
    isRegistered: observable,
    isLoading: observable,
    isFormValid: computed,
    validate: action,
    submit: action,
    showPage: observable,
    currentCountryValue: computed,
    currentIndustryValue: computed,
    canCloseCompany: computed,
    closeCompany: action
});

const CompanySettingsPage = observer(({theme, controller}) => {
    let currentPage;
    switch (controller.showPage) {
        case 'edit':
            currentPage = <CompanyEditView controller={controller}/>;
            break;
        case 'view':
            currentPage = <CompanyViewView controller={controller}/>;
            break;
        case 'result':
            currentPage = <CompanyResultView controller={controller}/>;
            break;
        case 'invites':
            currentPage = <CompanyInvitesView controller={controller.companyInvitesController}/>;
            break;
        default:
            currentPage = <div/>;
            break;
    }

    return (
        <React.Fragment>
            <LoadingModal isVisible={controller.isLoading}/>
            <main className="fadeIn" data-selenium-is-loading={controller.isLoading}>
                <InfoBox2 id='closeCompanyConfirm1' icon={'far fa-trash-alt'} hasYes={true} hasNo={true} controller={controller.companyCloseConfirmation1Controller}
                          title={lang.get('company.settings.closeCompany.confirm1.title')}>
                    <p>{lang.get('company.settings.closeCompany.confirm1.content')}</p>
                </InfoBox2>
                <InfoBox2 id='closeCompanyConfirm2' icon={'far fa-trash-alt'} hasYes={true} hasNo={true} controller={controller.companyCloseConfirmation2Controller}
                          title={lang.get('company.settings.closeCompany.confirm2.title')}>
                    <p>{lang.get('company.settings.closeCompany.confirm2.content')}</p>
                </InfoBox2>

                <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                <div className="appContent">
                    <div className="appContent-body app-footer-automatic-parent">
                        {currentPage}
                    </div>
                </div>
            </main>
        </React.Fragment>
    );
});

export {CompanySettingsPage, CompanySettingsController};
