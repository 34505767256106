import React from 'react';
import {action, decorate} from 'mobx';
import {observer} from 'mobx-react';
import Reaptcha from 'reaptcha';
import Cookies from 'js-cookie';
import {lang} from '../utils/Lang';
import {InfoBox2, InfoBox2Content, InfoBox2Controller} from './Inputs/InfoBox2';

class ModalRecaptchaStore{
    infoBoxController = new InfoBox2Controller();

    // we have to skip recaptcha if we are testing in selenium, since it's not mockable otherwise
    isDisabledForTesting = Cookies.get('seleniumTest') === 'true';

    verifyRecaptcha(response) {
        this.hide();
        if(this.resolve != null) this.resolve(response);
    }

    hide() {
        this.infoBoxController.hide();
    }

    async showAsync() {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            if (this.isDisabledForTesting) {
                this.verifyRecaptcha('fake-verified-response');
            } else {
                this.infoBoxController.show();
            }
        });
    }
}

decorate(ModalRecaptchaStore, {
    verifyRecaptcha: action,
    show: action
});

const ModalRecaptcha = observer(({controller}) => (
    <InfoBox2 controller={controller.infoBoxController} hasClose={true} icon={'far fa-shield-check'}
              title={lang.get('recaptcha.infobox.title')}>
        <InfoBox2Content>
            <div className="d-inline-block text-center">
                <Reaptcha
                    sitekey="6LeKe2IUAAAAAErmrqB_Xletw4Hi8xW5d5ZbxV_t"
                    onVerify={(response) => controller.verifyRecaptcha(response)}
                />
            </div>
        </InfoBox2Content>
    </InfoBox2>
));

export {ModalRecaptcha, ModalRecaptchaStore};