import {decorate, observable, runInAction} from 'mobx';
import SiteMap from './SiteMap';

class Navigator {
    userStore;
    currentUrl = '';
    currentRenderedPage = '';
    currentPage = null;
    queue = [];
    siteMap;
    allowPageChange = (page, location) => null;

    constructor(userStore, inviteService) {
        this.userStore = userStore;
        this.siteMap = new SiteMap(this.userStore, this, inviteService);
        window.onpopstate = () => {
            this.refreshState();
        };
    }

    getCurrentUrl() {
        return window.location.hash.replace('#', '');
    }

    refreshState() {
        let url = this.getCurrentUrl();

        try {
            let page = this.getPageByUrl(url);
            let location = this.getLocationByPage(url, page);
            if (this.allowPageChange(page, location) === true)
                this.setPage(page, location);
        } catch (e) {
            console.error(e);
            // goto 404
            this.goToPage(this.siteMap.pages.Dashboard);
        }
    }

    pushState(url) {
        // if (url === this.getCurrentUrl()) {
        //   return;
        // }
        let page = this.getPageByUrl(url);
        let location = this.getLocationByPage(url, page);
        if (this.allowPageChange(page, location) === true) {
            this.setPage(page, location);
            window.history.pushState(null, null, `#${url}`);
        }
    }

    /**
     * sets page without changing url or pushing any state in
     */
    setPageWithoutStateChange(pageInstance) {
        this.currentRenderedPage = pageInstance;
    }

    pushStateUnsafe(url) {
        if (url === this.getCurrentUrl())
            return;
        let page = this.getPageByUrl(url);
        let location = this.getLocationByPage(url, page);
        this.setPage(page, location);

        window.history.pushState(null, null, `#${url}`);
    }

    setPage(page, location) {
        runInAction(() => {
            this.currentPage = page;
            this.currentUrl = this.siteMap.calculatePageUrl(page, location);
            this.currentRenderedPage = page.constructor(location);

            //Send every page view on facebook pixel...
            window.facebookWrapper.pageView();
        });
    }

    getLocationByPage(url, page) {
        let urlParts = url.split('?');
        let path = urlParts[0];
        let queryString = this.parseQueryString(urlParts[1]);

        let currentPath = path.split('/');
        let pagePath = page.url.split('/');
        let location = {};
        for (let i = 0; i < pagePath.length; i++) {
            if (pagePath[i].startsWith(':') === true) {
                let varName = pagePath[i].substr(1);
                if (currentPath[i] === 'null')
                    location[varName] = null;
                else
                    location[varName] = currentPath[i];
            }
        }
        location.queryString = queryString;
        return location;
    }

    parseQueryString(queryString) {
        if (!queryString) return {};
        let result = {};
        queryString.split('&').forEach(it => {
            let parts = it.split('=');
            let key = parts[0];
            let value = decodeURIComponent(parts[1]);
            result[key] = value;
        })
        return result;
    }

    getPageByUrl(url) {
        let urlParts = url.split('?');
        let path = urlParts[0];
        let locationParts = path.split('/');

        let page = Object.values(this.siteMap.pages)
            .filter(it => {
                let itParts = it.url.split('/');
                if (itParts.length !== locationParts.length)
                    return false;

                for (let i = 0; i < locationParts.length; i++) {
                    if (locationParts[i] !== itParts[i] && itParts[i].startsWith(':') === false) return false;
                }
                return true;
            })[0];

        return page;
    }

    canGoTo(page) {
        let filteredRequiredRoles = page.requiredRoles.filter(requiredRole => this.userStore.canDoAction(requiredRole));
        return filteredRequiredRoles.length === page.requiredRoles.length;
    }

    goToPage(page, ...params) {
        this.pushState(page.createUrl(...params))
    }

    goToPageWithoutStateChange(page, ...params){
        this.setPageWithoutStateChange(page.constructor(...params));
    }

    pushStateWithoutPageChange(page, ...params) {
        let url = page.createUrl(...params);
        window.history.pushState(null, null, `#${url}`)
    }

    async logout() {
        await this.userStore.logout();
        this.goToPage(this.siteMap.pages.Login);
    }

    back() {
        window.history.back();
    }
}

decorate(Navigator, {
    currentRenderedPage: observable,
    currentPage: observable.ref
});

export default Navigator;