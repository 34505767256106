import React from 'react';
import {LoginController, LoginPage} from './LoginPage';
import {UserProfileEditController, UserProfileEditPage} from './UserProfile/UserProfileEditPage';
import {CompanySettingsController, CompanySettingsPage} from './CompanySettings/CompanySettingsPage';
import {SurveyListPageController, SurveyListPageView} from './Survey/SurveyListPage';
import {ProfilingController, ProfilingPage} from './Profiling/ProfilingPage';
import {ReportPageView, ReportViewPageController} from './Report/ReportPageView';
import {InitialSurveyController, InitialSurveyPage} from './Survey/InitialSurvey/InitialSurveyPage';
import {SurveyPageController, SurveyPageView} from './Survey/Survey/SurveyPageView';
import {FeedbackListPageController, FeedbackListPageView} from './Feedback/FeedbackListPageView';
import {FeedbackMessagesController, FeedbackMessagesView} from './Feedback/FeedbackMessages/FeedbackMessagesView';
import {TeamListPageController, TeamListPageView} from './TeamManagement/TeamList/TeamListPageView';
import {TeamEditPageController, TeamEditPageView} from './TeamManagement/TeamEdit/TeamEditPageView';
import {SolutingListPageController, SolutingListPageView} from './Soluting/SolutingList/SolutingListPageView';
import {
    SolutingArticlePageController,
    SolutingArticlePageView
} from './Soluting/SolutingArticle/SolutingArticlePageView';
import {SolutingPageView, SolutingPageViewController} from './Soluting/Soluting/SolutingPageView';
import {UserListPageController, UserListPageView} from './TeamManagement/UserList/UserListPageView';
import {EditUserPageController, EditUserPageView} from './TeamManagement/UserEdit/EditUserPageView';
import {InvitePageController, InvitePage} from './InvitePage';
import {NotificationListPageController, NotificationListPageView} from './Notification/NotificationListPageView';
import {ViewUserPageController, ViewUserPageView} from './TeamManagement/UserEdit/ViewUserPageView';
import {ProfilingResultController, ProfilingResultPage} from './ProfilingResult/ProfilingResultPage';
import {ViewTeamPageView, ViewTeamPageViewController} from './TeamManagement/TeamEdit/ViewTeamPageView';
import {VerificationPageController, VerificationPageView} from './Verification/VerificationPageView';
import {UserProfileViewController, UserProfileViewPage} from './UserProfile/UserProfileViewPage';
import {SignUpPage, SignUpPageController} from './SignUpPage';
import {
    NonCompanyUserWelcomeScreenPageController,
    NonCompanyUserWelcomeScreenPageView
} from './NonCompanyUserWelcomeScreenPage';
import {Role} from '../domain/Role';
import {COQListPageController, COQListPageView} from './COQ/COQListPageView';
import {COQEditPageController, COQEditPageView} from './COQ/COQEditPageView';
import {OpenDiscussionListPageController, OpenDiscussionListPageView} from './OpenDiscussion/OpenDiscussionListPageView';
import {OpenDiscussionMessagesController, OpenDiscussionMessagesView} from './OpenDiscussion/OpenDiscussionMessages/OpenDiscussionMessagesView';
import {ReportFilter} from '../domain/ReportService';
import {SubscriptionPlanPageController, SubscriptionPlanPageView} from "./Subscription/SubscriptionPlanPage";

class SiteMap {
    userStore;
    navigator;
    inviteService;

    constructor(userStore, navigator, inviteService) {
        this.userStore = userStore;
        this.navigator = navigator;
        this.inviteService = inviteService;
    }

    pages = {
        Login: {
            url: '/login',
            constructor: () => <LoginPage controller={new LoginController(this.navigator)}/>,
            createUrl: () => '/login',
            requiredRoles: []
        },

        ViewProfile: {
            url: '/view-profile',
            constructor: () => <UserProfileViewPage
                controller={new UserProfileViewController(this.navigator, this.userStore)}/>,
            createUrl: () => '/view-profile',
            requiredRoles: [Role.UserViewDetails]
        },

        EditProfile: {
            url: '/edit-profile',
            constructor: () => <UserProfileEditPage
                controller={new UserProfileEditController(this.navigator, this.userStore)}/>,
            createUrl: () => '/edit-profile',
            requiredRoles: [Role.UserEditDetails]
        },

        NonCompanyUserWelcomeScreen: {
            url: '/non-company-user-welcome',
            constructor: () => <NonCompanyUserWelcomeScreenPageView controller={new NonCompanyUserWelcomeScreenPageController(this.navigator, this.userStore)}/>,
            createUrl: () => '/non-company-user-welcome',
            requiredRoles: []
        },

        CompanySettings: {
            url: '/company-settings',
            constructor: () => <CompanySettingsPage
                controller={new CompanySettingsController(this.navigator, this.userStore)}/>,
            createUrl: () => '/company-settings',
            requiredRoles: [Role.EditCompanyData]
        },


        SurveyList: {
            url: '/survey-list',
            constructor: () => <SurveyListPageView
                controller={new SurveyListPageController(this.navigator, this.userStore)}/>,
            createUrl: () => '/survey-list',
            requiredRoles: [Role.SurveyViewOverview]
        },


        SignUp: {
            url: '/signup',
            constructor: () => <SignUpPage controller={new SignUpPageController(this.navigator, this.userStore)}/>,
            createUrl: () => '/signup',
            requiredRoles: []
        },

        ProfilingResult: {
            url: '/profiling-result/:token',
            constructor: (location) => <ProfilingResultPage
                controller={new ProfilingResultController(this.navigator, this.userStore, location.token)}/>,
            createUrl: (token) => `/profiling-result/${token}`,
            requiredRoles: []
        },


        Dashboard: {
            url: '/',
            constructor: () => <ReportPageView
                controller={new ReportViewPageController(this.navigator, this.userStore)}/>,
            createUrl: () => '/',
            requiredRoles: [Role.ReadReport]
        },


        InitialSurvey: {
            url: '/initial-survey/:surveyId',
            constructor: () => <InitialSurveyPage
                controller={new InitialSurveyController(this.navigator, this.userStore)}/>,
            createUrl: (surveyId) => `/initial-survey/${surveyId}`,
            requiredRoles: [Role.SurveyView]
        },


        Survey: {
            url: '/survey/:surveyId',
            constructor: (location) => <SurveyPageView
                controller={new SurveyPageController(this.navigator, this.userStore, location.surveyId)}/>,
            createUrl: (surveyId) => `/survey/${surveyId}`,
            requiredRoles: [Role.SurveyView]
        },


        FeedbackList: {
            url: '/feedback-list',
            constructor: () => <FeedbackListPageView
                controller={new FeedbackListPageController(this.navigator, this.userStore)}/>,
            createUrl: () => '/feedback-list',
            requiredRoles: [Role.FeedbackRead]
        },

        Feedback: {
            url: '/feedback/:feedbackId',
            constructor: (location) => <FeedbackMessagesView
                controller={new FeedbackMessagesController(this.navigator, this.userStore, location.feedbackId)}/>,
            createUrl: (feedbackId) => `/feedback/${feedbackId}`,
            requiredRoles: [Role.FeedbackRead]
        },

        OpenDiscussionList: {
            url: '/open-discussion-list',
            constructor: () => <OpenDiscussionListPageView
                controller={new OpenDiscussionListPageController(this.navigator, this.userStore)}/>,
            createUrl: () => '/open-discussion-list',
            requiredRoles: [Role.OpenDiscussionRead]
        },

        OpenDiscussion: {
            url: '/open-discussion-list/:openDiscussionId',
            constructor: (location) => <OpenDiscussionMessagesView
                controller={new OpenDiscussionMessagesController(this.navigator, this.userStore, location.openDiscussionId)}/>,
            createUrl: (openDiscussionId) => `/open-discussion-list/${openDiscussionId}`,
            requiredRoles: [Role.OpenDiscussionRead]
        },


        Report: {
            url: '/report',
            constructor: () => <ReportPageView
                controller={new ReportViewPageController(this.navigator, this.userStore)}/>,
            createUrl: () => '/report',
            requiredRoles: [Role.ReadReport]
        },

        ReportWithIdAndFilter: {
            url: '/report/:reportId',
            constructor: (location) => <ReportPageView
                controller={new ReportViewPageController(this.navigator, this.userStore, location.reportId, ReportFilter.parse(location.queryString))}/>,
            createUrl: (reportId, reportFilter) => `/report/${reportId}${this.toQueryString(reportFilter.toQueryObject())}`,
            requiredRoles: [Role.ReadReport]
        },


        TeamList: {
            url: '/team',
            constructor: () => <TeamListPageView
                controller={new TeamListPageController(this.navigator, this.userStore)}/>,
            createUrl: () => '/team',
            requiredRoles: [Role.GroupViewOverview]
        },


        ViewTeam: {
            url: '/team/:teamId',
            constructor: (location) => <ViewTeamPageView
                controller={new ViewTeamPageViewController(this.navigator, this.userStore, location.teamId)}/>,
            createUrl: (teamId) => `/team/${teamId}`,
            requiredRoles: [Role.GroupViewOverview]
        },

        EditTeam: {
            url: '/team/:teamId/edit',
            constructor: (location) => <TeamEditPageView
                controller={new TeamEditPageController(this.navigator, this.userStore, location.teamId)}/>,
            createUrl: (teamId) => `/team/${teamId}/edit`,
            requiredRoles: [Role.GroupCreate, Role.GroupDelete, Role.GroupViewOverview, Role.GroupChangeTeamName, Role.GroupChangeManager, Role.GroupAddAdmin, Role.GroupRemoveAdmin]
        },


        SolutingList: {
            url: '/soluting-list',
            constructor: () => <SolutingListPageView
                controller={new SolutingListPageController(this.navigator, this.userStore)}/>,
            createUrl: () => '/soluting-list',
            requiredRoles: []
        },


        SolutingArticle: {
            url: '/solutingArticle/:solutingId/:solutingArticleId',
            constructor: (location) => <SolutingArticlePageView
                controller={new SolutingArticlePageController(this.navigator, this.userStore, location.solutingId, location.solutingArticleId)}/>,
            createUrl: (solutingId, solutingArticleId) => `/solutingArticle/${solutingId}/${solutingArticleId}`,
            requiredRoles: []
        },


        Soluting: {
            url: '/soluting/:solutingId',
            constructor: (location) => <SolutingPageView
                controller={new SolutingPageViewController(this.navigator, this.userStore, location.solutingId)}/>,
            createUrl: (solutingId) => `/soluting/${solutingId}`,
            requiredRoles: []
        },

        UserList: {
            url: '/user',
            constructor: () => <UserListPageView
                controller={new UserListPageController(this.navigator, this.userStore)}/>,
            createUrl: () => '/user',
            requiredRoles: [Role.GroupViewOverview]
        },

        ViewUser: {
            url: '/user/:companyUserId',
            constructor: (location) => <ViewUserPageView
                controller={new ViewUserPageController(this.navigator, this.userStore, location.companyUserId)}/>,
            createUrl: (companyUserId) => `/user/${companyUserId}`,
            requiredRoles: [Role.GroupViewOverview]
        },

        EditUser: {
            url: '/user/:companyUserId/edit',
            constructor: (location) => <EditUserPageView
                controller={new EditUserPageController(this.navigator, this.userStore, location.companyUserId)}/>,
            createUrl: (companyUserId) => `/user/${companyUserId}/edit`,
            requiredRoles: [Role.GroupViewOverview, Role.GroupAddUser, Role.GroupRemoveUser]
        },

        Invite: {
            url: '/invite/:inviteId',
            constructor: (location) => <InvitePage
                controller={new InvitePageController(this.navigator, this.userStore, this.inviteService, location.inviteId)}/>,
            createUrl: (inviteId) => `/invite/${inviteId}`,
            requiredRoles: []
        },

        NotificationList: {
            url: '/notification',
            constructor: () => <NotificationListPageView
                controller={new NotificationListPageController(this.navigator, this.userStore)}/>,
            createUrl: () => '/notification',
            requiredRoles: []
        },

        Verification: {
            url: '/verification/:verificationId',
            constructor: (location) => <VerificationPageView
                controller={new VerificationPageController(this.navigator, this.userStore, location.verificationId, location.queryString)}/>,
            createUrl: (verificationId) => `/verification/${verificationId}`,
            requiredRoles: []
        },

        CustomOpenQuestions: {
            url: '/coq-list',
            constructor: () => <COQListPageView controller={new COQListPageController(this.navigator, this.userStore)}/>,
            createUrl: () => '/coq-list',
            requiredRoles: [Role.COQViewOverview]
        },

        EditCustomOpenQuestion: {
            url: '/coq/:coqId',
            constructor: (location) => <COQEditPageView controller={new COQEditPageController(this.navigator, this.userStore, location.coqId)}/>,
            createUrl: (coqId) => `/coq/${coqId}`,
            requiredRoles: [Role.COQUpdate]
        },

        SubscriptionPlan: {
            url: '/subscription',
            constructor: () => <SubscriptionPlanPageView controller={new SubscriptionPlanPageController(this.navigator, this.userStore)}/>,
            createUrl: () => '/subscription',
            requiredRoles: [Role.SubscriptionPlan]
        }
    };

    postPages = {
        Profiling: {
            constructor: (email) => <ProfilingPage
                controller={new ProfilingController(this.navigator, this.userStore, email)}/>
        },
    };

    toQueryString(object) {
        let parts = [];
        Object.keys(object).forEach(key => {
            let value = object[key];
            if (value !== null) {
                parts.push(`${key}=${encodeURIComponent(value)}`);
            }
        });
        if (parts.length > 0) return `?${parts.join('&')}`;
        return '';
    }

    calculatePageUrl(page, location) {
        let url = page.url;
        Object.keys(location)
            .forEach(key => {
                url = url.replace(`:${key}`, location[key])
            });
        return url;
    }
}

export default SiteMap;