import {axios} from '../../utils/WrappedAxios';

export default class Surveys {
    async getSubCategories() {
        let response = await axios.get('/api/user-survey/subcategories');
        return response.data.items;
    }

    async getCategories() {
        let response = await axios.get('/api/user-survey/categories');
        return response.data.items;
    }
}