import React, {Component} from 'react';
import {lang} from '../utils/Lang';
import {computed, decorate, observable} from 'mobx';
import {observer} from 'mobx-react';
import {InputSelect, InputSelectController} from '../components/Inputs/InputSelect';
import ValidatedValue from '../components/Inputs/ValidatedValue';
import {validateNotBlank, validateNotNull} from '../../utils/Validators';
import {DynamicTextarea} from '../components/Inputs/DynamicTextarea';
import {COQ} from '../../domain/COQ/COQ';
import LoadingModal from '../components/LoadingModal';
import Surveys from "../../domain/Survey/Surveys";


class COQEditItemController {
    content = new ValidatedValue([validateNotBlank]);
    category = new ValidatedValue([validateNotNull], null);
    isLoading = true;
    categoryOptions = [];

    formFields = [
        this.content,
        this.category,
    ];

    constructor(coqId) {
        this.coq = new COQ(coqId);
        this.loadItem();
        this.loadCategories();

        this.content.onFinalChange = (oldValue, newValue, isValid) => this.updateContent(oldValue, newValue, isValid);
        this.category.onFinalChange = (oldValue, newValue, isValid) => this.updateCategory(oldValue, newValue, isValid);
    }

    loadItem() {
        this.coq.getCoq()
            .then((response) => {
                this.content.value = response.content;
                this.category.value = response.categoryId;
                this.isLoading = false;
            })
            .catch(() => this.isLoading = false);
    }

    async loadCategories() {
        this.categorySelectController = new InputSelectController(this.categoryOptions, this.category);

        let categories = await new Surveys().getCategories();

        this.categoryOptions = [
            {text: lang.get('coq.categories.options.none'), value: null},
            ...categories.map(it => {
                return {value: it.id, text: it.text};
            })];

        this.categorySelectController.setItems(this.categoryOptions);
    }

    get isFormValid() {
        return this.formFields.filter(it => it.isValid === false).length === 0;
    }

    async updateContent(oldValue, newValue, isValid) {
        if (!isValid) return;
        await this.coq.updateCoq(this.content.value, this.category.value);
        this.content.showSaved();
    }

    async updateCategory(oldValue, newValue, isValid) {
        if (!isValid) return;
        await this.coq.updateCoq(this.content.value, this.category.value);
        this.category.showSaved();
    }
}


decorate(COQEditItemController, {
    isFormValid: computed,
    isLoading: observable
});

const COQEditItem = observer(class COQEditItem extends Component {
    render() {
        let controller = this.props.controller;

        return (
            <React.Fragment>
                <LoadingModal isVisible={controller.isLoading}/>
                <form id="coq" method="" name="coq">
                    <div className="card card-custom-open-question">
                        <div className="card-body">
                            <div className="card-header small">
                                <div className="card-header-icon">
                                    <i className="far fa-question"/>
                                </div>
                                <div className="card-header-title">
                                    <h2 className=""> {lang.get('coq.edit.card.title')}</h2>
                                </div>
                            </div>
                            <div className="card-content">
                                <DynamicTextarea name="coq"
                                          rows="2"
                                          maxLength="1500"
                                          placeholder={lang.get('coq.form.question')}
                                          validatedValue={controller.content}/>
                            </div>
                            <div className="card-footer">
                                <InputSelect controller={controller.categorySelectController}
                                             name="category"
                                             placeholder={lang.get('coq.form.category')}/>
                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
});


export {COQEditItem, COQEditItemController};