import {lang} from '../../../page/utils/Lang';

class AddedToTeamNotification {
    group = AddedToTeamNotification.group;

    constructor(teamId, userName, teamName) {
        this.teamId = teamId;
        this.userName = userName;
        this.teamName = teamName;
    }

    getLink(navigator) {
        return null;
    }

    get title() {
        return lang.get(`notification.${AddedToTeamNotification.group}.title`);
    }

    get content() {
        return lang.get(`notification.${AddedToTeamNotification.typeName}.content`).replace('$userName', this.userName).replace('$teamName', this.teamName);
    }

    static tryParse(json) {
        if (json.type !== this.typeName) return null;
        return new AddedToTeamNotification(json.teamId);
    }

    static get typeName() {
        return 'addedToTeam';
    }

    static get group() {
        return 'TeamManagement';
    }
}

export {AddedToTeamNotification};