import {decorate, observable, computed, action} from 'mobx';

class ValidatedValue {
    validators = [];
    _value = null;
    isTouched = false;
    isFocused = false;
    isSaved = false;

    onFinalChange = (oldValue, newValue, isValid) => {
    };

    constructor(validators, defaultValue) {
        this.validators = validators;
        this.value = defaultValue;
    }

    get validationError() {
        if (this.validators) {
            for (let i = 0; i < this.validators.length; i++) {
                let validationResult = this.validators[i](this.value);
                if (validationResult) {
                    return validationResult;
                }
            }
        }
        return null;
    }

    get displayError() {
        if (this.isTouched)
            return this.validationError;
        return null;
    }

    get isValid() {
        return !this.validationError;
    }

    touch() {
        this.isSaved = false;
        this.isTouched = true;
    }

    untouch() {
        this.isTouched = false;
    }

    valueBeforeFocus = null;

    focus() {
        this.isFocused = true;
        this.valueBeforeFocus = this.value;
    }

    blur() {
        this.isFocused = false;
        if (this.isValid) {
            this.onFinalChange(this.valueBeforeFocus, this.value, this.isValid);
        }
    }

    get isBeingChanged() {
        return this.isTouched;
    }

    get value() {
        return this._value;
    }

    set value(newValue) {
        this.untouch();
        this._value = newValue;
    }

    set valueWithTouch(newValue) {
        this.touch();
        this._value = newValue;
    }

    hideSavedTimeout = null;

    showSaved() {
        this.untouch();
        this.isSaved = true;
        if (this.hideSavedTimeout != null) clearTimeout(this.hideSavedTimeout);
        setTimeout(() => {
            this.isSaved = false;
        }, 2500);
    }
}

decorate(ValidatedValue, {
    _value: observable,
    value: computed,
    isTouched: observable,
    isFocused: observable,
    isSaved: observable,
    isBeingChanged: computed,
    setValue: action,
    setValueWithTouch: action,
    validationError: computed,
    displayError: computed,
    isValid: computed,
    touch: action,
    blur: action,
    focus: action
});

export default ValidatedValue;