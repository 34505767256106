import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {lang} from '../../utils/Lang'
import WellDone from '../../components/WellDone';

const InitialSurveyResultView = observer(({spirit, goToDashboard}) => (
    <WellDone title={lang.get('survey.initial.welldone.title')} subtitle={lang.get('survey.initial.welldone.subtitle')}
              content={lang.get('survey.initial.welldone.content')}
              btnTitle={lang.get('survey.initial.welldone.button')} onBtnClick={goToDashboard}/>
));

InitialSurveyResultView.PropTypes = {
    spirit: PropTypes.number.isRequired,
    goToDashboard: PropTypes.func.isRequired
};

export default InitialSurveyResultView;