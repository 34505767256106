import React, {Component} from 'react';
import {lang} from '../utils/Lang';
import {hideIfNot, roundSpirit} from '../../utils/Utils';
import {svgPath} from './SmoothCurve';
import moment from 'moment';
import {InputSelect} from '../components/Inputs/InputSelect';

export class BarChart extends Component {
    calcVertical(it) {
        let factor = 100 / this.maxYValue;
        return `${it * factor}%`;
    }

    calcHorizontal(it, count) {
        return `${it / count * 100}%`;
    }

    calcPoint(value) {
        let factor = 100 / this.maxYValue;
        return (100 - value * factor) * 10 / 10;
    }

    calcDate(item) {
        if (item && !item.isNullPoint) return <span className="no-wrap">{moment(item.surveyCreatedAt).locale(lang.language).format('MMM DD, YYYY')}</span>;
        else return lang.get('report.graph.points.notAvailable')
    }

    calcValue(item) {
        if (item && !item.isNullPoint) return roundSpirit(item.value);
        else return 'n/a'
    }

    get maxYValue() {
        return this.props.maxYValue;
    }

    calcDateLabel(items, index){

        let previousValue = "";
        let currentValue =  this.getMonth(items[index]);

        if(index - 1 >= 0){
            previousValue = this.getMonth(items[index - 1])
        }

        if(currentValue === previousValue) return "";

        return currentValue;
    }

    getMonth(item){
        if(item !== null){
            return moment(item.surveyCreatedAt).locale(lang.language).format('MMM YY')
        }
        return ""
    }


    render() {
        let points = this.props.data;

        //calc no of points
        let pointsLength = points.length;
        let xAxisData = [];
        for (let i = 0; i < pointsLength; i++) {
            xAxisData.push(i);
        }

        //draw x axis labels
        let xTicks = xAxisData.map((it, index) =>
            <div key={index} className="tick" style={{left: this.calcHorizontal(it, pointsLength - 1)}}>
                <div className="tick-rotated">
                    {this.calcDateLabel(points, index)}
                </div>
            </div>);

        //draw y axis labels
        let yTickData = [0, 0.5, 1.0].map(it => it * this.maxYValue);
        let yTicks = yTickData.map((it, index) => <div key={index} className="tick"
                                                       style={{bottom: this.calcVertical(it)}}>{it}</div>);

        //draw calculated number of lines for x axis
        let horizontalGridLines = xAxisData.map((it, index) => <div key={index} className="horizontal-grid-line"
                                                                    style={{left: this.calcHorizontal(it, pointsLength - 1)}}/>);

        //draw fixed 5 lines for y axis
        let verticalGridLineData = [0, 0.25, 0.5, 0.75, 1.0].map(it => it * this.maxYValue);
        let verticalGridLines = verticalGridLineData.map((it, index) => <div key={index} className="vertical-grid-line"
                                                                             style={{bottom: this.calcVertical(it)}}/>);


        //calc of valid data
        let pathData = [];
        let currentPath = [];
        for (let i = 0; i < pointsLength; i++) {
            if (points[i].value == null) {
                if (currentPath.length > 0) {
                    pathData.push(currentPath);
                    currentPath = [];
                }
                continue;
            }
            currentPath.push([i, this.calcPoint(points[i].value)]);
        }
        if (currentPath.length > 0) pathData.push(currentPath);
        let paths = pathData.filter(it => it.length > 1).map((it, index) => <React.Fragment key={index}>{svgPath(it)}</React.Fragment>);
        let dots = pathData.filter(it => it.length === 1).map(it => <ellipse key={it[0][0]} cx={it[0][0]} cy={it[0][1]} rx={0.20} ry={1}/>);

        let showInfoBox = () => this.props.onInfoIconClicked();

        let svgBoxXBoundary = 0;
        if (pointsLength > 0) svgBoxXBoundary = pointsLength - 1;


        // find all null line data
        let nullLines = []; // x1,x2,y1,y2

        let currentIntervalIsNullInterval = false;
        let nullIntervalStartingPointX = 0.0;
        let nullIntervalStartingPointY = 0.5 * this.maxYValue;
        let lastNonNullY = 0.5 * this.maxYValue;

        // find null lines
        points.forEach((it, index) => {
            if (it.value == null) {
                // check we are in null interval
                if (currentIntervalIsNullInterval) {
                    // do nothing
                } else {
                    currentIntervalIsNullInterval = true;
                    nullIntervalStartingPointX = (index - 1 > 0) ? index - 1 : 0;
                    nullIntervalStartingPointY = lastNonNullY;
                }
            } else {
                if (currentIntervalIsNullInterval) {
                    currentIntervalIsNullInterval = false;
                    nullLines.push({x1: nullIntervalStartingPointX, x2: index, y1: nullIntervalStartingPointY, y2: it.value});
                }
                lastNonNullY = it.value;
            }
        });
        if (currentIntervalIsNullInterval) {
            nullLines.push({x1: nullIntervalStartingPointX, x2: points.length - 1, y1: nullIntervalStartingPointY, y2: nullIntervalStartingPointY});
        }

        // linear interpolation of null lines
        function linearInterpolation(nullLine, index) {
            return ((index - nullLine.x1) * (nullLine.y2 - nullLine.y1) / (nullLine.x2 - nullLine.x1)) + nullLine.y1;
        }

        let nullPoints = [];
        nullLines.forEach(nullLine => {
            for (let index = nullLine.x1; index <= nullLine.x2; index++) {
                let x = index;
                let y = linearInterpolation(nullLine, x);
                nullPoints.push({x: x, y: y});
            }
        });

        // draw null lines and
        let noDataPaths = nullLines.map((it, index) => <React.Fragment key={index}>{svgPath([[it.x1, this.calcPoint(it.y1)], [it.x2, this.calcPoint(it.y2)]])}</React.Fragment>);

        //score points visible on hover
        let scores = points.map((it, index) => {
            if (it.value != null) return it;
            else return {
                value: nullPoints.filter(it => it.x === index)[0].y,
                surveyCreatedAt: points[index].surveyCreatedAt,
                isNullPoint: true
            };
        }).map((it, index) => {
            let isVisible = it.value !== null;
            let element =
                <React.Fragment key={index}>
                    <div className="score-dot" style={{left: this.calcHorizontal(index, pointsLength - 1), bottom: this.calcVertical(it.value)}}>
                        <div className="score-dot-hover">
                            <span>{this.calcValue(it)}</span>
                            <span className="point-date">{this.calcDate(it)}</span>
                        </div>
                    </div>
                </React.Fragment>;
            hideIfNot(isVisible, element.style);
            return element;
        });

        return (
            <div className="report-graph">
                <div className="title">
                    <h2>{this.props.title}</h2>
                    <button className="btn none" onClick={(e) => {
                        e.stopPropagation();
                        showInfoBox();
                    }}><i className="far fa-question-circle"/></button>
                </div>
                <div className="filter">
                    {this.props.movingAvgsFilter &&
                    <InputSelect controller={this.props.movingAvgsFilter}
                                 name={'input-select-moving-avg-filter'}
                                 blue
                                 licenseLock={false}
                                 placeholder={lang.get('report.filterByMovingAvgs')}/>
                    }
                </div>
                <div className="x-axis">
                    {xTicks}
                </div>
                <div className="y-axis">
                    {yTicks}
                </div>
                <div className="horizontal-grid">
                    {horizontalGridLines}
                </div>
                <div className="vertical-grid">
                    {verticalGridLines}
                </div>
                <div className="graph">
                    <svg viewBox={`0 0 ${svgBoxXBoundary} 100`} preserveAspectRatio="none" width="100%" height="100%" key={this.props.graphKey}>
                        <svg viewBox={`0 0 ${svgBoxXBoundary} 100`} width="100%" height="100%"
                             preserveAspectRatio="none">
                            {noDataPaths}
                        </svg>

                        <defs>
                            <linearGradient id="graph-gradient" gradientUnits="userSpaceOnUse" x1="0" y1="0" x2="0" y2="100">
                                <stop offset="0%" stopColor="#ACE700"/>
                                <stop offset="50%" stopColor="#F19100"/>
                                <stop offset="100%" stopColor="#FF0000"/>
                            </linearGradient>
                        </defs>
                        {paths}
                        {dots}
                    </svg>
                </div>
                <div className="scores">
                    {scores}
                </div>
            </div>
        );
    }
}