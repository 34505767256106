import {observer} from 'mobx-react';
import React from 'react';

export const NavigationSubItem = observer(({displayText, displayIcon, onSelect, isCurrent, onClose}) => {
    let cssCurrentClass = isCurrent ? 'current' : '';
    return (
        <li className={`nav-menu-item ${cssCurrentClass}`}>
            <a
                href="#/"
                onClick={(e) => {
                    e.preventDefault();
                    onSelect();
                }}
            >
                <i className={displayIcon}/>
                <span>{displayText}</span>
            </a>
        </li>
    );
});
