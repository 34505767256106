import {axios} from '../utils/WrappedAxios';
import {TeamPath} from './Team/Team';
import _ from 'lodash';

class ReportFilter {
    constructor(teamPath, movingAverageCount) {
        this.teamPath = teamPath;
        this.movingAverageCount = null;
        if (movingAverageCount !== undefined) this.movingAverageCount = movingAverageCount;
    }

    toQueryString() {
        let parts = [];
        if (this.teamPath) {
            parts.push(`teamPath=${encodeURIComponent(this.teamPath.asString)}`);
        }
        if (this.movingAverageCount !== null) {
            parts.push(`movingAverageCount=${encodeURIComponent(this.movingAverageCount)}`);
        }
        return parts.join('&');
    }

    toQueryObject() {
        let queryObject = {};
        if (this.teamPath) {
            queryObject.teamPath = this.teamPath.asString;
        }
        if (this.movingAverageCount !== null) {
            queryObject.movingAverageCount = this.movingAverageCount;
        }
        return queryObject;
    }

    static parse(queryStringObject) {
        let teamPath = null;
        let movingAverageCount = null;
        if ('teamPath' in queryStringObject) teamPath = new TeamPath(queryStringObject.teamPath);
        if ('movingAverageCount' in queryStringObject) movingAverageCount = parseInt(queryStringObject.movingAverageCount);

        return new ReportFilter(teamPath, movingAverageCount);
    }
}

class ReportService {

    getReport(reportName, reportFilter) {
        let queryParams = [`reportTitle=${reportName}`];
        if (reportFilter) {
            queryParams.push(reportFilter.toQueryString());
        }

        return axios.get(`/api/report?${queryParams.join('&')}`)
            .then(response => {
                return response.data;
            });
    }

    async getSpirit() {
        let result = await axios.get('/api/report/spirit');
        return result.data.spirit;
    }
}

function calculateMovingAverage(weekValues, numberOfWeeks) {
    let averageList = [];
    let result = [];
    for (let i = weekValues.length - 1; i >= 0; i--) {
        let currentWeek = weekValues[i];

        while (averageList.length >= numberOfWeeks) averageList.splice(averageList.length - 1, 1);
        averageList.unshift(currentWeek.value);
        let movingAverageValue = null;
        let nonNullCount = averageList.filter(it => it != null).length;
        if (nonNullCount > 0) movingAverageValue = _.sum(averageList) / averageList.filter(it => it != null).length;
        result.unshift({surveyCreatedAt: currentWeek.surveyCreatedAt, value: movingAverageValue});
    }

    return result;
}

function calculateTendency(movingAverageValues) {
    if (movingAverageValues.length < 2) return 0;
    if (movingAverageValues[0].value === null || movingAverageValues[1].value === null) return 0;

    if (movingAverageValues[0].value > movingAverageValues[1].value) return 1;
    else if (movingAverageValues[0].value < movingAverageValues[1].value) return -1;
    else return 0;
}

export {ReportService, ReportFilter, calculateMovingAverage, calculateTendency};