import React from 'react';
import {SelectionInfoBoxView} from './SelectionInfoBox';
import {lang} from '../utils/Lang';

const ChatMessageLabelInfoBox = ({selectedItemId, controller}) => {
    return (
        <SelectionInfoBoxView
            controller={controller}
            title={lang.get('feedbackMessages.infoBox.changeLabel.title')}
            legend={lang.get('feedbackMessages.infoBox.changeLabel.content')}
            id='selectLabelInfoBox'
        />
    );
};

export {ChatMessageLabelInfoBox};