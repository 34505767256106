import {decorate, observable, action} from 'mobx';
import {axios} from '../../utils/WrappedAxios';

class SurveyCollectionStore {
    surveys = [];

    /*    surveyId    createdAt    status  */

    load() {
        //TODO-low, we don't need to load all surveys that user ever made
        return axios.get('/api/user-survey')
            .then((response) => {
                this.surveys = response.data.surveys;
            });
    }

    getInitialSurvey() {
        return axios.get('/api/user-survey/initial')
            .then(response => response.data.survey)
            .catch(err => {
                if (err.response.status === 404) return null;
                else throw err;
            });
    }
}

decorate(SurveyCollectionStore, {
    surveys: observable,
    load: action
});

export default SurveyCollectionStore;