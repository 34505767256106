import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {decorate, observable, action, computed} from 'mobx';
import _ from 'lodash';
import {lang} from '../utils/Lang';
import Surveys from '../../domain/Survey/Surveys';
import {InputSelect, InputSelectController} from './Inputs/InputSelect';

class ListFilterController {
    filterControllerMap = {};

    onClose = () => {
    };
    onApply = () => {
    };
    onChange = () => {
    };

    /**
     * constructor
     *
     * @param onClose event handler for onClose
     * @param onApply event handler for onApply taking in [{id, name, selectedOption}]
     */
    constructor(onClose, onApply, onChange, filters) {
        if (onClose)
            this.onClose = onClose;
        if (onApply)
            this.onApply = onApply;
        if (onChange)
            this.onChange = onChange;

        filters.forEach((filter) => {
            this.filterControllerMap[filter.name] = {
                name: filter.name,
                placeholder: filter.placeholder,
                controller: new InputSelectController(filter.options, filter.validatedValue)
            };
            filter.validatedValue.onFinalChange = () => this.onFilterChanged();
        });

        //load subgroups if filter exists
        if(this.filterControllerMap['filterSubGroup']) this.loadSubGroupOptions();

        //load categories if filter exists
        if(this.filterControllerMap['filterGroups']) this.loadGroups();
    }

    async loadSubGroupOptions() {
        let subGroups = await new Surveys().getSubCategories();
        let subGroupOptions = [
            {text: lang.get('feedbackFilter.none'), value: null},
            ...subGroups.map(it => {
                return {text: it.value, value: it.id};
            })];
        this.filterControllerMap['filterSubGroup'].controller.setItems(subGroupOptions);
    }

    async loadGroups() {
        let groups = await new Surveys().getCategories();
        let groupOptions = [
            {text: lang.get('feedbackFilter.none'), value: null},
            ...groups.map(it => {
                return {text: it.text, value: it.id};
            })];
        this.filterControllerMap['filterGroups'].controller.setItems(groupOptions);
    }

    get filterControllers() {
        return Object.values(this.filterControllerMap);
    }

    onDropdownShown(controllerId) {
        this.hideDropdownsExcept(controllerId);
    }

    hideDropdownsExcept(controllerId) {
        this.filterControllers.forEach(it => {
            if (controllerId !== it.id)
                it.hideDropdown()
        });
    }

    onFooterReset() {
        // reset all filters to null
        this.filterControllers.forEach(it => {
            it.controller.validatedValue.value = null;
        });
    }

    get currentFilter() {
        let it = _.chain(this.filterControllers)
            .filter(it => it.controller.selectedValue !== null)
            .map(it => {
                return {id: it.id, name: it.name, selectedOption: it.controller.selectedItem}
            })
            .value();
        return it;
    }

    setFilter(selectedItems) {
        selectedItems.forEach(it => {
            if (this.filterControllerMap[it.name]) {
                this.filterControllerMap[it.name].selectItem(it.selectedOption);
            }
        });
    }

    onFilterChanged() {
        this.onChange(this.currentFilter);
    }

    onFooterSubmit() {
        this.onApply(this.currentFilter);
    }

    onFooterCancel() {
        this.onClose();
    }
}

decorate(ListFilterController, {
    filterControllerMap: observable,
    filterControllers: computed,
    onDropdownShown: action,
    hideDropdownsExcept: action
});

const ListFilterView = observer(class ListFilterView extends Component {
    render() {
        let controller = this.props.controller;
        let filterValues = controller.filterControllers.map((item, index) => {
            return <div key={index} className={item.name}>
                <label htmlFor={item.id} className="input-select-label">{item.placeholder}</label>
                <InputSelect name={item.name} placeholder={item.placeholder} controller={item.controller} blue
                             showNullInsteadPlaceholder={true}/>
            </div>;
        });

        return (
            <React.Fragment>
                <div className="feedback-filter-content">
                    <form action="/" method="" className="feedback-filter-items">
                        {filterValues}
                    </form>
                    <div className="button-row">
                        <button type="reset"
                                name="back"
                                className="btn left"
                                onClick={() => controller.onFooterReset()}>
                            <i className="far fa-undo"/> <span>{lang.get('feedbackFilter.footer.btn.reset')}</span>
                        </button>
                        <div className="horizontal-line line-left"/>
                        <button type="submit" name="next" className="btn right" form="feedbackFilterForm"
                                onClick={() => controller.onFooterSubmit()}><span>{lang.get('feedbackFilter.footer.btn.apply')}</span> <i
                            className="far fa-save"/></button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
});

export {ListFilterView, ListFilterController};