import {axios} from '../../utils/WrappedAxios';

export default class Invite {
    constructor(id) {
        this.id = id;
    }

    use() {
        return axios.put(`/api/invite/${this.id}`)
            .then(() => true)
            .catch(error => {
                if (error.response.status === 404) return false;
                throw error;
            });
    }
};