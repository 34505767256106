import React, {Component} from 'react';
import {hideIfNot} from '../../utils/Utils';

class COQListItem extends Component {

    getCOQItemStyle() {
        let style = {
            icon: 'far fa-play',
            statusClass: 'active',
            showActions: false,
        };


        if (this.props.status === 'Next') {
            style.icon = 'far fa-chevron-double-right';
            style.statusClass = 'next';
            style.showActions = true;
        }

        if (this.props.status === 'Used' || this.props.status === 'Canceled') {
            style.icon = 'far fa-square';
            style.statusClass = 'closed';
            style.showActions = false;
        }

        return style;
    }


    render() {
        let style = this.getCOQItemStyle();

        let id = this.props.id;
        let content = this.props.content;
        let onDelete = this.props.onDelete;
        let onEdit = this.props.onEdit;

        let canDelete = this.props.canDelete;
        let canEdit = this.props.canEdit;

        return (
            <div className={`coq-item ${style.statusClass}`}>
                <div className="coq-icon">
                    <i className={style.icon}/>
                </div>
                <div className="coq-content">
                    <div className="coq-title">
                        <h3>{content}</h3>
                    </div>
                    <div className="coq-icons">
                        <button id="coq-delete" className="btn plain" style={hideIfNot(canDelete && style.showActions)} onClick={(e) => {
                            e.stopPropagation();
                            onDelete(id);
                        }}><i className="far fa-trash-alt"/></button>
                        <button id="coq-edit" className="btn plain" style={hideIfNot(canEdit && style.showActions)} onClick={(e) => {
                            e.stopPropagation();
                            onEdit(id);
                        }}><i className="far fa-edit"/></button>
                    </div>
                </div>
            </div>
        );
    }
}

export {COQListItem};