import React, {Component} from 'react';
import moment from 'moment';
import {hideIfNot} from '../../utils/Utils';

class ChatMessage extends Component {

    getUserDisplayName() {
        if (this.props.isAnonymous) {
            return 'Anonymous';
        } else {
            return this.props.userDisplayName;
        }
    }

    getUserMessageClass() {
        if (this.props.isSender)
            return 'user-current';
        return 'user-other';
    }

    render() {

        return (
            <div className={`message ${this.getUserMessageClass()}`}>
                <div className="messageContent">
                    <h5 className="userName" style={hideIfNot(!this.props.isSender)}>{this.getUserDisplayName()}</h5>
                    <p>{this.props.content}</p>
                </div>
                <span className="time">{moment(this.props.createdAt).format('HH:mm')}</span>
            </div>
        );

    }

}

export {ChatMessage};