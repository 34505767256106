import React, {Component} from 'react';
import {lang} from '../utils/Lang';
import {observer} from 'mobx-react/index';
import {ContentTitle} from '../components/ContentTitle';
import moment from 'moment';
import {hideIfNot} from '../../utils/Utils';

const CompanyViewView = observer(class CompanyViewView extends Component {
    render() {
        let controller = this.props.controller;
        let licenseType = controller.licenseType;
        let licenseExpiry = moment(controller.licenseExpiry).format('MMM DD, YYYY');

        return (
            <React.Fragment>
                <ContentTitle title={lang.get('appheader.companyProfile.title')}
                              subtitle={lang.get('appheader.companyProfile.subtitle')}/>
                <div className="card">
                    <div className="card-body">
                        <div className="card-header small">
                            <div className="card-header-icon">
                                <i className="far fa-building"/>
                            </div>
                            <div className="card-header-title">
                                <h2>{lang.get('registration.company.companyDetails')}</h2>
                                <button className="btn plain" id="edit-company" onClick={() => controller.goToEdit()}>
                                    <span>{lang.get('registration.company.editTitle')}</span>
                                    <i className="far fa-edit"/>
                                </button>
                            </div>
                        </div>
                        <div className="card-content">
                            <table className="table">
                                <tbody>
                                <tr>
                                    <td>{lang.get('registration.company.name')}</td>
                                    <td className="txtRight" data-selenium-id="name">{controller.name.value}</td>
                                </tr>
                                <tr>
                                    <td>{lang.get('registration.company.address')}</td>
                                    <td className="txtRight" data-selenium-id="address">{controller.address.value}</td>
                                </tr>
                                <tr>
                                    <td>{lang.get('registration.zipCode')}</td>
                                    <td className="txtRight" data-selenium-id="zipCode">{controller.zipCode.value}</td>
                                </tr>
                                <tr>
                                    <td>{lang.get('registration.city')}</td>
                                    <td className="txtRight" data-selenium-id="city">{controller.city.value}</td>
                                </tr>
                                <tr>
                                    <td>{lang.get('registration.country')}</td>
                                    <td className="txtRight"
                                        data-selenium-id="country">{controller.currentCountryValue}</td>
                                </tr>
                                <tr>
                                    <td>{lang.get('registration.company.vatId')}</td>
                                    <td className="txtRight" data-selenium-id="vat">{controller.vatID.value}</td>
                                </tr>
                                <tr>
                                    <td>{lang.get('registration.company.noOfEmployees')}</td>
                                    <td className="txtRight"
                                        data-selenium-id="noOfEmployees">{controller.noOfEmployees.value}</td>
                                </tr>
                                <tr>
                                    <td>Industry</td>
                                    <td className="txtRight"
                                        data-selenium-id="industry">{controller.currentIndustryValue}</td>
                                </tr>
                                <tr>
                                    <td>{lang.get('registration.company.ohwMembership')}</td>
                                    <td className="txtRight"
                                        data-selenium-id="ohw">{controller.ohwMembership.value ? 'Yes' : 'No'}</td>
                                </tr>
                                {controller.industry.value === 'I' &&
                                <tr>
                                    <td>{lang.get('registration.company.hogastID')}</td>
                                    <td className="txtRight">{controller.hogastID.value}</td>
                                </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                        <div className="card-header small">
                            <div className="card-header-icon">
                                <i className="far fa-award"/>
                            </div>
                            <div className="card-header-title">
                                <h2>{lang.get('company.landing.title.licence')}</h2>
                                {/*<button className="btn plain">*/}
                                {/*    <span>{lang.get('registration.company.editTitle')}</span>*/}
                                {/*    <i className="far fa-edit"/>*/}
                                {/*</button>*/}
                            </div>
                        </div>
                        <div className="card-content">
                            <table className="table">
                                <tbody>
                                <tr>
                                    <td>{lang.get('company.landing.licence.type')}</td>
                                    <td className="txtRight">{lang.get(`company.landing.licence.type.${licenseType}`)}</td>
                                </tr>
                                <tr>
                                    <td>{lang.get('company.landing.licence.renewal')}</td>
                                    <td className="txtRight">{licenseExpiry}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="card-header small">
                            <div className="card-header-icon">
                                <i className="fal fa-envelope-open"/>
                            </div>
                            <div className="card-header-title">
                                <h2>{lang.get('company.landing.title.invites')}</h2>
                                <button className="btn plain" onClick={() => controller.goToInvites()}>
                                    <span>{lang.get('registration.company.viewAll')}</span>
                                    <i className="far fa-eye"/>
                                </button>
                            </div>
                        </div>

                        <div className="card-header small" style={hideIfNot(controller.canCloseCompany)}>
                            <div className="card-header-icon">
                                <i className="far fa-building"/>
                            </div>
                            <div className="card-header-title">
                                <h2>{lang.get('company.landing.closeCompany.title')}</h2>
                                <button id="close-company-btn" className="btn plain" onClick={() => controller.closeCompany()}>
                                    <span>{lang.get('company.landing.closeCompany.btn')}</span>
                                    <i className="far fa-trash-alt"/>
                                </button>
                            </div>
                        </div>

                        {/*<div className="card-header small">*/}
                        {/*    <div className="card-header-icon">*/}
                        {/*        <i className="far fa-credit-card"/>*/}
                        {/*    </div>*/}
                        {/*    <div className="card-header-title">*/}
                        {/*        <h2>{lang.get('company.landing.title.paymentOptions')}</h2>*/}
                        {/*        <button className="btn plain">*/}
                        {/*            <span>{lang.get('registration.company.editTitle')}</span>*/}
                        {/*            <i className="far fa-edit"/>*/}
                        {/*        </button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="card-content">*/}
                        {/*    <table className="table">*/}
                        {/*        <tbody>*/}
                        {/*        <tr>*/}
                        {/*            <td>{lang.get('company.landing.payment.creditcard')}</td>*/}
                        {/*            <td className="txtRight">****4242</td>*/}
                        {/*        </tr>*/}
                        {/*        </tbody>*/}
                        {/*    </table>*/}
                        {/*</div>*/}
                        {/*<div className="card-header small">*/}
                        {/*    <div className="card-header-icon">*/}
                        {/*        <i className="far fa-file-invoice"/>*/}
                        {/*    </div>*/}
                        {/*    <div className="card-header-title">*/}
                        {/*        <h2>{lang.get('company.landing.title.Invoices')}</h2>*/}
                        {/*        <button className="btn plain">*/}
                        {/*            <span>{lang.get('registration.company.viewAll')}</span>*/}
                        {/*            <i className="far fa-eye"/>*/}
                        {/*        </button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="card-content companyInfoItem">*/}
                        {/*    <table className="table">*/}
                        {/*        <tbody>*/}
                        {/*        <tr>*/}
                        {/*            <td>{lang.get('company.landing.invoice.item')} 1</td>*/}
                        {/*            <td className="txtRight">17/08/2019</td>*/}
                        {/*        </tr>*/}
                        {/*        <tr>*/}
                        {/*            <td>{lang.get('company.landing.invoice.item')} 2</td>*/}
                        {/*            <td className="txtRight">17/07/2019</td>*/}
                        {/*        </tr>*/}
                        {/*        <tr>*/}
                        {/*            <td>{lang.get('company.landing.invoice.item')} 3</td>*/}
                        {/*            <td className="txtRight">17/06/2019</td>*/}
                        {/*        </tr>*/}
                        {/*        </tbody>*/}
                        {/*    </table>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </React.Fragment>
        );
    }
});

export default CompanyViewView;