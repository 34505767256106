import React, {Component} from 'react';
import {observer} from 'mobx-react';
import InputWrap from './InputWrap';
import {hideIfNot} from '../../../utils/Utils';

const DynamicTextarea = observer(class DynamicTextarea extends Component {
    timeoutHandler = null;

    render() {
        let name = this.props.name;
        let extraClass = this.props.extraClass;
        let placeholder = this.props.placeholder;
        let validatedValue = this.props.validatedValue;
        let maxLength = this.props.maxLength;
        let rows = this.props.rows;
        let autocomplete = this.props.autocomplete;
        let disabled = this.props.disabled;

        let onChange = (e) => {
            validatedValue.valueWithTouch = e.target.value;
            if (this.timeoutHandler != null) clearTimeout(this.timeoutHandler);
            this.timeoutHandler = setTimeout(() => {
                validatedValue.blur();
            }, 2500);
        };

        return (
            <InputWrap id={name} validationMsg={validatedValue.displayError} extraClass={extraClass}>
                <div className="dynamic-textarea">
                    <textarea name={name}
                              className="form-input"
                              id={name}
                              rows={rows}
                              placeholder={placeholder}
                              value={validatedValue.value ? validatedValue.value : ''}
                              maxLength={maxLength}
                              autoComplete={autocomplete}
                              onChange={onChange}
                              // onBlur={() => validatedValue.blur()}
                              onFocus={() => validatedValue.focus()}
                              disabled={disabled}/>
                    <i className="fa fa-spinner fa-spin save-icon" style={hideIfNot(validatedValue.isBeingChanged)}/>
                    <i className="fa fa-check save-icon coq-saved" style={hideIfNot(validatedValue.isSaved)}/>
                </div>
            </InputWrap>
        );
    }
});

export {DynamicTextarea};