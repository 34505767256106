import React, {Component} from 'react';
import {ContentTitle} from './components/ContentTitle';
import {lang} from './utils/Lang';
import {Header, HeaderController} from './Header/HeaderView';
import Input from "./components/Inputs/Input";
import {computed, decorate, observable} from "mobx";
import {observer} from "mobx-react";
import ValidatedValue from "./components/Inputs/ValidatedValue";
import {validateNotBlank} from "../utils/Validators";
import Invite from "../domain/Invite/Invite";
import {notificationManager} from "./components/Notifications";

class NonCompanyUserWelcomeScreenPageController {
    connectLink = new ValidatedValue([validateNotBlank]);

    formFields = [
        this.connectLink
    ];

    constructor(navigator, userStore) {
        this.navigator = navigator;
        this.userStore = userStore;
    }

    get isFormValid() {
        return this.formFields.filter(it => it.isValid === false).length === 0;
    }

    goToCompanyCreate() {
        this.navigator.goToPage(this.navigator.siteMap.pages.CompanySettings);
    }

    async goToDashboard(){
        await this.userStore.checkLogin(); //recheck login data to get companyuserid and allow access to feature for company user
        this.navigator.goToPage(this.navigator.siteMap.pages.Dashboard);
    }

    connectToCompany() {
        this.formFields.forEach(it => it.touch());
        if (!this.isFormValid) {
            return;
        }
        let inviteId = this.parseConnectLink(this.connectLink.value);  console.log(inviteId);
        if(inviteId){
            this.tryToUseInvite(inviteId)
        }
    }

    tryToUseInvite(inviteId) {
        if (this.userStore.isLoggedIn) {
            return new Invite(inviteId).use()
                .then(result => {
                    if (result === true) {
                        notificationManager.info(lang.get('invitation.notification.connected'));
                        this.goToDashboard()
                    } else {
                        notificationManager.warn(lang.get('invitation.notification.failed'));
                    }
                });
        }
    }

    parseConnectLink(connectLink){
        let objectId = connectLink.substr(connectLink.lastIndexOf('/') + 1);
        if (objectId.match(/^[0-9a-fA-F]{24}$/)) {
            return objectId
        } else {
            notificationManager.warn(lang.get('invitation.notification.invalidLink'));
            return null;
        }
    }
}

decorate(NonCompanyUserWelcomeScreenPageController, {
    connectLink: observable,
    isFormValid: computed,
});


const NonCompanyUserWelcomeScreenPageView = observer(class NonCompanyUserWelcomeScreenPageView extends Component {
    render() {
        let controller = this.props.controller;
        let onCreateCompany = () => controller.goToCompanyCreate();
        let onConnectToCompany = () => controller.connectToCompany();

        return (
            <main className="fadeIn">
                <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                <div className="appContent">
                    <div className="appContent-body ">
                        <ContentTitle title={lang.get('appheader.nonCompanyUserWelcomeScreenPage.title')}
                                      subtitle={lang.get('appheader.nonCompanyUserWelcomeScreenPage.subtitle')}/>
                        <div className="non-company-welcome-page">
                            <div className="card company-register-info">
                                <div className="card-body">
                                    <div className="card-header small">
                                        <div className="card-header-icon">
                                            <i className="far fa-pen"/>
                                        </div>
                                        <div className="card-header-title">
                                            <h2>{lang.get('nonCompanyUserWelcomeScreenPage.leftCard.title')}</h2>
                                        </div>
                                    </div>
                                    <div className="card-content">
                                        <h3>{lang.get('nonCompanyUserWelcomeScreenPage.leftCard.smallTitle')}</h3>
                                        <p>{lang.get('nonCompanyUserWelcomeScreenPage.leftCard.content')}</p>
                                    </div>
                                    <div className="card-footer">
                                        <button className="btn btn-register-company" id="company-register-btn"
                                                onClick={onCreateCompany}
                                        ><span>{lang.get('nonCompanyUserWelcomeScreenPage.leftCard.button')}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="steps-column">
                                <div className="v-line"/>
                                <div className="steps-circle">
                                    <span></span>
                                    <span>{lang.get('general.or')}</span>
                                    <span></span>
                                </div>
                                <div className="v-line"/>
                            </div>
                            <div className="card company-register-info">
                                <div className="card-body">
                                    <div className="card-header small">
                                        <div className="card-header-icon">
                                            <i className="far fa-pen"/>
                                        </div>
                                        <div className="card-header-title">
                                            <h2>{lang.get('nonCompanyUserWelcomeScreenPage.rightCard.title')}</h2>
                                        </div>
                                    </div>
                                    <div className="card-content">
                                        <h3>{lang.get('nonCompanyUserWelcomeScreenPage.rightCard.smallTitle')}</h3>
                                        <p>{lang.get('nonCompanyUserWelcomeScreenPage.rightCard.content')}</p>
                                    </div>
                                    <div className="card-footer">
                                        <Input
                                            name="connectLink"
                                            placeholder={lang.get('nonCompanyUserWelcomeScreenPage.connectLink.input')}
                                            validatedValue={controller.connectLink}
                                        />
                                        <button className="btn mt-1" id="connect-to-company-btn"
                                                onClick={onConnectToCompany}>
                                            <span>{lang.get('nonCompanyUserWelcomeScreenPage.connectLink.button')}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
});

export {NonCompanyUserWelcomeScreenPageController, NonCompanyUserWelcomeScreenPageView};