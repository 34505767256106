import {axios} from '../../utils/WrappedAxios';

class COQ {
    id = null;

    constructor(id) {
        this.id = id;
    }

    async getCoq() {
        return axios.get(`/api/coq/${this.id}`)
            .then(response => {
                return response.data;
            });
    }

    async updateCoq(content, categoryId) {
        let response = await axios.put(`/api/coq/${this.id}`, {
            content: content,
            categoryId: categoryId,
        });
        return response.data;
    }
}

export {COQ};