import React, {Component} from 'react';
import {lang} from '../utils/Lang';

class SurveyListItem extends Component {

    getSurveyItemStyle() {
        let style = {
            icon: 'far fa-list-ul',
            statusClass: ''
        };

        if (this.props.status === 'Finished' || this.props.status === 'Expired') {
            if (this.props.status === 'Finished') {
                style.icon = 'far fa-check';
                style.statusClass = 'done';
            } else {
                style.icon = 'far fa-times';
                style.statusClass = 'expired';
            }
        }

        return style;
    }

    getStatusName() {
        return lang.get(`survey.status.${this.props.status}`);
    }

    render() {
        let style = this.getSurveyItemStyle();

        return (
            <a href="#/" className="survey-item" onClick={(e) => {
                e.preventDefault();
                this.props.onClick();
            }}>
                <div className="survey-icon">
                    <i className={style.icon}/>
                </div>
                <div className="survey-content">
                    <div className="survey-title">
                        <h3>{this.props.date}</h3>
                        <p>{lang.get('survey.status')}: <span
                            className={style.statusClass}>{this.getStatusName()}</span></p>
                    </div>
                    <div className="survey-icons">
                        <i className={`far fa-comment-dots ${this.props.hasOQ ? 'active' : ''}`}/>
                        <i className={`far fa-star ${this.props.hasFeedback ? 'active' : ''}`}/>
                    </div>
                </div>
            </a>
        );
    }
}

export {SurveyListItem};