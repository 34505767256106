import {lang} from '../../utils/Lang';
import React from 'react';
import {observer} from 'mobx-react';
import InputWrap from './InputWrap';


const InputGender = observer(({validatedValue}) => (
    <InputWrap id={'gender'} validationMsg={validatedValue.displayError} extraClass={'input-gender'}>
        <div className={`input-check-box male ${(validatedValue.value === 'm' ? 'selected' : '')}`}>
            <label htmlFor="gender-m" onClick={() => validatedValue.valueWithTouch = 'm'}>
                <input type="radio" name="gender-m" value={validatedValue} checked={validatedValue.value === 'm'}
                       onChange={() => validatedValue.valueWithTouch = 'm'}/>
                <i className="far fa-mars"/>
            </label>
            <span>{lang.get('registration.gender.m')}</span>
        </div>
        <div className={`input-check-box female ${(validatedValue.value === 'f' ? 'selected' : '')}`}>
            <label htmlFor="gender-f" onClick={() => validatedValue.valueWithTouch = 'f'}>
                <input type="radio" name="gender-f" value={validatedValue} checked={validatedValue.value === 'f'}
                       onChange={() => validatedValue.valueWithTouch = 'f'}/>
                <i className="far fa-venus"/>
            </label>
            <span>{lang.get('registration.gender.f')}</span>
        </div>
    </InputWrap>
));

export default InputGender;