import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {lang} from '../../utils/Lang'
import WellDone from '../../components/WellDone';

const SurveyResultView = observer(({goToDashboard}) => (
    <WellDone title={lang.get('survey.welldone.title')} subtitle={lang.get('survey.welldone.subtitle')}
              content={lang.get('survey.welldone.content')} btnTitle={lang.get('survey.welldone.button')}
              onBtnClick={goToDashboard}/>
));

SurveyResultView.PropTypes = {
    goToDashboard: PropTypes.func.isRequired
};

export default SurveyResultView;