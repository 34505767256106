import React, {Component} from 'react';
import moment from 'moment';
import {OpenDiscussionListItem} from './OpenDiscussionListItem';

class OpenDiscussionListView extends Component {
    createListItem(discussion) {
        return <OpenDiscussionListItem key={discussion.id}
                                       topicId={discussion.id}
                                       title={discussion.questionText}
                                       status={discussion.status}
                                       createdAt={moment(discussion.createdAt).format('MMM DD, YYYY')}
                                       updatedAt={moment(discussion.updatedAt).format('MMM DD, YYYY')}
                                       category={discussion.rootGroupId}
                                       unreadCount={discussion.unreadCount}
                                       onClick={() => this.props.goToDiscussion(discussion.id)}/>;
    }

    render() {
        let discussions = this.props.discussions;

        let discussionItems = discussions.map((discussion) => {
            return this.createListItem(discussion)
        });

        return (
            <React.Fragment>
                <ul className="open-discussions-content">
                    {discussionItems}
                </ul>
            </React.Fragment>
        );
    }
}

export default OpenDiscussionListView;


