function hideIfNot(isVisible, attachToStyle) {
    if (!isVisible && !attachToStyle)
        return {display: 'none'};

    if (!isVisible)
        return {
            ...attachToStyle,
            display: 'none'
        };

    return attachToStyle;
}

function roundSpirit(spirit) {
    let label = 'n/a';
    if (spirit !== null && !isNaN(spirit)) {
        label = spirit.toFixed(1);
    }
    return label;
}

export {
  hideIfNot,
  roundSpirit
};