import {axios} from '../../utils/WrappedAxios';
import Invite from './Invite';

class InviteCollection {
    async createInvite() {
        let response = await axios.get('/api/invite/');
        return new Invite(response.data.inviteId);
    }
}

export default InviteCollection;