import {axios} from '../../utils/WrappedAxios';
import CompanyStore from '../CompanyStore';

export default class CompanyCollection {
    createCompany(name, address, zipCode, city, country, vatID, industry, noOfEmployees, ohwMembership, hogastID) {
        return axios.post('/api/company', {
            name: name,
            address: address,
            zipCode: zipCode,
            city: city,
            country: country,
            vatID: vatID,
            industry: industry,
            noOfEmployees: noOfEmployees,
            ohwMembership: ohwMembership,
            hogastID: hogastID
        }).then(response => new CompanyStore(response.data));
    }
}