import React, {Component} from 'react';
import {lang} from '../utils/Lang';
import {decorate, observable, computed, action} from 'mobx';
import {observer} from 'mobx-react';
import StepsFooterBar from '../components/StepsFooterBar';
import ScrollEvents from '../../utils/ScrollEvents';
import {Header, HeaderController} from '../Header/HeaderView';
import {ContentTitle} from '../components/ContentTitle';

let steps = [
    {
        left: 101,
        right: 102,
        tokenLeft: 'E',
        tokenRight: 'I'
    },
    {
        left: 201,
        right: 202,
        tokenLeft: 'P',
        tokenRight: 'T'
    },
    {
        left: 301,
        right: 302,
        tokenLeft: 'H',
        tokenRight: 'K'
    },
    {
        left: 401,
        right: 402,
        tokenLeft: 'G',
        tokenRight: 'S'
    },
    {
        left: 501,
        right: 502,
        tokenLeft: 'R',
        tokenRight: 'E'
    }
];

class ProfilingPageStepController {
    step = 0;
    totalSteps = 5;
    profile = [null, null, null, null, null];
    stepChanged = false;
    isPrevBtnActive = false;
    isNextBtnActive = false;

    constructor(navigator, userStore, onProfilingStepsFinished) {
        this.navigator = navigator;
        this.userStore = userStore;
        this.onProfilingStepsFinished = onProfilingStepsFinished;
    }

    get canPrev() {
        return this.step > 0;
    }

    calcBtnActiveState() {
        if (this.canPrev) this.isPrevBtnActive = true; else this.isPrevBtnActive = false;
        if (this.canNext && (this.isLeftSelected || this.isRightSelected)) this.isNextBtnActive = true; else this.isNextBtnActive = false;
    }

    prev() {
        this.step--;
        this.stepChanged = true;
    }

    get canNext() {
        // only if this state is completed
        return this.profile[this.step] !== null && this.step < this.totalSteps - 1;
    }

    next() {
        this.step++;
        this.stepChanged = true;
    }

    get isLeftSelected() {
        return this.profile[this.step] === 'left';
    }

    clickLeft() {
        if (this.profile[this.step] === 'left')
            this.profile[this.step] = null;
        else
            this.profile[this.step] = 'left';
    }

    get isRightSelected() {
        return this.profile[this.step] === 'right';
    }

    clickRight() {
        if (this.profile[this.step] === 'right')
            this.profile[this.step] = null;
        else
            this.profile[this.step] = 'right';
    }

    get canFinish() {
        return this.profile[this.step] !== null && this.step === steps.length - 1;
    }

    get token() {
        let finalToken = [];
        for (let i = 0; i < this.profile.length; i++) {
            if (this.profile[i] === 'left')
                finalToken.push(steps[i].tokenLeft);
            if (this.profile[i] === 'right')
                finalToken.push(steps[i].tokenRight);
        }

        return finalToken.join('');
    }

    finish() {
        this.onProfilingStepsFinished();
    }
}

decorate(ProfilingPageStepController, {
    step: observable,
    totalSteps: observable,
    profile: observable,
    canPrev: computed,
    prev: action,
    canNext: computed,
    next: action,
    isLeftSelected: computed,
    clickLeft: action,
    isRightSelected: computed,
    clickRight: action,
    canFinish: computed,
    finish: action,
    token: computed,
    calcBtnActiveState: action,
    isPrevBtnActive: observable,
    isNextBtnActive: observable,

});


const Step = ({value, statements, isSelected, onClick}) => {
    return (
        <label htmlFor={value} data-selenium-option={value}>
            <input type="radio" name="profiling" id={value} checked={isSelected} value={value} hidden
                   onChange={onClick.bind(null)} data-selenium-input={value}/>
            <div className="card slideIn">
                <div className="card-body">
                    <div className="card-header small">
                        <div className="card-header-icon">
                            <i>{value}</i>
                        </div>
                        <div className="card-header-title">
                            <h2 className="">{lang.get('profilingSignUp.survey.option')}</h2>
                        </div>
                    </div>
                    <div className="card-content">
                        <div>
                            {
                                lang.get(`profilingStep.${statements}`).split('\n').map((i, idx) => (
                                    <div key={idx}><span>{i}</span></div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </label>
    );
};


const ProfilingPageStepView = observer(class ProfilingPageStepView extends Component {
    scrollEvents = new ScrollEvents();

    componentDidMount() {
        this.scrollEvents.startTrackingScroll(document.getElementsByClassName('appContent')[0]);
        this.scrollEvents.scrollToTop();
    }

    componentWillUnmount() {
        this.scrollEvents.stopTrackingScroll();
    }

    componentDidUpdate() {
        this.props.controller.calcBtnActiveState();

        if (this.props.controller.stepChanged) {
            this.props.controller.stepChanged = false;
            //scroll to top
            this.scrollEvents.scrollToTop();
        }
    }

    render() {
        let controller = this.props.controller;

        return (
            <React.Fragment>
                <main className="fadeIn bg-style1">
                    <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                    <div className="appContent">
                        <div className="appContent-body profilingContent">
                            <ContentTitle title={lang.get('appheader.profiling.title')}
                                          subtitle={lang.get('appheader.profiling.subtitle')}
                                          info={lang.get('infoBox.profiling.content')}/>
                            <form id="profStepsForm">
                                <Step
                                    value={'A'}
                                    statements={steps[controller.step].left}
                                    isSelected={controller.isLeftSelected}
                                    onClick={() => controller.clickLeft()}/>
                                <Step
                                    value={'B'}
                                    statements={steps[controller.step].right}
                                    isSelected={controller.isRightSelected}
                                    onClick={() => controller.clickRight()}/>
                            </form>
                            <StepsFooterBar
                                canPrev={controller.canPrev}
                                clickPrev={() => controller.prev()}
                                canNext={controller.canNext}
                                clickNext={() => controller.next()}
                                canFinish={controller.canFinish}
                                clickFinish={() => controller.finish()}
                                isNextBtnActive={controller.isNextBtnActive}
                                isPrevBtnActive={controller.isPrevBtnActive}
                                showCancel={false}
                                clickCancel={null}
                                showSteps={true}
                                currentStep={controller.step + 1}
                                totalSteps={controller.totalSteps}
                            />
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
});

export {ProfilingPageStepView, ProfilingPageStepController};