import {decorate, observable, action} from 'mobx';
import {axios} from '../../utils/WrappedAxios';

class OpenDiscussionCollection {
    discussions = [];

    loadListWithFilter(filter) {
        return axios.get('/api/feedback', {
            params: {
                type: [3],
                d: filter.date,
                g: filter.category,
                s: filter.status,
            }
        })
            .then((response) => {
                this.discussions = response.data.items;
            });
    }

    getCountForFilter(filter) {
        return axios.get('/api/feedback/count', {
            params: {
                type: 3,
                d: filter.date,
                g: filter.category,
                s: filter.status,
            }
        }).then((response) => {
            return response.data.count;
        });
    }

    clear() {
        this.discussions = [];
    }
}

decorate(OpenDiscussionCollection, {
    discussions: observable,
    loadList: action,
    loadListWithFilter:action,
    getCountForFilter:action
});

export default OpenDiscussionCollection;