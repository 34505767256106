import {observer} from 'mobx-react';
import React from 'react';
import {Header, HeaderController} from '../Header/HeaderView';
import {ProfilingResultView, ProfilingResultViewController} from '../ProfilingResult/ProfilingResultView';
import {lang} from '../utils/Lang';

export const ProfilingPageResultView = observer(({controller, onRepeat}) => {
    return (
        <React.Fragment>
            <input type="hidden" id="result-token" value={controller.token}/> {/*selenium testing :( */}
            <main className="fadeIn bg-style1">
                <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                <div className="appContent">
                    <div className="appContent-body slideDown profiling-result">
                        <ProfilingResultView controller={new ProfilingResultViewController(controller.token)}/>
                        <p className="repeat-note">{lang.get('profilingResult.repeat.note')}</p>
                        <div className="footer-btn">
                            <button name="repeat" className="btn" onClick={(e) => {
                                e.preventDefault();
                                onRepeat();
                            }}><span>{lang.get('profilingResult.repeat.link')} <i className="far fa-redo-alt"/></span>
                            </button>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>
    );
});