import {decorate, observable, action} from 'mobx';
import {axios} from '../../utils/WrappedAxios';

class SolutingItemStore {

    soluting = {};

    load(soulutingId) {
        return axios.get(`/api/soluting/${soulutingId}`)
            .then((response) => {
                this.soluting = response.data;
            });
    }

}

decorate(SolutingItemStore, {
    soluting: observable,
    load: action,
});

export default SolutingItemStore;