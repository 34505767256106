import React from 'react';
import {observer} from 'mobx-react';
import {lang} from '../utils/Lang'
import {ContentTitle} from '../components/ContentTitle';

const CompanyResultView = observer(({controller}) => (
    <React.Fragment>
        <ContentTitle title={lang.get('notification.success')} subtitle={lang.get('company.result.invite.title')}/>
        <div className="score-company">
            <div className="score-container">
                <div className="score-outer-circle">
                    <div className="score-inner-circle">
                        <div className="score-inner-circle-content">
                            <i className="fal fa-award"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="company-result">
            <p>{lang.get('company.result.invite.info')}</p>
            <div className="mt-3 mb-1 txtCenter">
                <button className="btn" onClick={() => controller.goToDashboard()}>
                    <span>{lang.get('company.result.btn.skip')}</span> <i className="far fa-arrow-circle-right"/>
                </button>
            </div>
            <div className="txtCenter">
                <button className="btn" onClick={() => controller.goToUserList()}>
                    <span>{lang.get('company.result.btn.invite')}</span> <i className="far fa-arrow-circle-right"/>
                </button>
            </div>
        </div>
    </React.Fragment>
));

export default CompanyResultView;