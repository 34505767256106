import {lang} from '../../../page/utils/Lang';

class UserDisconnectedFromCompanyNotification {
    group = UserDisconnectedFromCompanyNotification.group;

    constructor(disconnectedUserId, userName) {
        this.disconnectedUserId = disconnectedUserId;
        this.userName = userName;
    }

    get title() {
        return lang.get(`notification.${UserDisconnectedFromCompanyNotification.group}.title`);
    }

    get content() {
        return lang.get(`notification.${UserDisconnectedFromCompanyNotification.typeName}.content`).replace('$userName', this.userName);
    }

    getLink(navigator) {
        return null;
    }

    static tryParse(json) {
        if (json.type !== this.typeName) return null;
        return new UserDisconnectedFromCompanyNotification(json.disconnectedUserId);
    }

    static get typeName() {
        return 'userDisconnectedFromCompany';
    }

    static get group() {
        return 'TeamManagement';
    }
}

export {UserDisconnectedFromCompanyNotification};