import React, {Component} from 'react';
import {notificationManager, Notifications} from './page/components/Notifications';
import UserStore from './domain/UserStore';
import {decorate, observable} from 'mobx';
import {observer} from 'mobx-react';
import LoadingModal from './page/components/LoadingModal';
import Navigator from './page/Navigator';
import _ from 'lodash';
import {InviteService} from './page/InvitePage';
import {addInterceptor, axios} from './utils/WrappedAxios';
import FacebookPixel from "./utils/FacebookPixel";

class AppController {
    isLoading = true;
    userStore = new UserStore();
    inviteService = new InviteService(this.userStore);
    navigator = new Navigator(this.userStore, this.inviteService);
    facebookPixel = new FacebookPixel();


    constructor() {
        addInterceptor(this.navigator, this.userStore);
        this.navigator.allowPageChange = (page, location) => this.decideWhereToGo(page, location);

        if (!window.doods) window.doods = {};
        window.doods.createSurvey = () => {
            axios.get('/api/help/create-survey')
                .then(() => {
                    notificationManager.info('User survey created');
                });
        };

        this.load();
    }

    async load() {
        this.isLoading = true;
        try {
            if (this.userStore.lastCheck == null)
                await this.userStore.checkLogin();
        } catch (e) {
            console.error('could not check login', e);
            this.isLoading = false;
            this.navigator.goToPage(this.navigator.siteMap.pages.Login);
        }

        this.navigator.refreshState();
    }

    decideWhereToGo(newPage, location) {
        let anonymousPages = [
            this.navigator.siteMap.pages.Login,
            this.navigator.siteMap.pages.SignUp,
            this.navigator.siteMap.pages.Profiling,
        ];

        // user is not logged in
        if (!this.userStore.isLoggedIn) {
            if (!_.includes(anonymousPages, newPage) && newPage !== this.navigator.siteMap.pages.Invite && newPage !== this.navigator.siteMap.pages.Verification) {
                this.navigator.pushStateUnsafe(this.navigator.siteMap.pages.Login.createUrl());
                this.isLoading = false;
                return false;
            }

            this.isLoading = false;
            return true;
        } else {
            if(newPage === this.navigator.siteMap.pages.Verification) {
                this.isLoading = false;
                return true;
            }

            // if not registered go to edit profile
            if (!this.userStore.isRegistered) {
                if (newPage !== this.navigator.siteMap.pages.EditProfile) {
                    this.navigator.pushStateUnsafe(this.navigator.siteMap.pages.EditProfile.createUrl());
                    this.isLoading = false;
                    return false;
                }

                this.isLoading = false;
                return true;
            }

            //if user has invite show invite confirmation page
            if (this.inviteService.userHasInvite()) {
                if (newPage !== this.navigator.siteMap.pages.Invite) {
                    this.navigator.pushStateUnsafe(this.navigator.siteMap.pages.Invite.createUrl(this.inviteService.inviteId));
                    this.isLoading = false;
                    return false;
                }

                this.isLoading = false;
                return true;
            }

            // if not company user, show welcome screen, but allow company settings page for creating a company
            if(this.userStore.currentCompanyUser === null) {
                if(newPage !== this.navigator.siteMap.pages.NonCompanyUserWelcomeScreen
                    // TODO - remove list of allowed pages and replace with proper handling
                    && newPage !== this.navigator.siteMap.pages.CompanySettings
                    && newPage !== this.navigator.siteMap.pages.NotificationList
                    && newPage !== this.navigator.siteMap.pages.Invite
                    && newPage !== this.navigator.siteMap.pages.ProfilingResult
                    && newPage !== this.navigator.siteMap.pages.ViewProfile
                    && newPage !== this.navigator.siteMap.pages.EditProfile
                    && newPage !== this.navigator.siteMap.pages.SolutingList
                    && newPage !== this.navigator.siteMap.pages.SolutingArticle
                    && newPage !== this.navigator.siteMap.pages.Soluting) {
                    this.navigator.pushStateUnsafe(this.navigator.siteMap.pages.NonCompanyUserWelcomeScreen.createUrl());
                    this.isLoading = false;
                    return false;
                }

                this.isLoading = false;
                return true;
            }

            // user logged in
            if (_.includes(anonymousPages, newPage)) {
                this.navigator.pushStateUnsafe(this.navigator.siteMap.pages.Dashboard.createUrl());
                this.isLoading = false;
                return false;
            }

            this.isLoading = false;
            return true;
        }
    }
}

decorate(AppController, {
    isLoading: observable
});

const App = observer(class App extends Component {

    componentDidMount() {
        document.getElementsByTagName('body')[0].classList.add('colDefault'); //TODO hack
    }

    componentWillUnmount() {
        this.props.controller.scrollEvents.stopTrackingScroll();
    }

    render() {
        let controller = this.props.controller;
        let navigator = controller.navigator;

        return (
            <React.Fragment>
                    <LoadingModal isVisible={false}/>
                    {navigator.currentRenderedPage}
                    <Notifications/>
            </React.Fragment>
        );
    }
});

export {App, AppController};
