import React, {Component} from 'react';
import {observer} from 'mobx-react';

const SolutingSlider = observer(class SolutingSlider extends Component {
    constructor(props) {
        super(props);
        this.scrollElement = React.createRef();
    }

    render() {
        let children = this.props.children;

        return (
            <div className="group-slider">
                <LeftArrow
                    goToPrevSlide={() => this.scrollElement.current.scrollBy({left: -310, behavior: 'smooth'})}
                />
                <RightArrow
                    goToNextSlide={() => this.scrollElement.current.scrollBy({left: 310, behavior: 'smooth'})}
                />

                <div className="group-items-wrapper" ref={this.scrollElement}>
                    <div className="group-items">
                        {
                            children.map((item, i) => (
                                <Slide key={i} item={item}/>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }
});


const Slide = ({item}) => {
    return <React.Fragment>{item}</React.Fragment>
};


const LeftArrow = (props) => {
    return (<button className="btn l" onClick={props.goToPrevSlide}><i className="far fa-arrow-circle-left"/></button>
    )
};

const RightArrow = (props) => {
    return (<button className="btn r" onClick={props.goToNextSlide}><i className="far fa-arrow-circle-right"/></button>
    )
};

export {SolutingSlider};