import React, {Component} from 'react';
import {lang} from '../../utils/Lang';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import {ContentTitle} from '../../components/ContentTitle';


const InitialSurveyStartView = observer(class SurveyStart extends Component {
    render() {
        let displayName = this.props.displayName;
        let skipLater = this.props.skipLater;
        let skipReject = this.props.skipReject;
        let goToSurvey = this.props.goToSurvey;
        let canGoToSurvey = this.props.canGoToSurvey;

        return (
            <React.Fragment>
                <ContentTitle title={lang.get('header.title.initialSurvey')}/>
                <div className="card card-survey-question initial-survey slideIn">
                    <div className="card-body">
                        <div className="card-header small">
                            <div className="card-header-icon">
                                <i className="far fa-pen"/>
                            </div>
                            <div className="card-header-title">
                                <h2 className="">{lang.get('surveyOverview.helloPrefix')} {displayName}, {lang.get('surveyOverview.header')} </h2>
                            </div>
                        </div>
                        <div className="card-content">
                            <h3>{lang.get('surveyOverview.header')}</h3>
                            <p>
                                {lang.get('surveyOverview.instruction')}
                            </p>
                        </div>
                        <div className="card-footer">
                            <button className="btn btn-start-initial" id="start-btn" disabled={!canGoToSurvey}
                                    onClick={() => goToSurvey()}
                            ><span>{lang.get('surveyOverview.button')}</span></button>
                        </div>
                    </div>
                </div>
                <div className="mt-3 mb-1 txtCenter">
                    <button className="btn" id="skip-reject-btn"
                            onClick={(e) => {
                                e.preventDefault();
                                skipReject()
                            }}><span>{lang.get('surveyOverview.skipReject')}</span></button>
                </div>
                <div className="txtCenter">
                    <button className="btn" id="skip-later-btn"
                            onClick={(e) => {
                                e.preventDefault();
                                skipLater()
                            }}><span>{lang.get('surveyOverview.skipLater')}</span></button>
                </div>
            </React.Fragment>
        );
    }
});

InitialSurveyStartView.propTypes = {
    displayName: PropTypes.string.isRequired,
    skipLater: PropTypes.func.isRequired,
    skipReject: PropTypes.func.isRequired,
    goToSurvey: PropTypes.func.isRequired,
    canGoToSurvey: PropTypes.bool.isRequired
};


export default InitialSurveyStartView;