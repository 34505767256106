import React from 'react';
import ValidatedValue from './Inputs/ValidatedValue';
import {InputSelect, InputSelectController} from './Inputs/InputSelect';
import {lang} from '../utils/Lang';

class ChangeLanguageInputController {
    languages = [
        {value: 'DE', text: 'Deutsch'},
        {value: 'EN', text: 'English'},
        {value: 'HU', text: 'Magyar'},
        {value: 'SL', text: 'Slovak'},
        {value: 'HR', text: 'Hrvatski'}
    ];
    language = new ValidatedValue([], lang.language);
    languageDropdownController = new InputSelectController(this.languages, this.language);

    constructor() {
        this.language.onFinalChange = (oldValue, newValue) => lang.setLanguage(newValue);
    }
}

const ChangeLanguageInput = (props) => {
    return <InputSelect {...props} controller={props.controller.languageDropdownController}
                        placeholder={lang.get('profilingSignUp.start.language.Select.placeholder')} name="languageSelect"/>;
};

export {ChangeLanguageInput, ChangeLanguageInputController};