import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable, decorate, action, computed} from 'mobx';
import {NavigationItem, NavigationSubItemController} from './NavigationItem';
import MediaQuery from '../../utils/MediaQuery';
import logoImg from '../../../assets/img/logo-doods.svg';
import {hideIfNot} from '../../../utils/Utils';

class NavigationMenuController {
    navigator;
    userStore;

    isNavigationMenuVisible = false;
    menuItems;
    hasSpaceForNavigationMenu = MediaQuery.hasSpaceForNavigationMenu();

    constructor(navigator, userStore, menuItems) {
        this.navigator = navigator;
        this.userStore = userStore;
        this.menuItems = menuItems;
        if (this.hasSpaceForNavigationMenu === true) this.isNavigationMenuVisible = true;
    }

    get userInitials() {
        if (this.userStore.displayName) {
            return this.userStore.displayName.split(' ').reduce((initials, namePart) => (initials + namePart.charAt(0)), '');
        }
        return '';
    }

    isMenuItem(currentPage, menuItem) {
        let page = menuItem.page();
        if (page instanceof Array) {
            for (let i = 0; i < page.length; i++) {
                if (page[i] === currentPage) {
                    return true;
                }
            }
        } else {
            if (page === currentPage) {
                return true;
            }
        }
        return false;
    }

    get selectedMenuItem() {
        let currentPage = this.navigator.currentPage;
        if (!currentPage)
            return null;

        for (let i = 0; i < this.menuItems.length; i++) {
            if (this.menuItems[i].hasSubmenu) {
                for (let j = 0; j < this.menuItems[i].subMenuItems.length; j++) {
                    if (this.menuItems[i].subMenuItems[j].page) {
                        if (this.isMenuItem(currentPage, this.menuItems[i].subMenuItems[j]))
                            return this.menuItems[i].subMenuItems[j];
                        // let page = this.menuItems[i].subMenuItems[j].page();
                        // if (page.url === currentPage.url) {
                        //   return this.menuItems[i].subMenuItems[j];
                        // }
                    }
                }
            } else {
                if (this.menuItems[i].isVisible === true && this.menuItems[i].page) {
                    if (this.isMenuItem(currentPage, this.menuItems[i]))
                        return this.menuItems[i];
                    // let page = this.menuItems[i].page();
                    // if (page.url === currentPage.url) {
                    //   return this.menuItems[i];
                    // }
                }
            }
        }
        return null;
    }

    showNavigationMenu() {
        this.isNavigationMenuVisible = true;
    }

    hideNavigationMenu() {
        this.isNavigationMenuVisible = false;
    }

    toggleNavigationMenu() {
        if (this.isNavigationMenuVisible) {
            this.hideNavigationMenu();
        } else {
            this.showNavigationMenu();
        }
    }


    canGoToViewProfile(){
        this.navigator.canGoTo(this.navigator.siteMap.pages.ViewProfile);
    }

    goToViewProfile() {
        this.navigator.goToPage(this.navigator.siteMap.pages.ViewProfile);
    }
}

decorate(NavigationMenuController, {
    isNavigationMenuVisible: observable,
    hasSpaceForNavigationMenu: observable,
    showNavigationMenu: action,
    hideNavigationMenu: action,
    userInitials: computed,
    selectedMenuItem: computed
});


const NavigationMenu = observer(class NavigationMenu extends Component {

    createNavigationItem(item, selectedMenuItem, onClose) {
        return <NavigationItem key={item.id} item={item} selectedMenuItem={selectedMenuItem} onClose={onClose}
                               navigationSubItemController={new NavigationSubItemController()}/>;
    }

    toggleAppContentPadding() {  //TODO: We should refactor whole header and appContent into layout component then this can be avoid and doable only via css
        let controller = this.props.controller;
        if (controller.hasSpaceForNavigationMenu === true) {
            //find appContent and set or remove nav-active class(class sets padding left for navigation menu width)
            let appContent = document.getElementsByClassName('appContent')[0];
            if (controller.isNavigationMenuVisible) {
                if (appContent) appContent.classList.add('nav-active');
            } else {
                if (appContent) appContent.classList.remove('nav-active');
            }
        }
    }

    componentDidMount() {
        this.toggleAppContentPadding();
    }

    componentDidUpdate() {
        this.toggleAppContentPadding();
    }

    render() {
        let controller = this.props.controller;

        let navigationItems = controller.menuItems.map((item) => {
            return this.createNavigationItem(item, controller.selectedMenuItem, () => controller.hideNavigationMenu());
        });

        return (
            <div className={`nav-menu ${controller.isNavigationMenuVisible ? 'navSlideIn' : ''}`}>
                <div className="nav-menu-header">
                    <button className="nav-icon" onClick={() => controller.hideNavigationMenu()} style={hideIfNot(!controller.hasSpaceForNavigationMenu)}>
                        <i className="fas fa-bars"/>
                    </button>
                    <div className="nav-logo">
                        <img src={logoImg} alt="Doods"/>
                    </div>
                    <button className="nav-close" onClick={() => controller.hideNavigationMenu()}>
                        <i className="far fa-times"/>
                    </button>
                </div>
                <ul className="nav-menu-list">
                    {navigationItems}
                </ul>
                <a href="#/" onClick={(e) => {
                    e.preventDefault();
                    controller.goToViewProfile();
                }} className="nav-menu-user-info" style={hideIfNot(controller.canGoToViewProfile)}>
                    <span className="user-icon">{controller.userInitials}</span>
                    <div className="user-info">
                        <h5 className="user-name">{controller.userStore.displayName}</h5>
                        <span>{controller.userStore.email}</span>
                    </div>
                </a>
            </div>
        );
    }
});

export {NavigationMenu, NavigationMenuController};