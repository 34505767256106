import React from 'react';

function findFirstOrNull(children, instance) {
    if (React.Children.count(children) === 0)
        return null;

    let returnValue = null;
    React.Children.forEach(children, (child) => {
        if (child.type === instance)
            returnValue = child;
    });
    return returnValue;
}

export {findFirstOrNull};