import {decorate, observable, action, computed} from 'mobx';
import {axios} from '../../utils/WrappedAxios';

class OpenQuestionSurveyItem {
    positiveQuestionId;
    negativeQuestionId;
    positiveText;
    negativeText;
    isPositive;
    value;
    isAnonymous;
    type = 'open';

    constructor(positiveQuestionId, negativeQuestionId, positiveText, negativeText, isPositive, value, isAnonymous) {
        this.positiveQuestionId = positiveQuestionId;
        this.negativeQuestionId = negativeQuestionId;
        this.positiveText = positiveText;
        this.negativeText = negativeText;
        this.value = value;
        this.isAnonymous = isAnonymous;
    }

    fill(isPositive, value, isAnonymous) {
        this.isPositive = isPositive;
        this.value = value;
        this.isAnonymous = isAnonymous;
    }
}

class SliderQuestionSurveyItem {
    questionId;
    text;
    value;
    type = 'slider';

    constructor(questionId, text, value) {
        this.questionId = questionId;
        this.text = text;
        this.value = value;
    }

    fill(value) {
        this.value = value;
    }
}

class FeedbackSurveyItem {
    value;
    isAnonymous;
    type = 'feedback';

    constructor(value, isAnonymous) {
        this.value = value;
        this.isAnonymous = isAnonymous;
    }

    fill(value, isAnonymous) {
        this.value = value;
        this.isAnonymous = isAnonymous;
    }
}

class COQSurveyItem {
    coqId;
    title;
    value;
    isAnonymous;
    type = 'coq';

    constructor(coqId, title, value, isAnonymous) {
        this.coqId = coqId;
        this.title = title;
        this.value = value;
        this.isAnonymous = isAnonymous;
    }

    fill(value, isAnonymous) {
        this.value = value;
        this.isAnonymous = isAnonymous;
    }
}

class SurveyStore {
    id;
    type = '';
    status = null;
    createdAt = null;
    finishedAt = null;

    isLoaded = false;
    items = [];

    constructor(id) {
        this.id = id;
    }

    load() {
        this.isLoaded = false;
        return axios.get(`/api/user-survey/${this.id}`)
            .then((response) => {
                this.type = response.data.survey.type;
                this.status = response.data.survey.status;
                this.createdAt = response.data.survey.createdAt;
                this.finishedAt = response.data.survey.finishedAt;
                this.items = [];

                let questionText = response.data.questionText;
                response.data.survey.items.forEach(it => {
                    switch (it.type) {
                        case 'open':
                            this.items.push(new OpenQuestionSurveyItem(it.positiveQuestionId, it.negativeQuestionId, questionText[it.positiveQuestionId], questionText[it.negativeQuestionId], it.isPositive, it.value, it.isAnonymous));
                            break;
                        case 'slider':
                            this.items.push(new SliderQuestionSurveyItem(it.questionId, questionText[it.questionId], it.value));
                            break;
                        case 'feedback':
                            this.items.push(new FeedbackSurveyItem(it.value, it.isAnonymous));
                            break;
                        case 'coq':
                            let title = questionText[it.coqId];
                            this.items.push(new COQSurveyItem(it.coqId, title, it.value, it.isAnonymous));
                            break;
                        default:
                            throw new Error('SurveyItemType not recognized');
                    }
                });
            })
            .then(() => this.isLoaded = true);
    }

    get isOpen() {
        return this.status === 'Open';
    }

    finish() {
        let requestItems = this.items.map(it => {
            if (it instanceof OpenQuestionSurveyItem) {
                return {
                    type: it.type,
                    positiveQuestionId: it.positiveQuestionId,
                    negativeQuestionId: it.negativeQuestionId,
                    isPositive: it.isPositive,
                    value: it.value,
                    isAnonymous: it.isAnonymous
                };
            } else if (it instanceof SliderQuestionSurveyItem) {
                return {
                    type: it.type,
                    questionId: it.questionId,
                    value: it.value
                }
            } else if (it instanceof FeedbackSurveyItem) {
                return {
                    type: it.type,
                    value: it.value,
                    isAnonymous: it.isAnonymous
                }
            } else if (it instanceof COQSurveyItem) {
                return {
                    type: it.type,
                    coqId: it.coqId,
                    value: it.value,
                    isAnonymous: it.isAnonymous
                }
            }
            throw new Error('Unrecognized survey item type');
        });

        return axios.post(`/api/user-survey/${this.id}`, {items: requestItems})
            .then(() => this.load());
    }


}

decorate(SurveyStore, {
    status: observable,
    type: observable,
    createdAt: observable,
    finishedAt: observable,
    items: observable,
    isLoaded: observable,
    isOpen: computed,
    load: action,
    finish: action,
    skip: action
});

export {SurveyStore, SliderQuestionSurveyItem, OpenQuestionSurveyItem, FeedbackSurveyItem, COQSurveyItem};