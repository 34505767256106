import React from 'react';
import ReactDOM from 'react-dom';

//old UI stuff
import './assets/libs/font-awesome/css/all.min.css';
//----------------------------------------------------------


//New UI stuff
// import './assets/css/doods_old.scss';
import './assets/css/doods.scss';
import './assets/js/modernizr-custom.js';
import './assets/custom-css/removeAsSoonAsPossible.css';


// import registerServiceWorker from './registerServiceWorker';
// import { unregister } from './registerServiceWorker';
import {App, AppController} from './App';

//react app must have some root element inside body, avoid errors in console
ReactDOM.render(<App controller={new AppController()}/>, document.getElementsByClassName('root')[0]);

// registerServiceWorker();
// unregister();
