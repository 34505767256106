import React from 'react';
import {decorate, observable, computed} from 'mobx';
import {observer} from 'mobx-react';
import InitialSurveyStartView from './InitialSurveyStartView';
import {InitialSurveyStepView, SurveyStepViewController} from './InitialSurveyStepView';
import InitialSurveyResultView from './InitialSurveyResultView';
import {SurveyStore} from '../../../domain/Survey/SurveyStore';
import {notificationManager} from '../../components/Notifications';
import SurveyCollectionStore from '../../../domain/Survey/SurveyCollectionStore';
import {Header, HeaderController} from '../../Header/HeaderView';

class InitialSurveyController {
    userStore;
    navigator;
    surveyStepController;
    showPage = null;

    constructor(navigator, userStore) {
        this.navigator = navigator;
        this.userStore = userStore;

        this.survey = new SurveyStore(null);
        new SurveyCollectionStore()
            .getInitialSurvey()
            .then(survey => {
                this.survey.id = survey.surveyId;
                this.survey.load()
                    .then(() => {
                        if (!this.canGoToSurvey) {
                            notificationManager.info('Cannot start survey which is ' + this.survey.status);
                            this.navigator.back();
                        }
                    })
                    .catch(() => this.navigator.back());
            });
        this.goToOverview();
    }

    goToOverview() {
        this.showPage = 'survey';
    }

    get canGoToSurvey() {
        return this.survey.isLoaded && this.survey.isOpen;
    }

    goToSurvey() {
        this.surveyStepController = new SurveyStepViewController(this.navigator, () => this.allStepsFinished(), this.survey.items);
        this.showPage = 'steps';
    }

    allStepsFinished() {
        // map values to survey
        this.survey.items.forEach((it, idx) => it.value = this.surveyStepController.items[idx].value);
        this.survey.finish()
            .then(() => {
                this.userStore.didDoInitialSurvey = true;
                this.showPage = 'result';
            });
    }

    get spirit() {
        let sum = 0;
        this.surveyStepController.items.forEach(it => sum += it.value);
        let avgNotRounded = sum / this.surveyStepController.items.length;
        let avgRounded = Math.round(avgNotRounded * 10) / 10;
        return avgRounded;
    }

    skipLater() {
        this.survey.skipInitialSurvey()
            .then(() => {
                this.userStore.didDoInitialSurvey = true;
                this.navigator.goToPage(this.navigator.siteMap.pages.Dashboard);
            });
    }

    skipReject() {
        this.survey.rejectInitialSurvey()
            .then(() => {
                this.userStore.didDoInitialSurvey = true;
                this.navigator.goToPage(this.navigator.siteMap.pages.Dashboard);
            });
    }
}

decorate(InitialSurveyController, {
    showPage: observable,
    canGoToSurvey: computed,
    surveyStepStore: observable
});


const InitialSurveyPage = observer(({controller}) => {
    let currentPage;
    switch (controller.showPage) {
        case 'survey':
            currentPage = <InitialSurveyStartView displayName={controller.userStore.displayName}
                                                  skipLater={() => controller.skipLater()}
                                                  skipReject={() => controller.skipReject()}
                                                  canGoToSurvey={controller.canGoToSurvey}
                                                  goToSurvey={() => controller.goToSurvey()}/>;
            break;
        case 'steps':
            currentPage = <InitialSurveyStepView controller={controller.surveyStepController}/>;
            break;
        case 'result':
            currentPage = <InitialSurveyResultView spirit={controller.spirit}
                                                   goToDashboard={() => controller.navigator.goToPage(this.navigator.siteMap.pages.Dashboard)}/>;
            break;
        default:
            currentPage = <div/>;
            break;
    }

    return (
        <React.Fragment>
            <main className={`fadeIn ${controller.showPage === 'result' ? 'bg-style1' : ''}`}>
                <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                <div className="appContent">
                    <div className="appContent-body">{currentPage}</div>
                </div>
            </main>
        </React.Fragment>
    );
});

export {InitialSurveyPage, InitialSurveyController};