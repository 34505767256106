import React, {Component} from 'react';
import {Header, HeaderController} from '../../Header/HeaderView';
import {decorate, observable, action} from 'mobx';
import {observer} from 'mobx-react';
import {Team} from '../../../domain/Team/Team';
import {lang} from '../../../page/utils/Lang';
import {ContentTitle} from '../../components/ContentTitle';
import {
    InfoBox2,
    InfoBox2Content,
    InfoBox2Controller,
    InfoBox2Header
} from '../../components/Inputs/InfoBox2';
import {MultiSelectionListController, MultiSelectionListView} from '../../components/MultiSelectionList';
import UserCollection from '../../../domain/User/UserCollection';
import _ from 'lodash';
import Input from '../../components/Inputs/Input';
import ValidatedValue from '../../components/Inputs/ValidatedValue';
import {notificationManager} from '../../components/Notifications';
import {InputSelect, InputSelectController} from '../../components/Inputs/InputSelect';
import {validateNotBlank} from '../../../utils/Validators';
import {Role} from '../../../domain/Role';
import {hideIfNot} from '../../../utils/Utils';
import {InvitationBox, InvitationBoxController} from '../../components/InvitationBox';
import DynamicInput from '../../components/Inputs/DynamicInput';

class TeamEditPageController {
    managerOptions = [];
    managerValue = new ValidatedValue([], null);

    headerController;
    team = null;
    teamAdmins = [];
    subTeams = [];
    teamEmployees = [];
    userCollection = new UserCollection();

    managerSelectionController = new InputSelectController(this.managerOptions, this.managerValue);


    addTeamAdminsInfoBoxController = new InfoBox2Controller();
    teamAdminsListController = new MultiSelectionListController();

    addTeamEmployeesInfoBoxController = new InfoBox2Controller();
    teamEmployeesListController = new MultiSelectionListController();

    addSubTeamInfoBoxController = new InfoBox2Controller();
    subTeamName = new ValidatedValue([], null);

    confirmationContent = '';
    confirmationController = new InfoBox2Controller();

    groupName = new ValidatedValue([validateNotBlank], null);
    parentName = new ValidatedValue([], null);

    invitationLink = '';
    inviteController = new InfoBox2Controller();

    constructor(navigator, userStore, teamId) {
        this.navigator = navigator;
        this.userStore = userStore;
        this.headerController = new HeaderController(navigator, userStore);
        this.team = new Team(teamId);

        this.groupName.onFinalChange = (oldValue, newValue, isValid) => this.editGroupName(oldValue, newValue, isValid);
        this.managerValue.onFinalChange = (oldValue, newValue, isValid) => this.editManager(oldValue, newValue, isValid);
        this.managerSelectionController.loadData = () => this.onManagerSelectionStart();

        this.load();
    }

    async onManagerSelectionStart() {
        let users = (await this.userCollection.getCompanyUsers()).map(user => {
            return {value: user.id, text: user.name}
        });

        this.managerSelectionController.setItems(users);
    }

    async load() {
        this.refreshTeamAdmins();
        this.refreshOverviewData();
        this.refreshTeamEmployees();
    }

    async refreshTeamAdmins() {
        let teamAdmins = await this.team.getTeamAdmins();
        this.teamAdmins.clear();
        this.teamAdmins.push(...teamAdmins);
    }

    async refreshTeamEmployees() {
        let teamEmployees = await this.team.getTeamEmployees();
        this.teamEmployees.clear();
        this.teamEmployees.push(...teamEmployees);
    }

    async refreshOverviewData() {
        let overviewData = await this.team.getOverview();
        this.subTeams.clear();
        this.subTeams.push(...overviewData.subTeams);
        this.groupName.value = overviewData.team.name;
        this.parentName.value = overviewData.parentTeam ? overviewData.parentTeam.name : null;
        if (overviewData.manager) {
            this.managerValue.value = overviewData.manager.id;
            // complete hack but we will fix this later
            this.managerSelectionController.setItems([{
                value: overviewData.manager.id,
                text: overviewData.manager.name
            }]);
        }
    }

    async editGroupName(oldValue, newValue, isValid) {
        if (!isValid) return;
        // change user role
        try {
            await this.team.changeName(newValue);
            this.groupName.showSaved();
        } catch (e) {
            notificationManager.error(e, 'Could not change group name');
            this.groupName.value = oldValue;
        }
    }

    async editManager(oldValue, newValue, isValid) {
        if (!isValid) return;
        await this.team.changeManager(this.managerSelectionController.selectedItem.value);
    }

    async removeTeamAdmin(admin) {
        let confirmResult = await this._confirm(lang.get(`teamManagement.removeTeamAdmin.infoBox.part1`) + ` ${admin.name} ` + lang.get(`teamManagement.removeTeamAdmin.infoBox.part2`) + ` ${this.groupName.value} ` + lang.get(`teamManagement.removeTeamAdmin.infoBox.part3`));
        if (confirmResult !== 'yes')
            return;
        // remove admin from team
        await this.team.removeUsersFromAdmin([admin.id]);
        this.teamAdmins.remove(admin);
    }

    async removeEmployee(employee) {
        let confirmResult = await this._confirm(lang.get("teamManagement.removeEmployee.infoBox.part1") + ` ${employee.name} ` + lang.get(`teamManagement.removeEmployee.infoBox.part2`) + ` ${this.groupName.value} ` + lang.get(`teamManagement.removeEmployee.infoBox.part3`));
        if (confirmResult !== 'yes')
            return;
        // remove employee from team
        await this.team.removeUsersFromTeam([employee.id]);
        this.teamEmployees.remove(employee);
    }

    async removeSubTeam(subTeam) {
        let confirmResult = await this._confirm(lang.get("teamManagement.removeSubgroup.infoBox.part1") + ` ${subTeam.name} ` + lang.get(`teamManagement.removeSubgroup.infoBox.part2`) + ` ${this.groupName.value} ` + lang.get(`teamManagement.removeSubgroup.infoBox.part3`));
        if (confirmResult !== 'yes')
            return;
        // remove sub-team  from team
        await this.team.deleteSubTeam(subTeam.id);
        this.subTeams.remove(subTeam);
    }

    async openGroupAdminSelection() {
        // show info box immediately
        this.teamAdminsListController.setItems([]);
        let infoBoxRequest = this.addTeamAdminsInfoBoxController.show();

        // load users
        let users = await this.userCollection.getCompanyUsers();
        let availableUsers = _.differenceBy(users, this.teamAdmins, it => it.id);

        this.teamAdminsListController.setItems(availableUsers.map(user => {
            return {value: user.id, text: user.name}
        }));

        if (await infoBoxRequest !== 'yes') return;
        await this.team.addTeamAdmins(this.teamAdminsListController.selectedItems.map(selectedUser => selectedUser.value));
        return this.refreshTeamAdmins();
    }

    async openGroupEmployeesSelection() {
        // show info box immediately
        this.teamEmployeesListController.setItems([]);
        let infoBoxRequest = this.addTeamEmployeesInfoBoxController.show();

        // load users
        let users = await this.userCollection.getCompanyUsers();
        let availableUsers = _.differenceBy(users, this.teamEmployees, it => it.id);

        this.teamEmployeesListController.setItems(availableUsers.map(user => {
            return {value: user.id, text: user.name}
        }));

        if (await infoBoxRequest !== 'yes') return;
        await this.team.addUsersToTeam(this.teamEmployeesListController.selectedItems.map(selectedUser => selectedUser.value));
        return this.refreshTeamEmployees();
    }


    async inviteUsers() {
        let inviteId = await this.team.getInviteLink();

        this.invitationLink = `${window.location.origin.toString()}/#/invite/` + inviteId;
        return this.inviteController.show();
    }

    async openSubGroupsSelection() {
        this.subTeamName = new ValidatedValue([], null);
        let infoBoxRequest = this.addSubTeamInfoBoxController.show();
        if (await infoBoxRequest !== 'yes') return;
        await this.team.createSubTeam(this.subTeamName.value);
        return this.refreshOverviewData();
    }

    async _confirm(content) {
        this.confirmationContent = content;
        return await this.confirmationController.show();
    }
}

decorate(TeamEditPageController, {
    load: action,
    team: observable,
    teamAdmins: observable.shallow,
    subTeams: observable.shallow,
    teamEmployees: observable.shallow,
    removeTeamAdmin: action,
    removeEmployee: action,
    removeSubTeam: action,
    confirmationContent: observable,
    subTeamName: observable,
    groupName: observable,
    parentName: observable,
    managerOptions: observable.shallow,
    invitationLink: observable
});


const TeamEditPageView = observer(class TeamEditView extends Component {
    render() {
        let controller = this.props.controller;
        let teamName = controller.groupName.value;

        let removeTeamAdmin = (admin) => controller.removeTeamAdmin(admin);
        let removeEmployee = (employee) => controller.removeEmployee(employee);
        let removeSubTeam = (subTeam) => controller.removeSubTeam(subTeam);


        let canCreateSubTeam = controller.userStore.canDoAction(Role.GroupCreate);
        let canDeleteSubTeam = controller.userStore.canDoAction(Role.GroupDelete);
        let canAddEmployees = controller.userStore.canDoAction(Role.GroupAddUser);
        let canDeleteEmployees = controller.userStore.canDoAction(Role.GroupRemoveUser);
        let canAddAdmins = controller.userStore.canDoAction(Role.GroupAddAdmin);
        let canDeleteAdmins = controller.userStore.canDoAction(Role.GroupRemoveAdmin);
        let canChangeTeamName = controller.userStore.canDoAction(Role.GroupChangeTeamName);
        let canChangeManager = controller.userStore.canDoAction(Role.GroupChangeManager);
        let canInviteUsers = controller.userStore.canDoAction(Role.InviteUsers);

        let teamAdmins = controller.teamAdmins.map(admin =>
            <li key={admin.id} id={`admin-${admin.id}`}>
                <span className="name">{admin.name}</span>
                <button className="btn plain delete" style={hideIfNot(canDeleteAdmins)} onClick={() => removeTeamAdmin(admin)}>
                    <i className="far fa-trash-alt"/></button>
            </li>);


        let teamEmployees = controller.teamEmployees.map(employee =>
            <li key={employee.id} id={`employee-${employee.id}`}>
                <span className="name">{employee.name}</span>
                <button className="btn plain delete" style={hideIfNot(canDeleteEmployees)}
                        onClick={() => removeEmployee(employee)}><i className="far fa-trash-alt"/></button>
            </li>);

        let subTeams = controller.subTeams.map(subTeam =>
            <li key={subTeam.id} id={`subTeam-${subTeam.id}`}>
                <span className="name">{subTeam.name}</span>
                <button className="btn plain delete" style={hideIfNot(canDeleteSubTeam)}
                        onClick={() => removeSubTeam(subTeam)}><i className="far fa-trash-alt"/></button>
            </li>);


        let addTeamAdminsInfoBoxController = controller.addTeamAdminsInfoBoxController;
        let teamAdminsListController = controller.teamAdminsListController;

        let addTeamEmployeesInfoBoxController = controller.addTeamEmployeesInfoBoxController;
        let teamEmployeesListController = controller.teamEmployeesListController;

        let addSubTeamInfoBoxController = controller.addSubTeamInfoBoxController;
        let subTeamName = controller.subTeamName;


        let confirmationContent = controller.confirmationContent;
        let confirmationController = controller.confirmationController;

        let groupName = controller.groupName;
        let parentName = controller.parentName;
        let inviteController = controller.inviteController;

        return (
            <main className="fadeIn">

                <InfoBox2 id="info-box-add-group-admins" title={lang.get('teamManagement.admins.title')}
                          controller={addTeamAdminsInfoBoxController} hasYes={true} hasNo={true}>
                    <InfoBox2Header>
                        <i className="far fa-users"/>
                        <h2>{lang.get("teamManagement.editTeamTitle")}</h2>
                    </InfoBox2Header>
                    <InfoBox2Content>
                        <MultiSelectionListView controller={teamAdminsListController}/>
                    </InfoBox2Content>
                </InfoBox2>

                <InfoBox2 id="info-box-add-group-employees" title={lang.get('teamManagement.employees.title')}
                          controller={addTeamEmployeesInfoBoxController} hasYes={true} hasNo={true}>
                    <InfoBox2Header>
                        <i className="far fa-users"/>
                        <h2>{lang.get("teamManagement.editTeamTitle")}</h2>
                    </InfoBox2Header>
                    <InfoBox2Content>
                        <MultiSelectionListView controller={teamEmployeesListController}/>
                    </InfoBox2Content>
                </InfoBox2>

                <InfoBox2 id="info-box-add-sub-group" title={lang.get('teamManagement.group.subGroups')}
                          controller={addSubTeamInfoBoxController} hasYes={true} hasNo={true}>
                    <InfoBox2Header>
                        <i className="far fa-users"/>
                        <h2>{lang.get("teamManagement.editTeamTitle")}</h2>
                    </InfoBox2Header>
                    <InfoBox2Content>
                        <Input name="input-subteam-name"
                               placeholder={lang.get('teamManagement.group.subGroups.placeholder')}
                               validatedValue={subTeamName}/>
                    </InfoBox2Content>
                </InfoBox2>

                <InfoBox2 id="info-box-confirmation" title={lang.get('teamManagement.confirmTitle')} controller={confirmationController}
                          hasYes={true} hasNo={true}>
                    <InfoBox2Header>
                        <i className="far fa-users"/>
                        <h2>{lang.get("teamManagement.editTeamTitle")}</h2>
                    </InfoBox2Header>
                    <InfoBox2Content>
                        {confirmationContent}
                    </InfoBox2Content>
                </InfoBox2>

                <InfoBox2 id="info-box-invitation" title={lang.get('teamManagement.infoBox.users.invite.title')}
                          controller={inviteController} hasClose={true}>
                    <InvitationBox controller={new InvitationBoxController(controller.invitationLink)}/>
                </InfoBox2>

                <Header controller={controller.headerController}/>
                <div className="appContent">
                    <div className="appContent-body edit-team-page app-footer-automatic-parent">
                        <ContentTitle title={lang.get('appheader.teamMgmt.groups.title')}
                                      subtitle={lang.get('appheader.teamMgmt.groups.subtitle')}/>
                        <div className="card">
                            <div className="card-body">
                                <div className="card-header small">
                                    <div className="card-header-icon">
                                        <i className="far fa-draw-circle"/>
                                    </div>
                                    <div className="card-header-title">
                                        <h2>{teamName}</h2>
                                        <button className="btn plain" style={hideIfNot(canInviteUsers)}
                                                onClick={() => controller.inviteUsers()}><span>{lang.get('teamManagement.addBtn')}</span><i
                                            className="far fa-user-plus"/></button>
                                    </div>
                                </div>
                                <div className="card-content">

                                    <div className="content-inputs">
                                        <DynamicInput name="input-group-name"
                                                      placeholder={lang.get('teamManagement.group.name')}
                                                      validatedValue={groupName}
                                                      disabled={!canChangeTeamName}/>

                                        <Input name="input-parent-name"
                                               placeholder={lang.get('teamManagement.group.parent')}
                                               validatedValue={parentName}
                                               disabled="disabled"/>

                                        <InputSelect controller={controller.managerSelectionController}
                                                     name="manager"
                                                     placeholder={lang.get('teamManagement.manager.title')}
                                                     disabled={!canChangeManager}/>

                                    </div>

                                    <ul className="role-list team-admins">
                                        <li>
                                            <h2>Group Admins ({teamAdmins.length})</h2>
                                            <button className="btn plain admins-role-btn" style={hideIfNot(canAddAdmins)}
                                                    onClick={() => controller.openGroupAdminSelection()}><i
                                                className="far fa-plus-circle"/></button>
                                        </li>
                                        {teamAdmins}
                                    </ul>

                                    <ul className="role-list team-users">
                                        <li>
                                            <h2>Employees ({teamEmployees.length})</h2>
                                            <button className="btn plain users-role-btn" style={hideIfNot(canAddEmployees)}
                                                    onClick={() => controller.openGroupEmployeesSelection()}><i
                                                className="far fa-plus-circle"/></button>
                                        </li>
                                        {teamEmployees}
                                    </ul>

                                    <ul className="role-list team-subteams">
                                        <li>
                                            <h2>Sub-groups ({subTeams.length})</h2>
                                            <button className="btn plain subgroups-role-btn" style={hideIfNot(canCreateSubTeam)}
                                                    onClick={() => controller.openSubGroupsSelection()}><i
                                                className="far fa-plus-circle"/></button>
                                        </li>
                                        {subTeams}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
});

export {TeamEditPageView, TeamEditPageController};