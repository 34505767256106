import React, {Component} from 'react';
import {FeedbackListItem} from './FeedbackListItem';
import moment from 'moment';

class FeedbackListView extends Component {
    createSurveyListItem(feedback) {
        return <FeedbackListItem key={feedback.id}
                                 title={feedback.questionText}
                                 status={feedback.status}
                                 createdAt={moment(feedback.createdAt).format('MMM DD, YYYY')}
                                 updatedAt={moment(feedback.updatedAt).format('MMM DD, YYYY')}
                                 rootGroup={feedback.rootGroupId}
                                 subGroup={feedback.subGroupName}
                                 unreadCount={feedback.unreadCount}
                                 description={feedback.firstMessage}
                                 tone={feedback.tone}
            // tags
                                 tagCreatedBy={feedback.createdBy} // createdBy
                                 tagLabel={feedback.label}
                                 tagStatus={feedback.status}
                                 onClick={() => this.props.goToFeedback(feedback.id)}/>;
    }

    render() {
        let feedbackList = this.props.feedbackList;

        let feedbackListItems = feedbackList.map((survey) => {
            return this.createSurveyListItem(survey)
        });

        return (
            <React.Fragment>
                <ul className="feedback-list-content">
                    {feedbackListItems}
                </ul>
            </React.Fragment>
        );
    }
}

export default FeedbackListView;


