import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Header, HeaderController} from '../../Header/HeaderView';
import SolutingArticleStore from '../../../domain/Soluting/SolutingArticleStore';
import {decorate, observable} from 'mobx';
import LoadingModal from '../../components/LoadingModal';
import {ContentTitle} from '../../components/ContentTitle';
import StepsFooterBar from '../../components/StepsFooterBar';


class SolutingArticlePageController {
    userStore;
    navigator;
    solutingId;
    currentArticleId;
    isLoading;
    solutingArticle = {
        breadcrumbs: []
    };


    constructor(navigator, userStore, solutingId, articleId) {
        this.userStore = userStore;
        this.navigator = navigator;
        this.solutingId = solutingId;
        this.currentArticleId = articleId;

        this.solutingArticleStore = new SolutingArticleStore();
        this.loadSolutingArticle()
    }


    loadSolutingArticle() {
        this.isLoading = true;
        this.solutingArticleStore.load(this.solutingId, this.currentArticleId)
            .then(() => {
                this.isLoading = false;
                this.solutingArticle = this.solutingArticleStore.solutingArticle;
            })
            .catch(() => {
                this.navigator.goToPage(this.navigator.siteMap.pages.Dashboard);
                this.isLoading = false;
            });
    }


    onBack() {
        return () => this.navigator.back();
    }

    onCancel() {
        return () => this.navigator.goToPage(this.navigator.siteMap.pages.Soluting, this.solutingId);
    }

    onNext() {
        if (this.solutingArticle.idNextArticle !== '') {
            return () => this.navigator.goToPage(this.navigator.siteMap.pages.SolutingArticle, this.solutingId, this.solutingArticle.idNextArticle);
        } else {
            return () => this.navigator.goToPage(this.navigator.siteMap.pages.Soluting, this.solutingId);
        }
    }
}


decorate(SolutingArticlePageController, {
    solutingArticle: observable,
    currentArticleId: observable,
    solutingId: observable,
    isLoading: observable
});


const SolutingArticlePageView = observer(class SolutingArticlePageView extends Component {
    render() {
        let controller = this.props.controller;
        let solutingArticle = this.props.controller.solutingArticle;

        let categoryClass = '';
        if (solutingArticle.category) {
            switch (solutingArticle.category.toLowerCase()) {
                case 'growth':
                    categoryClass = 'growth';
                    break;
                case 'facts':
                    categoryClass = 'facts';
                    break;
                case 'relationship':
                    categoryClass = 'relationship';
                    break;
                case 'culture':
                    categoryClass = 'culture';
                    break;
                default:
                    categoryClass = '';
                    break;
            }
        }
        ;

        let articleImage = {
            backgroundImage: `url(${solutingArticle.imageUrl})`
        };


        let content = '';
        if (solutingArticle && solutingArticle.content) content = solutingArticle.content.split('\n').map(it =>
            <p>{it}</p>);

        return (
            <React.Fragment>
                <LoadingModal isVisible={controller.isLoading}/>
                <main className="fadeIn">
                    <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                    <div className="appContent">
                        <div className="appContent-body soluting-item">
                            <ContentTitle title={solutingArticle.title} subtitle={solutingArticle.description}/>
                            <div className={`card slideIn ${categoryClass}`}>
                                <div className="card-body">
                                    <div className="card-image" style={articleImage}/>
                                    <div className="card-header">
                                        <h2>{solutingArticle.title}</h2>
                                        <h3>{solutingArticle.description}</h3>
                                    </div>
                                    <div className="card-content">
                                        {content}
                                    </div>
                                </div>
                            </div>
                            <StepsFooterBar
                                canPrev={true}
                                clickPrev={controller.onBack()}
                                canNext={true}
                                clickNext={controller.onNext()}
                                canFinish={false}
                                clickFinish={controller.onNext()}
                                isNextBtnActive={true}
                                isPrevBtnActive={true}
                                showCancel={true}
                                clickCancel={controller.onCancel()}/>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
});

export {SolutingArticlePageController, SolutingArticlePageView};