import React, {Component} from 'react';
import {lang} from '../utils/Lang';

class StepsFooterBar extends Component {
    render() {
        let canPrev = this.props.canPrev;
        let clickPrev = this.props.clickPrev;
        let canNext = this.props.canNext;
        let clickNext = this.props.clickNext;
        let canFinish = this.props.canFinish;
        let clickFinish = this.props.clickFinish;
        let showCancel = this.props.showCancel;
        let isPrevBtnActive = this.props.isPrevBtnActive;
        let isNextBtnActive = this.props.isNextBtnActive;
        let clickCancel = this.props.clickCancel;
        let showSteps = this.props.showSteps;
        let currentStep = this.props.currentStep;
        let totalSteps = this.props.totalSteps;

        return (
            <div className="app-footer-automatic">
                <button id="prev-btn" className="btn left" disabled={!isPrevBtnActive}
                        onClick={(e) => canPrev && clickPrev(e)}>
                    <i className="far fa-arrow-circle-left"/> <span>{lang.get('profilingSignUp.survey.back')}</span>
                </button>
                <div className="horizontal-line line-left"/>
                {showCancel &&
                <button id="cancel-btn" className="btn center" onClick={(e) => clickCancel(e)}><i
                    className="far fa-times-circle"/></button>
                }
                {showSteps &&
                <div className="steps-circle">
                    <span>{currentStep}</span>
                    <span>|</span>
                    <span>{totalSteps}</span>
                </div>
                }
                <div className="horizontal-line line-right"/>
                {
                    !canFinish ?
                        <button id="next-btn" className="btn right" disabled={!isNextBtnActive}
                                onClick={(e) => canNext && clickNext(e)}>
                            <span>{lang.get('profilingSignUp.survey.next')}</span> <i
                            className="far fa-arrow-circle-right"/></button>
                        :
                        <button id="finish-btn" className="btn right" onClick={(e) => clickFinish(e)}>
                            <span>{lang.get('profilingSignUp.survey.submitBtn')}</span> <i
                            className="far fa-arrow-circle-right"/></button>
                }
            </div>
        );
    }
}

export default StepsFooterBar;