import Cookies from 'universal-cookie';

class Cookie {
    cookies;

    constructor() {
        this.cookies = new Cookies();
    }

    set(name, data, exp) {
        this.cookies.set(name, data, {path: '/', expires: exp});
    }

    get(name) {
        return this.cookies.get(name)
    }

    remove(name) {
        this.cookies.remove(name)
    }

}

export default Cookie;