import ReactPixel from "./FacebookPixelModule";

window.facebookWrapper = null;

class FacebookPixel {
    PIXEL_ID = '1628140713908904'; // dev id: '168786031069212'
    options = {
        autoConfig: true, 	// set pixel's autoConfig
        debug: false, 		// disable logs
    };

    constructor() {
        ReactPixel.init(this.PIXEL_ID, undefined, this.options);
        window.facebookWrapper = this
    }

    pageView(){
        ReactPixel.pageView();
    }

    track(page, data){
        ReactPixel.track(page, data);
    }
}
export default FacebookPixel;