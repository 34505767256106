import React, {Component} from 'react';
import {decorate, observable, action, computed} from 'mobx';
import {observer} from 'mobx-react';
import moment from 'moment';
import {get} from 'lodash';
import {lang} from '../../utils/Lang';
import ScrollEvents from '../../../utils/ScrollEvents';
import {Header, HeaderController} from '../../Header/HeaderView';
import {CSSTransition} from 'react-transition-group';
import {AnonymousCheckBox} from '../../components/AnonymousCheckBox';
import {SelectionInfoBoxController} from '../../components/SelectionInfoBox';
import {ChatMessageStatusInfoBox} from '../../components/ChatMessageStatusInfoBox';
import {InfoBox2, InfoBox2Controller} from '../../components/Inputs/InfoBox2';
import {ChatMessage} from '../../components/ChatMessage';
import {OpenDiscussionItem} from '../../../domain/OpenDiscussions/OpenDiscussionItem';
import {LicensedAction, Role} from '../../../domain/Role';
import {LicenseLock} from '../../components/LicenseLock';
import {CompanyUser} from '../../../domain/User/CompanyUser';
import {ScrollPreserver} from "../../../utils/ScrollPreserver";

class OpenDiscussionMessagesController {
    openDiscussionItem = null;
    textareaContent = null;
    isCompanyAdmin = false;
    isGroupAdmin = false;

    isAnonymousInfoBoxController = new InfoBox2Controller();
    cannotChangeAnonymityInfoBoxController = new InfoBox2Controller();

    changeStatusInfoBoxController = new SelectionInfoBoxController(
        [
            {value: 'Active', text: lang.get('feedback.status.active')},
            {value: 'Archived', text: lang.get('feedback.status.archived')}
        ],
        null);

    constructor(navigator, userStore, openDiscussionId) {
        this.navigator = navigator;
        this.userStore = userStore;

        this.openDiscussionItem = new OpenDiscussionItem(openDiscussionId);

        this.openDiscussionItem.load()
            .catch(() => this.navigator.goToPage(this.navigator.siteMap.pages.Dashboard));

        this.load(userStore.companyUserId);
    }

    async load(companyUserId) {
        this.companyUser = new CompanyUser(companyUserId);
        this.isCompanyAdmin = await this.companyUser.getIsCompanyAdmin();

        //TODO: Unfortunately current there is no other way to know if user is group admin for custom open questions because they
        // are visible to everyone in company and nobody is a owner, we need this info to disable button and prevent on change anonymity click
        let adminTeams = await this.companyUser.getAdminTeams();
        this.isGroupAdmin = adminTeams !== null && adminTeams.length > 0;
    }

    get canChangeStatus() {
        return this.userStore.canDoAction(Role.FeedbackChangeStatus);
    }

    get canToggleAnonimity() {
        return !(this.isCompanyAdmin || this.isGroupAdmin);
    }

    sendMessage() {
        if (!this.textareaContent || this.textareaContent.trim() === '')
            return;

        this.openDiscussionItem.sendMessage(this.textareaContent.trim());
        this.textareaContent = '';
    }

    goBackToList() {
        this.navigator.goToPage(this.navigator.siteMap.pages.OpenDiscussionList);
    }

    changeStatus(newStatus) {
        this.openDiscussionItem.changeStatus(newStatus);
    }

    toggleAnonimity() {
        if (!this.canToggleAnonimity) {
            this.cannotChangeAnonymityInfoBoxController.show();
            return;
        }

        this.isAnonymousInfoBoxController.show()
            .then(result => {
                if (result === 'yes') {
                    this.openDiscussionItem.setIsAnonymous(!this.openDiscussionItem.isCurrentUserAnonymous);
                }
            });
    }

    get isAnonymous() {
        let isAnonymous = typeof this.openDiscussionItem.isCurrentUserAnonymous === 'undefined' || this.openDiscussionItem.isCurrentUserAnonymous;
        if (this.isCompanyAdmin || this.isGroupAdmin) isAnonymous = false;
        return isAnonymous;
    }

    async openStatusSelect() {
        if (!this.canChangeStatus)
            return;

        this.changeStatusInfoBoxController.selectByValue(this.openDiscussionItem.status);
        this.isOptionsVisible = false;
        let infoBoxResult = await this.changeStatusInfoBoxController.show();
        if (infoBoxResult !== 'yes') return;
        this.openDiscussionItem.changeStatus(this.changeStatusInfoBoxController.selectedItem.value);
    }

    isOptionsVisible = false;
}

decorate(OpenDiscussionMessagesController, {
    textareaContent: observable,
    openDiscussionItem: observable,
    canChangeStatus: computed,
    canToggleAnonimity: computed,
    toggleAnonimity: action,
    openStatusSelect: action,
    isOptionsVisible: observable,
    isCompanyAdmin: observable,
    isGroupAdmin: observable
});

const FadeIn = ({isVisible, children}) => (
    <CSSTransition in={isVisible} timeout={750} classNames={{enterActive: 'fadeIn', exitActive: 'fadeOut'}}
                   unmountOnExit>
        {children}
    </CSSTransition>
);

const OpenDiscussionMessagesView = observer(class OpenDiscussionMessagesView extends Component {
    // using this to scroll to end of messages only on first load
    lastRenderMessageSize = null;
    scrollEvents = new ScrollEvents();

    getSnapshotBeforeUpdate(prevProps, prevState) {
        return ScrollPreserver.getScrollPosition();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        ScrollPreserver.updateScrollPosition(snapshot);

        //this takes care of the messages scroller
        if (this.lastRenderMessageSize !== this.props.controller.openDiscussionItem.messages.length) {
            this.lastRenderMessageSize = this.props.controller.openDiscussionItem.messages.length;
            this.scrollEvents.stopTrackingScroll();
            this.scrollEvents.startTrackingScroll(document.getElementsByClassName('feedback-message-content')[0]);
            this.scrollEvents.scrollToBottom();
        }
    }

    componentWillUnmount() {
        this.scrollEvents.stopTrackingScroll();
    }

    render() {
        let controller = this.props.controller;

        let messageItems = [];
        let lastDate = '';
        controller.openDiscussionItem.messages.forEach((message, idx) => {
            let date = moment(message.createdAt).format('DD-MM-YYYY');
            if (lastDate !== date) {
                lastDate = date;
                messageItems.push(<span key={lastDate} className="time-separator"><span>{lastDate}</span></span>)
            }
            messageItems.push(<ChatMessage
                key={idx}
                userDisplayName={message.userName}
                createdAt={message.createdAt}
                isAnonymous={(!message.userName)}
                isSender={(message.userId === controller.userStore.companyUserId)}
                content={message.content}/>);
        });

        const currentUserDetails = get(controller, ['userStore', 'userDetails'], {});
        let userName = `${currentUserDetails.firstName} ${currentUserDetails.lastName}`;
        let statusLabel = controller.openDiscussionItem.status;

        let licenseLocked = !controller.userStore.isLicensedFor(LicensedAction.FeedbackReply);

        let licenseLockedClass = '';
        if (licenseLocked === true) licenseLockedClass = 'license-lock-opacity';

        return (
            <React.Fragment>
                <ChatMessageStatusInfoBox controller={controller.changeStatusInfoBoxController}/>
                <InfoBox2 icon={'far fa-exclamation-triangle'}
                          title={lang.get('feedbackMessages.infoBox.changeAnonymous.title')}
                          hasYes={true}
                          hasNo={true}
                          id='changeAnonimityInfoBox'
                          controller={controller.isAnonymousInfoBoxController}>
                    <p>{lang.get(`feedbackMessages.infoBox.changeAnonymous.content.${controller.isAnonymous ? 'unhide' : 'hide'}`)}</p>
                </InfoBox2>
                <InfoBox2 icon={'far fa-exclamation-triangle'}
                          title={lang.get('feedbackMessages.infoBox.changeAnonymous.title')}
                          hasYes={true}
                          hasNo={false}
                          controller={controller.cannotChangeAnonymityInfoBoxController}>
                    <p>{lang.get('feedbackMessages.infoBox.changeAnonymous.adminCannotChange')}</p>
                </InfoBox2>
                <main className="fadeIn">
                    <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                    <div className="appContent" data-selenium-topic-id={controller.openDiscussionItem.feedbackId}>
                        <div className="appContent-body feedback-message">
                            <div className="feedback-message-header dropdown-header">
                                <h1>{lang.get('survey.normal.openDiscussion.title')}</h1>
                                <p>{controller.openDiscussionItem.topicName}</p>
                                <div>
                                    <div className="input-check-box-anonymous-reverse">
                                        <AnonymousCheckBox disabled={!controller.canToggleAnonimity}
                                                           onToggle={() => controller.toggleAnonimity()}
                                                           isChecked={controller.isAnonymous}
                                                           name={userName}/>
                                    </div>
                                    <button className="btn plain"
                                            onClick={() => controller.isOptionsVisible = !controller.isOptionsVisible}>
                                        <span>{lang.get('feedbackMessages.options')}</span> <i className="far fa-cog"/></button>
                                </div>
                                <FadeIn isVisible={controller.isOptionsVisible}>
                                    <ul className="dropdown">
                                        <li onClick={() => controller.openStatusSelect()}
                                            className={(controller.canChangeStatus ? '' : 'disabled')}><i
                                            className="far fa-cog"/> {lang.get('feedbackMessages.status')}: <span>{statusLabel}</span>
                                        </li>
                                    </ul>
                                </FadeIn>
                            </div>
                            <div className="feedback-message-content dropdown-content">
                                {messageItems}
                            </div>
                            <div className="feedback-message-footer">
                                <LicenseLock showText={true} isLocked={licenseLocked} navigator={controller.navigator}>
                                    <textarea name="msgContent"
                                              className={licenseLockedClass}
                                              id="msgContent"
                                              rows={4}
                                              placeholder={lang.get('feedbackMessages.textAreaPlaceholder')}
                                              value={controller.textareaContent}
                                              onChange={(e) => controller.textareaContent = e.target.value}/>
                                    <div className={`buttons ${licenseLockedClass}`}>
                                        <button id="close" onClick={() => controller.goBackToList()} className="btn">
                                            <i className="far fa-times"/> <span>Close</span>
                                        </button>
                                        <button id="send" onClick={() => controller.sendMessage()} className="btn">
                                            <span>Send</span>
                                            <i className="far fa-paper-plane"/>
                                        </button>
                                    </div>
                                </LicenseLock>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
});

export {OpenDiscussionMessagesView, OpenDiscussionMessagesController};