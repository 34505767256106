import React, {Component} from 'react';
import {roundSpirit} from '../../utils/Utils';
import {calculateColor} from './ColorCalculator';

export class Score extends Component {
    render() {
        let colorClass = calculateColor(this.props.spirit);

        let label = roundSpirit(this.props.spirit);
        let progress = 100;
        if (this.props.spirit !== null) {
            progress = 100 - this.props.spirit * 10;
        }

        return <div className="new-wave">
            <div className={`wave ${colorClass}`} style={{top: `calc(${progress}%)`}}/>
            <div className="bubbles">
                <div className="first"/>
                <div className="second"/>
                <div className="third"/>
            </div>
            <span>{label}</span>
        </div>;

    }
}