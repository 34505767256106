import React from 'react';
import {computed, decorate, action, observable} from 'mobx';
import {observer} from 'mobx-react';
import {Header, HeaderController} from '../Header/HeaderView';
import {lang} from '../utils/Lang';
import {ContentTitle} from '../components/ContentTitle';
import countryList from '../../assets/resources/countries';
import {hideIfNot} from '../../utils/Utils';
import {InfoBox2, InfoBox2Controller} from '../components/Inputs/InfoBox2';
import {Role} from '../../domain/Role';
import {StatisticsService} from '../../domain/StatisticsService';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import LoadingModal from '../components/LoadingModal';
import {notificationManager} from '../components/Notifications';

class UserProfileViewController {
    isLoading = false;
    confirmDelete1Controller = new InfoBox2Controller();
    confirmDelete2Controller = new InfoBox2Controller();

    statisticsInfoBox = new InfoBox2Controller();
    statisticsService = new StatisticsService();
    statisticsResult = null;

    constructor(navigator, userStore) {
        this.navigator = navigator;
        this.userStore = userStore;
        this.userDetails = this.userStore.userDetails;
        for (let i = 0; i < countryList.length; i++) {
            let item = countryList[i];
            if (this.userDetails.country === item.code) {
                this.countryText = item.name;
            }
        }
    }

    canGoToEditProfile() {
        this.navigator.canGoTo(this.navigator.siteMap.pages.EditProfile);
    }

    goToEditProfile() {
        this.navigator.goToPage(this.navigator.siteMap.pages.EditProfile);
    }

    get canDelete() {
        // current user can only access this page, so he can delete himself
        return true;
    }

    get canSeeStatistics() {
        return this.userStore.canDoAction(Role.DoodsStatisticsView);
    }

    async showStatistics() {
        this.isLoading = true;
        try {
            let csv = await this.statisticsService.getCompanyStatistics();
            console.log(csv);
            this.statisticsResult = csv;
            this.statisticsInfoBox.show();
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            notificationManager.info(lang.get('notification.error.default_400'));
        }
    }

    async exportUsersWithoutCompany() {
        this.isLoading = true;
        try {
            let csv = await this.statisticsService.getUsersWithoutCompany();
            console.log(csv);
            this.statisticsResult = csv;
            this.statisticsInfoBox.show();
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            notificationManager.info(lang.get('notification.error.default_400'));
        }
    }

    async statisticsCopied() {
        this.statisticsInfoBox.hide();
        notificationManager.info(lang.get('user.statistic.copyNotification'));
    }

    async deleteUser() {
        if (await this.confirmDelete1Controller.show() !== 'yes') return;
        if (await this.confirmDelete2Controller.show() !== 'yes') return;
        await this.userStore.deleteUser();
        this.navigator.goToPage(this.navigator.siteMap.pages.Dashboard);
    }
}

decorate(UserProfileViewController, {
    isLoading: observable,
    canDelete: computed,
    deleteUser: action,
    canSeeStatistics: computed,
    statisticsResult: observable
});

const UserProfileViewPage = observer(({controller}) => {
    return (
        <main className="fadeIn">
            <LoadingModal isVisible={controller.isLoading}/>
            <InfoBox2 id="delete-user-confirm1" hasYes={true} hasNo={true} controller={controller.confirmDelete1Controller} icon={'far fa-trash-alt'}
                      title={lang.get('userProfile.view.delete.confirm1.title')}>
                <p>{lang.get('userProfile.view.delete.confirm1.content')}</p>
            </InfoBox2>
            <InfoBox2 id="delete-user-confirm2" hasYes={true} hasNo={true} controller={controller.confirmDelete2Controller} icon={'far fa-trash-alt'}
                      title={lang.get('userProfile.view.delete.confirm2.title')}>
                <p>{lang.get('userProfile.view.delete.confirm2.content')}</p>
            </InfoBox2>
            <InfoBox2 id="statistics-result" hasClose={true} controller={controller.statisticsInfoBox} icon={'far fa-database'} title={'Statistics result'}>
                <CopyToClipboard text={controller.statisticsResult}
                                 onCopy={() => controller.statisticsCopied()}
                                 options={{format: 'text/plain'}}>
                    <button className="btn btnSmall mt-1"><span>{lang.get('user.statistic.copyData')}</span></button>
                </CopyToClipboard>
            </InfoBox2>

            <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
            <div className="appContent">
                <div className="appContent-body">
                    <ContentTitle title={lang.get('appheader.userProfile.title')}
                                  subtitle={lang.get('appheader.userProfile.subtitle')}/>
                    <div className="card">
                        <div className="card-body">
                            <div className="card-header small">
                                <div className="card-header-icon">
                                    <i className="far fa-user-circle"/>
                                </div>
                                <div className="card-header-title">
                                    <h2>{lang.get('registration.personalDetails')}</h2>
                                    <button className="btn plain" onClick={() => controller.goToEditProfile()} style={hideIfNot(controller.canGoToEditProfile)}>
                                        <span>{lang.get('registration.editBtn')}</span>
                                        <i className="far fa-edit"/>
                                    </button>
                                </div>
                            </div>
                            <div className="card-content">
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <td>{lang.get('registration.firstName')}</td>
                                        <td id="firstName" className="txtRight">{controller.userDetails.firstName}</td>
                                    </tr>
                                    <tr>
                                        <td>{lang.get('registration.lastName')}</td>
                                        <td id="lastName" className="txtRight">{controller.userDetails.lastName}</td>
                                    </tr>
                                    <tr>
                                        <td>{lang.get('registration.email')}</td>
                                        <td id="email" className="txtRight">{controller.userStore.email}</td>
                                    </tr>
                                    <tr>
                                        <td>{lang.get('registration.birthYear')}</td>
                                        <td id="birthYear" className="txtRight">{controller.userDetails.birthYear}</td>
                                    </tr>
                                    <tr>
                                        <td>{lang.get('registration.gender')}</td>
                                        <td id="gender" className="txtRight">{lang.get(`registration.gender.${controller.userDetails.gender}`)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="card-header small">
                                <div className="card-header-icon">
                                    <i className="far fa-user-circle"/>
                                </div>
                                <div className="card-header-title">
                                    <h2>{lang.get('registration.additionalDetails')}</h2>
                                </div>
                            </div>
                            <div className="card-content">
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <td>{lang.get('registration.street')}</td>
                                        <td id="street" className="txtRight">{controller.userDetails.street}</td>
                                    </tr>
                                    <tr>
                                        <td>{lang.get('registration.zipCode')}</td>
                                        <td id="zipCode" className="txtRight">{controller.userDetails.zipCode}</td>
                                    </tr>
                                    <tr>
                                        <td>{lang.get('registration.city')}</td>
                                        <td id="city" className="txtRight">{controller.userDetails.city}</td>
                                    </tr>
                                    <tr>
                                        <td>{lang.get('registration.country')}</td>
                                        <td id="countryText" className="txtRight">{controller.countryText}</td>
                                    </tr>
                                    <tr>
                                        <td>{lang.get('registration.industry')}</td>
                                        <td id="industry" className="txtRight">{lang.get(`industryList.${controller.userDetails.industry}`)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="card-header small" style={hideIfNot(controller.canDelete)}>
                                <div className="card-header-icon">
                                    <i className="far fa-trash-alt"/>
                                </div>
                                <div className="card-header-title">
                                    <h2>{lang.get('userProfile.view.delete.header')}</h2>
                                    <button id="delete-user-btn" className="btn plain" onClick={() => controller.deleteUser()}>
                                        <span>{lang.get('userProfile.view.delete.button')}</span><i className="far fa-trash-alt"/>
                                    </button>
                                </div>
                            </div>
                            <div className="card-header small" style={hideIfNot(controller.canSeeStatistics)}>
                                <div className="card-header-icon">
                                    <i className="far fa-database"/>
                                </div>
                                <div className="card-header-title">
                                    <h2>{lang.get("user.statistic.showStatistic.title")}</h2>
                                    <button id="show-statistics-btn" className="btn plain" onClick={() => controller.showStatistics()}>
                                        <span>{lang.get('user.statistic.showBtn')}</span><i className="fa fa-database"/>
                                    </button>
                                </div>
                            </div>
                            <div className="card-header small" style={hideIfNot(controller.canSeeStatistics)}>
                                <div className="card-header-icon">
                                    <i className="far fa-database"/>
                                </div>
                                <div className="card-header-title">
                                    <h2>{lang.get("user.statistic.usersWithoutCompany.title")}</h2>
                                    <button id="show-statistics-btn" className="btn plain" onClick={() => controller.exportUsersWithoutCompany()}>
                                        <span>{lang.get('user.statistic.showBtn')}</span><i className="fa fa-database"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
});

export {UserProfileViewController, UserProfileViewPage};