import {decorate, observable, action} from 'mobx';
import {axios} from '../../utils/WrappedAxios';

class SolutingCollectionStore {

    groups = [];

    load() {
        return axios.get('/api/soluting')
            .then((response) => {
                this.groups = response.data.groups;
            });
    }

    loadByCategory(categroy, movingAverageCount) {
        return axios.get(`/api/soluting/report/${categroy}/${movingAverageCount}`)
            .then((response) => {
                this.groups = response.data.groups;
            });
    }

}

decorate(SolutingCollectionStore, {
    groups: observable,
    load: action,
});

export default SolutingCollectionStore;