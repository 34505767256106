import {lang} from '../../../page/utils/Lang';

class TopicUpdatedNotification {
    group = TopicUpdatedNotification.group;

    constructor(topicId, updatedBy) {
        this.topicId = topicId;
        this.updatedBy = updatedBy || 'n/a';
    }

    get title() {
        return lang.get(`notification.${TopicUpdatedNotification.group}.title`);
    }

    get content() {
        return lang.get(`notification.${TopicUpdatedNotification.typeName}.content`).replace('$userName', this.updatedBy);
    }

    getLink(navigator) {
        return () => navigator.goToPage(navigator.siteMap.pages.Feedback, this.topicId);
    }

    static tryParse(json) {
        if (json.type !== this.typeName) return null;
        return new TopicUpdatedNotification(json.topicId, json.updatedBy);
    }

    static get typeName() {
        return 'topicUpdated';
    }

    static get group() {
        return 'Feedback';
    }
}

export {TopicUpdatedNotification};