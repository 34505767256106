import React from 'react';
import {lang} from '../utils/Lang';
import {decorate, observable} from 'mobx';
import {observer} from 'mobx-react';

class NotificationManager {

    notifications = [];
    timeout = 10000;

    error(error, innerError) {
        console.error(error, innerError);
        let newNotification = {value: error, type: 'error'};
        this.notifications.push(newNotification);
        this._scheduleNotificationRemove(newNotification);
    }

    info(info) {
        let newNotification = {value: info, type: 'info'};
        this.notifications.push(newNotification);
        this._scheduleNotificationRemove(newNotification);
    }

    infoNoDismiss(info) {
        let newNotification = {value: info, type: 'info'};
        this.notifications.push(newNotification);
    }

    warn(content) {
        let newNotification = {value: content, type: 'info'};
        this.notifications.push(newNotification);
        this._scheduleNotificationRemove(newNotification);
    }

    custom(content, title) {
        let newNotification = {value: content, title: title, type: 'custom'};
        this.notifications.push(newNotification);
        this._scheduleNotificationRemove(newNotification);
    }

    _scheduleNotificationRemove(notification) {
        setTimeout(() => {
            this.notifications.remove(notification);
        }, this.timeout);
    }
}

decorate(NotificationManager, {
    notifications: observable.shallow
});

let notificationManager = new NotificationManager();
if (!window.doods) window.doods = {};
window.doods.notify = notificationManager;

const Notifications = observer(() => {
    let notifications = notificationManager.notifications.map((notification, index) =>
        <div className="notification-box" key={index}>
            <div className="info-box-body">
                <div className="info-box-header">
                    <i className="far fa-exclamation-circle"/>
                    {notification.type === 'info' && <h2>{lang.get('notification.title.info')}</h2>}
                    {notification.type === 'error' && <h2>{lang.get('notification.title.error')}</h2>}
                    {notification.type === 'custom' && <h2>{notification.title}</h2>}
                    <button className="btn plain"
                            onClick={() => notificationManager.notifications.remove(notification)}><i
                        className="far fa-times"/></button>
                </div>
                <div className="info-box-content">
                    <p dangerouslySetInnerHTML={{__html: notification.value}}/>
                </div>
            </div>
        </div>
    );

    return (
        <div className="notification-manager-list">
            {notifications}
        </div>
    );
});

export {Notifications, notificationManager};
