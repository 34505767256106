import React from 'react';
import {observer} from 'mobx-react';
import InputWrap from './InputWrap';

const Input = observer(({type, name, extraClass, placeholder, validatedValue, maxLength, autocomplete, children, disabled}) => {
    return (
        <InputWrap id={name} validationMsg={validatedValue.displayError} extraClass={extraClass}>
            <input type={type || 'text'}
                   className="form-input"
                   name={name}
                   id={name}
                   placeholder={placeholder}
                   value={validatedValue.value ? validatedValue.value : ''}
                   maxLength={maxLength}
                   autoComplete={autocomplete}
                   onChange={(e) => validatedValue.valueWithTouch = e.target.value}
                   onInput={(e) => {
                       if (type === 'number') e.target.value = e.target.value.slice(0, e.target.maxLength)
                   }}
                   onBlur={() => validatedValue.blur()}
                   onFocus={() => validatedValue.focus()}
                   disabled={disabled}
            />
            {children}
        </InputWrap>
    );
});

export default Input;