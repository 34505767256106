import React from 'react';
import {lang} from './utils/Lang';
import UserService from '../services/UserService';
import {notificationManager} from './components/Notifications';
import {decorate, observable} from 'mobx';
import {observer} from 'mobx-react';
import logoImage from '../assets/img/logo-doods.svg';
import {ChangeLanguageInput, ChangeLanguageInputController} from './components/ChangeLanguageInput';
import {LoginRecaptchaForm, LoginRecaptchaFormController} from './components/LoginRecaptchaForm';

class LoginController {
    userService = new UserService();
    languageSelectController = new ChangeLanguageInputController();
    showPage = null;
    email = null;

    constructor(navigator) {
        this.navigator = navigator;
        this.loginFormController = new LoginRecaptchaFormController(navigator, (email, token) => this.onRecaptchaVerified(email, token), false);
        this.showPage = 'signIn';
    }

    onRecaptchaVerified(email, token) {
        this.userService.sendLoginEmail(email, token)
            .then(() => {
                this.goToLoginSuccess(email);
            })
            .catch(error => {
                if (error.response && error.response.status === 404)
                    notificationManager.info(lang.get('login.notification.userNotFound'));
                else
                    throw error;
            })
            .then(() => this.loginFormController.modalRecaptchaStore.hide());
    }

    goToSignUp() {
        this.navigator.goToPage(this.navigator.siteMap.pages.SignUp);
    }

    goToLoginSuccess(email) {
        this.email = email;
        this.showPage = 'success';
        window.facebookWrapper.track("Verification email sent", {email: email});
    }
}

decorate(LoginController, {
    showPage: observable,
    email: observable
});

const SingIn = observer(({controller}) => {
    return (
        <React.Fragment>
            <div className="card-header">
                <h1>{lang.get('login.header.signin')}</h1>
                <ChangeLanguageInput controller={controller.languageSelectController} txtCenter={true}/>
            </div>
            <div className="card-content">
                <LoginRecaptchaForm controller={controller.loginFormController}>
                    <button id="login-submit" type="submit" className="btn fadeIn blue">
                        <span>{lang.get('login.button.login')}</span></button>
                </LoginRecaptchaForm>
            </div>
            <div className="card-footer">
                <p>{lang.get('profilingSignUp.start.dontHaveAccount')}
                    <a href="#/" id="goto-profiling-btn" onClick={(e) => {
                        e.preventDefault();
                        controller.goToSignUp();
                    }}> {lang.get('login.button.startProfiling')}</a>
                </p>
            </div>
        </React.Fragment>
    );
});

const Success = observer(({controller}) => {
    return (
        <React.Fragment>
            <div className="card-content login-success">
                <div className="success-icon">
                    <i className="fal fa-mail-bulk"/>
                </div>
                <p>{lang.get('login.success.linkSent')}</p>
                <h3>{controller.email}</h3>
                <p>{lang.get('login.success.clickInEmail')}</p>
            </div>
        </React.Fragment>
    );
});


const LoginPage = observer(({controller}) => {

    let currentPage;
    switch (controller.showPage) {
        case 'signIn':
            currentPage = <SingIn controller={controller}/>;
            break;
        case 'success':
            currentPage = <Success controller={controller}/>;
            break;
        default:
            currentPage = <div/>;
            break;
    }

    return (
        <main className="bg-style2">
            <div className="appContent">
                <div className="appContent-body fadeIn login-content">
                    <div className="logo">
                        <img src={logoImage} alt="DooDs Logo"/>
                    </div>
                    <div className="content">
                        <div className="card">
                            <div className="card-body">
                                {currentPage}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
});

export {LoginPage, LoginController};
