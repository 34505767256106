import React, {Component} from 'react';
import {observer} from 'mobx-react';
import SolutingListGroup from './SolutingListGroup';
import {Header, HeaderController} from '../../Header/HeaderView';
import SolutingCollectionStore from '../../../domain/Soluting/SolutingCollectionStore';
import {decorate, observable} from 'mobx';
import LoadingModal from '../../components/LoadingModal';
import {lang} from '../../utils/Lang';
import {ContentTitle} from '../../components/ContentTitle';


class SolutingListPageController {
    userStore;
    navigator;
    groups = [];
    isLoading = false;

    constructor(navigator, userStore) {
        this.userStore = userStore;
        this.navigator = navigator;

        this.solutingCollectionStore = new SolutingCollectionStore();
        this.loadSolutings()
    }

    loadSolutings() {
        this.isLoading = true;
        this.solutingCollectionStore.load()
            .then(() => {
                this.isLoading = false;
                this.groups = this.solutingCollectionStore.groups;
            })
            .catch(() => {
                this.navigator.goToPage(this.navigator.siteMap.pages.Dashboard);
                this.isLoading = false;
            });
    }

}

decorate(SolutingListPageController, {
    groups: observable,
    isLoading: observable
});

const SolutingListPageView = observer(class SolutingListPageView extends Component {
    createSolutingListGroup(group) {
        return <SolutingListGroup key={group.name} name={group.name} items={group.items}
                                  navigator={this.props.controller.navigator}/>
    }

    render() {
        let controller = this.props.controller;

        let groups = controller.groups.map((group) => {
            return this.createSolutingListGroup(group);
        });

        return (
            <React.Fragment>
                <LoadingModal isVisible={controller.isLoading}/>
                <main className="fadeIn">
                    <Header controller={new HeaderController(controller.navigator, controller.userStore)}/>
                    <div className="appContent">
                        <div className="appContent-body fadeIn soluting-list">
                            <ContentTitle title={lang.get('appheader.soluting.title')}
                                          subtitle={lang.get('appheader.soluting.subtitle')}/>
                            {groups}
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
});

export {SolutingListPageController, SolutingListPageView};