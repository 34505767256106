import React from 'react';
import {RangeSlider, RangeSliderController} from '../../components/RangeSlider';
import {decorate, observable, computed} from 'mobx';
import {observer} from 'mobx-react';
import {ContentTitle} from '../../components/ContentTitle';


class SurveySliderQuestionController {
    text = '';
    sliderController = new RangeSliderController();

    constructor(text, value) {
        this.text = text || '';
        this.sliderController.value = value || 5;
    }

    get value() {
        return this.sliderController.value;
    }
}

decorate(SurveySliderQuestionController, {
    text: observable,
    value: computed
});

const SurveySliderQuestionView = observer(({controller, title}) => (
    <React.Fragment>
        <ContentTitle title={title}/>
        <div className="survey-textbox">
            <div className="survey-text">
                <p>
                    {controller.text}
                </p>
            </div>
        </div>
        <RangeSlider controller={controller.sliderController}/>
    </React.Fragment>
));


export {SurveySliderQuestionView, SurveySliderQuestionController};
