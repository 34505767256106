import React, {Component} from 'react';
import {action, decorate, observable, runInAction} from 'mobx';
import {observer} from 'mobx-react';
import {Header, HeaderController} from '../../Header/HeaderView';
import {lang} from '../../utils/Lang';
import {ContentTitle} from '../../components/ContentTitle';
import ValidatedValue from '../../components/Inputs/ValidatedValue';
import {
    InfoBox2,
    InfoBox2Content,
    InfoBox2Controller,
    InfoBox2Header
} from '../../components/Inputs/InfoBox2';
import {notificationManager} from '../../components/Notifications';
import {LicensedAction, Role} from '../../../domain/Role';
import {hideIfNot} from '../../../utils/Utils';
import DynamicInput from '../../components/Inputs/DynamicInput';
import DynamicInputCheckbox from '../../components/Inputs/DynamicInputCheckbox';
import {CompanyUser} from '../../../domain/User/CompanyUser';
import _ from 'lodash';
import {MultiSelectionListController, MultiSelectionListView} from '../../components/MultiSelectionList';
import TeamCollection from '../../../domain/Team/TeamCollection';

class EditUserPageController {
    navigator;
    userStore;
    headerController;
    companyUser;

    userName = '';
    teams = []; // id, name, count
    adminTeams = []; // id, name, count
    isCompanyAdmin = new ValidatedValue([], false);
    isOnHoliday = new ValidatedValue([], false);
    role = new ValidatedValue([], null);
    isDeactivated = false;
    isRemoved = false;

    teamCollection = new TeamCollection();

    addEmployeeTeamsInfoBoxController = new InfoBox2Controller();
    employeeTeamsListController = new MultiSelectionListController();

    confirmationContent = '';
    confirmationController = new InfoBox2Controller();
    deleteUserConfirmationController = new InfoBox2Controller();
    deactivateUserConfirmationController = new InfoBox2Controller();

    constructor(navigator, userStore, companyUserId) {
        this.navigator = navigator;
        this.userStore = userStore;
        this.headerController = new HeaderController(navigator, userStore);
        this.headerController.manualTitle = lang.get('teamManagement.employees.header');

        this.role.onFinalChange = (oldValue, newValue, isValid) => this.editRole(oldValue, newValue, isValid);
        this.isCompanyAdmin.onFinalChange = (oldValue, newValue, isValid) => this.editCompanyAdmin(oldValue, newValue, isValid);
        this.isOnHoliday.onFinalChange = (oldValue, newValue, isValid) => this.editIsOnHoliday(oldValue, newValue, isValid);

        this.load(companyUserId);
    }

    async load(companyUserId) {
        this.companyUser = new CompanyUser(companyUserId);

        let [profile, companyProfile] = await Promise.all([this.companyUser.getProfile(), this.companyUser.get()]);

        runInAction(() => {
            this.userName = profile.email;
            if (profile.details)
                this.userName = profile.details.firstName + ' ' + profile.details.lastName;

            this.isDeactivated = companyProfile.isDeactivated;
            this.isRemoved = companyProfile.isRemoved;
            this.isCompanyAdmin.value = companyProfile.isCompanyAdmin;
            this.isOnHoliday.value = companyProfile.isOnHoliday;
            this.role.value = companyProfile.roleName;

            this.teams.clear();
            this.teams.push(...companyProfile.teams);
            this.adminTeams.clear();
            this.adminTeams.push(...companyProfile.adminTeams);

            this.headerController.manualTitle = this.userName;
        });
    }

    removeUserFromCompany() {
        // remove user from company???
    }


    async editRole(oldValue, newValue, isValid) {
        if (!isValid) return;
        // change user role
        try {
            await this.companyUser.setRole(newValue);
            this.role.showSaved();
        } catch (e) {
            notificationManager.error(e, lang.get('notification.error.cannot_change_role'));
            this.role.value = oldValue;
        }
    }

    async editCompanyAdmin(oldValue, newValue, isValid) {
        if (!isValid) return;

        try {
            await this.companyUser.setIsCompanyAdmin(newValue);
            this.isCompanyAdmin.showSaved();
        } catch (e) {
            notificationManager.error(e, lang.get('notification.error.cannot_change_company_admin'));
            this.isCompanyAdmin.value = oldValue;
        }
    }

    async editIsOnHoliday(oldValue, newValue, isValid) {
        if (!isValid) return;

        try {
            await this.companyUser.setIsOnHoliday(newValue);
            this.isOnHoliday.showSaved();
        } catch (e) {
            notificationManager.error(e, lang.get('notification.error.cannot_set_deactivated_state'));
            this.isOnHoliday.value = oldValue;
        }
    }

    async removeAdminGroup(team) {
        let confirmResult = await this._confirm(lang.get(`teamManagement.infoBox.admin.remove.team.title`) + ` ${team.name}`);
        if (confirmResult !== 'yes')
            return;
        // remove user as admin from team
        await this.companyUser.removeFromAdmin(team.id);
        this.adminTeams.remove(team);
    }

    async removeGroup(team) {
        let confirmResult = await this._confirm(lang.get(`teamManagement.infoBox.user.remove.team.title`) + ` ${team.name}`);
        if (confirmResult !== 'yes')
            return;
        // remove user from group
        await this.companyUser.removeFromTeam(team.id);
        this.teams.remove(team);
    }

    async _confirm(content) {
        this.confirmationContent = content;
        return await this.confirmationController.show();
    }

    async openGroupSelection() {

        // show info box immediately
        this.employeeTeamsListController.setItems([]);
        let infoBoxRequest = this.addEmployeeTeamsInfoBoxController.show();

        // load teams
        let allTeams = await this.teamCollection.getTeamsAndSubteams();

        //remove any already selected team
        let availableTeams = _.pullAllWith(allTeams, this.teams, (x, y) => x.team.id === y.id);
        //sort by name
        availableTeams = _.sortBy(availableTeams, (it) => it.team.name);

        this.employeeTeamsListController.setItems(availableTeams.map(it => {
            return {value: it.team.id, text: it.team.name}
        }));

        if (await infoBoxRequest !== 'yes') return;
        await this.teamCollection.addUserToTeams(this.companyUser.id, this.employeeTeamsListController.selectedItems.map(selectedTeam => selectedTeam.value));
        return this.load(this.companyUser.id);
    }

    async deleteUser() {
        let confirm = await this.deleteUserConfirmationController.show();
        if (confirm !== 'yes') return;
        await this.companyUser.setIsRemoved(true);
        this.navigator.goToPage(this.navigator.siteMap.pages.UserList);
    }

    async deactivateUser() {
        let confirm = await this.deactivateUserConfirmationController.show();
        if (confirm !== 'yes') return;
        await this.companyUser.setIsDeactivated(true);
        this.navigator.goToPage(this.navigator.siteMap.pages.UserList);
    }
}

decorate(EditUserPageController, {
    isDeactivated: observable,
    isRemoved: observable,
    userName: observable,
    isCompanyAdmin: observable,
    role: observable,
    teams: observable.shallow,
    adminTeams: observable.shallow,
    addAdminGroup: action,
    removeAdminGroup: action,
    addGroup: action,
    removeGroup: action,
    load: action,
    deleteUser: action,
    confirmationContent: observable
});

const EditUserPageView = observer(class EditUserPageView extends Component {
    render() {
        let controller = this.props.controller;

        let userName = controller.userName;

        let removeAdminGroup = (group) => controller.removeAdminGroup(group);
        let removeGroup = (group) => controller.removeGroup(group);

        let licenseForTeamEdit = controller.userStore.isLicensedFor(LicensedAction.TeamManagementEdit);

        let canAddUserToGroup = controller.userStore.canDoAction(Role.GroupAddUser) && licenseForTeamEdit;
        let canDeleteSubTeam = controller.userStore.canDoAction(Role.GroupDelete) && licenseForTeamEdit;
        let canDeleteAdmins = controller.userStore.canDoAction(Role.GroupRemoveAdmin) && licenseForTeamEdit;
        let canChangeIsAdmin = controller.userStore.canDoAction(Role.CompanyViewIsAdmin);
        let canChangeIsOnHoliday = controller.userStore.canDoAction(Role.UserChangeIsOnHoliday);
        let canChangeRole = controller.userStore.canDoAction(Role.UserChangeRole);
        let canDeleteUser = controller.userStore.canDoAction(Role.UserChangeIsRemoved) && !controller.isRemoved;
        let canDeactivateUser = controller.userStore.canDoAction(Role.UserChangeIsDeactivated) && !controller.isRemoved && !controller.isDeactivated;

        let confirmationContent = controller.confirmationContent;
        let confirmationController = controller.confirmationController;

        let addEmployeeTeamsInfoBoxController = controller.addEmployeeTeamsInfoBoxController;
        let employeeTeamsListController = controller.employeeTeamsListController;

        let isCompanyAdmin = controller.isCompanyAdmin;
        let isOnHoliday = controller.isOnHoliday;
        let role = controller.role;

        let adminGroups = controller.adminTeams.map(team =>
            <li key={team.id}>
                <span>{team.name}</span>
                <button className="btn plain" style={hideIfNot(canDeleteAdmins)} onClick={() => removeAdminGroup(team)}>
                    <i className="far fa-trash-alt"/></button>
            </li>);

        let groups = controller.teams.map(team =>
            <li key={team.id}>
                <span>{team.name}</span>
                <button className="btn plain" style={hideIfNot(canDeleteSubTeam)} onClick={() => removeGroup(team)}><i
                    className="far fa-trash-alt"/></button>
            </li>);

        return (
            <main className="fadeIn">
                <Header controller={controller.headerController}/>


                <InfoBox2 id="info-box-add-employee-to-groups" title={lang.get('teamManagement.group')}
                          controller={addEmployeeTeamsInfoBoxController} hasYes={true} hasNo={true}>
                    <InfoBox2Header>
                        <i className="far fa-users"/>
                        <h2>{lang.get('teamManagement.editGroupsTitle')}</h2>
                    </InfoBox2Header>
                    <InfoBox2Content>
                        <MultiSelectionListView controller={employeeTeamsListController}/>
                    </InfoBox2Content>
                </InfoBox2>

                <InfoBox2 id="deleteUserInfoBox"
                          controller={controller.deleteUserConfirmationController}
                          icon={'far fa-trash-alt'}
                          hasYes={true} hasNo={true} title={lang.get('teamManagement.infoBox.removeUserFromCompany.title')}>
                    <p>{lang.get('teamManagement.infoBox.removeUserFromCompany.content')}</p>
                </InfoBox2>

                <InfoBox2 id="deactivateUserInfoBox"
                          controller={controller.deactivateUserConfirmationController}
                          icon={'far fa-user-slash'}
                          hasYes={true} hasNo={true} title={lang.get('teamManagement.infoBox.deactivateUser.title')}>
                    <p>{lang.get('teamManagement.infoBox.deactivateUser.content')}</p>
                </InfoBox2>

                <div className="appContent">
                    <div className="appContent-body edit-user-page app-footer-automatic-parent">
                        <ContentTitle title={lang.get('appheader.teamMgmt.employees.title')}
                                      subtitle={lang.get('appheader.teamMgmt.employees.subtitle')}/>
                        <InfoBox2 id="info-box-confirmation" title={lang.get('teamManagement.confirmTitle')}
                                  controller={confirmationController} hasYes={true} hasNo={true}>
                            <InfoBox2Header>
                                <i className="far fa-users"/>
                                {/*TODO - commit*/}
                                <h2>User edit</h2>
                            </InfoBox2Header>
                            <InfoBox2Content>
                                {confirmationContent}
                            </InfoBox2Content>
                        </InfoBox2>

                        <div className="card">
                            <div className="card-body">
                                <div className="card-header small">
                                    <div className="card-header-icon">
                                        <i className="far fa-address-book"/>
                                    </div>
                                    <div className="card-header-title">
                                        <h2>{userName}</h2>
                                        <div className="btn-group">
                                            <button id="deactivate-user-btn" onClick={() => controller.deactivateUser()} className="btn plain" style={hideIfNot(canDeactivateUser)}>
                                                <span>{lang.get('teamManagement.deactivateUser.btn')}</span><i className="far fa-user-slash"/>
                                            </button>
                                            <button id="delete-user-btn" onClick={() => controller.deleteUser()} className="btn plain" style={hideIfNot(canDeleteUser)}>
                                                <span>{lang.get('teamManagement.deleteUser.btn')}</span><i className="far fa-trash-alt"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-content">
                                    {/*TODO - commit*/}
                                    <DynamicInput name="input-role"
                                                  placeholder={lang.get('teamManagement.editUser.role')}
                                                  validatedValue={role}
                                                  disabled={!canChangeRole}/>

                                    <DynamicInputCheckbox name="input-isCompanyAdmin"
                                                          label={lang.get('teamManagement.editUser.companyAdmin')}
                                                          validatedValue={isCompanyAdmin}
                                                          disabled={!canChangeIsAdmin}/>

                                    <DynamicInputCheckbox name="input-isOnHoliday"
                                                          label={lang.get('teamManagement.editUser.onHoliday')}
                                                          validatedValue={isOnHoliday}
                                                          disabled={!canChangeIsOnHoliday}/>

                                    <ul className="role-list admin-groups">
                                        <li>
                                            <h2>{lang.get("teamManagement.admin")} ({adminGroups.length})</h2>
                                        </li>
                                        {adminGroups}
                                    </ul>

                                    <ul className="role-list regular-groups">
                                        <li>
                                            <h2>{lang.get("teamManagement.group")} ({groups.length})</h2>
                                            <button className="btn plain add-group" style={hideIfNot(canAddUserToGroup)}
                                                    onClick={() => controller.openGroupSelection()}><i
                                                className="far fa-plus-circle"/></button>
                                        </li>
                                        {groups}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
});

export {EditUserPageController, EditUserPageView};