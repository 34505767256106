import {decorate, observable, action} from 'mobx';
import {axios} from '../../utils/WrappedAxios';

class SolutingArticleStore {

    solutingArticle = {};

    load(soulutingId, articleId) {
        return axios.get(`/api/soluting/${soulutingId}/article/${articleId}`)
            .then((response) => {
                this.solutingArticle = response.data;
            });
    }

}

decorate(SolutingArticleStore, {
    solutingArticle: observable,
    load: action,
});

export default SolutingArticleStore;