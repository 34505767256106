import React from 'react';
import moment from 'moment';
import {lang} from '../utils/Lang';

let groupToIconName = {
    'Survey': 'fa-clipboard-list',
    'General': 'fa-bullhorn',
    'Soluting': 'fa-clipboard-list-check',
    'Feedback': 'fa-comments',
    'TeamManagement': 'fa-users-cog',
    'Profiling': 'fa-user'
};

const NotificationCardBigView = ({title, content, createInstant, group, isRead, goToLink, onClick}) => {
    let isReadClass = '';
    if (isRead === true) isReadClass = 'read';

    let iconName = groupToIconName[group];

    return (
        <div className={`card no-lines notification-card big ${isReadClass}`} onClick={(e) => {
            e.preventDefault();
            goToLink();
        }}>
            <div className="card-body">
                <div className="card-header">
                    <div className="card-header-icon">
                        <i className={`far ${iconName}`}/>
                    </div>
                    <div className="card-header-title two-row">
                        <h2>{title}</h2>
                        <h3>{moment(createInstant).format('MMMM DD, YYYY / HH:mm')}</h3>
                        <div>
                            <button className="btn btnSmall blue" onClick={goToLink}><span>{lang.get('notification.goTo.btn')}</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-content padding-s">
                    <p>{content}</p>
                </div>
            </div>
        </div>
    );
};

export {NotificationCardBigView};