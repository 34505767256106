export default {
    isMobile: () => {
        return window.matchMedia('(max-width: 576px)').matches;
    },
    hasSpaceForNavigationMenu: () => {
        return window.matchMedia('(min-width: 1320px)').matches;
    },
    isDesktop: () => {
        return !this.isMobile();
    }
};