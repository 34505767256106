import {decorate, observable, action} from 'mobx';
import {axios} from '../utils/WrappedAxios';

class CompanyStore {
    companyId = null;
    companyDetails = null;

    constructor(id) {
        this.companyId = id;
    }

    load() {
        if (this.companyId === '') return null;

        return axios.get(`/api/company/${this.companyId}`)
            .then(response => {
                if (response.status === 200) {
                    this.companyId = response.data.companyId;
                    this.companyDetails = response.data.companyDetails;

                    return this
                }
            });
    }

    async closeCompany() {
        return axios.delete(`/api/company/${this.companyId}`);
    }
}

decorate(CompanyStore, {
    companyId: observable,
    companyDetails: observable,
    load: action,
    save: action,
});

export default CompanyStore;