import {lang} from '../page/utils/Lang';

function isValidEmail(email) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
}

function isBlank(str) {
    return (!str || /^\s*$/.test(str));
}

function validateRequired(str) {
    if (!str)
        return lang.get('validators.field.required');
}

function validateNotBlank(str) {
    if (isBlank(str))
        return lang.get('validators.field.notBlank');
}

function validateIsNumber(str) {
    if (isNaN(str))
        return lang.get('validators.field.isNumber');
}

function validateBirthYear(str) {
    let year = Number.parseInt(str, 10);
    let currentYear = new Date().getFullYear();
    if (year < 1900 || year > currentYear) {
        return lang.get('validators.birthYear')  + " " + (currentYear);
    }
}

function validateEmail(email) {
    if (!isValidEmail(email))
        return lang.get('validators.email.invalid2');
}

function validateNotNull(value) {
    if (value === null) {
        return lang.get('validators.field.notNull');
    }
}

export {
    isValidEmail,
    isBlank,
    validateNotBlank,
    validateRequired,
    validateEmail,
    validateIsNumber,
    validateBirthYear,
    validateNotNull
};