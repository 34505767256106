import React, {Component} from 'react';
import {Header, HeaderController} from '../../Header/HeaderView';
import {decorate, observable, action} from 'mobx';
import {observer} from 'mobx-react';
import {Team} from '../../../domain/Team/Team';
import {lang} from '../../../page/utils/Lang';
import {ContentTitle} from '../../components/ContentTitle';
import {InfoBox2, InfoBox2Controller} from '../../components/Inputs/InfoBox2';
import TeamCollection from '../../../domain/Team/TeamCollection';
import {hideIfNot} from '../../../utils/Utils';
import {LicensedAction, Role} from '../../../domain/Role';
import {LicenseLock} from '../../components/LicenseLock';
import UserCollection from "../../../domain/User/UserCollection";

class ViewTeamPageViewController {
    teamName = '';
    parentTeamId = null;
    parentTeamName = '';
    managerId = null;
    managerName = '';
    headerController;
    team = null;
    teamCollection = new TeamCollection();

    teamAdmins = [];
    subTeams = [];
    teamEmployees = [];

    confirmInfoBoxController = new InfoBox2Controller();

    constructor(navigator, userStore, teamId) {
        this.navigator = navigator;
        this.userStore = userStore;
        this.headerController = new HeaderController(navigator, userStore);
        this.team = new Team(teamId);

        this.load();
    }

    async load() {
        let [teamAdmins, teamEmployees, overviewData] = await Promise.all([this.team.getTeamAdmins(), new UserCollection().getCompanyUsers(null, null, this.team.teamId), this.team.getOverview()]);

        this.teamAdmins.clear();
        this.teamAdmins.push(...teamAdmins);

        this.teamEmployees.clear();
        this.teamEmployees.push(...teamEmployees);

        this.subTeams.clear();
        this.subTeams.push(...overviewData.subTeams);

        this.teamName = overviewData.team.name;
        if (overviewData.manager) {
            this.managerName = overviewData.manager.name;
            this.managerId = overviewData.manager.id;
        } else {
            this.managerName = lang.get('teamManagement.none');
        }
        if (overviewData.parentTeam) {
            this.parentTeamId = overviewData.parentTeam.id;
            this.parentTeamName = overviewData.parentTeam.name;
        } else {
            this.parentTeamName = lang.get('teamManagement.none');
        }
    }

    goToEditTeam() {
        this.navigator.goToPage(this.navigator.siteMap.pages.EditTeam, this.team.teamId)
    }

    deleteTeam() {
        this.confirmInfoBoxController.show()
            .then(response => {
                if (response === 'yes') {
                    this.team = new Team(this.team.teamId);
                    this.team.deleteSubTeam(this.team.teamId)
                        .then(() => this.navigator.back());
                }
            });
    }

    goToTeam(teamId) {
        this.navigator.goToPage(this.navigator.siteMap.pages.ViewTeam, teamId);
    }

    goToUser(userId) {
        this.navigator.goToPage(this.navigator.siteMap.pages.ViewUser, userId);
    }
}

decorate(ViewTeamPageViewController, {
    teamName: observable,
    parentTeamId: observable,
    parentTeamName: observable,
    managerId: observable,
    managerName: observable,
    load: action,
    team: observable,
    teamAdmins: observable.shallow,
    subTeams: observable.shallow,
    teamEmployees: observable.shallow
});


const ViewTeamPageView = observer(class ViewTeamPageView extends Component {
    render() {
        let controller = this.props.controller;
        let teamName = controller.teamName;
        let parentTeamName = controller.parentTeamName;
        let managerName = controller.managerName;

        let teamAdmins = <li>{lang.get('teamManagement.none')}</li>;
        if (controller.teamAdmins.length > 0) {
            teamAdmins = controller.teamAdmins.map(admin =>
                <li key={admin.id}>
                    <button className="btn plain inherit" onClick={() => controller.goToUser(admin.id)}>
                        <span>  {admin.name}</span><i className="far fa-eye"/></button>
                </li>);
        }

        let teamEmployees = <li>{lang.get('teamManagement.none')}</li>;
        if (controller.teamEmployees.length > 0) {
            teamEmployees = controller.teamEmployees.map(employee =>
                <li key={employee.id} className={`user-in-team-item`}>
                    <span className="icons">
                        <i className={'far fa-tree-palm selected'} style={hideIfNot(employee.isOnHoliday)}/>
                        <i className={'far fa-user-slash selected'} style={hideIfNot(employee.isDeactivated)}/>
                        <i className={'far fa-bed selected'} style={hideIfNot(employee.isInactive)}/>
                    </span>
                    <button className="btn plain inherit" onClick={() => controller.goToUser(employee.id)}>
                        <span> {employee.name}</span><i className="far fa-eye"/>
                    </button>
                </li>);
        }

        let subTeams = <li>{lang.get('teamManagement.none')}</li>;
        if (controller.subTeams.length > 0) {
            subTeams = controller.subTeams.map(subTeam =>
                <li key={subTeam.id}>
                    <button className="btn plain inherit" onClick={() => controller.goToTeam(subTeam.id)}>
                        <span>{subTeam.name}</span><i className="far fa-eye"/></button>
                </li>);
        }

        let goToEdit = () => controller.goToEditTeam();
        let onDelete = () => controller.deleteTeam();

        let canEditTeam = controller.navigator.canGoTo(controller.navigator.siteMap.pages.EditTeam);
        let canDeleteTeam = controller.userStore.canDoAction(Role.GroupDelete);

        let isLicenseLocked = !controller.userStore.isLicensedFor(LicensedAction.TeamManagementEdit);

        return (
            <main className="fadeIn">
                <InfoBox2 hasYes={true} hasNo={true}
                          title={lang.get('teamManagement.infoBox.delete.team.title')}
                          icon={'fas fa-trash-alt'}
                          id={'deleteConfirmationInfoBox'}
                          controller={controller.confirmInfoBoxController}>
                    <p>
                        {lang.get('teamManagement.infoBox.delete.team.description')}
                    </p>
                </InfoBox2>
                <Header controller={controller.headerController}/>
                <div className="appContent">
                    <div className="appContent-body view-team-page">
                        <ContentTitle title={lang.get('appheader.teamMgmt.groups.title')}
                                      subtitle={lang.get('appheader.teamMgmt.groups.subtitle')}/>
                        <LicenseLock isLocked={isLicenseLocked} showText={true} navigator={controller.navigator}>
                            <div className="card license-lock-opacity">
                                <div className="card-body">
                                    <div className="card-header small">
                                        <div className="card-header-icon">
                                            <i className="far fa-draw-circle"/>
                                        </div>
                                        <div className="card-header-title">
                                            <h2>{teamName}</h2>
                                            <div className="btn-group">
                                                <button className="btn plain" style={hideIfNot(canDeleteTeam)}
                                                        onClick={onDelete}><span>{lang.get('teamManagement.deleteTeamBtn')}</span><i
                                                    className="far fa-trash-alt"/></button>
                                                <button className="btn plain" style={hideIfNot(canEditTeam)}
                                                        onClick={goToEdit}><span>{lang.get('teamManagement.editTeamBtn')}</span><i
                                                    className="far fa-edit"/></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-content">
                                        <table className="table">
                                            <tbody>
                                            <tr>
                                                <td>{lang.get('teamManagement.group.parent')}</td>
                                                <td className="txtRight">{parentTeamName}</td>
                                            </tr>
                                            <tr>
                                                <td>{lang.get('teamManagement.manager.title')}</td>
                                                <td className="txtRight">{managerName}</td>
                                            </tr>
                                            <tr>
                                                <td>{lang.get('teamManagement.admins.title')}</td>
                                                <td className="txtRight">
                                                    <ul className="simple-list">
                                                        {teamAdmins}
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{lang.get('teamManagement.employees.title')}</td>
                                                <td className="txtRight">
                                                    <ul className="simple-list">
                                                        {teamEmployees}
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{lang.get('teamManagement.group.subGroups')}</td>
                                                <td className="txtRight">
                                                    <ul className="simple-list">
                                                        {subTeams}
                                                    </ul>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </LicenseLock>
                    </div>
                </div>
            </main>
        );
    }
});

export {ViewTeamPageView, ViewTeamPageViewController};