import React, {Component} from 'react';
import {decorate, observable, action} from 'mobx';
import {observer} from 'mobx-react';
import {Header, HeaderController} from '../../Header/HeaderView';
import {lang} from '../../utils/Lang';
import {ContentTitle} from '../../components/ContentTitle';
import {Role} from '../../../domain/Role';
import {hideIfNot} from '../../../utils/Utils';
import {CompanyUser} from '../../../domain/User/CompanyUser';
import {InfoBox2, InfoBox2Controller} from '../../components/Inputs/InfoBox2';
import {notificationManager} from '../../components/Notifications';
import moment from 'moment';
import InviteCollection from "../../../domain/Invite/InviteCollection";
import iconAddUser from "../../../assets/img/icons-tm-add-user.svg";
import {InvitationBox, InvitationBoxController} from "../../components/InvitationBox";

class ViewUserPageController {
    navigator;
    userStore;
    headerController;
    companyUser;
    lastFinishedSurvey = null;
    lastSurveyReminderSent = null;
    isInactive = false;
    isRemoved = false;
    isDeactivated = false;
    userName = '';
    profilingToken = null;
    roleName = '';
    teams = []; // id, name, count
    adminTeams = []; // id, name, count
    isCompanyAdmin = false;
    invitationLink = '';
    deleteUserConfirmationController = new InfoBox2Controller();
    deactivateUserConfirmationController = new InfoBox2Controller();
    inviteUsersInfoBoxController = new InfoBox2Controller();

    constructor(navigator, userStore, companyUserId) {
        this.navigator = navigator;
        this.userStore = userStore;
        this.headerController = new HeaderController(navigator, userStore);
        this.companyUser = new CompanyUser(companyUserId);

        this.load();
    }

    async load() {
        let [profile, teams, adminTeams, role, isCompanyAdmin] = await Promise.all([
            this.companyUser.getProfile(),
            this.companyUser.getTeams(),
            this.companyUser.getAdminTeams(),
            this.companyUser.getRole(),
            this.companyUser.getIsCompanyAdmin()]);

        this.userName = profile.email;
        if (profile.details)
            this.userName = profile.details.firstName + ' ' + profile.details.lastName;
        if (profile.profile)
            this.profilingToken = profile.profile.profilingToken;
        else
            this.profilingToken = null;

        this.isCompanyAdmin = isCompanyAdmin;
        this.roleName = role;
        this.teams.clear();
        this.teams.push(...teams);
        this.adminTeams.clear();
        this.adminTeams.push(...adminTeams);
        this.lastFinishedSurvey = profile.lastFinishedSurvey;
        this.lastSurveyReminderSent = profile.lastSurveyReminderSent;
        this.isInactive = profile.isInactive;
        this.isRemoved = profile.isRemoved;
        this.isDeactivated = profile.isDeactivated;
        this.headerController.manualTitle = this.userName;
    }

    goToEditUser() {
        this.navigator.goToPage(this.navigator.siteMap.pages.EditUser, this.companyUser.id);
    }

    goToProfilingResult() {
        this.navigator.goToPage(this.navigator.siteMap.pages.ProfilingResult, this.profilingToken)
    }

    goToTeam(teamId) {
        this.navigator.goToPage(this.navigator.siteMap.pages.ViewTeam, teamId);
    }

    async sendReminder() {
        if(this.lastSurveyReminderSent && moment.duration(moment().diff(this.lastSurveyReminderSent)).asDays() < 7) {
            notificationManager.warn(lang.get('teamManagement.viewUser.info.cannotResendReminder'));
            return
        }
        await this.companyUser.sendReminder()
        notificationManager.info(lang.get('teamManagement.viewUser.info.reminderSent'));
        this.load()
    }

    async deleteUser() {
        let confirm = await this.deleteUserConfirmationController.show();
        if (confirm !== 'yes') return;
        await this.companyUser.setIsRemoved(true);
        this.navigator.goToPage(this.navigator.siteMap.pages.UserList);
    }

    async deactivateUser() {
        let confirm = await this.deactivateUserConfirmationController.show();
        if (confirm !== 'yes') return;
        await this.companyUser.setIsDeactivated(true);
        this.navigator.goToPage(this.navigator.siteMap.pages.UserList);
    }

    async inviteUser() {
        let invite = await new InviteCollection().createInvite();
        this.invitationLink = `${window.location.origin.toString()}/#/invite/` + invite.id;
        this.inviteUsersInfoBoxController.show();
    }
}

decorate(ViewUserPageController, {
    profilingToken: observable,
    roleName: observable,
    editRoleValue: observable,
    teams: observable.shallow,
    adminTeams: observable.shallow,
    load: action,
    isCompanyAdmin: observable,
    lastFinishedSurvey: observable,
    lastSurveyReminderSent: observable,
    isInactive: observable,
    isRemoved: observable,
    isDeactivated: observable,
    sendReminder: action,
    invitationLink: observable,
});

const ViewUserPageView = observer(class ViewUserPageView extends Component {
    render() {
        let controller = this.props.controller;

        let userName = controller.userName;
        let profilingResult = 'N/A';
        if (controller.profilingToken) profilingResult = lang.get(`profilingResult.${controller.profilingToken}.title`);
        let isRemoved = controller.isRemoved;
        let isDeactivated = controller.isDeactivated;
        let roleName = controller.roleName;
        let isCompanyAdmin = controller.isCompanyAdmin;
        let canSendReminder = controller.isInactive && controller.userStore.canDoAction(Role.SendSurveyReminder) && !controller.isRemoved;

        let lastFinishedSurvey = controller.lastFinishedSurvey ? moment(controller.lastFinishedSurvey).format('MMMM DD, YYYY / HH:mm') : 'n/a';
        let lastSurveyReminderSent = controller.lastSurveyReminderSent ? moment(controller.lastSurveyReminderSent).format('MMMM DD, YYYY / HH:mm') : 'n/a';

        let adminGroups = controller.adminTeams.map(team =>
            <li key={team.id}>
                <button className="btn plain inherit preview-admin-groups" onClick={() => controller.goToTeam(team.id)}>
                    <span>{team.name}</span><i className="far fa-eye"/></button>
            </li>);


        let groups = controller.teams.map(team =>
            <li key={team.id}>
                <button className="btn plain inherit preview-groups" onClick={() => controller.goToTeam(team.id)}>
                    <span>{team.name}</span><i className="far fa-eye"/></button>
            </li>);


        let goToEdit = () => controller.goToEditUser();
        let onDelete = () => controller.deleteUser();
        let onDeactivate = () => controller.deactivateUser();
        let goToProfilingResult = () => controller.goToProfilingResult();
        let inviteUser = () => controller.inviteUser();

        let canGoToEditUser = controller.navigator.canGoTo(controller.navigator.siteMap.pages.EditUser) && !controller.isRemoved;
        let canDeleteUser = controller.userStore.canDoAction(Role.UserChangeIsRemoved) && !controller.isRemoved;
        let canDeactivateUser = controller.userStore.canDoAction(Role.UserChangeIsDeactivated) && !controller.isRemoved && !controller.isDeactivated;
        let canInviteUsers = controller.userStore.canDoAction(Role.InviteUsers) && (controller.isRemoved || controller.isDeactivated);

        return (
            <main className="fadeIn">
                <Header controller={controller.headerController}/>

                <InfoBox2 id="deleteUserInfoBox"
                          controller={controller.deleteUserConfirmationController}
                          icon={'far fa-trash-alt'}
                          hasYes={true} hasNo={true} title={lang.get('teamManagement.infoBox.removeUserFromCompany.title')}>
                    <p>{lang.get('teamManagement.infoBox.removeUserFromCompany.content')}</p>
                </InfoBox2>
                <InfoBox2 id="deactivateUserInfoBox"
                          controller={controller.deactivateUserConfirmationController}
                          icon={'far fa-user-slash'}
                          hasYes={true} hasNo={true} title={lang.get('teamManagement.infoBox.deactivateUser.title')}>
                    <p>{lang.get('teamManagement.infoBox.deactivateUser.content')}</p>
                </InfoBox2>
                <InfoBox2 hasYes={true}
                          title={lang.get('teamManagement.infoBox.employees.invite.title')}
                          icon={iconAddUser}
                          id={'inviteInfoBox'}
                          controller={controller.inviteUsersInfoBoxController}>
                    <InvitationBox controller={new InvitationBoxController(controller.invitationLink)}/>
                </InfoBox2>

                <div className="appContent">
                    <div className="appContent-body view-user-page">
                        <ContentTitle title={lang.get('appheader.teamMgmt.employees.title')}
                                      subtitle={lang.get('appheader.teamMgmt.employees.subtitle')}/>
                        <div className="card">
                            <div className="card-body">
                                <div className="card-header small">
                                    <div className="card-header-icon">
                                        <i className="far fa-address-book"/>
                                    </div>
                                    <div className="card-header-title">
                                        <h2>{userName}</h2>
                                        <div className="btn-group">
                                            <button id="btn-deactivate-user" className="btn plain" style={hideIfNot(canDeactivateUser)}
                                                    onClick={onDeactivate}><span>{lang.get('teamManagement.deactivateUser.btn')}</span><i
                                                className="far fa-user-slash"/></button>
                                            <button id="btn-remove-user" className="btn plain" style={hideIfNot(canDeleteUser)}
                                                    onClick={onDelete}><span>{lang.get('teamManagement.viewUser.delete.btn')}</span><i
                                                className="far fa-trash-alt"/></button>
                                            <button className="btn plain" style={hideIfNot(canGoToEditUser)}
                                                    onClick={goToEdit}><span>{lang.get('teamManagement.viewUser.edit.btn')}</span><i
                                                className="far fa-edit"/></button>
                                            <button className="btn plain" style={hideIfNot(canInviteUsers)}
                                                    onClick={inviteUser}><span>{lang.get('teamManagement.addBtn')}</span><i
                                                className="far fa-user-plus"/></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-content">
                                    <table className="table">
                                        <tbody>
                                        <tr>
                                            <td>{lang.get('teamManagement.viewUser.isCompanyAdmin')}</td>
                                            <td className="txtRight">{(isCompanyAdmin ? lang.get('general.yes') : lang.get('general.no'))}</td>
                                        </tr>
                                        <tr>
                                            <td>{lang.get('teamManagement.subgroup.profiling')}</td>
                                            <td className="txtRight">
                                                <button className="btn plain inherit preview-profiling" onClick={goToProfilingResult}>
                                                    <span>{profilingResult}</span><i className="far fa-eye"/></button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{lang.get('teamManagement.role.title')}</td>
                                            <td className="txtRight">{roleName}</td>
                                        </tr>
                                        <tr>
                                            <td>{lang.get('teamManagement.isRemoved.title')}</td>
                                            <td className="txtRight">{(isRemoved ? lang.get('general.yes') : lang.get('general.no'))}</td>
                                        </tr>
                                        <tr>
                                            <td>{lang.get("teamManagement.isDeactivated.title")}</td>
                                            <td className="txtRight">{(isDeactivated ? lang.get('general.yes') : lang.get('general.no'))}</td>
                                        </tr>
                                        <tr>
                                            <td>{lang.get('teamManagement.admin')} ({adminGroups.length})</td>
                                            <td className="txtRight">
                                                <ul className="simple-list">
                                                    {adminGroups}
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{lang.get('teamManagement.group')} ({groups.length})</td>
                                            <td className="txtRight">
                                                <ul className="simple-list">
                                                    {groups}
                                                </ul>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-header small">
                                    <div className="card-header-icon">
                                        <i className="far fa-address-book"/>
                                    </div>
                                    <div className="card-header-title">
                                        <h2>{lang.get('teamManagement.viewUser.activity')}</h2>
                                        <button id="send-survey-reminder-user-btn" onClick={() => controller.sendReminder()} className="btn plain" style={hideIfNot(canSendReminder)}>
                                            <span>{lang.get('teamManagement.viewUser.btn.sendReminder')}</span><i className="far fa-envelope"/>
                                        </button>
                                    </div>
                                </div>
                                <div className="card-content">
                                    <table className="table">
                                        <tbody>
                                        <tr>
                                            <td>{lang.get('teamManagement.viewUser.lastFinishedSurvey')}</td>
                                            <td id="last-finished-survey-time" className="txtRight">{lastFinishedSurvey}</td>
                                        </tr>
                                        <tr>
                                            <td>{lang.get('teamManagement.viewUser.lastReminderSent')}</td>
                                            <td id="last-survey-reminder-time" className="txtRight">{lastSurveyReminderSent}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
});

export {ViewUserPageController, ViewUserPageView};